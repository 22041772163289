import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/role.css';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

function QuantityAlertReportBody() {
    // State management
    const [stokeData, setStokeData] = useState({});
    const [searchedStokeReport, setSearchedStokeReport] = useState(null);
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [error, setError] = useState('')
    const [warehouseData, setWarehouseData] = useState([]);
    const [warehouse, setWarehouse] = useState(['all'])
    const ProductIcon = 'https://cdn0.iconfinder.com/data/icons/creative-concept-1/128/PACKAGING_DESIGN-512.png';

    //COMBINE ALL DATA FETCHING TYPE INTO ONE STATE
    const combinedProductData = Array.isArray(searchedStokeReport) && searchedStokeReport.length > 0
        ? searchedStokeReport
        : Array.isArray(stokeData) && stokeData.length > 0
            ? stokeData
            : [];

    useEffect(() => {
        const fetchReportData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/findStokeReport/${warehouse}`);
                if (response.data && response.data.products && Array.isArray(response.data.products)) {
                    setStokeData(response.data.products);
                    console.log(response.data.products)
                } else {
                    console.error('Unexpected response format:', response.data);
                    setStokeData([]);
                }
            } catch (err) {
                console.error('Error fetching report data:', err);
                setError('Failed to fetch report data');
            } finally {
                setLoading(false);
            }
        };
        fetchReportData();
    }, [warehouse]);

    const fetchData = async (url, setter) => {
        setLoading(true);
        try {
            const { data } = await axios.get(url);
            setter(data);
        } catch (error) {
            console.error(`${url} fetch error:`, error);
            setter([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(`${process.env.REACT_APP_BASE_URL}/api/fetchWarehouses`, setWarehouseData);
    }, []);

    // Handle search input change
    const handleFindUser = async (e) => {
        const { value } = e.target;
        setKeyword(value); // Update the keyword state
        if (value.length > 0) {
            await handleSubmit(); // Call your search function
        } else {
            setSearchedStokeReport([]); // Clear results if input is empty
        }
    };

    // Handle search form submission
    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/findStokeReportByCode`, {
                params: { name: keyword }
            });
            console.log("find by id ", response.data);
            if (Array.isArray(response.data)) {
                setSearchedStokeReport(response.data);
            } else if (response.data && response.data.products) {
                setSearchedStokeReport(response.data.products); // Adjust based on your actual response structure
            } else {
                setSearchedStokeReport([]); // Fallback to an empty array if data is unexpected
            }
        } catch (error) {
            console.error('Find customer error:', error);
            setSearchedStokeReport([]); // Clear the search results on error
        } finally {
            setLoading(false);
        }
    };

    // Calculate price range (min and max) for products with variations
    const getPriceRange = (product) => {
        if (product.variationValues) {
            // Get prices from variationValues
            const prices = Object.values(product.variationValues).map(variation => Number(variation.productPrice)).filter(price => !isNaN(price));

            if (prices.length > 0) {
                const minPrice = Math.min(...prices);
                const maxPrice = Math.max(...prices);

                // If all variation prices are the same, return the single price
                if (minPrice === maxPrice) {
                    return `${minPrice}`;
                } else {
                    return `${minPrice} - ${maxPrice}`;
                }
            }
        }
        // Fallback to single product price if no variations are available
        const singlePrice = Number(product.productPrice);
        if (!isNaN(singlePrice) && singlePrice > 0) {
            return `${singlePrice}`;
        }
        return 'Price not available'; // Default case when no price is found
    };
    // Calculate price range (min and max) for products with variations
    const getQty = (product) => {
        if (product.variationValues) {
            const qty = Object.values(product.variationValues).map(variation => Number(variation.productQty)).filter(qty => !isNaN(qty));
            return qty.length > 0 ? qty.reduce((total, current) => total + current, 0) : 0;
        }
        const singleProductQty = Number(product.productQty);
        return !isNaN(singleProductQty) && singleProductQty > 0 ? singleProductQty : 0;
    };

    return (
        <div className='relative bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] min-h-screen p-5'>
            <div>
                <div className="m-0 flex justify-center">
                    {/* Warehouse field */}
                    <div className="mt-5 text-center">
                        <label className="block text-sm font-medium leading-6 text-gray-900 mb-2 text-left">Warehouse</label>
                        <select
                            value={warehouse}
                            onChange={(e) => setWarehouse(e.target.value)}
                            className="block w-[400px] mx-auto rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-200 focus:outline-none sm:text-sm sm:leading-6"
                        >
                            <option value="all">All warehouse</option>
                            {warehouseData.map((wh) => (
                                <option key={wh.name} value={wh.name}>
                                    {wh.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="m-6 flex justify-left">
                    <h1 className="text-lightgray-300 m-0 p-0 text-2xl">Quantity Alert Report</h1>
                </div>

                <div className='mt-5 mb-2 ml-[24px]'>
                    <form onChange={handleSubmit} className="flex items-center">
                        <input
                            onChange={handleFindUser}
                            name='keyword'
                            type="text"
                            placeholder="Search..."
                            className="searchBox w-80 pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                            value={keyword}
                        />
                    </form>
                </div>

                {loading ? (
                    <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                        <LinearProgress />
                    </Box>
                ) : combinedProductData.length > 0 ? (
                    <div className="overflow-x-auto p-6">
                        <table className="min-w-full bg-white border border-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product</th>
                                    <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                    <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Code</th>
                                    <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Brand</th>
                                    <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                                    <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">In Stock</th>
                                    <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ">Quantity alert</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {combinedProductData.map((p) => (
                                    <tr key={p._id}>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <img
                                                src={p.image ? p.image : ProductIcon}
                                                alt={p.name}
                                                className="w-10 h-10 object-cover rounded-full"
                                            />
                                        </td>
                                        <td className="px-7 py-5 text-left whitespace-nowrap text-m text-gray-900">{p.name}</td>
                                        <td className="px-7 py-5 text-left whitespace-nowrap text-m text-gray-900">{p.code}</td>
                                        <td className="px-4 py-5 text-left whitespace-nowrap text-m text-gray-900">{p.brand}</td>
                                        <td className="px-7 py-5 text-left whitespace-nowrap text-m text-gray-900">{getPriceRange(p)}</td>
                                        <td className="px-7 py-5 text-left whitespace-nowrap text-m text-gray-900 flex">
                                            <p
                                                className={`mr-2 rounded-[5px] text-center p-[6px] ${p.productQty && p.productQty < p.stockAlert ? 'bg-red-100 text-red-500' : 'bg-green-100 text-green-500'}`}
                                            >
                                                {p.productQty ? p.productQty : getQty(p)}
                                            </p>
                                            <p className="rounded-[5px] text-center p-[6px] bg-green-100 text-blue-500">
                                                {p.saleUnit}
                                            </p>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-left text-sm text-gray-900 text-right">
                                            <p
                                                className={`mr-2 rounded-[5px] text-center p-[6px] ${p.productQty && p.productQty < p.stockAlert ? 'bg-red-100 text-red-500' : 'bg-green-100 text-green-500'}`}
                                            >
                                                {p.stockAlert}
                                            </p>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) :
                    <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                        <LinearProgress />
                    </Box>}
                <div>
                    {error && <p className="text-green-500 mt-5 text-center">{error}</p>}
                </div>
            </div>
        </div>
    );
}
export default QuantityAlertReportBody;
