import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/sidebar.css';
import { HomeIcon, CubeIcon, ShoppingBagIcon, TagIcon, CogIcon, DocumentChartBarIcon, ReceiptRefundIcon, ChartPieIcon, DocumentTextIcon, UserGroupIcon, UsersIcon, CubeTransparentIcon, FolderIcon, UserIcon, TruckIcon, BuildingStorefrontIcon, ArrowRightOnRectangleIcon, ShoppingCartIcon, CurrencyDollarIcon, AdjustmentsVerticalIcon, GlobeAltIcon, ShieldCheckIcon } from '@heroicons/react/24/outline';
import ArrowIcon from '../../img/right-arrow (3).png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../../context/UserContext';

const Sidebar = () => {
  const { userData } = useContext(UserContext);
  const [isPeopleDropdownOpen, setPeopleDropdownOpen] = useState(false);
  const [isProductDropdownOpen, setProductDropdownOpen] = useState(false);
  const [isSaleDropdownOpen, setSaleDropdownOpen] = useState(false)
  const [isPurchaseDropdownOpen, setPurchaseDropdownOpen] = useState(false)
  const [isExpensesDropdownOpen, setExpensesDropdownOpen] = useState(false)
  const [isSettingsDropdownOpen, setSettingsDropdown] = useState(false)
  const [searchTerm, setSearchTerm] = useState("");
  const [permissionData, setPermissionData] = useState({
    manageRoles: false,
    manageProducts: false,
    manageUser: false,
    manageWarehouse: false,
    manageBrands: false,
    manageUnits: false,
    manageSuppliers: false,
    manageExpenseCategories: false,
    manageCurrency: false,
    manageProductCategories: false,
    manageCustomers: false,
    manageExpenses: false,
  });

  useEffect(() => {
    if (userData) {
      // Update permissionData from the decrypted userData
      setPermissionData({
        manageRoles: userData._doc?.manageRoles || false,
        manageProducts: userData._doc?.manageProducts || false,
        manageUser: userData._doc?.manageUser || false,
        manageWarehouse: userData._doc?.manageWarehouse || false,
        manageBrands: userData._doc?.manageBrands || false,
        manageUnits: userData._doc?.manageUnits || false,
        manageSuppliers: userData._doc?.manageSuppliers || false,
        manageExpenseCategories: userData._doc?.manageExpenseCategories || false,
        manageCurrency: userData._doc?.manageCurrency || false,
        manageProductCategories: userData._doc?.manageUnits || false,
        manageCustomers: userData._doc?.manageCustomers || false,
        manageExpenses: userData._doc?.manageExpenses || false,
      });
    }
  }, [userData]);

  const PeopletoggleDropdown = () => {
    setPeopleDropdownOpen(!isPeopleDropdownOpen);
  };
  const ProductToggleDropdown = () => {
    setProductDropdownOpen(!isProductDropdownOpen);
  }
  const SaletoggleDropdown = () => {
    setSaleDropdownOpen(!isSaleDropdownOpen);
  }
  const PurchasetoggleDropdown = () => {
    setPurchaseDropdownOpen(!isPurchaseDropdownOpen);
  }

  const ExpensestoggleDropdown = () => {
    setExpensesDropdownOpen(!isExpensesDropdownOpen)
  }

  const SettingsDropdown = () => {
    setSettingsDropdown(!isSettingsDropdownOpen)
  }

  const handleSearch = () => {
    // Get all tabs by their id attribute
    const allTabs = document.querySelectorAll('li[id]');

    allTabs.forEach((tab) => {
      if (tab.id.toLowerCase().includes(searchTerm.toLowerCase())) {
        tab.style.display = ''; // Show tab
      } else {
        tab.style.display = 'none'; // Hide tab
      }
    });
  };

  return (
    <div className="sidebar">
      <h2 className='mb-5 text-xl text-left text-[#333]"'>Menu</h2>
      <ul className='list-none p-0 pb-20'>
        <li className="flex items-center space-x-2 mb-10">
          <div className="w-full max-w-md relative">
            <input
              type="text"
              placeholder="Search ... "
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                handleSearch();
              }}
              className="searchBox w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
            />
            <button className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-400">
              <svg
                className="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M9 3a6 6 0 100 12A6 6 0 009 3zm0-1a7 7 0 110 14A7 7 0 019 2z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M12.9 12.9a1 1 0 011.41 0l3 3a1 1 0 01-1.41 1.41l-3-3a1 1 0 010-1.41z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </li>
        {/* {filteredTabs.map((tab) => ( */}
        <li id="dashboard" className="flex items-center space-x-2">
          <HomeIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
          <Link to="/dashboard" className="text-black hover:text-[#2a9d34]">Dashboard</Link>
        </li>
        {/* ))} */}

        {/* {filteredTabs.map((tab) => ( */}
        <li id="products" className="dropdown flex items-center space-x-2">
          <ShoppingBagIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
          <button onClick={ProductToggleDropdown} className="dropdown-toggle text-gray hover:text-gray-700 flex items-center">
            <span className="text-black hover:text-gray-700">Products</span>
            <span className={`ml-auto transition-transform duration-500 ${isProductDropdownOpen ? 'rotate-90' : ''}`}>
              <img src={ArrowIcon} style={{ marginLeft: "20px" }} className="h-3 w-3 text-gray-500" alt="arrow icon" aria-hidden="true" />
            </span>
          </button>
        </li>
        {/* ))} */}
        {/**Drop down links for products sub catergories */}
        <ul className={`dropdown-menu ${isProductDropdownOpen ? 'open' : ''}`}>

          {permissionData.manageProducts === true && (
            <li className="flex items-center space-x-2">
              <ShoppingBagIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
              <Link to="/viewProducts" className="text-black hover:text-gray-700">Products</Link>
            </li>
          )}

          {permissionData.manageUnits === true && (
            <li className="flex items-center space-x-2">
              <CubeIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
              <Link to="/viewBaseUnit" className="text-black hover:text-gray-700">Base units</Link>
            </li>
          )}

          {permissionData.manageUnits === true && (
            <li className="flex items-center space-x-2">
              <CubeTransparentIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
              <Link to="/viewUnit" className="text-black hover:text-gray-700">Units</Link>
            </li>
          )}

          {permissionData.manageProducts === true && (
            <li className="flex items-center space-x-2">
              < AdjustmentsVerticalIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
              <Link to="/viewVariation" className="text-black hover:text-gray-700">Variation</Link>
            </li>
          )}

          {permissionData.manageBrands === true && (
            <li className="flex items-center space-x-2">
              <TagIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
              <Link to="/viewBrands" className="text-black hover:text-gray-700">Brands</Link>
            </li>
          )}

          {permissionData.manageProductCategories === true && (
            <li className="flex items-center space-x-2">
              <FolderIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
              <Link to="/viewCategory" className="text-black hover:text-gray-700">Category</Link>
            </li>
          )}

          {permissionData.manageProducts === true && (
            <li className="flex items-center space-x-2">
              <FontAwesomeIcon icon={faBarcode} className="ml-[2px] h-5 w-5 text-gray-500" />
              <Link to="/barcodePrint" className="text-black hover:text-gray-700">Barcode Print</Link>
            </li>
          )}
        </ul>

        <li id='users' className="dropdown flex items-center space-x-2">
          <UserGroupIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
          <button onClick={PeopletoggleDropdown} className="dropdown-toggle text-gray hover:text-gray-700 ">
            <span className="text-black hover:text-gray-700">People</span>
            <span className={`ml-auto transition-transform duration-500 ${isPeopleDropdownOpen ? 'rotate-90' : ''}`}>
              <img src={ArrowIcon} style={{ marginLeft: "20px" }} className="h-3 w-3 text-gray-500" alt="arrow icon" aria-hidden="true" />
            </span>
          </button>
        </li>

        {/**Drop down links for people sub categories */}
        <ul className={`dropdown-menu ${isPeopleDropdownOpen ? 'open' : ''}`}>
          {permissionData.manageCustomers === true && (
            <li className="flex items-center space-x-2">
              <UsersIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
              <Link to="/viewCustomers" className="text-black hover:text-gray-700">Customers</Link>
            </li>
          )}

          {permissionData.manageUser === true && (
            <li className="flex items-center space-x-2">
              <UserIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
              <Link to="/users" className="text-black hover:text-gray-700">Users</Link>
            </li>
          )}

          {permissionData.manageSuppliers === true && (
            <li className="flex items-center space-x-2">
              <TruckIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
              <Link to="/viewSuplier" className="text-black hover:text-gray-700">Suppliers</Link>
            </li>
          )}
        </ul>
        
        <li className="flex items-center space-x-2">
          <BuildingStorefrontIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
          <Link to="/viewWarehouse" className="text-black hover:text-[#2a9d34]">Warehouse</Link>
        </li>

        <li className="flex items-center space-x-2">
          <ArrowRightOnRectangleIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
          <Link to="/viewTransfer" className="text-black hover:text-gray-700">Transfer</Link>
        </li> 

        {/* SALE*/}
        <li id='sale' className="dropdown flex items-center space-x-2">
          <ShoppingCartIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
          <button onClick={SaletoggleDropdown} className="dropdown-toggle text-gray hover:text-gray-700 ">
            <span className="text-black hover:text-gray-700">Sale</span>
            <span className={`ml-auto transition-transform duration-500 ? ${isSaleDropdownOpen ? 'rotate-90' : ''}`}>
              <img src={ArrowIcon} style={{ marginLeft: "20px" }} className="h-3 w-3 text-gray-500" alt="arrow icon" aria-hidden="true" />
            </span>
          </button>
        </li>

        {/**Drop down links for sale sub categories */}
        <ul className={`dropdown-menu ${isSaleDropdownOpen ? 'open' : ''}`}>
          {permissionData.manageCustomers && (
            <li className="flex items-center space-x-2">
              <ShoppingCartIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
              <Link to="/ViewSale" className="text-black hover:text-gray-700">Sale</Link>
            </li>
          )}
          {permissionData.manageUser && (
            <li className="flex items-center space-x-2">
              < ArrowRightOnRectangleIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
              <Link to="/ViewSaleReturns" className="text-black hover:text-gray-700">Returns</Link>
            </li>
          )}
        </ul>

        {/* PURCHASE */}
        <li id='purchase' className="dropdown flex items-center space-x-2">
          <ShoppingCartIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
          <button onClick={PurchasetoggleDropdown} className="dropdown-toggle text-gray hover:text-gray-700 ">
            <span className="text-black hover:text-gray-700">Purchase</span>
            <span className={`ml-auto transition-transform duration-500 ? ${isPurchaseDropdownOpen ? 'rotate-90' : ''}`}>
              <img src={ArrowIcon} style={{ marginLeft: "20px" }} className="h-3 w-3 text-gray-500" alt="arrow icon" aria-hidden="true" />
            </span>
          </button>
        </li>

        {/**Drop down links for sale sub categories */}
        <ul className={`dropdown-menu ${isPurchaseDropdownOpen ? 'open' : ''}`}>
          {permissionData.manageCustomers && (
            <li className="flex items-center space-x-2">
              <ShoppingCartIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
              <Link to="/ViewPurchase" className="text-black hover:text-gray-700">Purchase</Link>
            </li>
          )}
          {permissionData.manageUser && (
            <li className="flex items-center space-x-2">
              < ArrowRightOnRectangleIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
              <Link to="/ViewPurchaseReturns" className="text-black hover:text-gray-700">Returns</Link>
            </li>
          )}
        </ul>

        {/* QUOTATION */}
        <li id='quotation' className="dropdown flex items-center space-x-2">
          <DocumentTextIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
          <Link to={'/ViewQuatation'} className="dropdown-toggle text-gray hover:text-gray-700 ">
            <span className="text-black hover:text-gray-700">Quotation</span>
          </Link>
        </li>


        {/*Currency */}
        <li id='currencies' className="flex items-center space-x-2">
          <CurrencyDollarIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
          <Link to="/ViewCurrency" className="text-black hover:text-gray-700">Currencies</Link>
        </li>

        {/*EXPENSES*/}
        <li id='expenses' className="dropdown flex items-center space-x-2">
          <ReceiptRefundIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
          <button onClick={ExpensestoggleDropdown} className="dropdown-toggle text-gray hover:text-gray-700 ">
            <span className="text-black hover:text-gray-700">Expenses</span>
            <span className={`ml-auto transition-transform duration-500 ${isExpensesDropdownOpen ? 'rotate-90' : ''}`}>
              <img src={ArrowIcon} style={{ marginLeft: "20px" }} className="h-3 w-3 text-gray-500" alt="arrow icon" aria-hidden="true" />
            </span>
          </button>
        </li>

        {/* Drop down links for expense sub-categories */}
        <ul className={`dropdown-menu ${isExpensesDropdownOpen ? 'open' : ''}`}>
          {permissionData.manageExpenses && (
            <li className="flex items-center space-x-2">
              <ReceiptRefundIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
              <Link to="/ViewExpenses" className="text-black hover:text-gray-700">Expenses</Link>
            </li>
          )}
          {permissionData.manageExpenseCategories && (
            <li className="flex items-center space-x-2">
              <ChartPieIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
              <Link to="/ViewExpensesCategory" className="text-black hover:text-gray-700">Exp Category</Link>
            </li>
          )}
        </ul>

        {permissionData.manageRoles === true && (
          <li id='roles' className="flex items-center space-x-2">
            <ShieldCheckIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
            <Link to="/ViewroleAndPermissions" className="text-black hover:text-gray-700">Roles/Permissions</Link>
          </li>
        )}
        {permissionData.manageRoles === true && (
          <li id='reports' className="flex items-center space-x-2">
            <DocumentChartBarIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
            <Link to="/ViewReport" className="text-black hover:text-gray-700">Reports</Link>
          </li>
        )}
        <li id='adjustment' className="flex items-center space-x-2">
          <AdjustmentsVerticalIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
          <Link to="/ViewAdjustment" className="text-black hover:text-gray-700">Adjustment</Link>
        </li>
        <li id='language' className="flex items-center space-x-2">
          <GlobeAltIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
          <Link to="/language" className="text-black hover:text-gray-700">Language</Link>
        </li>

        <li className="flex items-center space-x-2">
          <ArrowRightOnRectangleIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
          <Link to="/logout" className="text-black hover:text-gray-700">Logout</Link>
        </li>
        {/* ======================================================================================================================================== */}

        {/* Drop down links for expense sub-categories */}
        <li className="dropdown flex items-center space-x-2">
          <CogIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
          <button onClick={SettingsDropdown} className="dropdown-toggle text-gray hover:text-gray-700 ">
            <span className="text-black hover:text-gray-700">Settings</span>
            <span className={`ml-auto transition-transform duration-500 ${isSettingsDropdownOpen ? 'rotate-90' : ''}`}>
              <img src={ArrowIcon} style={{ marginLeft: "20px" }} className="h-3 w-3 text-gray-500" alt="arrow icon" aria-hidden="true" />
            </span>
          </button>
        </li>

        <ul id='settings' className={`dropdown-menu ${isSettingsDropdownOpen ? 'open' : ''}`}>
          {permissionData.manageUser && (
            <li className="flex items-center space-x-2">
              <CogIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
              <Link to="/settings" className="text-black hover:text-gray-700">Settings</Link>
            </li>
          )}
          {permissionData.manageCustomers && (
            <li className="flex items-center space-x-2">
              <CogIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
              <Link to="/mailSettings" className="text-black hover:text-gray-700">Mail settings</Link>
            </li>
          )}

          {permissionData.manageCustomers && (
            <li className="flex items-center space-x-2">
              <CogIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
              <Link to="/receiptSettings" className="text-black hover:text-gray-700">Receipt settings</Link>
            </li>
          )}

          {permissionData.manageCustomers && (
            <li className="flex items-center space-x-2">
              <CogIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
              <Link to="/prefixSettings" className="text-black hover:text-gray-700">Prefixes settings</Link>
            </li>
          )}
        </ul>

      </ul>
    </div>
  );
};

export default Sidebar;
