import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/role.css';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { FaShoppingCart, FaCartArrowDown, FaArrowRight, FaArrowLeft } from 'react-icons/fa'
import { useParams } from 'react-router-dom';

function ClickedSuplierReport() {
    // State management
    const [suplierData, setSuplierData] = useState([]);
    const [searchedCustomerSale, setSearchedCustomerSale] = useState(null);
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [error, setError] = useState('')
    const { sale } = useParams();

    const name = sale;
    console.log("this is the name ", name)
    //COMBINE ALL DATA FETCHING TYPE INTO ONE STATE
    const combinedProductData = Array.isArray(searchedCustomerSale) && searchedCustomerSale.length > 0
        ? searchedCustomerSale
        : Array.isArray(suplierData) && suplierData.length > 0
            ? suplierData
            : [];

    useEffect(() => {
        const fetchReportData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/allSuplierReportById/${name}`);
                setSuplierData(response.data);
                console.log(response.data)
            } catch (err) {
                console.error('Error fetching report data:', err);
                setError('Failed to fetch report data');
            }
        };

        fetchReportData();
    }, [name]);

    // Handle search input change
    const handleFindUser = async (e) => {
        const { value } = e.target;
        setKeyword(value); // Update the keyword state
        if (value.length > 0) {
            await handleSubmit(); // Call your search function
        } else {
            setSearchedCustomerSale([]); // Clear results if input is empty
        }
    };

    // Handle search form submission
    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/findReportByCustomer`, {
                params: { name: keyword }
            });
            console.log("now", response.data);
            if (Array.isArray(response.data)) {
                setSearchedCustomerSale(response.data);
            }
        } catch (error) {
            console.error('Find customer error:', error);
            setSearchedCustomerSale([]); // Clear the search results on error
        } finally {
            setLoading(false);
        }
    };

    //CALCULATING CARDS VALUE (NO OF SALE, PAID, TOTAL, DUE)
    // Function to format numbers in K or M based on size
    const formatValue = (value) => {
        if (value >= 1000000) {
            return `${(value / 1000000).toFixed(1)}M`;  // Divide by 1,000,000 and add "M"
        } else if (value >= 1000) {
            return `${(value / 1000).toFixed(1)}K`;  // Divide by 1,000 and add "K"
        } else {
            return value.toFixed(1);  // Display exact value if less than 1,000
        }
    };

    // Calculating card values and formatting them
    const numberOfSales = Array.isArray(suplierData)
        ? formatValue(suplierData.reduce((total, customer) => total + (customer.sales?.length || 0), 0))
        : '0';

    const totalAmount = Array.isArray(suplierData)
        ? formatValue(suplierData.reduce((total, customer) =>
            total + (customer.sales?.reduce((sum, sale) => sum + sale.amount, 0) || 0), 0))
        : '0';

    const totalPaidAmount = Array.isArray(suplierData)
        ? formatValue(suplierData.reduce((total, customer) =>
            total + (customer.sales?.reduce((sum, sale) => sum + sale.paid, 0) || 0), 0))
        : '0';

    const dueAmount = formatValue(totalAmount - totalPaidAmount);

    return (
        <div className='relative bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] min-h-screen p-5'>
            <div>
                <div className="m-6 flex justify-left">
                    <h1 className="text-lightgray-300 m-0 p-0 text-2xl">Customer Report</h1>
                </div>
                <div className="flex justify-between p-4 bg-gray-100 mt-5">
                    <div className="flex-1 mx-2 h-40 flex flex-col items-center justify-center rounded-lg shadow-md" style={{ backgroundColor: '#6571ff' }}>
                        <FaShoppingCart className="text-white" style={{ fontSize: '2vw' }} />
                        <h1 className="text-white font-bold text-center" style={{ fontSize: '1vw' }}>Number Of Sales</h1>
                        <p className="text-white font-bold text-center" style={{ fontSize: '2vw' }}>{numberOfSales}</p>
                    </div>
                    <div className="flex-1 mx-2 h-40 flex flex-col items-center justify-center rounded-lg shadow-md" style={{ backgroundColor: '#0ac074' }}>
                        <FaCartArrowDown className="text-white" style={{ fontSize: '2vw' }} />
                        <p className="text-white font-bold text-center" style={{ fontSize: '1vw' }}>Total Amount</p>
                        <p className="text-white font-bold text-center" style={{ fontSize: '2vw' }}>{totalAmount}</p>
                    </div>
                    <div className="flex-1 mx-2 h-40 flex flex-col items-center justify-center rounded-lg shadow-md" style={{ backgroundColor: '#0099fb' }}>
                        <FaArrowRight className="text-white" style={{ fontSize: '2vw' }} />
                        <h1 className="text-white font-bold text-center" style={{ fontSize: '1vw' }}>Paid Amount</h1>
                        <p className="text-white font-bold text-center" style={{ fontSize: '2vw' }}>{totalPaidAmount}</p>
                    </div>
                    <div className="flex-1 mx-2 h-40 flex flex-col items-center justify-center rounded-lg shadow-md" style={{ backgroundColor: '#ffb821' }}>
                        <FaArrowLeft className="text-white" style={{ fontSize: '2vw' }} />
                        <h1 className="text-white font-bold text-center" style={{ fontSize: '1vw' }}>Due to Pay</h1>
                        <p className="text-white font-bold text-center" style={{ fontSize: '2vw' }}>{dueAmount}</p>
                    </div>
                </div>

                <div className='mt-5 mb-2 ml-[24px]'>
                    <form onChange={handleSubmit} className="flex items-center">
                        <input
                            onChange={handleFindUser}
                            name='keyword'
                            type="text"
                            placeholder="Search..."
                            className="searchBox w-80 pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                            value={keyword}
                        />
                    </form>
                </div>

                {loading ? (
                    <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                        <LinearProgress />
                    </Box>
                ) : suplierData.length > 0 ? (
                    <div className="overflow-x-auto p-6">
                        <table className="min-w-full bg-white border border-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mobile</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Warehouse</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Paid</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Due</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Status</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {suplierData.map((customer) => (
                                    <React.Fragment key={customer._id}>
                                        {customer.sales.map((sale) => (
                                            <tr key={sale.saleId}>
                                                <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">
                                                    <p className="rounded-[5px] text-center p-[6px] bg-red-100 text-red-500">{customer.name}</p>
                                                </td>
                                                <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">
                                                    {customer.mobile}
                                                </td>
                                                <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">
                                                    {new Date(sale.date).toLocaleDateString()}
                                                </td>
                                                <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">
                                                    {sale.warehouse}
                                                </td>
                                                <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">
                                                    Rs {sale.amount}
                                                </td>
                                                <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">
                                                    Rs {sale.paid}
                                                </td>
                                                <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">
                                                    Rs {(sale.amount - sale.paid)}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">
                                                    <p className={`rounded-[5px] text-center p-[6px] ${sale.paymentStatus === 'paid' ? 'bg-green-100 text-green-500' : sale.paymentStatus === 'Partial' ? 'bg-yellow-100 text-yellow-500' :
                                                        'bg-red-100 text-red-500'}`}>
                                                        {sale.paymentStatus}
                                                    </p>
                                                </td>
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : null}
                <div>
                    {error && <p className="text-green-500 mt-5 text-center">{error}</p>}
                </div>
            </div>
        </div>
    );
}
export default ClickedSuplierReport;
