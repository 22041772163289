import { useState, useEffect, } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import '../../styles/role.css';
import { fetchExpensesCatData, handleDelete, handleFormSubmit, fetchExpensesById, updateExpenses } from './ExpensesController';
function ViewExpensesCategoryBody() {
    // State variables
    const [exCategoryData, setExCatergoryData] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [searchedExpensesCataergoryByName, setSearchedExpensesCataergoryByName] = useState(null);
    const [loading, setLoading] = useState(false);
    const [expensesName, setExpensesName] = useState('');
    const [editExpensesName, setEditExpensesName] = useState('');
    const [isPopupEdit, setIsPopUpEdit] = useState(false)
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [selectedExpensesCatId, setSelectedExpensesCatId] = useState(null);
    const [responseMessage, setResponseMessage] = useState('')
    const [error, setError] = useState(null);

    //COMBINE ALL DATA FETCHING TYPE INTO ONE STATE
    const combinedProductData = Array.isArray(searchedExpensesCataergoryByName) && searchedExpensesCataergoryByName.length > 0
        ? searchedExpensesCataergoryByName
        : Array.isArray(exCategoryData) && exCategoryData.length > 0
            ? exCategoryData
            : [];


    //Load currencies when the component mounts
    useEffect(() => {
        // Call fetchSaleData and pass setCurrencyData, setLoading, and setError
        fetchExpensesCatData(setExCatergoryData, setLoading, setError);
    }, []);

    // Handle search input change
    const handleFindUser = async (e) => {
        const { value } = e.target;
        setKeyword(value); // Update the keyword state
        if (value.length > 0) {
            await handleSubmit(value); // Pass the input value to handleSubmit
        } else {
            setSearchedExpensesCataergoryByName([]); // Clear results if input is empty
        }
    };

    // Handle search form submission
    const handleSubmit = async (inputValue) => { // Accept the keyword as a parameter
        setLoading(true);
        setError(null);
        setResponseMessage('');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/getExpensesCategory`, {
                params: { expensesName: inputValue } // Use the passed keyword
            });

            console.log(response.data); // Log the response data to check its structure

            // Check if the response.data is an array or has a property that holds the array
            if (Array.isArray(response.data)) {
                setSearchedExpensesCataergoryByName(response.data.data);
            } else if (response.data && response.data.data) {
                setSearchedExpensesCataergoryByName(response.data.data); // Adjust based on your actual response structure
            } else {
                setSearchedExpensesCataergoryByName([]); // Fallback to an empty array if data is unexpected
                setError('No categories found.')
            }
        } catch (error) {
            console.error('Find customer error:', error);
            setSearchedExpensesCataergoryByName([]); // Clear the search results on error
            setError('Failed to search for categories. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleTogglePopup = () => {
        setPopupVisible(!isPopupVisible);
    };

    const handleTogglePopupEdit = () => {
        setIsPopUpEdit(!isPopupEdit);
        window.location.reload();
    };

    return (
        <div className='relative bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] h-[100vh] p-5'>
            <div className='flex justify-between mb-4'>
                <div className="relative w-full max-w-md">
                    <form onChange={handleSubmit} className="flex items-center">
                        <input
                            onChange={handleFindUser}
                            name='keyword'
                            type="text"
                            placeholder="Search..."
                            className="searchBox w-80 pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                            value={keyword}
                        />
                        <button type="submit" className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-400">
                            <svg
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M9 3a6 6 0 100 12A6 6 0 009 3zm0-1a7 7 0 110 14A7 7 0 019 2z"
                                    clipRule="evenodd"
                                />
                                <path
                                    fillRule="evenodd"
                                    d="M12.9 12.9a1 1 0 011.41 0l3 3a1 1 0 01-1.41 1.41l-3-3a1 1 0 010-1.41z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </form>
                </div>
                <div className="flex items-center">
                    <div>
                        <button
                            onClick={handleTogglePopup}
                            className="submit rounded-md px-5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-[250px] text-center"
                        >
                            Create Expenses Category
                        </button>
                    </div>
                </div>
            </div>

            {loading ? (
                <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                    <LinearProgress />
                </Box>
            ) : combinedProductData.length > 0 ? (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Expenses Catergory</th>
                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {combinedProductData.map((expenses) => (
                                <tr key={expenses._id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900"><p className='rounded-[5px] w-[200px] text-center p-[6px] bg-green-100 text-green-500'>{expenses.expensesName}</p></td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-right">
                                        <div className="text-right">
                                            <button
                                                onClick={() => fetchExpensesById(expenses._id, setEditExpensesName, setSelectedExpensesCatId, setIsPopUpEdit)}
                                                className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-edit mr-1"></i>
                                            </button>
                                            <button
                                                onClick={() => handleDelete(expenses._id, exCategoryData, setExCatergoryData)}
                                                className="text-red-500 hover:text-red-700 font-bold py-1 px-2"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-trash mr-1"></i>
                                            </button>
                                        </div>
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                    <LinearProgress />
                </Box>
            )
            }
            {/* Popup overlay */}
            {isPopupVisible && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white w-[600px] h-[600px] rounded-lg p-6 relative">
                        {/* Close button */}
                        <button
                            onClick={handleTogglePopup}
                            className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
                        >
                            ✕
                        </button>

                        {/* Form content */}
                        <h2 className="text-xl text-gray-700 font-semibold mb-4">Create Expenses Category</h2>
                        <form onSubmit={(e) => handleFormSubmit(e, setLoading, expensesName, setExpensesName, setResponseMessage)}>
                            <div className='mt-10'>
                                <label className="text-left block text-sm font-medium text-gray-700">Expenses Category</label>
                                <input
                                    type="text"
                                    value={expensesName}
                                    required
                                    onChange={(e) => setExpensesName(e.target.value)}
                                    className="searchBox mt-2 w-full pl-2 pr-2 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                                    placeholder="Enter expenses name"
                                />
                            </div>
                            <div className='mt-5' >
                                <button
                                    type="submit"
                                    className="submit mt-10 w-full py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700"
                                >
                                    Save
                                </button>
                            </div>

                            {responseMessage && <p className="mt-10 text-green-500 text-center">{responseMessage}</p>}
                        </form>
                    </div>
                </div>
            )}

            {isPopupEdit && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white w-[600px] h-[600px] rounded-lg p-6 relative">
                        {/* Close button */}
                        <button
                            onClick={handleTogglePopupEdit}
                            className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
                        >
                            ✕
                        </button>

                        {/* Form content */}
                        <h2 className="text-xl font-semibold mb-4 text-gray-700">Edit Expenses Category </h2>
                        <form onSubmit={(e) => updateExpenses(e, selectedExpensesCatId, editExpensesName, setEditExpensesName, setResponseMessage, setIsPopUpEdit)}>
                            <div className='mt-5'>
                                <label className="block text-left text-sm font-medium text-gray-700">Expenses Category</label>
                                <input
                                    type="text"
                                    value={editExpensesName}
                                    required
                                    onChange={(e) => setEditExpensesName(e.target.value)}
                                    className="searchBox mt-2 w-full pl-2 pr-2 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                                    placeholder="Enter expenses name"
                                />
                            </div>
                            <div className='mt-5' >
                                <button
                                    type="submit"
                                    className="submit mt-10 w-full py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700"
                                >
                                    Save
                                </button>
                            </div>

                            {/* Error and Response Messages */}
                            <div className='mt-10'>
                                {error && (
                                    <p className="text-red-600 px-5 py-2 rounded-md bg-red-100 mt-5 text-center mx-auto max-w-sm">
                                        {error}
                                    </p>
                                )}
                                {responseMessage && (
                                    <p className="text-green-600 px-5 py-2 rounded-md bg-green-100 mt-5 text-center  mx-auto max-w-sminline-block">
                                        {responseMessage}
                                    </p>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div >
    );
}
export default ViewExpensesCategoryBody;