import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/role.css';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Link } from 'react-router-dom';

function SuplierReportBody() {
    // State management
    const [saleData, setSaleData] = useState({});
    const [searchedSuplierSale, setSearchedSuplierSale] = useState(null);
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [error, setError] = useState('')

    //COMBINE ALL DATA FETCHING TYPE INTO ONE STATE
    const combinedProductData = Array.isArray(searchedSuplierSale) && searchedSuplierSale.length > 0
        ? searchedSuplierSale
        : Array.isArray(saleData) && saleData.length > 0
            ? saleData
            : [];

    useEffect(() => {
        const fetchReportData = async () => {
            setLoading(true)
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/allSuplierReport/`);
                setSaleData(response.data);
                console.log(response.data);
            } catch (err) {
                console.error('Error fetching report data:', err);
                setError('Failed to fetch report data');
            } finally {
                setLoading(false);
            }
        };
        fetchReportData();
    }, []);

    // Handle search input change
    const handleFindUser = async (e) => {
        const { value } = e.target;
        setKeyword(value); // Update the keyword state
        if (value.length > 0) {
            await handleSubmit(); // Call your search function
        } else {
            setSearchedSuplierSale([]); // Clear results if input is empty
        }
    };

    // Handle search form submission
    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/findReportBySuplier`, {
                params: { name: keyword }
            });
            console.log(response.data);
            if (Array.isArray(response.data)) {
                setSearchedSuplierSale(response.data);
            } else if (response.data && response.data.sales) {
                setSearchedSuplierSale(response.data.sales); // Adjust based on your actual response structure
            } else {
                setSearchedSuplierSale([]); // Fallback to an empty array if data is unexpected
            }
        } catch (error) {
            console.error('Find customer error:', error);
            setSearchedSuplierSale([]); // Clear the search results on error
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='relative bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] min-h-screen p-5'>
            <div>
                <div className="m-6 flex justify-left">
                    <h1 className="text-lightgray-300 m-0 p-0 text-2xl">Suplier Report</h1>
                </div>

                <div className='mt-5 mb-2 ml-[24px]'>
                    <form onChange={handleSubmit} className="flex items-center">
                        <input
                            onChange={handleFindUser}
                            name='keyword'
                            type="text"
                            placeholder="Search..."
                            className="searchBox w-80 pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                            value={keyword}
                        />
                    </form>
                </div>

                {loading ? (
                    <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                        <LinearProgress />
                    </Box>
                ) : combinedProductData.length > 0 ? (
                    <div className="overflow-x-auto p-6">
                        <table className="min-w-full bg-white border border-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Suplier</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mobile</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total amount</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sale Amount</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Paid</th>
                                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {combinedProductData.map((sale) => {
                                    // Calculate total sales amount and the number of sales
                                    const totalSalesAmount = sale.sales.reduce((acc, sale) => acc + sale.amount, 0);
                                    const numberOfSales = sale.sales.length;

                                    return (
                                        <tr key={sale._id}>
                                            <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">
                                                <p className="rounded-[5px] text-center p-[6px] bg-red-100 text-red-500">{sale.name}</p>
                                            </td>
                                            <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">{sale.mobile}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">
                                                {numberOfSales} {/* Display number of sales */}
                                            </td>
                                            <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">
                                                Rs {totalSalesAmount.toFixed(2)} {/* Display total sales amount */}
                                            </td>
                                            <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">
                                                {/* Optionally, display total paid amount if needed */}
                                                Rs {sale.sales.reduce((acc, sale) => acc + sale.paid, 0).toFixed(2)}
                                            </td>
                                            <td className="px-6 flex justify-end py-4 whitespace-nowrap text-m text-gray-900">
                                                <Link to={`/viewSuplierRep/${sale.name}`}
                                                    className="text-green-500 hover:text-green-700 font-bold py-1 px-2 mr-2 text-lg"
                                                    style={{ background: 'transparent' }}
                                                >
                                                    <i className="fas fa-eye mr-1"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                ) :
                    <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                        <LinearProgress />
                    </Box>}
                <div>
                    {error && <p className="text-green-500 mt-5 text-center">{error}</p>}
                </div>
            </div>
        </div>
    );
}

export default SuplierReportBody;
