import axios from "axios";
import { getTax, getPriceRange } from '../../component/sales/SaleController';

//HANDLE PREVIEW
export const handlePreview = (selectedProduct, grandTotal, paymentStatus, paymentType, orderStatus, discountType, discount, shipping, setSavedProducts) => {
    if (!selectedProduct || selectedProduct.length === 0) {
        console.error('No selected products to preview.');
        return;
    }
    const productsToSave = selectedProduct.map(product => {
        const price = getPriceRange(product, product.selectedVariation);
        const quantity = product.barcodeQty || 1;
        const taxRate = product.oderTax ? product.oderTax / 100 : getTax(product, product.selectedVariation) / 100;
        const subtotal = (price * quantity) + (price * quantity * taxRate);

        return {
            name: product.name,
            quantity,
            price,
            subtotal,
            selectedVariation: product.selectedVariation,
            tax: taxRate * 100,
            discount,
            shipping,
            grandTotal: Number(grandTotal) || 0,
            pStatus: paymentStatus,
            pType: paymentType,
            productStatus: orderStatus
        };
    });
    setSavedProducts(productsToSave);
};


//HANDLE SAVE PRODUCT
export const handleSaveQuatation = async (grandTotal, orderStatus, paymentStatus, paymentType, shipping, discountType, discount, tax, warehouse, selectedCustomer, selectedProduct, date, setResponseMessage) => {
    if (!selectedCustomer || !selectedCustomer.name) {
        //setError('Customer information is required');
        return;
    }
    if (!date) {
        //setError('Date is required');
        return;
    }
    const totalAmount = Number(grandTotal) || 0;
    const paidAmount = paymentStatus.toLowerCase() === 'paid' ? grandTotal : 0;

    const commonSaleData = {
        date,
        customer: selectedCustomer.name,
        warehouse: warehouse || null,
        tax,
        discountType,
        discount,
        shipping,
        paymentStatus,
        paymentType,
        orderStatus,
        paidAmount,
        grandTotal: totalAmount,
    };

    // Create products data array
    const productsData = selectedProduct.map(product => {
        const currentID = product._id;
        const ptype = product.ptype;
        const variationValue = product.selectedVariation;
        const price = getPriceRange(product, product.selectedVariation);
        const quantity = product.barcodeQty || 1;
        const taxRate = product.oderTax ? product.oderTax / 100 : getTax(product, product.selectedVariation) / 100;
        const subtotal = (price * quantity) + (price * quantity * taxRate);

        return {
            currentID,
            ptype,
            variationValue,
            name: product.name,
            price,
            quantity,
            taxRate,
            subtotal,
        };
    });

    // Combine common sale data with products data
    const finalQuatationData = {
        ...commonSaleData,
        productsData,
    };

    console.log('Final Quatation Data:', finalQuatationData);
    // try {
    //     const response = await axios.post('http://localhost:5000/api/createQuatation', finalQuatationData);
    //     console.log('Response:', response.data);
    //     //setError('');
    //     setResponseMessage(response.data);
    // } catch (error) {
    //     console.error('Error creating Quatation:', error);
    //     //setError(error.response?.data?.message || 'Failed to create sale');
    //     setResponseMessage(error);
    // }
};

export const handleUpdateQuatation = async (
    id, grandTotal, orderStatus, paymentStatus, paidAmount, paymentType, shipping,
    discountType, discount, tax, warehouse, selectedCustomer,
    productData, date, setError, setResponseMessage
) => {
    console.log('Quatation Data:', productData);

    if (!Array.isArray(productData)) {
        setError('Invalid Quatation data format. Expected an array.');
        return;
    }

    const totalAmount = Number(grandTotal) || 0;
    const PaidAmount = paymentStatus.toLowerCase() === 'paid' ? grandTotal : 0;

    const commonSaleData = {
        date,
        selectedCustomer,
        warehouse: warehouse || null,
        tax,
        discountType,
        discount,
        shipping,
        paymentStatus,
        paymentType,
        orderStatus,
        paidAmount:PaidAmount,
        grandTotal: totalAmount,
    };

    // Create products data array
    const productsData = productData.map(product => {
        const currentID = product.currentID;
        const variationValue = product.variationValue;
        const price = product.price;
        const ptype =product.ptype;
        const quantity = product.quantity || 1;
        const taxRate = product.taxRate
        const subtotal = product.subtotal;

        return {
            currentID,
            variationValue,
            name: product.name,
            ptype,
            price,
            quantity,
            taxRate,
            subtotal,
        };
    });

    const updatedQuatationData = {
        ...commonSaleData,
        productsData,
    };

    console.log('Updated quatation Data:', updatedQuatationData);
    try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/updateQuatation/${id}`, updatedQuatationData);
        console.log('Response:', response.data);
        // setError('');
        setResponseMessage([response.data.message]);
    } catch (error) {
        console.error('Error creating Quatation:', error);

    }
};

//HANDLE SAVE PRODUCT
export const handleCreateSale = async (grandTotal,orderStatus,paymentStatus,paymentType,shipping,discountType,discount,tax,warehouse,selectedCustomer,quatationProductData,date,setError,setResponseMessage) => {
    if (!selectedCustomer) {
        alert('Customer information is required');
        return;
    }
    if (!date) {
        alert('Date is required');
        return;
    }

    const totalAmount = Number(grandTotal) || 0;
    const paidAmount = paymentStatus.toLowerCase() === 'paid' ? totalAmount : 0;

    const commonSaleData = {
        date,
        customer: selectedCustomer,
        warehouse: warehouse || null,
        tax,
        discountType,
        discount,
        shipping,
        paymentStatus,
        paymentType,
        orderStatus,
        paidAmount,
        grandTotal: totalAmount,
    };

    // Create products data array
    const productsData = (Array.isArray(quatationProductData) ? quatationProductData : []).map(product => {
        const currentID = product.currentID;
        const ptype = product.ptype;
        const variationValue = product.variationValue;
        const price = product.price;
        const quantity = product.quantity;
        const taxRate = product.taxRate;
        const subtotal = (price * quantity) + (price * quantity * taxRate);

        return {
            currentID,
            ptype,
            variationValue,
            name: product.name,
            price,
            quantity,
            taxRate,
            subtotal,
        };
    });

    // Combine common sale data with products data
    const finalSaleData = {
        ...commonSaleData,
        productsData,
    };

    console.log('Final Sale Data:', finalSaleData);
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/createSale`, finalSaleData);
        console.log('Response:', response.data);
        //setError(response.data);
        setResponseMessage('Sale created successfully!');
    } catch (error) {
        console.error('Error creating sale:', error);
        //setError(error || 'Failed to create sale');
        setResponseMessage(error);
    }
};
