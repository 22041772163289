import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import '../../styles/role.css';
import { usePDF } from 'react-to-pdf';

function ViewSaleBody() {
    // State variables
    const [saleData, setSaleData] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [searchedCustomerSale, setSearchedCustomerSale] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openPopupId, setOpenPopupId] = useState(null);
    const popupRef = useRef(null);
    const [openViewSale, setOpenViewSale] = useState(null);
    const [openViewPayment, setViewPayment] = useState(null);
    const [filteredSaleData, setFilteredSaleData] = useState(saleData);
    const [openEditPopup, setEditPopup] = useState(false);
    const { toPDF, targetRef } = usePDF({ filename: `${saleData.customer || 'invoice'}.pdf` });
    //COMBINE ALL DATA FETCHING TYPE INTO ONE STATE
    const combinedProductData = Array.isArray(searchedCustomerSale) && searchedCustomerSale.length > 0
        ? searchedCustomerSale
        : Array.isArray(saleData) && saleData.length > 0
            ? saleData
            : [];

    const [paymentType, setPaymentType] = useState('');
    const [amountToPay, setAmountToPay] = useState(0);
    const [payingAmount, setPayingAmount] = useState('');
    const [currentDate] = useState(new Date().toISOString().slice(0, 10));
    const [responseMSG, setResponse] = useState('')
    const [paymentData, setPaymentData] = useState([]);
    const [error, setError] = useState(null);

    // Fetch all customers
    useEffect(() => {
        const fetchSaleData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/fetchSales`);
                console.log('Fetched sale data:', response.data);
                setSaleData(response.data);
            } catch (error) {
                console.error('Fetch sale data error:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSaleData();
    }, []);

    // Handle delete customer
    const handleDelete = async (_id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/DeleteSale/${_id}`);
            setSaleData(saleData.filter(sale => sale._id !== _id));
        } catch (error) {
            console.error('Delete sale error:', error);
        }
    };


    // Determine search type based on the keyword
    const determineSearchType = (keyword) => {
        console.log('Keyword received:', keyword); // Debug log
    
        if (/^SL_\d{4}$/.test(keyword)) {
            return 'referenceId'; // Matches SL_XXXX pattern
        } else {
            return 'customer'; // Defaults to customer
        }
    };
    
    

    
    const handleTogglePopup = (saleId) => {
        setOpenPopupId(openPopupId === saleId ? null : saleId);
    };

    // Close popup when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setOpenPopupId(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [popupRef]);

    const handleSaleViewPopUp = async (saleId) => {
        setOpenPopupId(null);
        setPayingAmount('');
        setOpenViewSale(openViewSale === saleId ? null : saleId);
        if (openViewSale !== saleId) {
            const sale = saleData.find((sale) => sale._id === saleId);
            const customerName = sale.customer;

            try {
                if (customerName !== "") {
                    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/searchCustomerByName?name=${customerName}`);
                    setFilteredSaleData(response.data.customer);
                    console.log(response.data)
                } else {
                    setFilteredSaleData(saleData);
                }
            } catch (error) {
                console.log(error);
                setError(error)
            }
        }
    };

    const handleShowPaymentPopUp = (saleId) => {
        setPaymentData([]);
        setOpenPopupId(null);
        setViewPayment(openViewPayment === saleId ? null : saleId);
        const fetchPaymentData = async (saleId) => {
            try {

                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/getPaymentOfSaleById/${saleId}`);
                console.log('Fetched payment data:', response.data);
                setPaymentData(response.data.payments || []);
                setError(null);
            } catch (error) {
                console.error('Error fetching payment data:', error);
                setError('An error occurred while fetching payment data.');
            }
        };
        fetchPaymentData(saleId);
    };

    const handleEditClick = (saleId) => {
        alert(saleId);
        setPayingAmount('');
        setResponse('');
        setEditPopup(openEditPopup === saleId ? null : saleId);
    };

    const savePayingData = async (e, saleId, grandTotal) => {
        e.preventDefault()
        const paidData = {
            saleId,
            amountToPay: grandTotal,
            payingAmount,
            currentDate,
            paymentType
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/payingForSale`, paidData);
            if (response.data) {
                setResponse(response.data.message ? response.data.message : response.data);
            }
        } catch (error) {
            console.log(error);
        }
    }


     // Handle search input change
     const handleFindSale = async (e) => {
        const { value } = e.target;
        setKeyword(value); // Update the keyword state
        if (value.length > 0) {
            await handleSubmit(); // Call your search function
        } else {
            setSearchedCustomerSale([]); // Clear results if input is empty
        }
    };

    // Handle search form submission
    const handleSubmit = async () => {
        setLoading(true);
        try {
            console.log('Raw keyword:', keyword); // Debug log
    
            const searchType = determineSearchType(keyword); // Determine the search type
            console.log('Search Type:', searchType); // Debug log
    
            let params = {};
    
            if (searchType === 'referenceId') {
                params = { referenceId: keyword };
            } else if (searchType === 'customer') {
                params = { customerName: keyword };
            } else {
                throw new Error('Invalid search type.');
            }
    
            console.log('Search Params:', params); // Debug log
    
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/fetchSales`, { params });
            console.log('Response:', response.data);
    
            if (Array.isArray(response.data)) {
                setSearchedCustomerSale(response.data);
            } else if (response.data && typeof response.data === 'object') {
                setSearchedCustomerSale([response.data]);
            } else {
                setSearchedCustomerSale([]);
            }
        } catch (error) {
            console.error('Search error:', error.response ? error.response.data : error.message);
            if (error.response) {
                if (error.response.status === 404) {
                    alert('No sales found matching the search criteria.');
                } else if (error.response.status === 400) {
                    alert(error.response.data.message || 'Invalid input. Please check your search criteria.');
                }
            } else {
                alert('An unexpected error occurred. Please try again later.');
            }
            setSearchedCustomerSale([]);
        } finally {
            setLoading(false);
        }
    };
    
    return (
        <div className='relative bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] min-h-[100vh] p-5'>
            <div className='flex justify-between mb-4 '>
                <div className="relative w-full max-w-md">
                    <form onChange={handleSubmit} className="flex items-center">
                        <input
                            onChange={handleFindSale}
                            name='keyword'
                            type="text"
                            placeholder="Search..."
                            className="searchBox w-80 pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                            value={keyword}
                        />
                        <button type="submit" className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-400">
                            <svg
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M9 3a6 6 0 100 12A6 6 0 009 3zm0-1a7 7 0 110 14A7 7 0 019 2z"
                                    clipRule="evenodd"
                                />
                                <path
                                    fillRule="evenodd"
                                    d="M12.9 12.9a1 1 0 011.41 0l3 3a1 1 0 01-1.41 1.41l-3-3a1 1 0 010-1.41z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </form>
                </div>
                <div className="flex items-center">
                    <div>
                        <Link
                            to={'/CreateSale'}
                            className="submit rounded-md px-5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-[200px] text-center"
                        >
                            Create Sale
                        </Link>
                    </div>
                </div>
            </div>

            {loading ? (
                <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                    <LinearProgress />
                </Box>
            ) : combinedProductData.length > 0 ? (
                <div className="overflow-x-auto">
                    <table className=" overflow-x-auto min-w-full bg-white border mb-20 border-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Refference</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Warehouse</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Status</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Type</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Grand Total</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Paid</th>
                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {combinedProductData.map((sale) => (
                                <tr key={sale._id}>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900"><p className='rounded-[5px] text-center p-[6px] bg-red-100 text-red-500'>{sale.refferenceId}</p></td>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900"><p className='rounded-[5px] text-center p-[6px] bg-red-100 text-red-500'>{sale.customer}</p></td>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">{sale.warehouse}</td>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">{new Date(sale.date).toLocaleDateString()}</td>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900"><p className='rounded-[5px] text-center p-[6px] bg-green-100 text-green-500'>{sale.orderStatus}</p></td>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">
                                        <p className={`rounded-[5px] text-center p-[6px] ${sale.paymentStatus === 'paid' ? 'bg-green-100 text-green-500' :sale.paymentStatus === 'Partial' ? 'bg-yellow-100 text-yellow-500' :
                                                        'bg-red-100 text-red-500'}`}>
                                            {sale.paymentStatus}
                                        </p>
                                    </td>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900"><p className='rounded-[5px] text-center p-[6px] bg-blue-100 text-blue-500'>{sale.paymentType}</p></td>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">Rs {sale.grandTotal}</td>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">Rs {sale.paidAmount}</td>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">
                                        <div className='flex items-center'>
                                            <Link to={`/EditSale/${sale._id}`}
                                                className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2 flex items-center"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-edit mr-1"></i>
                                            </Link>
                                            <button
                                                onClick={() => handleDelete(sale._id)}
                                                className="text-red-500 hover:text-red-700 font-bold py-1 px-2 flex items-center"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-trash mr-1"></i>
                                            </button>
                                            <button
                                                onClick={() => handleTogglePopup(sale._id)}
                                                className="text-gray-500 hover:text-gray-700 font-bold py-1 px-2 flex items-center rotate-90"
                                            >
                                                <i className="fa fa-ellipsis-h"></i>
                                            </button>

                                            {/* Conditional rendering of the popup for the specific sale._id */}
                                            {openPopupId === sale._id && (
                                                <div ref={popupRef} className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-10">
                                                    <ul className="text-sm text-gray-700">
                                                        <li onClick={() => handleSaleViewPopUp(sale._id)} className="px-4 py-4 hover:bg-gray-100 cursor-pointer flex items-center">
                                                            <i className="fas fa-eye mr-2 text-gray-600"></i> {/* Icon for "View Sale" */}
                                                            View Sale
                                                        </li>
                                                        <li className="px-4 py-4 hover:bg-gray-100 cursor-pointer flex items-center">
                                                            <i className="fas fa-file-pdf mr-2 text-gray-600"></i> {/* Icon for "Download PDF" */}
                                                            Download PDF
                                                        </li>
                                                        <li
                                                            key={sale._id}
                                                            onClick={() => handleShowPaymentPopUp(sale._id)}
                                                            // Associate click event here
                                                            className="px-4 py-4 hover:bg-gray-100 cursor-pointer flex items-center"
                                                        >
                                                            <i className="fas fa-credit-card mr-2 text-gray-600"></i>
                                                            Show Payment
                                                        </li>
                                                        <Link to={`/CreateSaleReturn/${sale._id}`} className="px-4 py-4 hover:bg-gray-100 cursor-pointer flex items-center">
                                                            <i className="fas fa-undo-alt mr-2 text-gray-600"></i> {/* Icon for "Create Return" */}
                                                            Create Return
                                                        </Link>
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    {/* View Sale popup */}
                                    {openViewSale === sale._id && (
                                        <div ref={popupRef} className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                                            <div className="bg-white w-[1300px] max-h-[90vh] overflow-auto p-8 pt-16 rounded-md shadow-lg mt-40 mb-10">
                                                <div ref={targetRef} className="w-[1250px] p-10 bg-white" style={{ margin: '0 auto', padding: '15px', boxSizing: 'border-box' }}>
                                                    {/* Header */}
                                                    <div className="mb-6 flex justify-between items-center border-b pb-4">
                                                        <h2 className="text-2xl font-bold text-gray-800">Sale Details for {sale.customer}</h2>
                                                    </div>

                                                    {/* Sale Info Section */}
                                                    <div className="grid grid-cols-3 gap-8 text-gray-700">
                                                        {/* Customer Info */}
                                                        <div className="border-r pr-8">
                                                            <h3 className="text-lg font-semibold mb-2 p-[8px] bg-gray-100 text-gray-900">
                                                                <i className="fas fa-user mr-2 text-gray-600"></i>
                                                                Customer Info
                                                            </h3>
                                                            <p className="mb-1"><i className="fas fa-user ml-2 mr-2 text-gray-400"></i><span className="font-medium">Customer:</span> {sale.customer}</p>

                                                            {filteredSaleData.map((customer) => (
                                                                <div>
                                                                    <p className='m-2'><i className="fas fa-envelope mr-2 text-gray-400"></i><span className="font-medium">Email:</span> {customer.username}</p>
                                                                    <p className='m-2'><i className="fas fa-city mr-2 text-gray-400"></i><span className="font-medium">City:</span> {customer.city}</p>
                                                                    <p className='m-2'><i className="fas fa-phone mr-2 text-gray-400"></i><span className="font-medium">Mobile:</span> {customer.mobile}</p>
                                                                </div>
                                                            ))}
                                                        </div>

                                                        {/* Company Info */}
                                                        <div className="border-r pr-8">
                                                            <h3 className="text-lg p-[8px] bg-gray-100 font-semibold mb-2 text-gray-900">
                                                                <i className="fas fa-building mr-2 text-gray-600"></i>
                                                                Company Info
                                                            </h3>
                                                            <p className="m-2"><i className="fas fa-building mr-2 text-gray-400"></i><span className="font-medium">Company:</span> Ideazone</p>
                                                            <p className="m-2"><i className="fas fa-envelope mr-2 text-gray-400"></i><span className="font-medium">Email:</span> ideazone@info.gmail.com</p>
                                                            <p className="m-2"><i className="fas fa-phone mr-2 text-gray-400"></i><span className="font-medium">Phone:</span> +94 2828 395</p>
                                                            <p className="m-2"><i className="fas fa-map-marker-alt mr-2 text-gray-400"></i><span className="font-medium m-2">Address:</span> No 17 Rockview Rd</p>
                                                        </div>

                                                        {/* Invoice Info <span className="font-medium m-2">Orser status:</span>*/}
                                                        <div>
                                                            <h3 className="text-lg p-[8px] bg-gray-100 font-semibold mb-2 text-gray-900">
                                                                <i className="fas fa-file-invoice mr-2 text-gray-600"></i>
                                                                Invoice Info
                                                            </h3>
                                                            <p className='flex items-center'>
                                                                <span className="font-medium m-2 flex items-center"><i className="fas fa-money-bill-wave mr-1 text-gray-400"></i>Payment status:</span>
                                                                <span className={`w-20 flex items-center rounded-[5px] text-center m-2 p-[2px] ${sale.paymentStatus === 'paid' ? 'bg-green-100 text-green-500' : 'bg-red-100 text-red-500'}`}>
                                                                    {sale.paymentStatus}
                                                                </span>
                                                            </p>
                                                            <p className='flex items-center'>
                                                                <span className=" flex items-center font-medium m-2"><i className="fas fa-check-circle mr-1 text-gray-400"></i>Order status:</span>
                                                                <span className=' flex items-center w-20 rounded-[5px] text-center m-2 p-[2px] bg-green-100 text-green-500'>
                                                                    {sale.orderStatus}
                                                                </span>
                                                            </p>
                                                            <p className='mt-2'>
                                                                <span className="font-medium m-2 mt-4"><i className="fas fa-warehouse mr-1 text-gray-400"></i>Warehouse:</span>
                                                                {sale.warehouse}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {/* Product data */}
                                                    <div className="mt-10">
                                                        <h3 className="text-lg font-semibold mb-2 mt-10 p-[8px] bg-gray-100 text-gray-900">Product Details</h3>
                                                        <table className=" mt-10 min-w-full bg-white border border-gray-300">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-gray-900 py-2 px-4 border-b text-left bg-gray-100 ">Product ID</th>
                                                                    <th className="text-gray-900 py-2 px-4 border-b text-left bg-gray-100 ">Product name</th>
                                                                    <th className="text-gray-900 py-2 px-4 border-b text-left bg-gray-100 ">Product price</th>
                                                                    <th className="text-gray-900 py-2 px-4 border-b text-left bg-gray-100 ">qty</th>
                                                                    <th className="text-gray-900 py-2 px-4 border-b text-left bg-gray-100 ">Sub total</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {sale.productsData.map((product) => (
                                                                    <tr key={product._id} className="text-gray-700">
                                                                        <td className="py-2 px-4 border-b">{product.currentID}</td>
                                                                        <td className="py-2 px-4 border-b">{product.name}</td>
                                                                        <td className="py-2 px-4 border-b">Rs {product.price}</td>
                                                                        <td className="py-2 px-4 border-b">{product.quantity}</td>
                                                                        <td className="py-2 px-4 border-b">Rs {product.subtotal}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    {/* Additional data */}
                                                    <div className="mt-10">
                                                        <table className=" mt-10 min-w-[400px] bg-white border border-gray-300">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="py-2 px-4 border-b">Tax</td>
                                                                    <td className="py-2 px-4 border-b">{sale.tax} %</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="py-2 px-4 border-b">Shipping</td>
                                                                    <td className="py-2 px-4 border-b">Rs {sale.shipping}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="py-2 px-4 border-b">Discount</td>
                                                                    <td className="py-2 px-4 border-b">Rs {sale.discount}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="py-2 px-4 border-b">Total</td>
                                                                    <td className="py-2 px-4 border-b">Rs {sale.grandTotal}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {/* Footer */}
                                                <div className="mt-8 flex justify-end">
                                                    {openViewSale === sale._id && (
                                                        <button onClick={() => toPDF()} className="submit px-6 py-3 mr-2 text-white font-semibold rounded-md shadow-md -600 transition">
                                                            <i className="fas fa-file-pdf mr-2 text-white"></i>
                                                            Download PDF
                                                        </button>
                                                    )}
                                                    <button
                                                        onClick={() => setOpenViewSale(null)}
                                                        className="px-6 py-3 bg-gray-500 text-white font-semibold rounded-md shadow-md hover:bg-gray-600 transition">
                                                        Close
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* Show payment */}
                                    {openViewPayment === sale._id && (
                                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                                            <div className="bg-white w-[800px] h-[600px] overflow-auto p-8 pt-16 rounded-md shadow-lg mt-40 mb-10">
                                                <h2 className="text-xl text-black-500 font">Payment Details</h2>
                                                <div>
                                                    <table className="mt-10 min-w-full bg-white">
                                                        <thead>
                                                            <tr>
                                                                <td className="text-gray-600 py-2 px-4 border-b text-left bg-gray-100">Date</td>
                                                                <td className="text-gray-600 py-2 px-4 border-b text-left bg-gray-100">Amount</td>
                                                                <td className="text-gray-600 py-2 px-4 border-b text-left bg-gray-100">Paid by</td>
                                                                <td className="text-gray-600 py-2 px-4 border-b text-left bg-gray-100 text-right pr-10">Action</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {paymentData && paymentData.length > 0 ? (
                                                                paymentData.map((pd) => (
                                                                    <tr key={pd._id}>
                                                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{pd.currentDate}</td>
                                                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{sale.grandTotal}</td>
                                                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{pd.payingAmount ? pd.payingAmount : null}</td>
                                                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-right">
                                                                            <div className="flex justify-end items-center">
                                                                                <button
                                                                                    onClick={() => handleEditClick(sale._id)}
                                                                                    className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2"
                                                                                    style={{ background: 'transparent' }}
                                                                                >
                                                                                    <i className="fas fa-edit mr-1"></i>
                                                                                </button>
                                                                                <button
                                                                                    onClick={() => handleDelete(sale._id)}
                                                                                    className="text-red-500 hover:text-red-700 font-bold py-1 px-2"
                                                                                    style={{ background: 'transparent' }}
                                                                                >
                                                                                    <i className="fas fa-trash mr-1"></i>
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="4" className="text-center py-4">
                                                                        No payment data available.
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {/* Edit payment popup */}
                                                {openEditPopup === sale._id && (
                                                    <div className="fixed inset-0 flex justify-center items-center mt-40">
                                                        <div className="bg-white w-[800px] h-[600px] overflow-auto p-8 pt-16 rounded-md shadow-lg mb-10">
                                                            <h1 className="text-gray-600 text-left">Make payment</h1>
                                                            <form>
                                                                <div className="mb-4">
                                                                    <label className="block text-gray-700 mb-2 text-left" htmlFor="date">Date:</label>
                                                                    <input
                                                                        type="date"
                                                                        id="date"
                                                                        value={currentDate}
                                                                        readOnly
                                                                        className="border border-gray-300 rounded p-2 w-full"
                                                                    />
                                                                </div>
                                                                <div className="mb-4">
                                                                    <label className="block text-gray-700 mb-2 text-left" htmlFor="paymentType">Payment type:</label>
                                                                    <select
                                                                        id="paymentType"
                                                                        required
                                                                        value={paymentType}
                                                                        onChange={(e) => setPaymentType(e.target.value)}
                                                                        className="border border-gray-300 rounded p-2 w-full text-left"
                                                                    >
                                                                        <option value="">Select Payment Type</option>
                                                                        <option value="cash">Cash</option>
                                                                        <option value="card">Card</option>
                                                                        <option value="check">Check</option>
                                                                        <option value="bank_transfer">Bank Transfer</option>
                                                                    </select>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <label className="block text-gray-700 mb-2 text-left" htmlFor="amountToPay">Amount To Pay:</label>
                                                                    <input
                                                                        type="number"
                                                                        required
                                                                        id="amountToPay"
                                                                        value={sale.grandTotal}
                                                                        onChange={(e) => setAmountToPay(e.target.value)}
                                                                        className="border border-gray-300 rounded p-2 w-full"
                                                                        placeholder="Enter amount to pay"
                                                                    />
                                                                </div>
                                                                <div className="mb-4">
                                                                    <label className="block text-gray-700 mb-2 text-left" htmlFor="payingAmount">Paying Amount:</label>
                                                                    <input
                                                                        type="number"
                                                                        id="payingAmount"
                                                                        required
                                                                        value={payingAmount}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            if (Number(value) <= sale.grandTotal) {
                                                                                setPayingAmount(value);
                                                                            } else {
                                                                                setResponse('Paying amount cannot exceed Amount To Pay.');
                                                                            }
                                                                        }}
                                                                        className="border border-gray-300 rounded p-2 w-full"
                                                                        placeholder="Enter paying amount"
                                                                    />
                                                                </div>
                                                                <div className="flex justify-end items-center">
                                                                    <button
                                                                        onClick={(e) => savePayingData(e, sale._id, sale.grandTotal)}
                                                                        type="submit"
                                                                        className="text-white submit py-2 px-4 rounded mt-4"
                                                                    >
                                                                        Save Changes
                                                                    </button>
                                                                    <button
                                                                        onClick={() => setEditPopup(false)}
                                                                        className="px-6 ml-2 mt-[17px] h-[45px] bg-gray-500 text-white font-semibold rounded-md shadow-md hover:bg-gray-600 transition"
                                                                    >
                                                                        Close
                                                                    </button>
                                                                </div>
                                                                <div>
                                                                    {responseMSG && <p className="text-green-500 mt-5 text-center">{responseMSG}</p>}
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className='flex items-center'>
                                                    <button onClick={() => handleEditClick(sale._id)} className="px-6 flex items-center submit mt-5 text-white mr-2 h-[40px] rounded-md shadow-md transition">Create Payment</button>
                                                    <button onClick={() => setViewPayment(false)} className="px-6 py-2 bg-gray-500 mt-5 text-white rounded-md shadow-md hover:bg-gray-600 transition">Close</button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                    <LinearProgress />
                </Box>
            )
            }
        </div >
    );
}

export default ViewSaleBody;
