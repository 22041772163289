import axios from "axios";

// Handle form submission to create a new expenses category
export const handleFormSubmit = async (e, setLoading, expensesName, setExpensesName, setResponse) => {
    e.preventDefault();

    const expensesData = { expensesName };
    setLoading(true); // Start loading indicator

    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/createExpensesCategory`, expensesData);
        console.log(response.data.message);

        // Clear form fields and display success message
        setExpensesName('');
        setResponse(response.data.message);
        console.log('Expenses created successfully:', response.data.message);
    } catch (error) {
        const errorMessage = error.response?.data?.message || "Failed to create expenses category.";
        console.error('Error creating expenses category:', errorMessage);
        // setResponse(errorMessage); // Set error message for display
    } finally {
        setLoading(false); // Stop loading indicator
    }
};

// Fetch all expenses categories
export const fetchExpensesCatData = async (setExCategoryData, setLoading, setError) => {
    setLoading(true);

    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/getExpensesCategory`);
        setExCategoryData(response.data.data);
        console.log('Fetched expenses categories:', response.data.data);
    } catch (error) {
        const errorMessage = error.response?.data?.message || "Failed to fetch expenses categories.";
        console.error('Error fetching expenses categories:', errorMessage);
        setError(errorMessage);
    } finally {
        setLoading(false);
    }
};
// Delete an expense category
export const handleDelete = async (_id, exCategoryData, setExCategoryData) => {
    try {
        await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/deleteExpensesCategory/${_id}`);
        setExCategoryData(exCategoryData.filter(expenses => expenses._id !== _id));
    } catch (error) {
        const errorMessage = error.response?.data?.message || "Failed to delete the expense.";
        console.error('Error deleting expense:', errorMessage);
    }
};

// Fetch an expense by ID for editing
export const fetchExpensesById = async (id, setEditExpensesName, setSelectedExpensesCatId, setIsPopUpEdit) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/findExpensesById`, {
            params: { id }
        });
        const expenses = response.data.data;
        console.log(expenses)
        setEditExpensesName(expenses.expensesName);
        setSelectedExpensesCatId(id);
        setIsPopUpEdit(true); // Open the popup for editing
        console.log('Fetched expense for editing:', expenses);
    } catch (error) {
        const errorMessage = error.response?.data?.message || "Failed to fetch expense.";
        console.error('Error fetching expense by ID:', errorMessage);
    }
};

// Update an expense category
export const updateExpenses = async (e, selectedExpensesCatId, editExpensesName, setEditExpensesName, setResponse, setIsPopUpEdit) => {
    e.preventDefault();

    const updatedExpensesData = { editExpensesName };
    try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/updateExpensesCategory/${selectedExpensesCatId}`, updatedExpensesData);
        console.log('expenses updated successfully:', response.data.message);

        setEditExpensesName('');
        setResponse(response.data.message);
        setIsPopUpEdit(false); // Close the popup
        console.log('Expense updated successfully:', response.data.message);
    } catch (error) {
        const errorMessage = error.response?.data?.message || "Failed to update expense.";
        console.error('Error updating expense:', errorMessage);
    }
};

// Search for expenses by name
export const handleSubmit = async (inputValue, setLoading, setSearchedCurrencyByName) => {
    setLoading(true);

    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/findCurruncyByName`, {
            params: { currencyName: inputValue } // Use the passed keyword
        });

        const data = response.data?.data || [];
        setSearchedCurrencyByName(data);
        console.log('Searched expenses:', data);
    } catch (error) {
        const errorMessage = error.response?.data?.message || "Failed to find expenses by name.";
        console.error('Error searching for expenses:', errorMessage);
        setSearchedCurrencyByName([]);
    } finally {
        setLoading(false);
    }
};
