import React, { forwardRef, useState, useEffect } from "react";
import axios from "axios"; // Ensure axios is imported
import logo from '../../../img/shop-logo.jpg';

const currentDate = new Date().toLocaleDateString();
const currentTime = new Date().toLocaleTimeString();

const generateBillNumber = () => {
    return `BILL-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
};

const billNumber = generateBillNumber();

const PrintInvoice = forwardRef((props, ref) => {
    const [isOn, setIsOn] = useState({
        phone: false,
        customer: false,
        taxDiscountShipping: false,
        barcode: false,
        logo: false,
    });
    const [error, setError] = useState('');
    console.log(error)
    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/getReceiptSettings`);
                console.log(data);

                setIsOn({
                    phone: data.phone || false,
                    customer: data.customer || false,
                    address: data.address || false,
                    email: data.email || false,
                    taxDiscountShipping: data.taxDiscountShipping || false,
                    barcode: data.barcode || false,
                    productCode: data.productCode || false,
                    logo: data.logo || false,
                });
            } catch (error) {
                console.error('Error fetching settings:', error);
                setError('Error fetching settings');
            }
        };

        fetchSettings();
    }, []);

    return (
        <div
            ref={ref}
            className="p-2 bg-white text-gray-800 border border-gray-300 w-[80mm]"
        >
            <div className="print-container">
                {/* Logo section */}
                {isOn.logo && (
                    <img className="w-8 h-8 mx-auto" src={logo} alt="logo" /> // Logo
                )}

                <h1 className="text-lg font-bold pb-2 text-center">Keells Super</h1>
                <p className="text-center text-sm">Thannekubura</p>

                {/* Phone Section */}
                {isOn.phone && (
                    <>
                        <p className="text-center text-sm">0776363698 / 0662246985</p>
                        <p className="w-full text-center">--------------------------------</p>
                    </>
                )}

                {/* Customer and Order Details Section */}

                <div className="flex justify-between">
                    {isOn.customer && isOn.taxDiscountShipping && (
                        <div className="mb-4 w-1/2">
                            <p className="text-xs p-1"><strong>Customer:</strong> {props.selectedCustomer}</p>
                            <p className="text-xs p-1"><strong>Total Products:</strong> {props.qty}</p>
                            <p className="text-xs p-1"><strong>Order Tax:</strong> {props.tax} % </p>
                            <p className="text-xs p-1"><strong>Discount:</strong> Rs {props.discount}</p>
                            <p className="text-xs p-1"><strong>Shipping:</strong> Rs {props.shipping}</p>
                        </div>
                    )}

                    <div className="mb-4 w-1/2">
                        <p className="text-xs p-1"><strong>Date:</strong> {currentDate}</p>
                        <p className="text-xs p-1"><strong>Time:</strong> {currentTime}</p>
                    </div>
                </div>

                {/* Order Details Section */}
                <p className="w-full">--------------------------------</p>

                <div className="mb-4">
                    <h2 className="text-sm font-semibold pb-1 mb-2">Product Details</h2>
                    <table className="w-full text-xs" style={{ borderCollapse: "collapse" }}>
                        <thead>
                            <tr>
                                <th className="px-1 py-1 w-1/6">Product</th>
                                <th className="px-1 py-1 w-1/6">Qty</th>
                                <th className="px-1 py-1 w-1/6">Price</th>
                                <th className="px-1 py-1 w-1/6">Subtotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.productDetails.map((product, index) => {
                                const price = parseFloat(product.price) || 0;
                                const qty = parseInt(product.qty, 10) || 0;
                                return (
                                    <tr key={index}>
                                        <td className="px-1 py-1 whitespace-normal break-words">{product.name}</td>
                                        <td className="px-1 py-1 text-center">{qty}</td>
                                        <td className="px-1 py-1 text-right">Rs {price.toFixed(2)}</td>
                                        <td className="px-1 py-1 text-right">Rs {(price * qty).toFixed(2)}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                <p className="text-sm font-bold mt-5"><strong>Grand Total:</strong> Rs {props.total}</p>
                <p className="w-full">--------------------------------</p>

                {/* Payment Details */}
                <div>
                    <h2 className="text-sm font-semibold pb-1 mb-2">Payment Details</h2>
                    <p className="text-xs"><strong>Received:</strong> Rs {props.receivedAmount}</p>
                    <p className="text-xs"><strong>Return:</strong> Rs {props.returnAmount}</p>
                    <p className="text-xs"><strong>Payment Type:</strong> {props.paymentType}</p>
                    <p className="text-xs"><strong>Note:</strong> {props.note}</p>
                    <p className="text-xs"><strong>Status:</strong> {props.paymentStatus}</p>
                </div>

                <p className="w-full mt-2">--------------------------------</p>


                <div className="mt-2 mb-5">
                    {/* Bill Number and Cashier */}
                    {isOn.barcode && (
                        <p className="text-xs text-center"><strong>Bill number:</strong> {billNumber}</p>
                    )}
                    <p className="text-xs text-center"><strong>Cashier:</strong>{props.registerData}</p>
                </div>

            </div>
        </div>
    );
});

export default PrintInvoice;
