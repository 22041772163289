// Frontend: ViewRoleAndPermissionBody.js
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import '../../styles/login.css';

function ViewRoleAndPermissionBody() {
    const [permissionData, setPermissionData] = useState([]);
    const [roleName, setKeyword] = useState('');
    const [searchedRole, setSearchedRole] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    useEffect(() => {
        const fetchPermissionData = async () => {
            setLoading(true);
            setError(null);  // Reset error message on new fetch
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/getJobRoles`);
                console.log('Fetched permission data:', response.data);
                if (response.data?.jobRoles) {
                    setPermissionData(response.data.jobRoles);
                } else {
                    setError('No roles found.');
                }
            } catch (error) {
                console.error('Fetch permission data error:', error);
                setError('Failed to fetch roles. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchPermissionData();
    }, []);

    // Function to handle delete
    const handleDelete = async (_id) => {
        setLoading(true);
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/deleteRole/${_id}`);
            setPermissionData(prevData => prevData.filter(permission => permission._id !== _id));
            setResponseMessage('Role deleted successfully.');
        } catch (error) {
            console.error('Delete error:', error);
            setError('Failed to delete role. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    // Function to handle delete when searching
    const handleDeleteByFind = async (_id) => {
        setLoading(true);
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/deleteRole/${_id}`);
            setSearchedRole(null); 
            setResponseMessage('Role deleted successfully.');
        } catch (error) {
            console.error('Delete error:', error);
            setError('Failed to delete role. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleFindRole = (e) => {
        setKeyword(e.target.value);
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);  // Reset error message on new submit
        setResponseMessage(null); // Reset success message on new submit

        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/findRole/${roleName}`);
            console.log('Search response:', response.data);
            
            if (response.data.length > 0) {
                setSearchedRole(response.data[0]);
                setResponseMessage('Role found successfully.');
            } else {
                setSearchedRole(null);
                setError('Role not found.');
            }
        } catch (error) {
            console.error('Find role error:', error);
            setError('Failed to find the role. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='relative bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] h-[100vh] p-5'>
            <div className='flex justify-between mb-4'>
                <div className="relative w-full max-w-md">
                    <form onSubmit={handleSubmit} className="flex items-center">
                        <input
                            onChange={handleFindRole}
                            name='keyword'
                            type="text"
                            placeholder="Search..."
                            className="searchBox w-80 pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                            value={roleName}
                        />
                        <button type="submit" className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-400">
                            <svg
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M9 3a6 6 0 100 12A6 6 0 009 3zm0-1a7 7 0 110 14A7 7 0 019 2z"
                                    clipRule="evenodd"
                                />
                                <path
                                    fillRule="evenodd"
                                    d="M12.9 12.9a1 1 0 011.41 0l3 3a1 1 0 01-1.41 1.41l-3-3a1 1 0 010-1.41z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </form>
                </div>
                <div className="flex items-center">
                    <div>
                        <Link
                            to={'/CreateroleAndPermissions'}
                            className="submit flex-none rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-40 text-center"
                        >
                            Create Permissions
                        </Link>
                    </div>
                </div>
            </div>

            {loading ? (
                <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                    <LinearProgress />
                </Box>
            ) : searchedRole ? (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role name</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Permissions</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            <tr key={searchedRole._id}>
                                <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{searchedRole.roleName}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">
                                {Object.entries(searchedRole).map(([key, value]) => {
                                            if (key !== '_id' && key !== 'roleName' && key !== '__v') {
                                                return (
                                                    <div key={key}>
                                                        {`${key.replace(/([A-Z])/g, ' $1')}: ${value}`}
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">
                                    <div className='flex items-center'>
                                        <Link to={`/editPermissions/${searchedRole._id}`}
                                            className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2 flex items-center"
                                            style={{ background: 'transparent' }}
                                        >
                                            <i className="fas fa-edit mr-1"></i>
                                        </Link>
                                        <button
                                            onClick={() => handleDeleteByFind(searchedRole._id)}
                                            className="text-red-500 hover:text-red-700 font-bold py-1 px-2 flex items-center"
                                            style={{ background: 'transparent' }}
                                        >
                                            <i className="fas fa-trash mr-1"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : permissionData.length > 0 ? (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role name</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Permissions</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {permissionData.map((Permission) => (
                                <tr key={Permission._id}>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">{Permission.roleName}</td>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">
                                        {Object.entries(Permission).map(([key, value]) => {
                                            if (key !== '_id' && key !== 'roleName' && key !== '__v') {
                                                return (
                                                    <div key={key}>
                                                        {`${key.replace(/([A-Z])/g, ' $1')}: ${value}`}
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                    </td>

                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">
                                        <div className='flex items-center'>
                                            <Link to={`/editPermissions/${Permission._id}`}
                                                className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2 flex items-center"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-edit mr-1"></i>
                                            </Link>
                                            <button
                                                onClick={() => handleDelete(Permission._id)}
                                                className="text-red-500 hover:text-red-700 font-bold py-1 px-2 flex items-center"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-trash mr-1"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <p className="text-gray-500 text-center mt-4"></p>
            )}
            {/* Error and Response Messages */}
            {error && <p className="text-red-600 px-5 py-2 rounded-md bg-red-100 mt-5 text-center inline-block">{error}</p>}
                    {responseMessage && <p className="text-green-600 px-5 py-2 rounded-md bg-green-100 mt-5 text-center inline-block">{responseMessage}</p>}
        </div>
    );
}

export default ViewRoleAndPermissionBody;
