import { useState, useEffect, useRef } from 'react';
import CryptoJS from 'crypto-js';
import '../../../styles/role.css';
import 'react-loading-skeleton/dist/skeleton.css'
import '../utils/fetchDefaultData';
import Menu from '../../../img/menu (2).png';
import pro from '../../../img/cubes.png';
import Full from '../../../img/full-screen.png';
import Cal from '../../../img/calculator.png';
import Back from '../../../img/return (2).png';
import Box from '@mui/material/Box';
import Skeleton from 'react-loading-skeleton'
import BillingSection from './posBillCalculation';
import popSound from '../../../../src/audio/b.mp3';
import axios from 'axios';
import LinearProgress from '@mui/material/LinearProgress';
import Calculator from './posCalCulator';
import ProductVariationModal from './productVariationEdit';
import { handleFindProductBySearch, handleProductSubmit } from '../utils/searchProduct';
import { getHeldProducts, handleDeleteHoldProduct } from '../utils/holdProductControll';
import { fetchCategoryData } from '../utils/fetchByCategory';
import { fetchBrandData } from '../utils/fetchByBrand';
import { fetchAllData } from '../utils/fetchAllData';
import { handleFullScreen } from '../utils/fullScreenView';
import { handlePopupOpen } from '../utils/registerHandling';
import { fetchProductDataByWarehouse } from '../utils/fetchByWarehose';
import { getPriceRange, getQty, getTax } from '../utils/qtyAndPriceCalculation';
import { useNavigate, Link } from "react-router-dom";

function PosSystemBody() {
    const ProductIcon = 'https://cdn0.iconfinder.com/data/icons/creative-concept-1/128/PACKAGING_DESIGN-512.png';
    // State management
    const [warehouse, setWarehouse] = useState('');
    const [productData, setProductData] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState('')
    const [searchCustomerResults, setSearchCustomerResults] = useState([]);
    const [defaultProductData, setDefaultProductData] = useState([]);
    const [searchedProductData, setSearchedProductData] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [Productkeyword, setProductKeyword] = useState('');
    const [brandData, setBrandData] = useState([]);
    const [selectedBrandProducts, setSelectedBrandProducts] = useState([]);
    const [selectedCategoryProducts, setSelectedCategoryProducts] = useState([]);
    const [warehouseData, setWarehouseData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [productBillingHandling, setProductBillingHandling] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectVariation, setSelectVariation] = useState(false);
    const [showCalculator, setShowCalculator] = useState(false);
    const [isHoldList, setIsHoldList] = useState(false)
    const [heldProducts, setHeldProducts] = useState([])
    const [heldProductsQty, setHeldProductsQtys] = useState([]);
    const [isExitingPopupOpen, setIsExitingPopupOpen] = useState(false);
    const [isPopUpRegisterReport, setIsPopUpRegisterReport] = useState(false);
    const [registerData, setRegisterData] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const inputRef = useRef(null);

    //COMBINE ALL DATA FETCHING TYPE INTO ONE STATE
    const combinedProductData =
        searchedProductData.length > 0
            ? searchedProductData
            : (selectedBrandProducts.length > 0
                ? selectedBrandProducts
                : (selectedCategoryProducts.length > 0
                    ? selectedCategoryProducts
                    : (productData.length > 0
                        ? productData
                        : defaultProductData)));
    const navigate = useNavigate();

    // Reusable function for making API requests
    const fetchData = async (url, setter) => {
        setLoading(true);
        try {
            const { data } = await axios.get(url);
            setter(data);
        } catch (error) {
            console.error(`${url} fetch error:`, error);
            setter([]);
        } finally {
            setLoading(false);
        }
    };

    //FETCHINF ALL DATA (DEFAULT, BRAND OR CATEGORY)
    useEffect(() => {
        fetchAllData(setProductData, setSelectedCategoryProducts, setSelectedBrandProducts, setSearchedProductData, setLoading
        );
    }, []);


    // FETCHING ALL DATA BY WAREHOUSE
    const handleWarehouseChange = (e) => {
        const selectedWarehouse = e.target.value;
        setWarehouse(selectedWarehouse);
        if (selectedWarehouse) {
            fetchProductDataByWarehouse(selectedWarehouse, setProductData, setSelectedCategoryProducts, setSelectedBrandProducts, setSearchedProductData, setLoading
            );
        } else {
            setProductData([]);
        }
    };

    useEffect(() => {
        fetchData(`${process.env.REACT_APP_BASE_URL}/api/fetchWarehouses`, setWarehouseData);
        fetchData(`${process.env.REACT_APP_BASE_URL}/api/fetchBrands`, setBrandData);
        fetchData(`${process.env.REACT_APP_BASE_URL}/api/fetchCategories`, setCategoryData);
        return () => { };
    }, []);

    const playSound = () => {
        const audio = new Audio(popSound);
        audio.play().catch(error => {
            console.error('Audio play failed:', error);  // Check for errors
        });
    };

    useEffect(() => {
    }, [productBillingHandling]);

    const toggleCalculator = () => {
        setShowCalculator(!showCalculator);
    };

    useEffect(() => {
        getHeldProducts(setHeldProducts);
    }, []);

    // Effect to automatically add each searched product to the cart
    useEffect(() => {
        if (searchedProductData.length > 0) {
            searchedProductData.forEach((product) => {
                handleAddingProduct({
                    id: product._id,
                    name: product.name,
                    price: getPriceRange(product),
                    stokeQty: product.productQty || getQty(product),
                    tax: product.oderTax ? product.oderTax / 100 : getTax(product) / 100,
                    ptype: product.ptype,
                    variation: product.variation,
                    variationType: product.variationType,
                    variationValues: product.variationValues,
                });
            });
        }
    }, [searchedProductData]);

    const handleAddingProduct = (product) => {
        setProductBillingHandling((prevBilling) => {
            // Handle Single Product Type
            if (product.ptype === 'Single') {
                const existingProduct = prevBilling.find((p) => p.id === product.id);

                if (existingProduct) {
                    // Check if adding another unit exceeds the stock
                    if (existingProduct.qty + 1 > product.stokeQty) {
                        alert('Cannot add more than available stock.');
                        return prevBilling; // Do not add more if it exceeds stock
                    } else {
                        // Update quantity of the existing product
                        return prevBilling.map((p) =>
                            p.id === product.id
                                ? { ...p, qty: p.qty + 1 }
                                : p
                        );
                    }
                } else {
                    // Add product if it does not exist and has available stock
                    if (product.stokeQty > 0) {
                        console.log(product)
                        return [
                            ...prevBilling,
                            { ...product, qty: 1 },
                        ];
                    } else {
                        alert('This product is out of stock.');
                        return prevBilling; // Do not add if out of stock
                    }
                }

                // Handle Variation Product Type
            } else if (product.ptype === 'Variation') {
                const existingVariation = prevBilling.find(
                    (p) => p.id === product.id && p.selectedVariation === product.selectedVariation
                );

                // Check if variation exists from held products (to avoid adding manually)
                if (existingVariation) {
                    alert('This variation is already added from held products.');
                    return prevBilling; // Prevent adding manually
                }

                setSelectVariation(true);
                setSelectedProduct(product);
                console.log(product)
                return prevBilling;
            }
        });
        setProductKeyword('');
        inputRef.current.focus();
    };

    const handleHoldOpen = () => {
        console.log('handleHoldOpen called', isHoldList);
        setIsHoldList(!isHoldList);
    };


    // Handle search input change
    const handleFindUser = (e) => {
        setKeyword(e.target.value);
    };

    // Determine search type based on the keyword
    const determineSearchType = (keyword) => {
        if (/^\d+$/.test(keyword)) { // If the keyword is numeric
            return 'mobile';
        } else if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(keyword)) { // If the keyword looks like an email
            return 'username';
        } else {
            return 'name'; // Default to name if nothing else fits
        }
    };

    // Handle search form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const searchType = determineSearchType(keyword);
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/fetchCustomer`, {
                params: { keyword, searchType }
            });
            console.log(response.data)
            setSearchCustomerResults(response.data); // Save the search results// Select the first result if available
        } catch (error) {
            console.error('Find customer error:', error);
        }
    };

    const handleEditHoldProduct = async (heldProduct) => {
        try {
            const productToAdd = heldProduct.products.map(product => {
                const baseDetails = product.baseProductDetails || {};

                let productPrice = product.price;
                let productQty = baseDetails.productQty || null;

                if (product.variation && product.variationValues) {
                    const selectedVariation = product.variationValues[product.variation];

                    if (selectedVariation) {
                        productPrice = selectedVariation.productPrice || productPrice;
                        productQty = selectedVariation.productQty || productQty;
                    }
                }
                return {
                    holdProductID: product._id,
                    id: product.curruntID,
                    name: product.name,
                    tax:product.tax,
                    price: product.price || productPrice,
                    stokeQty: product.stokeQty || productQty,
                    qty: product.purchaseQty,
                    ptype: product.ptype || 'Single',
                    selectedVariation: product.variation ? product.variation : null,
                    variationValues: {
                        ...baseDetails.variationValues
                    },
                };

            });

            const uniqueProductsToAdd = productToAdd.filter(newProduct => {
                return !productBillingHandling.some(existingProduct => {
                    return existingProduct.curruntID === newProduct.curruntID &&
                        existingProduct.selectedVariation === newProduct.selectedVariation;
                });
            });
            setProductBillingHandling(uniqueProductsToAdd);
            handleDeleteHoldProduct(heldProduct._id, heldProducts, setHeldProducts)
            setIsHoldList(false);

        } catch (error) {
            console.error('Error fetching products by IDs:', error);
        }

    };

    const handlePopupClose = () => {
        setIsPopupOpen(false);
    };

    const handleExitingPopupClose = () => {
        setIsExitingPopupOpen(false);
    }

    const handleRegisterReportOpen = async () => {
        setIsPopUpRegisterReport(true);
        const fetchReportData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/findRegisterData`);
                if (response.data) {
                    setRegisterData(response.data.data);
                    console.log(response.data)
                } else {
                    console.error('Unexpected response format:', response.data);
                    setRegisterData([]);
                }
            } catch (err) {
                console.error('Error fetching report data:', err);
                setErrorMessage('Failed to fetch report data');
            }
        };
        fetchReportData();

        setIsPopupOpen(false)
    }

    const handleRegisterReportClose = () => {
        setIsPopUpRegisterReport(false)
    }
    const handlePOSClose = async () => {
        console.log('POS closed');
        const cashRegisterID = sessionStorage.getItem('cashRegisterID');
        console.log('Register ID:', cashRegisterID);

        try {
            // Attempt to delete the register
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/closeRegister/${cashRegisterID}`);

            // If successful, remove the ID from sessionStorage
            sessionStorage.removeItem('cashRegisterID');

            // If successful, remove the ID from localStorage
            sessionStorage.removeItem('cashierUsername');
            sessionStorage.removeItem('cashRegisterID');


            // Close the popup and navigate to the dashboard
            setIsPopupOpen(false);
            navigate('/dashboard');
        } catch (error) {
            console.error('Error closing POS:', error);

            // Set an error message to inform the user
            setErrorMessage('Failed to close POS due to server error. Please try again.');
        }
    };


    const handleExitingFromPos = () => {
        console.log('POS closed');
        setIsExitingPopupOpen(false);
        navigate('/dashboard');
    };

    let username = '';
    const encryptedCashierUsername = sessionStorage.getItem('cashierUsername');
    if (encryptedCashierUsername) {
        try {
            const userKey = CryptoJS.AES.decrypt(encryptedCashierUsername, 'ldunstvd');
            username = userKey.toString(CryptoJS.enc.Utf8);

            if (!username) {
                console.error('Decryption successful, but username is empty.');
            }
        } catch (error) {
            console.error('Error decrypting username:', error);
        }
    } else {
        console.error('No cashierUsername found in sessionStorage.');
    }

    return (
        <div className="bg-[#eff3f7] absolute w-full h-screen p-2 overflow-hidden">
            {/* HEADER SECTION */}
            <div className="flex justify-between  w-full h-[80px]">
                <div className="flex justify-between w-[35%] bg-white h-[80px] rounded-[15px] ">
                    <div className="w-1/2 h-[82px] flex items-center relative  pb-[2px]">
                        <form onChange={handleSubmit} className="flex items-center relative w-full">
                            <input
                                name="keyword"
                                type="text"
                                placeholder="Find Customer"
                                className="searchBox w-[100%] m-2 pl-10 py-5 px-4 border border-gray-300 rounded-[10px] shadow-sm focus:border-transparent"
                                value={keyword}
                                onChange={handleFindUser}
                            />
                            <button type="submit" className="absolute inset-y-0 left-0 pl-6 flex items-center text-gray-400">
                                <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M9 3a6 6 0 100 12A6 6 0 009 3zm0-1a7 7 0 110 14A7 7 0 019 2z" clipRule="evenodd" />
                                    <path fillRule="evenodd" d="M12.9 12.9a1 1 0 011.41 0l3 3a1 1 0 01-1.41 1.41l-3-3a1 1 0 010-1.41z" clipRule="evenodd" />
                                </svg>
                            </button>

                            {/* Right-side additional button */}
                            <Link
                                to={'/createCustomer'}
                                className="absolute inset-y-0 right-0 pr-4 flex items-center text-gray-400"
                            >
                                <img className='w-[30px] h-[30px]' src={'https://th.bing.com/th/id/OIP.vbmhgMWYNh0skrSCJNHhXAHaHa?w=512&h=512&rs=1&pid=ImgDetMain'} alt='add user' />
                            </Link>
                        </form>
                        {keyword && searchCustomerResults.length > 0 && (
                            <div className="absolute top-[90px] w-[278px] left-[7px] bg-white border border-gray-300 rounded-lg shadow-md">
                                <ul>
                                    {searchCustomerResults.map((customer, index) => (
                                        <li
                                            key={index}
                                            className="p-2 cursor-pointer hover:bg-gray-100"
                                            onClick={() => {
                                                setSelectedCustomer(customer.name);
                                                setKeyword('');
                                            }}
                                        >
                                            {customer.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>

                    <div className="w-1/2 h-[82px] flex items-center pb-[2px] relative rounded-[15px] mr-1 ">
                        <form className="w-full">
                            <select
                                id="warehouse"
                                name="warehouse"
                                value={warehouse}
                                onChange={handleWarehouseChange}
                                className="searchBox w-[97%]  pl-4 pr-2 py-5 border border-gray-300 rounded-[10px] shadow-sm focus:border-transparent"
                            >
                                <option value="">Select a warehouse</option>
                                {warehouseData.map((wh) => (
                                    <option key={wh.name} value={wh.name}>
                                        {wh.name}
                                    </option>
                                ))}
                            </select>
                        </form>
                    </div>
                </div>

                <div className="w-[65%] ml-2 rounded-[15px] items-center h-[80px] bg-white flex">
                    <form
                        onSubmit={(e) => handleProductSubmit(e, Productkeyword, setLoading, setSearchedProductData)}
                        className="flex items-center relative"
                    >
                        <input
                            name="Productkeyword"
                            type="text"
                            placeholder="Find Product By name or code"
                            className="searchBox sm:w-[10vw] md:w-[20vw] lg:w-[27vw] xl:w-[37vw] m-2 pl-10 pr-2 py-5 px-4 border border-gray-300 rounded-[10px] shadow-sm focus:border-transparent"
                            value={Productkeyword}
                            ref={inputRef}
                            onChange={(e) => handleFindProductBySearch(e, setProductKeyword, (keyword) => handleProductSubmit(keyword, setLoading, setSearchedProductData,))}
                        />
                        <p type="button" className="absolute inset-y-0 left-0 pl-6 flex items-center text-gray-400">
                            <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M9 3a6 6 0 100 12A6 6 0 009 3zm0-1a7 7 0 110 14A7 7 0 019 2z" clipRule="evenodd" />
                                <path fillRule="evenodd" d="M12.9 12.9a1 1 0 011.41 0l3 3a1 1 0 01-1.41 1.41l-3-3a1 1 0 010-1.41z" clipRule="evenodd" />
                            </svg>
                        </p>
                    </form>
                    {/* Action Buttons */}
                    <div className="relative p-2 m-2 w-[75px] h-[65px] border submit rounded-[10px] flex items-center justify-center">
                        <button onClick={() => handleHoldOpen(setIsHoldList)}>
                            <img className="w-[45px] h-[45px]" src={Menu} alt="" />
                        </button>

                        {/* Notification Badge */}
                        {heldProducts && heldProducts.length > 0 && (
                            <span className="absolute top-[-8px] right-[-8px] bg-red-400 text-white text-xs rounded-full w-6 h-6 p-2 flex items-center justify-center">
                                {heldProducts.length}
                            </span>
                        )}
                    </div>

                    {/* Popup for Hold list */}
                    {isHoldList && heldProducts && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                            <div className="bg-white w-[600px] h-auto p-6 rounded-md shadow-lg">
                                <h2 className="text-xl font-semibold mb-4">Held Products</h2>

                                {/* Table to display held products */}
                                <table className="min-w-full bg-white border">
                                    <thead>
                                        <tr>
                                            <th className="border px-4 py-2">ID</th>
                                            <th className="border px-4 py-2">Reference No</th>
                                            <th className="border px-4 py-2">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {heldProducts.map((product) => (
                                            <tr key={product._id}>
                                                <td className="border px-4 py-2">{product._id}</td>
                                                <td className="border px-4 py-2">{product.referenceNo}</td>
                                                <td className="border px-4 py-2 flex">
                                                    {/* Edit and Delete actions */}
                                                    <button
                                                        className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2 flex items-center"
                                                        style={{ background: 'transparent' }}
                                                        onClick={() => handleEditHoldProduct(product)}
                                                    >
                                                        <i className="fas fa-edit mr-1"></i>
                                                    </button>
                                                    <button
                                                        className="text-red-500 hover:text-red-700 font-bold py-1 px-2"
                                                        style={{ background: 'transparent' }}
                                                        onClick={() => handleDeleteHoldProduct(product._id, heldProducts, setHeldProducts)}
                                                    >
                                                        <i className="fas fa-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <div className="flex justify-end mt-4">
                                    <button
                                        className="px-4 py-2 bg-red-500 text-white rounded-md"
                                        onClick={() => handleHoldOpen(setIsHoldList)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="p-2 m-2 w-[75px] h-[65px] border submit rounded-[10px] flex items-center justify-center">
                        <button onClick={() => handlePopupOpen(setIsPopupOpen)}>
                            <img className="w-[45px] h-[45px]" src={pro} alt="" />
                        </button>
                    </div>

                    {/* Popup for pos close*/}
                    {isPopupOpen && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                            <div className="bg-white w-[400px] h-[240px] p-6 rounded-md shadow-lg">
                                <h2 className="text-xl font-semibold">Closing POS</h2>
                                <p>Are you sure you want to Close the Register?</p>
                                <div className="flex  mt-4">
                                    <button
                                        className="px-4 py-2 mr-2 bg-gray-500 text-white rounded-md"
                                        onClick={() => handlePopupClose(setIsPopupOpen)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="px-4 py-2 bg-green-500 text-white rounded-md"
                                        onClick={() => {
                                            console.log('POS closed');
                                            handleRegisterReportOpen(setIsPopUpRegisterReport, setIsPopupOpen);
                                        }}
                                    >
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Popup Register report*/}
                    {isPopUpRegisterReport && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                            <div className="bg-white w-[60%] h-[400px] p-6 rounded-md shadow-lg">
                                <h2 className="text-xl font-semibold">Register Report</h2>
                                {loading ? (
                                    <p>Loading</p>
                                ) : registerData.length > 0 ? (
                                    <div className="overflow-x-auto p-6">
                                        <table className="min-w-full bg-white border border-gray-200">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Open Time</th>
                                                    <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Username</th>
                                                    <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">user</th>
                                                    <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cash hand in</th>
                                                    <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {registerData.map((reg) => (
                                                    <tr key={reg._id}>
                                                        <td className="px-7 py-5 whitespace-nowrap text-m text-gray-900"><p className="rounded-[5px] text-center p-[6px] bg-red-100 text-red-500">{reg.openTime}</p></td>
                                                        <td className="px-7 py-5 whitespace-nowrap text-m text-gray-900">{username || 'Unknown'}</td>
                                                        <td className="px-7 py-5 whitespace-nowrap text-m text-gray-900">{reg.name}</td>
                                                        <td className="px-4 py-5 whitespace-nowrap text-m text-gray-900"> <p className="rounded-[5px] text-center py-[6px] bg-blue-100 text-blue-500">Rs {reg.cashHandIn}</p></td>
                                                        <td className="px-7 py-5 whitespace-nowrap text-m text-gray-900"> <p className="rounded-[5px] text-center py-[6px] bg-green-100 text-green-500">Rs {reg.totalBalance}</p></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        <div className="flex  mt-4">
                                            <button
                                                className="px-4 py-2 mr-2 bg-gray-500 text-white rounded-md"
                                                onClick={() => handleRegisterReportClose(setIsPopupOpen)}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="px-4 py-2 bg-green-500 text-white rounded-md"
                                                onClick={() => {
                                                    console.log('POS closed');
                                                    handlePOSClose(setIsPopupOpen, navigate);
                                                }}
                                            >
                                                Confirm
                                            </button>
                                        </div>
                                    </div>
                                ) :
                                    null}
                            </div>
                        </div>
                    )}


                    <div className="p-2 m-2 w-[75px] h-[65px]  border bg-[#6571ff] rounded-[10px] flex items-center justify-center">
                        <button className='' onClick={handleFullScreen}>
                            <img className="w-[45px] h-[45px]" src={Full} alt="" />
                        </button>
                    </div>

                    <div className="p-2 m-2 w-[75px] h-[65px]  pb-2 border bg-[#6571ff] rounded-[10px] flex items-center justify-center">
                        <button onClick={toggleCalculator}>
                            <img className="w-[45px] h-[45px]" src={Cal} alt="Calculator" />
                        </button>
                    </div>

                    <div className="p-2 m-2 w-[75px] h-[65px]  pb-2 border bg-gray-800  rounded-[10px] flex items-center justify-center">
                        <button onClick={() => setIsExitingPopupOpen(true)} className="focus:outline-none">
                            <img className="w-[45px] h-[45px]" src={Back} alt="Back" />
                        </button>
                    </div>
                    {/* The Popup modal */}
                    {isExitingPopupOpen && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                            <div className="bg-white w-[400px] h-[240px] p-6 rounded-md shadow-lg">
                                <h2 className="text-xl font-semibold">Exiting POS</h2>
                                <p>Do you want to exit without closing the POS?</p>
                                <div className="flex mt-4">
                                    {/* Cancel button */}
                                    <button
                                        className="px-4 py-2 mr-2 bg-gray-500 text-white rounded-md"
                                        onClick={() => handleExitingPopupClose(setIsExitingPopupOpen)}
                                    >
                                        Cancel
                                    </button>
                                    {/* Confirm button */}
                                    <button
                                        className="px-4 py-2 bg-green-500 text-white rounded-md"
                                        onClick={handleExitingFromPos}
                                    >
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* MAIN BODY SECTION */}
            {/* Produc billing section in right */}
            <div className="flex justify-between mt-2 w-full h-screen">
                <div className="w-[34.6%] h-screen rounded-[15px] bg-white p-4">
                    <div>
                        <BillingSection
                            productBillingHandling={productBillingHandling}
                            setProductBillingHandling={setProductBillingHandling}
                            handleDeleteHoldProduct={handleDeleteHoldProduct}
                            setProductData={setProductData}
                            selectedCustomer={selectedCustomer}
                            setSelectedCustomer={setSelectedCustomer}
                            warehouse={warehouse}
                        />
                    </div>
                </div>

                <div className="w-[65%] ml-2 rounded-[15px] h-screen bg-white">
                    {/* Brands selection section */}
                    <div className="w-full overflow-x-auto p-4 bg-white rounded-[15px]">
                        <div className="flex space-x-2">
                            {/* All Brands Button */}
                            <button
                                onClick={() => fetchAllData(setProductData, setSelectedCategoryProducts, setSelectedBrandProducts, setSearchedProductData, setLoading)}
                                className="submit rounded-lg px-4 py-2 flex-shrink-0 flex flex-col items-center justify-center transition-colors"
                            >
                                <h3 className="text-center text-m font-medium text-white">All Brands</h3>
                            </button>
                            {brandData.map((b) => (
                                <button
                                    key={b._id}
                                    onClick={() => fetchBrandData(b.brandName, setSelectedBrandProducts, setSelectedCategoryProducts, setSearchedProductData, setLoading)}
                                    className="flex-shrink-0 border border-gray-200 rounded-lg px-4 py-2 flex flex-col items-center justify-center hover:shadow-md"
                                >
                                    <h3 className="text-center text-m font-medium text-gray-900">{b.brandName}</h3>
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Category selection section */}
                    <div className="w-full overflow-x-auto p-4 bg-white rounded-[15px]">
                        <div className="flex space-x-4">
                            {/* All Category Button */}
                            <button onClick={() => fetchAllData(setProductData, setSelectedCategoryProducts, setSelectedBrandProducts, setSearchedProductData, setLoading)}
                                className="submit rounded-lg px-4 py-2 flex-shrink-0 flex flex-col items-center justify-center transition-colors">
                                <h3 className="text-center text-m font-medium text-white">All Categories</h3>
                            </button>
                            {categoryData.map((c) => (
                                <button
                                    key={c._id}
                                    onClick={() => fetchCategoryData(c.category, setSelectedCategoryProducts, setSelectedBrandProducts, setSearchedProductData, setLoading)}
                                    className="flex-shrink-0 border border-gray-200 rounded-lg px-4 py-2 flex flex-col items-center justify-center hover:shadow-md"
                                >
                                    <h3 className="text-center text-m font-medium text-gray-900">{c.category}</h3>
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Product data display section */}
                    {loading ? (
                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-5 rounded-[18px] xl:gap-[4px] 2xl:gap-2 lg:gap-[4px] md:gap-2 p-4 bg-white">
                            {Array(20).fill().map((_, index) => (
                                <div key={index} className="w-[200px] rounded-[15px]">
                                    <Skeleton height={183} width={183} className="rounded-[15px]" />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="h-[500px] overflow-y-scroll">
                            {/* Check if searchedProductData has items */}
                            {combinedProductData.length > 0 ? (
                                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-5 rounded-[15px] xl:gap-[6px] 2xl:gap-1 lg:gap-2 md:gap-2  p-4 bg-white">
                                    {combinedProductData.map((p) => (
                                        <div
                                            key={p._id}
                                            className="shadow-md hover:shadow-lg w-[183px] h-[183px] border border-gray-200 rounded-lg p-4 flex flex-col items-center justify-center"
                                            onClick={() => {
                                                playSound();
                                                handleAddingProduct({
                                                    id: p._id,
                                                    name: p.name,
                                                    price: getPriceRange(p),
                                                    stokeQty: p.productQty || getQty(p),
                                                    tax: p.oderTax ? p.oderTax / 100 : getTax(p) / 100,
                                                    ptype: p.ptype,
                                                    variation: p.variation,
                                                    variationType: p.variationType,
                                                    variationValues: p.variationValues,
                                                });
                                            }}
                                        >
                                            <img
                                                src={p.image || ProductIcon}
                                                alt={p.name}
                                                className="w-20 h-20 object-cover rounded-md"
                                            />
                                            <h3 className="mt-2 text-center text-m font-medium text-gray-900">{p.name}</h3>
                                            <p className="text-center text-sm text-gray-600">{p.code}</p>
                                            <div className="flex space-between items-center">
                                                <p className="bg-blue-400 px-2 py-[2px] mr-2 rounded-[13px] text-center text-xs text-white">
                                                    {'Rs ' + getPriceRange(p)}
                                                </p>
                                                <p className="bg-blue-600 px-2 py-[1px] rounded-[13px] text-center text-xs text-white">
                                                    {p.productQty || getQty(p)}{' ' + p.saleUnit}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                    {heldProductsQty.map((product) => (
                                        <li key={product.id}>
                                            {/* Render product details here */}
                                            <p>Product Name: {product.name}</p>
                                            <p>QTY: {product.quantity || getQty(product)}</p>
                                            {/* Add more details as needed */}
                                        </li>
                                    ))}
                                </div>

                            ) : (
                                <>
                                    <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                                        <LinearProgress />
                                    </Box>
                                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-5 rounded-[15px] xl:gap-[4px] 2xl:gap-2 lg:gap-[4px] md:gap-2 p-4 bg-white">
                                        {Array(20).fill().map((_, index) => (
                                            <div key={index} className="w-[200px] rounded-[15px]">
                                                <Skeleton height={183} width={183} className="rounded-[15px]" />
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                    )}

                    {/* VARIATION */}
                    <div>
                        {selectVariation && (
                            <ProductVariationModal
                                selectedProduct={selectedProduct}
                                setSelectVariation={setSelectVariation}
                                productBillingHandling={productBillingHandling}
                                setProductBillingHandling={setProductBillingHandling}
                                setProductKeyword={setProductKeyword}
                                inputRef={inputRef}
                            />
                        )}
                    </div>

                    {/* CALCULATOR */}
                    {showCalculator && (
                        <div className="fixed top-0 right-0  p-4  rounded-lg">
                            <Calculator />
                            <button onClick={toggleCalculator} className="relative bottom-[95px] mt-5 left-[38px] bg-gray-200 p-2 rounded-lg hover:bg-gray-300">
                                Close
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <div>
                {errorMessage && <p className="text-green-500 mt-5 text-center">{errorMessage}</p>}
            </div>
        </div>
    );
}
export default PosSystemBody;
