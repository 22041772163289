import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { handleProductSelect, handleProductSearch, handleWarehouseChange } from '../sales/SaleController';
import { handleUpdateAdjustment } from './AdjustmentController';
import '../../styles/role.css';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { fetchProductDataByWarehouse } from '../pos/utils/fetchByWarehose';
import Decrease from '../../img/down-arrow (1).png'

function EditAdjustmentBody() {
  // State management
  const [warehouse, setWarehouse] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedCategoryProducts, setSelectedCategoryProducts] = useState([]);
  const [selectedBrandProducts, setSelectedBrandProducts] = useState([]);
  const [productBillingHandling, setSearchedProductData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [saleProduct, setSaleProduct] = useState([])
  const [saleReturProductData, setSaleReturProductData] = useState([])
  const [selectedDate, setSelectedDate] = useState('');
  const { id } = useParams();
  const [adjustmentTypes, setAdjustmentTypes] = useState(''); // Initialize with fetched types


    // Fetch adjustment by ID
    useEffect(() => {
      const findSaleById = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/findAdjustmentById/${id}`);
          const fetchedProductsQty = response.data.productsData || [];
          const initializedProductsQty = fetchedProductsQty.map((pq) => ({
            ...pq,
            quantity: pq.quantity || Object.keys(pq.quantity)[0]
          }));
          setSaleReturProductData(initializedProductsQty);
          setSaleProduct(response.data);
        } catch (error) {
          console.error("Error fetching sale by ID:", error);
          const errorMessage =
            error.response?.data?.message || "An error occurred while fetching sale details.";
          alert(errorMessage);
        }
      };
    
      if (id) {
        findSaleById();
      }
    }, [id]);
    console.log("product of this sale ", saleProduct)

    const calculateTotal = () => {
      try {
        const productTotal = selectedProduct.reduce((total, product) => {
          const productPrice = Number(product.price) || 0;
          const productQty = product.barcodeQty || 1;
          const taxRate = product.oderTax ? product.oderTax / 100 : 0;
    
          const subTotal = productPrice * productQty * (1 + taxRate);
          return total + subTotal;
        }, 0);
        const grandTotal = productTotal;
        return grandTotal.toFixed(2);
      } catch (error) {
        console.error("Error calculating total:", error);
        alert("An error occurred while calculating the total.");
        return "0.00";
      }
    };

    //HANDLE DATE
    useEffect(() => {
      try {
        if (saleProduct.date) {
          const formattedDate = new Date(saleProduct.date).toISOString().slice(0, 10);
          setSelectedDate(formattedDate);
        }
      } catch (error) {
        console.error("Error formatting date:", error);
        alert("An error occurred while processing the date.");
      }
    }, [saleProduct.date]);
    
    const handleDateChange = (e) => {
      try {
        setSelectedDate(e.target.value);
      } catch (error) {
        console.error("Error handling date change:", error);
        alert("An error occurred while updating the date.");
      }
    };

  // HANDLE ADJUSTMENT TYPE
  useEffect(() => {
    try {
      saleReturProductData.forEach((product) => {
        if (product.AdjustmentType) {
          setAdjustmentTypes(product.AdjustmentType);
        }
      });
    } catch (error) {
      console.error("Error setting adjustment types:", error);
      alert("An error occurred while processing adjustment types.");
    }
  }, [saleReturProductData]);

  const handleTypeChange = (index, value) => {
    try {
      setAdjustmentTypes(value);
    } catch (error) {
      console.error("Error handling type change:", error);
      alert("An error occurred while updating the adjustment type.");
    }
  };

  const handleQtyChange = (index, delta) => {
    try {
      setSaleReturProductData((prev) => {
        const currentQty = prev[index]?.quantity || 1;
        let newQty = currentQty + delta;
        const stockQty = prev[index]?.stokeQty || 0;

        // Ensure the quantity is within valid bounds
        newQty = Math.max(1, Math.min(newQty, stockQty));

        const productPrice = prev[index]?.price || 0;
        const productTaxRate = prev[index]?.taxRate || 0;
        const newSubtotal = productPrice * newQty * (1 + productTaxRate);
        // Update the state with new quantity and subtotal
        const updatedSaleReturnData = prev.map((item, i) =>
          i === index
            ? { ...item, quantity: newQty, subtotal: newSubtotal.toFixed(2) }
            : item
        );
        console.log("Updated State:", updatedSaleReturnData);
        return updatedSaleReturnData;
      });
    } catch (error) {
      console.error("Error updating quantity:", error);
      alert("An error occurred while updating the quantity.");
    }
  };


  const handleDelete = async (adjustmentID, productID) => {
    const total = calculateTotal();
    console.log('purchaseID ', adjustmentID, 'product id ', productID);
    try {
      const confirmDelete = window.confirm("Are you sure you want to delete this item?");
      if (!confirmDelete) return;
      const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/deleteProductFromAdjustment`, {
        params: { adjustmentID, productID, total }
      });

      if (response.status === 200) {
        alert("Item deleted successfully!");

      } else {
        alert("Failed to delete the item.");
      }
    } catch (error) {
      console.error("Error deleting item:", error);
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while deleting the item.";
      alert(errorMessage);
    }
  };

  return (
    <div className='bg-[#eff3f7] relative left-[18%] w-[82%] min-h-[100vh] p-5'>
      <div className='flex justify-between items-center'>
        <div>
          <h2 className="text-lightgray-300 ml-4 m-0 p-0 text-2xl">Create Sale</h2>
        </div>
        <div>
          <Link className='px-4 py-1.5 border border-[#37b34a] text-[#37b34a] rounded-md transition-colors duration-300 hover:bg-[#37b34a] hover:text-white' to={'/viewCustomers'}>Back</Link>
        </div>
      </div>
      <div className="bg-white mt-[100px] w-full rounded-2xl px-8 shadow-md">
        <div className="flex  flex-1 flex-col px-2 py-12 lg:px-8">
          <form >
            <div className="flex w-full space-x-5"> {/* Add space between inputs if needed */}
              {/* warehouse*/}
              <div className="flex-1"> {/* Use flex-1 to allow the field to take full width */}
                <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Select warehouse</label>
                <select
                  id="warehouse"
                  name="warehouse"
                  value={saleProduct.warehouse}
                  onChange={(e) => handleWarehouseChange(e, setWarehouse, fetchProductDataByWarehouse, setProductData, setSelectedCategoryProducts, setSelectedBrandProducts, setSearchedProductData, setLoading)}
                  className="searchBox w-full pl-10 pr-2 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                >
                  <option value="">{saleProduct.warehouse}</option>
                </select>
                {error.username && <p className="text-red-500">{error.username}</p>}
              </div>

              {/* Date */}
              <div className="flex-1 "> {/* Use flex-1 here as well */}
                <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Date</label>
                <input
                  id="date"
                  name="date"
                  type="date"
                  required
                  value={selectedDate}
                  onChange={handleDateChange}
                  autoComplete="given-name"
                  className="block w-full rounded-md border- pl-5 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </form>

          <div className="flex-1 mt-5">
            {/* Input Field */}
            <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Product : </label>
            <input
              id="text"
              name="text"
              type="text"
              required
              value={searchTerm}
              onChange={(e) => handleProductSearch(e, setSearchTerm, setFilteredProducts, warehouse)}
              placeholder={searchTerm ? "" : "        Search..."}
              className="block w-full rounded-md border-0 py-2.5 pl-10 pr-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
            />
          </div>
          <div>
            {filteredProducts.length > 0 && (
              <ul className="absolute left-0 z-10 ml-[82px] w-[1213px] bg-white border border-gray-300 rounded-md shadow-lg">
                {filteredProducts.map((product) => (
                  <li
                    key={product._id}
                    onClick={() => handleProductSelect(product, setSelectedProduct, setSearchTerm, setFilteredProducts)}
                    className="cursor-pointer hover:bg-gray-100 px-4 py-4"
                  >
                    {product.name}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="overflow-x-auto">
            <table className="mt-10 min-w-full bg-white border rounded-md border-gray-200">
              <thead className="rounded-md bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Name</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Stock Qty</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Purchase Qty</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Price</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Type</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Action</th>
                </tr>
              </thead>
              {saleReturProductData.length > 0 && (
                <tbody>
                  {saleReturProductData.map((product, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500">
                        {product.name}
                      </td>

                      <td className="px-6 text-left py-4 text-left whitespace-nowrap text-sm ">
                        <p className='rounded-[5px] text-center p-[6px] bg-green-100 text-green-500'>{product.stokeQty}</p>
                      </td>

                      <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500">
                        <div className="flex items-center">
                          <button
                            onClick={() => handleQtyChange(index, -1)} // Decrement
                            disabled={!(saleReturProductData[index]?.quantity > 1)}
                            className="px-2 py-2 bg-gray-100 rounded hover:bg-gray-200"
                          >
                            <img className='w-[16px] h-[16px]' src={Decrease} alt='decrease' />
                          </button>
                          <span className="mx-2">
                            {saleReturProductData[index]?.quantity || 1} {/* Display the current quantity */}
                          </span>
                          <button
                            onClick={() => handleQtyChange(index, 1)} // Increment
                            disabled={!(saleReturProductData[index]?.quantity < product.stokeQty)}
                            className="px-2 py-2 bg-gray-100 rounded hover:bg-gray-200"
                          >
                            <img className='w-[16px] h-[16px] transform rotate-180' src={Decrease} alt='increase' />
                          </button>
                        </div>
                      </td>

                      {/* Product Price */}
                      <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500">
                        Rs {product.price}
                      </td>

                      {/* Product adajustment type */}
                      <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500">
                        <select
                          required
                          value={adjustmentTypes}
                          onChange={(e) => handleTypeChange(index, e.target.value)}
                          className="block w-full border py-2 border-gray-300 rounded-md shadow-sm focus:border-transparent"
                        >
                          <option value='addition'>Addition</option>
                          <option value='subtraction'>Subtraction</option>
                        </select>
                      </td>

                      {/* Delete Action */}
                      <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500">
                        <button
                          onClick={() => handleDelete(id, product.currentID)}
                          className="text-red-500 hover:text-red-700 font-bold py-1 px-2"
                        >
                          <i className="fas fa-trash mr-1"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
          <div className="mt-4 text-right text-lg font-semibold">
            Total: Rs {calculateTotal()}
          </div>
          <button
            onClick={() => handleUpdateAdjustment(saleProduct._id, saleProduct.refferenceId, calculateTotal(), saleProduct.warehouse, saleReturProductData, adjustmentTypes, selectedDate, setError, setResponseMessage)}
            className="mt-5 submit w-[200px] text-white rounded py-2 px-4"
          >
            Update & Save
          </button>

          {/* Error and Response Messages */}
          <div className='mt-10'>
            {error && (
              <p className="text-red-600 px-5 py-2 rounded-md bg-red-100 mt-5 text-center inline-block">
                {error}
              </p>
            )}
            {responseMessage && (
               <p className="text-green-600 px-5 py-2 rounded-md bg-green-100 mt-5 text-center w-full">
                {responseMessage}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default EditAdjustmentBody;