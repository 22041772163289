import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import '../../styles/dashboardBody.css';
import avatarIcon from '../../img/profile.png';

function EditCustomerBody() {
    const { id } = useParams();
    const [formData, setFormData] = useState({
        username: '',
        name: '',
        dob: '',
        mobile: '',
        country: '',
        city: '',
        address: ''
    });
    const [errors, setErrors] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [loading, setLoading] = useState(true);

    // Fetch user data when component mounts
    useEffect(() => {
        const fetchCustomerData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/fetchCustomer`, {
                    params: { id }, // Pass `id` as a query parameter
                });
                const fetchedData = response.data;
                setFormData(fetchedData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching customer data:', error);
                setErrors(prevErrors => ({ ...prevErrors, general: 'Failed to fetch customer data.' }));
                setLoading(false);
            }
        };
    
        fetchCustomerData();
    }, [id]);
    

    // Handle input field values
    const handleChange = (e) => {
        setErrors('')
        setResponseMessage('')

        const { name, value } = e.target;
        let updatedFormData = { ...formData, [name]: value };

        // Mobile number validation
        if (name === 'mobile') {
            const mobileRegex = /^\+94\d{9}$/;  // This ensures the format +94xxxxxxxxx
            if (!mobileRegex.test(value)) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    mobile: 'Invalid mobile number. Format: +94xxxxxxxxx'
                }));
            } else {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    mobile: ''  // Clear error if valid
                }));
            }
        }

        // Email validation for username field
        if (name === 'username') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value) || !value.endsWith('@gmail.com')) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    username: 'Username must be a valid email address with @gmail.com'
                }));
            } else {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    username: ''  // Clear error if valid
                }));
            }
        }

        setFormData(updatedFormData);
    };


    // Handle submit
    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors('')
        setResponseMessage('')

        if (errors.mobile || errors.username) {
            alert('Please fix the errors before submitting.');
            return;
        }

        const formDataToSubmit = {
            id,
            username: formData.username,
            name: formData.name,
            dob: formData.dob,
            mobile: formData.mobile,
            country: formData.country,
            city: formData.city,
            address: formData.address
        };

        axios.put(`${process.env.REACT_APP_BASE_URL}/api/editCustomerProfileByAdmin`, formDataToSubmit)
            .then(response => {
                console.log('User updated successfully:', response.data);
                setResponseMessage("Successfully updated the user");
                setLoading(false);
            })
            .catch((error) => {
                const errorMessage =
                    error.response?.data?.message || 'An error occurred while updating the user.';
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    general: errorMessage
                }));
                setErrors('Unsuccessfully updated the user.');
                console.error('Error updating user:', error);
            });
    };

    // Clear all fields except the profileImage
    const handleClear = () => {
        setFormData({
            username: '',
            name: '',
            dob: '',
            mobile: '',
            country: '',
            city: '',
            address: ''
        });
        setErrors('');
        setResponseMessage('')
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className='bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] h-[900px] p-5'>
            <div className='flex justify-between items-center'>
                <div>
                    <h2 className="text-lightgray-300 m-0 p-0 text-2xl">Edit Customer</h2>
                </div>
                <div>
                    <Link className='px-4 py-1.5 border border-[#37b34a] text-[#37b34a] rounded-md transition-colors duration-300 hover:bg-[#37b34a] hover:text-white' to={'/viewCustomers'}>Back</Link>
                </div>
            </div>
            <div className="bg-white mt-[20px] w-full h-[800px] rounded-2xl px-8 shadow-md">
                <div className="flex min-h-full flex-1 flex-col px-2 py-12 lg:px-8">
                    <div className="flex items-center justify-center">
                        <img className='w-[120px] h-[120px] rounded mb-10' src={avatarIcon} alt="icon" />
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="flex space-x-16">
                            <div className="flex-1">
                                {/* Username field */}
                                <div className="mt-2">
                                    <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Enter the Email</label>
                                    <input
                                        id="username"
                                        name="username"
                                        type="email"
                                        required
                                        placeholder='sample@gmail.com'
                                        value={formData.username}
                                        onChange={handleChange}
                                        autoComplete="email"
                                        className="block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                    />
                                </div>

                                {/* Country field */}
                                <div className="mt-5">
                                    <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Country</label>
                                    <input
                                        id="country"
                                        name="country"
                                        type="text"
                                        required
                                        placeholder='Sri Lanka'
                                        value={formData.country}
                                        onChange={handleChange}
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                    />
                                </div>

                                {/* City field */}
                                <div className="mt-5">
                                    <label className="block text-sm font-medium leading-6 text-gray-900 text-left">City</label>
                                    <input
                                        id="city"
                                        name="city"
                                        type="text"
                                        required
                                        placeholder='Kandy'
                                        value={formData.city}
                                        onChange={handleChange}
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                    />
                                </div>

                                {/* Address field */}
                                <div className="mt-5">
                                    <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Address</label>
                                    <textarea
                                        id="address"
                                        name="address"
                                        required
                                        placeholder='No 46, Rock view Garden Thennekumbura'
                                        value={formData.address}
                                        onChange={handleChange}
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                    />
                                </div>

                            </div>

                            <div className="flex-1">
                                {/* Name field */}
                                <div className="mt-2">
                                    <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Name</label>
                                    <input
                                        id="name"
                                        name="name"
                                        type="text"
                                        required
                                        placeholder='Ben'
                                        value={formData.name}
                                        onChange={handleChange}
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                    />
                                </div>

                                {/* Date of Birth field */}
                                <div className="mt-5">
                                    <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Date Of Birth</label>
                                    <input
                                        id="dob"
                                        name="dob"
                                        type="date"
                                        required
                                        value={formData.dob}
                                        onChange={handleChange}
                                        className="block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                    />
                                </div>

                                {/* Mobile number field */}
                                <div className="mt-5">
                                    <label htmlFor="mobile" className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                        Mobile number
                                    </label>
                                    <input
                                        id="mobile"
                                        name="mobile"
                                        type="text"
                                        required
                                        placeholder='+94 xx xxxx xxx'
                                        value={formData.mobile}
                                        onChange={handleChange}
                                        className="block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="container mx-auto text-left">
                            <div className='mt-10 flex justify-start'>
                                <button type='submit' className="saveBtn flex-none rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 w-[100px] text-center focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                                    Save
                                </button>
                                <button
                                    type="button"
                                    className="inline-flex ml-2 justify-center rounded-md bg-gray-600 py-2.5 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 w-[100px]  focus:ring-gray-500 focus:ring-offset-2"
                                    onClick={handleClear}
                                >
                                    Clear
                                </button>
                            </div>
                        </div>
                    </form>

                    {/* Display multiple error messages in one block */}
                    {(errors.username || errors.mobile) && (
                        <div className="text-red-600 px-5 py-2 rounded-md bg-red-100 mt-5 text-center mx-auto max-w-sm">
                            {errors.username && (
                                <p>{errors.username}</p>
                            )}
                            {errors.mobile && (
                                <p>{errors.mobile}</p>
                            )}
                        </div>
                    )}

                    {responseMessage && (
                        <p className="text-green-600 px-5 py-2 rounded-md bg-green-100 mt-5 text-center mx-auto max-w-sm">
                            {responseMessage}
                        </p>
                    )}

                </div>
            </div>
        </div>
    );
}

export default EditCustomerBody;
