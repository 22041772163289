import { useState, useEffect, } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import '../../styles/role.css';
import {handleDelete, handleFormSubmit, fetchCurrencyById, updateCurrency } from './CurrencyController';
function ViewCurrencyBody() {
    // State variables
    const [currencyData, setCurrencyData] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [searchedCurrencyByName, setSearchedCurrencyByName] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupOpenEdit, setIsPopUpEdit] = useState(false)
    const [currencyName, setCurrencyName] = useState('');
    const [currencyCode, setCurrencyCode] = useState('');
    const [currencySymbole, setCurrencySymbole] = useState('')
    const [editcurrencyName, setEditCurrencyName] = useState('');
    const [editcurrencyCode, setEditCurrencyCode] = useState('');
    const [editcurrencySymbole, setEditCurrencySymbole] = useState('')
    const [selectedCurrencyId, setSelectedCurrencyId] = useState(null);
    const [response, setResponse] = useState('')
    const [error, setError] = useState(null);

    //COMBINE ALL DATA FETCHING TYPE INTO ONE STATE
    const combinedProductData =
        Array.isArray(searchedCurrencyByName) && searchedCurrencyByName.length > 0
            ? searchedCurrencyByName
            : Array.isArray(currencyData) && currencyData.length > 0
                ? currencyData
                : [];

    // Load currencies when the component mounts
    useEffect(() => {
        fetchCurrencies();
    }, []);

    // Fetch all currencies
    const fetchCurrencies = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/fetchCurrency`);
            setCurrencyData(response.data.data);
        } catch (err) {
            console.error('Error fetching currencies:', err);
            setError(err.response?.data?.message || 'Failed to fetch currencies.');
        } finally {
            setLoading(false);
        }
    };

    // Handle search input change
    const handleFindUser = async (e) => {
        const { value } = e.target;
        setKeyword(value); // Update the keyword state
        if (value.length > 0) {
            await handleSubmit(value); // Pass the input value to handleSubmit
        } else {
            setSearchedCurrencyByName([]); // Clear results if input is empty
        }
    };

    // Handle search form submission
    const handleSubmit = async (inputValue) => {
        setLoading(true);
        setError(null);
        setResponse('')
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/fetchCurrency`, {
                params: { currencyName: inputValue } // Use the passed keyword
            });

            if (response.data && response.data.data) {
                setSearchedCurrencyByName(response.data.data);
            } else {
                setSearchedCurrencyByName([]);
                setResponse('No currencies found with the provided name.');
            }
        } catch (err) {
            console.error('Error searching for currency:', err);
            setError(err.response?.data?.message || 'Search failed.');
        } finally {
            setLoading(false);
        }
    };

    // Toggle popups
    const handleTogglePopup = () => setIsPopupOpen(!isPopupOpen);
    const handleTogglePopupEdit = () => setIsPopUpEdit(!isPopupOpenEdit);

    return (
        <div className='relative bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] h-[100vh] p-5'>
            <div className='flex justify-between mb-4'>
                <div className="relative w-full max-w-md">
                    <form onChange={handleSubmit} className="flex items-center">
                        <input
                            onChange={handleFindUser}
                            name='keyword'
                            type="text"
                            placeholder="Search..."
                            className="searchBox w-80 pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                            value={keyword}
                        />
                        <button type="submit" className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-400">
                            <svg
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M9 3a6 6 0 100 12A6 6 0 009 3zm0-1a7 7 0 110 14A7 7 0 019 2z"
                                    clipRule="evenodd"
                                />
                                <path
                                    fillRule="evenodd"
                                    d="M12.9 12.9a1 1 0 011.41 0l3 3a1 1 0 01-1.41 1.41l-3-3a1 1 0 010-1.41z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </form>
                </div>
                <div className="flex items-center">
                    <div>
                        <button
                            onClick={() => handleTogglePopup()}
                            className="submit rounded-md px-5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-[200px] text-center"
                        >
                            Create Currency
                        </button>
                    </div>
                </div>
            </div>

            {loading ? (
                <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                    <LinearProgress />
                </Box>
            ) : combinedProductData.length > 0 ? (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Currency Name</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Currency code</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Currency Symbol</th>
                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {combinedProductData.map((currency) => (
                                <tr key={currency._id}>
                                    <td className="px-6 py-4 text-left  whitespace-nowrap text-m text-gray-900"><p className='rounded-[5px] w-[200px] text-center p-[6px] bg-red-100 text-red-500'>{currency.currencyName}</p></td>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">{currency.currencyCode}</td>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900"><p className='rounded-[5px] w-[80px] text-center p-[6px] bg-green-100 text-green-500'>{currency.currencySymbole}</p></td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-right">
                                        <div className="text-right">
                                            <button
                                                onClick={() => fetchCurrencyById(currency._id, setEditCurrencyName, setEditCurrencyCode, setEditCurrencySymbole, setSelectedCurrencyId, setIsPopUpEdit,setError,setResponse)}
                                                className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-edit mr-1"></i>
                                            </button>
                                            <button
                                                onClick={() => handleDelete(currency._id, currencyData, setCurrencyData,)}
                                                className="text-red-500 hover:text-red-700 font-bold py-1 px-2"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-trash mr-1"></i>
                                            </button>
                                        </div>
                                    </td>

                                </tr>
                            ))}

                            {/* Popup overlay */}
                            {isPopupOpen && (
                                <div className="fixed inset-0 mt-20 bg-black bg-opacity-50 flex justify-center items-center">
                                    <div className="bg-white w-[550px] h-[600px] rounded-lg p-6 relative">
                                        {/* Close button */}
                                        <button
                                            onClick={handleTogglePopup}
                                            className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
                                        >
                                            ✕
                                        </button>

                                        {/* Form content */}
                                        <h2 className="text-xl font-semibold mb-4 text-gray-700">Create Currency</h2>
                                        <form onSubmit={(e) => handleFormSubmit(e, setLoading, currencyName, currencyCode, currencySymbole, setCurrencyName, setCurrencyCode, setCurrencySymbole, setResponse)}>
                                            <div className='mt-10'>
                                                <label className="text-left block text-sm font-medium text-gray-700">Currency Name</label>
                                                <input
                                                    type="text"
                                                    value={currencyName}
                                                    required
                                                    onChange={(e) => setCurrencyName(e.target.value)}
                                                    className="searchBox mt-2 w-full pl-2 pr-2 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                                                    placeholder="Enter currency name"
                                                />
                                            </div>

                                            <div className='mt-10'>
                                                <label className="text-left block text-sm font-medium text-gray-700">Currency Code</label>
                                                <input
                                                    type="text"
                                                    value={currencyCode}
                                                    onChange={(e) => setCurrencyCode(e.target.value)}
                                                    className="searchBox w-full mt-2 pl-2 pr-2 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                                                    placeholder="Enter currency code"
                                                    required
                                                />
                                            </div>

                                            <div className='mt-10'>
                                                <label className="text-left block text-sm font-medium text-gray-700">Currency Symbol</label>
                                                <input
                                                    type="text"
                                                    value={currencySymbole}
                                                    onChange={(e) => setCurrencySymbole(e.target.value)}
                                                    className="searchBox w-full mt-2 pl-2 pr-2 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                                                    placeholder="Enter currency symbol"
                                                    required
                                                />
                                            </div>

                                            <div className='mt-5' >
                                                <button
                                                    type="submit"
                                                    className="submit mt-10 w-full py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700"
                                                >
                                                    Save
                                                </button>
                                            </div>

                                            {/* Error and Response Messages */}
                                            <div className='mt-5'>
                                                {error && (
                                                    <p className="text-red-600 px-5 py-2 rounded-md bg-red-100 mt-5 text-center mx-auto max-w-sm">
                                                        {error}
                                                    </p>
                                                )}
                                                {response && (
                                                    <p className="text-green-600 px-5 py-2 rounded-md bg-green-100 mt-5 text-center  mx-auto max-w-sminline-block">
                                                        {response}
                                                    </p>
                                                )}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}

                            {/* Edit view */}
                            {isPopupOpenEdit && (
                                <div className="fixed inset-0 bg-black mt-20 bg-opacity-50 flex justify-center items-center">
                                    <div className="bg-white w-[550px] h-[600px] rounded-lg p-6 relative">
                                        {/* Close button */}
                                        <button
                                            onClick={handleTogglePopupEdit}
                                            className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
                                        >
                                            ✕
                                        </button>

                                        {/* Form content */}
                                        <h2 className="text-xl font-semibold mb-4 text-gray-700">Update Currency</h2>
                                        <form onSubmit={(e) => updateCurrency(e, selectedCurrencyId, editcurrencyName, editcurrencyCode, editcurrencySymbole, setEditCurrencyName, setEditCurrencyCode, setEditCurrencySymbole, setResponse, setIsPopupOpen)} className="space-y-4">
                                            <div className='mt-10'>
                                                <label className="block text-left text-sm font-medium text-gray-700">Currency Name</label>
                                                <input
                                                    type="text"
                                                    value={editcurrencyName}
                                                    required
                                                    onChange={(e) => setEditCurrencyName(e.target.value)}
                                                    className="searchBox mt-2 w-full pl-2 pr-2 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                                                    placeholder="Enter currency name"
                                                />
                                            </div>

                                            <div className='mt-10'>
                                                <label className="block text-left text-sm font-medium text-gray-700">Currency Code</label>
                                                <input
                                                    type="text"
                                                    value={editcurrencyCode}
                                                    onChange={(e) => setEditCurrencyCode(e.target.value)}
                                                    className="searchBox w-full mt-2 pl-2 pr-2 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                                                    placeholder="Enter currency code"
                                                    required
                                                />
                                            </div>

                                            <div className='mt-10'>
                                                <label className="block text-left text-sm font-medium text-gray-700">Currency Symbol</label>
                                                <input
                                                    type="text"
                                                    value={editcurrencySymbole}
                                                    onChange={(e) => setEditCurrencySymbole(e.target.value)}
                                                    className="searchBox w-full mt-2 pl-2 pr-2 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                                                    placeholder="Enter currency symbol"
                                                    required
                                                />
                                            </div>

                                            <div className='mt-5' >
                                                <button
                                                    type="submit"
                                                    className="submit mt-10 w-full py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700"
                                                >
                                                    Update & Save
                                                </button>
                                            </div>

                                            {/* Error and Response Messages */}
                                            <div className='mt-5'>
                                                {error && (
                                                    <p className="text-red-600 px-5 py-2 rounded-md bg-red-100 mt-5 text-center mx-auto max-w-sm">
                                                        {error}
                                                    </p>
                                                )}
                                                {response && (
                                                    <p className="text-green-600 px-5 py-2 rounded-md bg-green-100 mt-5 text-center  mx-auto max-w-sminline-block">
                                                        {response}
                                                    </p>
                                                )}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}
                        </tbody>
                    </table>
                </div>
            ) : (
                <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                    <LinearProgress />
                </Box>
            )
            }
        </div >
    );
}
export default ViewCurrencyBody;