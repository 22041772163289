import axios from "axios";
import {getTax, getPriceRange} from '../sales/SaleController';


//HANDLE SAVE ADJUSTMENT
export const handleCreateAdjustmenet = async (grandTotal, warehouse, selectedProduct, date, setError, setResponseMessage) => {
    setResponseMessage('')
    setError('')
    if (!warehouse) {
        setError('Warehouse is required');
        return;
    }
    if (!date) {
        setError('Date is required');
        return;
    }
    const referenceId = `ADJ_${Math.floor(1000 + Math.random() * 9000)}`; // Generate ID in the format ADJ_XXXX

    const commonAdjustmentData = {
        date,
        warehouse: warehouse || null,
        grandTotal,
        refferenceId: referenceId, // Include reference ID in adjustment data
    };

    // Create products data array
    const productsData = selectedProduct.map(product => {
        const currentID = product._id;
        const ptype = product.ptype;
        const variationValue = product.selectedVariation || product.variationValues[0];
        const AdjustmentType = product.AdjustmentType ? product.AdjustmentType : 'addition';
        const price = getPriceRange(product, product.selectedVariation);
        const quantity = product.barcodeQty || 1;
        const taxRate = product.oderTax ? product.oderTax / 100 : getTax(product, product.selectedVariation) / 100;
        const subtotal = (price * quantity) + (price * quantity * taxRate);

        return {
            currentID,
            ptype,
            variationValue : variationValue ? variationValue : 'No variations',
            AdjustmentType,
            name: product.name,
            price,
            quantity,
            taxRate,
            subtotal,
        };
    });

    // Combine common sale data with products data
    const finalAdjustmentData = {
        ...commonAdjustmentData,
        productsData,
    };

    console.log('Final Adjustment Data:', finalAdjustmentData);

    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/createAdjustment`, finalAdjustmentData);
        console.log('Response:', response.data);
        setResponseMessage('Adjustment created successfully!');
    } catch (error) {
        console.error('Error updating Purchase:', error);
        if (error.response) {
            console.error('Error details:', error.response.data);
            setError(error.response.data.message || 'An error occurred on the server');
        } else if (error.request) {
            console.error('No response received:', error.request);
            setError('No response received from server. Please try again later.');
        } else {
            console.error('Request setup error:', error.message);
            setError(error.message || 'An unexpected error occurred.');
        }
    }
};


//HANDLE UPDATE THE ADJUSTMENT
export const handleUpdateAdjustment = async (id, refferenceId, grandTotal, warehouse, selectedProduct, adjustmentTypes, date, setError, setResponseMessage) => {
    setResponseMessage('')
    setError('')
    if (!warehouse) {
        alert('Warehouse information is required');
        return;
    }
    if (!date) {
        alert('Date is required');
        return;
    }

    const commonAdjustmentData = {
        id,
        date,
        warehouse: warehouse || null,
        grandTotal,
        refferenceId, // Include reference ID in adjustment data
    };

    // Create products data array
    const productsData = selectedProduct.map(product => {
        const currentID = product.currentID;
        const ptype = product.ptype;
        const variationValue = product.variationValue;
        const AdjustmentType = adjustmentTypes ? adjustmentTypes : 'addition';
        const price = product.price;
        const quantity = product.quantity;
        const taxRate = product.taxRate;
        const subtotal = (price * quantity) + (price * quantity * taxRate);

        return {
            currentID,
            ptype,
            variationValue,
            AdjustmentType,
            name: product.name,
            price,
            quantity,
            taxRate,
            subtotal,
        };
    });

    // Combine common sale data with products data
    const finalAdjustmentData = {
        ...commonAdjustmentData,
        productsData,
    };

    console.log('Updated Adjustment Data:', finalAdjustmentData);

 try {
         const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/updateAdjustment/${id}`, finalAdjustmentData);
         console.log('Response:', response.data);
         setResponseMessage('Adjustment updated successfully!');
     } catch (error) {
         // Extract and set a meaningful error message
         const errorMessage = error.response?.data?.message || error.message || 'Failed to updated adjustment';
         setResponseMessage(errorMessage);
        console.error('Error updated Adjustment:', errorMessage);
     }

}