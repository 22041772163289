import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

function ViewCategoryBody() {
    // State variables
    const [categoryData, setCategoryData] = useState([]);
    const [category, setCategory] = useState('');
    const [searchedCat, setSearchedCat] = useState(null);
    const [loading, setLoading] = useState(false);

    // Fetch all category data
    useEffect(() => {
        const fetchCategoryData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/findCategory`);
                console.log('Fetched categories data:', response.data);
                setCategoryData(response.data); // Set the category data to state
            } catch (error) {
                console.error('Fetch category data error:', error);
                setCategoryData([]);
            } finally {
                setLoading(false);
            }
        };

        fetchCategoryData();
    }, []);

    // Handle delete category from full list
    const handleDelete = async (_id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/deleteCategory/${_id}`);
            setCategoryData(categoryData.filter(category => category._id !== _id));
        } catch (error) {
            console.error('Delete category error:', error);
        }
    };

    // Handle delete category from search results
    const handleDeleteByFind = async (_id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/deleteCategory/${_id}`);
            setSearchedCat(null);
            // Also remove from the full list if it exists there
            setCategoryData(categoryData.filter(category => category._id !== _id));
        } catch (error) {
            console.error('Delete category error:', error);
        }
    };

    const handleFindCat = async (e) => {
        const value = e.target.value;
        setCategory(value);

        if (value) {
            await performSearch(value);
        } else {
            setSearchedCat(null);
        }
    };

    // Function to perform the search
    const performSearch = async (category) => {
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/findOneCategory`, { category });
            setSearchedCat(response.data);
        } catch (error) {
            console.error('Find category error:', error);
            setSearchedCat(null);
        } finally {
            setLoading(false);
        }
    };

    // Handle search form submission (button click)
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (category) {
            await performSearch(category);
        }
    };

    return (
        <div className='relative bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] h-[100vh] p-5'>
            <div className='flex justify-between mb-4'>
                <div className="relative w-full max-w-md">
                    <form onSubmit={handleSubmit} className="flex items-center">
                        <input
                            onChange={handleFindCat}
                            name='category'
                            type="text"
                            placeholder="Search..."
                            className="searchBox w-80 pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                            value={category}
                        />
                        <button type="submit" className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-400">
                            <svg
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M9 3a6 6 0 100 12A6 6 0 009 3zm0-1a7 7 0 110 14A7 7 0 019 2z"
                                    clipRule="evenodd"
                                />
                                <path
                                    fillRule="evenodd"
                                    d="M12.9 12.9a1 1 0 011.41 0l3 3a1 1 0 01-1.41 1.41l-3-3a1 1 0 010-1.41z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </form>
                </div>
                <div className="flex items-center">
                    <div>
                        <Link
                            to={'/createCategory'}
                            className="submit flex-none rounded-md px-4 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-60 text-center"
                        >
                            Create Category
                        </Link>
                    </div>
                </div>
            </div>

            {loading ? (
                <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                    <LinearProgress />
                </Box>
            ) : searchedCat ? (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Logo</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Category</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            <tr key={searchedCat._id}>
                                <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">
                                    <img className="rounded-full" style={{ width: "40px", height: "40px" }} src={searchedCat.logo} alt='logo' />
                                </td>
                                <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">{searchedCat.category}</td>
                                <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900 text-right">
                                    <div className='flex justify-end items-center'>
                                        <Link to={`/editCategory/${searchedCat._id}`}
                                            className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2 flex items-center"
                                            style={{ background: 'transparent' }}
                                        >
                                            <i className="fas fa-edit mr-1"></i>
                                        </Link>
                                        <button
                                            onClick={() => handleDeleteByFind(searchedCat._id)}
                                            className="text-red-500 hover:text-red-700 font-bold py-1 px-2"
                                            style={{ background: 'transparent' }}
                                        >
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Logo</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Category</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {categoryData.map((category) => (
                                <tr key={category._id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">
                                        <img className="rounded-full" style={{ width: "40px", height: "40px" }} src={category.logo} alt='logo' />
                                    </td>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">{category.category}</td>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900 text-right">
                                        <div className='flex justify-end items-center'>
                                            <Link to={`/editCategory/${category._id}`}
                                                className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2 flex items-center"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-edit mr-1"></i>
                                            </Link>
                                            <button
                                                onClick={() => handleDelete(category._id)}
                                                className="text-red-500 hover:text-red-700 font-bold py-1 px-2"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-trash"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default ViewCategoryBody;
