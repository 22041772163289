import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import PermissionController from '../utill/permissionController';

const PermissionGuard = ({ children, requiredPermissions }) => {
    const { userData } = useContext(UserContext);

    if (!userData) {
        // Redirect to login if user is nsot authenticated
        return <Navigate to="/" replace />;
    }

    //permissions are extracted correctly
    const userPermissions = userData._doc
        ? Object.keys(userData._doc).filter((key) => userData._doc[key] === true)
        : [];
    console.log("User Permissions in PermissionGuard:", userPermissions);

    if (!PermissionController.hasPermission(userPermissions, requiredPermissions)) {
        console.log("Access Denied. Required Permissions:", requiredPermissions);
        return <p>Access Denied</p>;
    }

    // If all checks pass, render the children
    return children;
};

export default PermissionGuard;
