import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import '../../styles/dashboardBody.css';
import { PencilIcon } from '@heroicons/react/24/outline';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { encryptData } from '../utill/encryptionUtils';

function ProfileBody() {
    const { userData } = useContext(UserContext);
    const [formData, setFormData] = useState({ username: '', firstName: '', lastName: '', profileImage: '', mobile: '' });
    const [ImageFile, setSelectedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [errors, setErrors] = useState({});
    const [responseMessage, setResponseMessage] = useState('');

    const defaultAvatar = 'https://jingslearning.com/media/images/login-user-head.png';

    // Initialize form data
    useEffect(() => {
        if (userData) {
            try {
                setFormData({
                    username: userData.username || '',
                    firstName: userData.firstName || '',
                    lastName: userData.lastName || '',
                    profileImage: userData.profileImage || '',
                    mobile: userData.mobile || ''
                });
            } catch (error) {
                console.error('Error setting form data:', error);
            }
        }
    }, [userData]);

    // Update sessionStorage whenever formData changes
    useEffect(() => {
        if (userData) {
            const updatedUser = { ...userData, ...formData };
            const encryptedUser = encryptData(updatedUser);
            sessionStorage.setItem('user', encryptedUser);
        }
    }, [formData, userData]);

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setErrors({});
        setResponseMessage('');

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (name === 'mobile') {
            // Regex to validate a 12-character mobile number starting with +94
            const mobileRegex = /^\+94\d{9}$/;

            setErrors((prevErrors) => ({
                ...prevErrors,
                mobile: mobileRegex.test(value)
                    ? ''
                    : 'Invalid mobile number. It must start with +94 and be 12 characters long.',
            }));
        }
    };


    // Handle image changes
    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setSelectedImage(file);
            setImagePreview(URL.createObjectURL(file));
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        setResponseMessage('');

        const formDataToSubmit = new FormData();
        formDataToSubmit.append('firstName', formData.firstName);
        formDataToSubmit.append('lastName', formData.lastName);
        formDataToSubmit.append('mobile', formData.mobile);
        formDataToSubmit.append('username', formData.username);
        if (ImageFile) formDataToSubmit.append('profileImage', ImageFile);

        try {
            const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/updateUser`, formDataToSubmit, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            if (response.data.status === 'success') {
                setResponseMessage('User updated successfully!');
            } else {
                setResponseMessage(response.data.message || 'User update failed.');
            }
        } catch (error) {
            const errorMsg = error.response?.data?.message || 'An error occurred while updating user.';
            setResponseMessage(errorMsg);
        }
    };

    // Handle cancel
    const handleCancel = () => {
        setFormData({ username: '', firstName: '', lastName: '', profileImage: '', mobile: '' });
        setSelectedImage(null);
        setImagePreview(null);
        setErrors({});
        setResponseMessage('');
    };

    return (
        <div className='dashBody'>
            {formData ? (
                <form onSubmit={handleSubmit}>
                    <div className="flex items-center justify-center h-20 mt-20 relative">
                        <img
                            style={{ width: '140px', height: '140px' }}
                            className="rounded-full"
                            alt="Profile"
                            src={imagePreview || formData.profileImage || defaultAvatar}
                            onError={(e) => { e.target.src = defaultAvatar; }}
                        />
                        <input
                            type="file"
                            id="fileInput"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleImageChange}
                        />
                        <label htmlFor="fileInput" style={{ position: "relative", top: "60px", right: "30px" }} className="absolute cursor-pointer">
                            <PencilIcon className="text-blue-500 w-6 h-6" />
                        </label>
                        <Link className="absolute px-4 py-1.5 border border-[#37b34a] text-[#37b34a] rounded-md transition-colors hover:bg-[#37b34a] hover:text-white" style={{ top: '-20px', right: '40px' }} to={'/dashboard'}>
                            Back
                        </Link>
                    </div>
                    <div className="flex space-x-6 mt-10" style={{ padding: '40px' }}>
                        <div className="flex-1 space-y-6">
                            <div>
                                <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900 text-left">First Name</label>
                                <input
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    required
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    placeholder="Alex"
                                    className="block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 focus:ring-2"
                                />
                            </div>
                            <div>
                                <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900 text-left">Last Name</label>
                                <input
                                    id="lastName"
                                    name="lastName"
                                    type="text"
                                    required
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    placeholder="Boult"
                                    className="block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 focus:ring-2"
                                />
                            </div>
                        </div>
                        <div className="flex-1 space-y-6">
                            <div>
                                <label htmlFor="mobile" className="block text-sm font-medium leading-6 text-gray-900 text-left">Mobile Number</label>
                                <input
                                    id="mobile"
                                    name="mobile"
                                    type="text"
                                    required
                                    value={formData.mobile}
                                    onChange={handleChange}
                                    placeholder="+94 xx xxxx xxx"
                                    className="block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 focus:ring-2"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex space-x-4 ml-10">
                        <button
                            type="submit"
                            className="saveBtn inline-flex justify-center rounded-md bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            className="inline-flex justify-center rounded-md bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                            onClick={handleCancel} // Reset fields on cancel
                        >
                            Cancel
                        </button>
                    </div>
                    {/* Error and Response Messages */}
                    {errors.mobile && (
                        <p className="text-red-500 text-base mt-1 px-5 py-2 rounded-md mt-5 text-center mx-auto max-w-sm">
                            {errors.mobile}
                        </p>
                    )}
                    {responseMessage && (
                        <p className={`text-base px-5 py-2 rounded-md mt-5 text-center mx-auto max-w-sm ${responseMessage.includes('success') ? 'text-green-600 bg-green-100' : 'text-red-600 bg-red-100'}`}>
                            {responseMessage}
                        </p>
                    )}

                </form>
            ) : (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            )}
        </div>
    );
}

export default ProfileBody;
