import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import '../../styles/login.css';

function ViewUserBody() {
    const [usersData, setUserData] = useState([]);
    const [username, setUsernameFindUser] = useState('');
    const [searchedUser, setSearchedUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(''); // State for error messages

    // Fetch all user data
    useEffect(() => {
        const fetchUserData = async () => {
            setErrorMessage(''); // Clear any previous errors
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/fetchUsers`);
                setUserData(response.data);
            } catch (error) {
                console.error('Fetch user data error:', error);
                setErrorMessage('Failed to fetch user data. Please try again later.');
            }
        };
        fetchUserData();
    }, []);

    // Handle delete user
    const handleDelete = async (_id) => {
        setErrorMessage(''); // Clear any previous errors
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/DeleteUser/${_id}`);
            setUserData(usersData.filter(user => user._id !== _id));
        } catch (error) {
            console.error('Delete user error:', error);
            setErrorMessage('Failed to delete user. Please try again later.');
        }
    };

    // Handle deleting searched user
    const handleDeleteByFind = async (_id) => {
        setErrorMessage(''); // Clear any previous errors
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/DeleteUser/${_id}`);
            setSearchedUser(null);
        } catch (error) {
            console.error('Delete user error:', error);
            setErrorMessage('Failed to delete the searched user. Please try again later.');
        }
    };

    // Handle find user by username
    const handleFindUser = async (e) => {
        const username = e.target.value.trim();
        setUsernameFindUser(username);

        if (username === '') {
            setSearchedUser(null);
            setErrorMessage('');
            return;
        }
    };

    const defaultAvatar = 'https://static.vecteezy.com/system/resources/previews/008/302/462/original/eps10-grey-user-icon-or-logo-in-simple-flat-trendy-modern-style-isolated-on-white-background-free-vector.jpg';

    return (
        <div className='bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] min-h-[100vh] p-5'>
            <div className='flex justify-between mb-4'>
                <div className="">
                    <div className="relative w-full max-w-md">
                        <input
                            onChange={handleFindUser}
                            name='username'
                            type="text"
                            placeholder="Search..."
                            className="searchBox w-80 pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                        />
                        <button className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-400">
                            <svg
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M9 3a6 6 0 100 12A6 6 0 009 3zm0-1a7 7 0 110 14A7 7 0 019 2z"
                                    clipRule="evenodd"
                                />
                                <path
                                    fillRule="evenodd"
                                    d="M12.9 12.9a1 1 0 011.41 0l3 3a1 1 0 01-1.41 1.41l-3-3a1 1 0 010-1.41z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="">
                    <Link
                        to={'/createUser'}
                        className="submit absolute right-5 flex-none rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-40 text-center"
                    >
                        Create User
                    </Link>
                </div>
            </div>

            {loading ? (
                <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                    <LinearProgress />
                </Box>
            ) :
                searchedUser ? (
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white border border-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Profile</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Username</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">First Name</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Name</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mobile</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                <tr key={searchedUser._id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">
                                        <img
                                            style={{ width: "40px", height: "40px" }}
                                            className="rounded-full"
                                            alt="Profile"
                                            src={searchedUser.profileImage}
                                        />
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-left">{searchedUser.username}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-left">{searchedUser.firstName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-left">{searchedUser.lastName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-left">{searchedUser.role}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-left">{searchedUser.mobile}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">
                                        <div className='flex items-center justify-end'>
                                            <Link to={`/editprofilebyadmin/${searchedUser._id}`}
                                                className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2 flex items-center"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-edit mr-1"></i>
                                            </Link>
                                            <button
                                                onClick={() => handleDeleteByFind(searchedUser._id)}
                                                className="text-red-500 hover:text-red-700 font-bold py-1 px-2 flex items-center"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-trash mr-1"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ) : usersData.length > 0 ? (
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white border border-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Profile</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Username</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">First Name</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Name</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mobile</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {usersData.map((user) => (
                                    <tr key={user._id}>
                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">
                                            <img
                                                style={{ width: "40px", height: "40px" }}
                                                className="rounded-full"
                                                alt="Profile"
                                                src={user.profileImage || defaultAvatar}
                                            />
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-left">{user.username}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-left">{user.firstName}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-left">{user.lastName}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-left">{user.role}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-left">{user.mobile}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">
                                            <div className='flex items-center justify-end'>
                                                <Link to={`/editprofilebyadmin/${user._id}`}
                                                    className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2 flex items-center"
                                                    style={{ background: 'transparent' }}
                                                >
                                                    <i className="fas fa-edit mr-1"></i>
                                                </Link>
                                                <button
                                                    onClick={() => handleDelete(user._id)}
                                                    className="text-red-500 hover:text-red-700 font-bold py-1 px-2 flex items-center"
                                                    style={{ background: 'transparent' }}
                                                >
                                                    <i className="fas fa-trash mr-1"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                        <LinearProgress />
                    </Box>
                )}
        </div>
    );
}

export default ViewUserBody;
