import axios from "axios";

// Handle form submission to create a new currency
export const handleFormSubmit = async (e,setLoading,currencyName,currencyCode,currencySymbole,setCurrencyName,setCurrencyCode,setCurrencySymbole,setRespones) => {
    e.preventDefault();

    const currencyData = {
        currencyName,
        currencyCode,
        currencySymbole,
    };
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/createCurrency`, currencyData);
        console.log(response.data.message);

        // Clear form fields
        setCurrencyName('');
        setCurrencyCode('');
        setCurrencySymbole('');
        setRespones(response.data.message);

        // Fetch updated currency data
        //fetchSaleData(setLoading);
    } catch (error) {
        console.error('Error creating currency:', error.response.data.message);
    }
};

 // Function to fetch all currencies
 export const fetchSaleData = async (setCurrencyData, setLoading, setError) => {
    setLoading(true);
    setError(null); // Reset error state
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/fetchCurrency`);
        console.log('Fetched purchased data:', response.data);
        setCurrencyData(response.data.data);
    } catch (error) {
        console.error('Fetch purchased data error:', error);
        setError('Failed to fetch currencies'); // Set error message
    } finally {
        setLoading(false);
    }
};

 // Handle delete purchase
export const handleDelete = async (_id,currencyData,setCurrencyData) => {
    try {
        await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/deleteCurrency/${_id}`);
        setCurrencyData(currencyData.filter(currency => currency._id !== _id));
    } catch (error) {
        console.error('Delete sale error:', error);
    }
};

export const fetchCurrencyById = async (id,setEditCurrencyName,setEditCurrencyCode,setEditCurrencySymbole,setSelectedCurrencyId,setIsPopUpEdit,setError,setResponse) => {
    setError(null)
    setResponse('')
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/fetchCurrency`, {
            params: { id }
        });
        const currency = response.data.data;
        setEditCurrencyName(currency.currencyName);
        setEditCurrencyCode(currency.currencyCode);
        setEditCurrencySymbole(currency.currencySymbole);
        setSelectedCurrencyId(id);
        setIsPopUpEdit(true); // Open popup for editing
    } catch (error) {
        console.error('Error fetching currency by ID:', error);
    }
};

export const updateCurrency = async (e,selectedCurrencyId,editcurrencyName,editcurrencyCode,editcurrencySymbole,setEditCurrencyName,setEditCurrencyCode,setEditCurrencySymbole,setRespones,setIsPopupOpen) => {
    e.preventDefault();
    const updatedCurrencyData = {
        editcurrencyName,
        editcurrencyCode,
        editcurrencySymbole,
    };
    console.log('updated currency ',updatedCurrencyData)

    try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/updateCurrency/${selectedCurrencyId}`, updatedCurrencyData);
        console.log('Currency updated successfully:', response.data.message);

        // Clear the form and close the popup
        setEditCurrencyName('');
        setEditCurrencyCode('');
        setEditCurrencySymbole('');
        setRespones(response.data.message);
        setIsPopupOpen(false);

        // Fetch updated data
        //fetchSaleData();
    } catch (error) {
        console.error('Error updating currency:', error.response?.data?.message || error.message);
    }
};

//Find currency by name
export const handleSubmit = async (inputValue,setLoading,setSearchedCurrencyByName) => { // Accept the keyword as a parameter
    setLoading(true);
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/findCurruncyByName`, {
            params: { currencyName: inputValue } // Use the passed keyword
        });

        console.log(response.data); // Log the response data to check its structure

        // Check if the response.data is an array or has a property that holds the array
        if (Array.isArray(response.data)) {
            setSearchedCurrencyByName(response.data.data);
        } else if (response.data && response.data.data) {
            setSearchedCurrencyByName(response.data.data); // Adjust based on your actual response structure
        } else {
            setSearchedCurrencyByName([]); // Fallback to an empty array if data is unexpected
        }
    } catch (error) {
        console.error('Find customer error:', error);
        setSearchedCurrencyByName([]); // Clear the search results on error
    } finally {
        setLoading(false);
    }
};