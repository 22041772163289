import axios from 'axios';

export const fetchProductDataByWarehouse = (selectedWarehouse, setProductData, setSelectedCategoryProducts, setSelectedBrandProducts, setSearchedProductData, setLoading) => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/findProductsByWarehouse/${selectedWarehouse}`)
        .then((response) => {
            const data = response.data;
            setProductData(data?.products || []);
            console.log("NEW DATA ",data)

            // Clear other search filters when fetching by warehouse
            setSelectedCategoryProducts([]);
            setSelectedBrandProducts([]);
            setSearchedProductData([]);
        })
        .catch((error) => {
            console.error('Error fetching products by warehouse:', error);
        })
        .finally(() => {
            setLoading(false);
        });
};
