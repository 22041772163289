import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import '../../styles/role.css';
import { usePDF } from 'react-to-pdf';

function ViewQuatationBody() {
    // State variables
    const [quatationData, setQuatationData] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [searchedCustomerQuatation, setSearchedCustomerQuatation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openPopupId, setOpenPopupId] = useState(null);
    const popupRef = useRef(null);
    const [openViewQuatation, setOpenViewQuatation] = useState(null);
    const [filteredQuatationData, setFilteredQuatationData] = useState(quatationData);
    const { toPDF, targetRef } = usePDF({ filename: `${quatationData.customer || 'invoice'}.pdf` });
    //COMBINE ALL DATA FETCHING TYPE INTO ONE STATE
    const combinedProductData = Array.isArray(searchedCustomerQuatation) && searchedCustomerQuatation.length > 0
        ? searchedCustomerQuatation
        : Array.isArray(quatationData) && quatationData.length > 0
            ? quatationData
            : [];

    // Fetch all customers
    useEffect(() => {
        const fetchQuatationData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/getQuatation`);
                console.log('Fetched sale data:', response.data);
                setQuatationData(response.data);
            } catch (error) {
                console.error('Fetch sale data error:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchQuatationData();
    }, []);

    // Handle delete customer
    const handleDelete = async (_id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/DeleteQuatation/${_id}`);
            setQuatationData(quatationData.filter(sale => sale._id !== _id));
        } catch (error) {
            console.error('Delete sale error:', error);
        }
    };

    // Handle search input change
    const handleFindUser = async (e) => {
        const { value } = e.target;
        setKeyword(value); // Update the keyword state
        if (value.length > 0) {
            await handleSubmit(); // Call your search function
        } else {
            setSearchedCustomerQuatation([]); // Clear results if input is empty
        }
    };

    // Determine search type based on the keyword
    const determineSearchType = (keyword) => {
        if (/^\d+$/.test(keyword)) { // If the keyword is numeric
            return 'mobile';
        } else if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(keyword)) { // If the keyword looks like an email
            return 'customer';
        } else {
            return 'customer'; // Default to name if nothing else fits
        }
    };

    // Handle search form submission
    const handleSubmit = async () => {
        setLoading(true);
        try {
            const searchType = determineSearchType(keyword);
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/getQuatation`, {
                params: { customerName: keyword } // Send the keyword
            });

            console.log(response.data); // Log the response data to check its structure

            // Check if the response.data is an array or has a property that holds the array
            if (Array.isArray(response.data)) {
                setSearchedCustomerQuatation(response.data);
            } else if (response.data && response.data.sales) {
                setSearchedCustomerQuatation(response.data.sales); // Adjust based on your actual response structure
            } else {
                setSearchedCustomerQuatation([]); // Fallback to an empty array if data is unexpected
            }
        } catch (error) {
            console.error('Find customer error:', error);
            setSearchedCustomerQuatation([]); // Clear the search results on error
        } finally {
            setLoading(false);
        }
    };

    const handleTogglePopup = (saleId) => {
        setOpenPopupId(openPopupId === saleId ? null : saleId);
    };

    // Close popup when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setOpenPopupId(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [popupRef]);

    const handleSaleViewPopUp = async (saleId) => {
        setOpenPopupId(null);
        setOpenViewQuatation(openViewQuatation === saleId ? null : saleId);
        if (openViewQuatation !== saleId) {
            const sale = quatationData.find((sale) => sale._id === saleId);
            const customerName = sale.customer;

            try {
                if (customerName !== "") {
                    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/searchCustomerByName?name=${customerName}`);
                    setFilteredQuatationData(response.data.customer);
                    console.log(response.data)
                } else {
                    setFilteredQuatationData(quatationData);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }
    return (
        <div className='relative bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] min-h-[100vh] p-5'>
            <div className='flex justify-between mb-4'>
                <div className="relative w-full max-w-md">
                    <form onChange={handleSubmit} className="flex items-center">
                        <input
                            onChange={handleFindUser}
                            name='keyword'
                            type="text"
                            placeholder="Search..."
                            className="searchBox w-80 pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                            value={keyword}
                        />
                        <button type="submit" className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-400">
                            <svg
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M9 3a6 6 0 100 12A6 6 0 009 3zm0-1a7 7 0 110 14A7 7 0 019 2z"
                                    clipRule="evenodd"
                                />
                                <path
                                    fillRule="evenodd"
                                    d="M12.9 12.9a1 1 0 011.41 0l3 3a1 1 0 01-1.41 1.41l-3-3a1 1 0 010-1.41z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </form>
                </div>
                <div className="flex items-center">
                    <div>
                        <Link
                            to={'/CreateQuatation'}
                            className="submit rounded-md px-5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-[200px] text-center"
                        >
                            Create Quatation
                        </Link>
                    </div>
                </div>
            </div>

            {loading ? (
                <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                    <LinearProgress />
                </Box>
            ) : combinedProductData.length > 0 ? (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Warehouse</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Status</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Type</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Grand Total</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Paid</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {combinedProductData.map((sale) => (
                                <tr key={sale._id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900"><p className='rounded-[5px] text-center p-[6px] bg-red-100 text-red-500'>{sale.customer}</p></td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{sale.warehouse}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{new Date(sale.date).toLocaleDateString()}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900"><p className='rounded-[5px] text-center p-[6px] bg-green-100 text-green-500'>{sale.orderStatus}</p></td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">
                                        <p className={`rounded-[5px] text-center p-[6px] ${sale.paymentStatus === 'paid' ? 'bg-green-100 text-green-500' :sale.paymentStatus === 'Partial' ? 'bg-yellow-100 text-yellow-500' :
                                                        'bg-red-100 text-red-500'}`}>
                                            {sale.paymentStatus}
                                        </p>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-left text-m text-gray-900"><p className='rounded-[5px] text-center p-[6px] bg-blue-100 text-blue-500'>{sale.paymentType}</p></td>
                                    <td className="px-6 py-4 whitespace-nowrap text-left text-m text-gray-900">Rs {sale.grandTotal}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-left text-m text-gray-900">Rs {sale.paidAmount}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">
                                        <div className='flex items-center'>
                                            <Link to={`/EditQutation/${sale._id}`}
                                                className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2 flex items-center"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-edit mr-1"></i>
                                            </Link>
                                            <button
                                                onClick={() => handleDelete(sale._id)}
                                                className="text-red-500 hover:text-red-700 font-bold py-1 px-2 flex items-center"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-trash mr-1"></i>
                                            </button>
                                            <button
                                                onClick={() => handleTogglePopup(sale._id)}
                                                className="text-gray-500 hover:text-gray-700 font-bold py-1 px-2 flex items-center rotate-90"
                                            >
                                                <i className="fa fa-ellipsis-h"></i>
                                            </button>

                                            {/* Conditional rendering of the popup for the specific sale._id */}
                                            {openPopupId === sale._id && (
                                                <div ref={popupRef} className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-10">
                                                    <ul className="text-sm text-gray-700">
                                                        <li onClick={() => handleSaleViewPopUp(sale._id)} className="px-4 py-4 hover:bg-gray-100 cursor-pointer flex items-center">
                                                            <i className="fas fa-eye mr-2 text-gray-600"></i> {/* Icon for "View Sale" */}
                                                            View Quatation
                                                        </li>
                                                        <Link to={`/CreateSaleFromQuatation/${sale._id}`} className="px-4 py-4 hover:bg-gray-100 cursor-pointer flex items-center">
                                                            <i className="fas fa-undo-alt mr-2 text-gray-600"></i> {/* Icon for "Create Return" */}
                                                            Create Sale
                                                        </Link>
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    {/* View quatation popup */}
                                    {openViewQuatation === sale._id && (
                                        <div ref={popupRef} className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                                            <div className="bg-white w-[1300px] max-h-[90vh] overflow-auto p-8 pt-8 rounded-md shadow-lg mt-40 mb-10">
                                                <div ref={targetRef} className="w-[1250px] p-10 bg-white" style={{ margin: '0 auto', padding: '15px', boxSizing: 'border-box' }}>
                                                    {/* Header */}
                                                    <div className="mb-6 flex justify-between items-center border-b pb-4">
                                                        <h2 className="text-2xl font-bold text-gray-700">Sale Details for {sale.customer}</h2>
                                                    </div>

                                                    {/* quatation Info Section */}
                                                    <div className="grid grid-cols-3 gap-8 text-gray-700">
                                                        {/* Customer Info */}
                                                        <div className="border-r pr-8">
                                                            <h3 className="text-lg font-semibold mb-2 p-[8px] bg-gray-100 text-gray-700">
                                                                <i className="fas fa-user mr-2 text-gray-600"></i>
                                                                Customer Info
                                                            </h3>
                                                            <p className="mb-1 text-left"><i className="fas fa-user ml-2 mr-2 text-gray-400"></i><span className="font-medium">Customer:</span> {sale.customer}</p>

                                                            {filteredQuatationData.map((customer) => (
                                                                <div>
                                                                    <p className='m-2 text-left'><i className="fas fa-envelope mr-2 text-gray-400"></i><span className="font-medium">Email:</span> {customer.username}</p>
                                                                    <p className='m-2 text-left'><i className="fas fa-city mr-2 text-gray-400"></i><span className="font-medium">City:</span> {customer.city}</p>
                                                                    <p className='m-2 text-left'><i className="fas fa-phone mr-2 text-gray-400"></i><span className="font-medium">Mobile:</span> {customer.mobile}</p>
                                                                </div>
                                                            ))}
                                                        </div>

                                                        {/* Company Info */}
                                                        <div className="border-r pr-8">
                                                            <h3 className="text-lg p-[8px] bg-gray-100 font-semibold mb-2 text-gray-700">
                                                                <i className="fas fa-building mr-2 text-gray-600"></i>
                                                                Company Info
                                                            </h3>
                                                            <p className="m-2 text-left"><i className="fas fa-building mr-2 text-gray-400"></i><span className="font-medium">Company:</span> Ideazone</p>
                                                            <p className="m-2 text-left"><i className="fas fa-envelope mr-2 text-gray-400"></i><span className="font-medium">Email:</span> ideazone@info.gmail.com</p>
                                                            <p className="m-2 text-left"><i className="fas fa-phone mr-2 text-gray-400"></i><span className="font-medium">Phone:</span> +94 2828 395</p>
                                                            <p className="m-2 text-left"><i className="fas fa-map-marker-alt mr-2 text-gray-400"></i><span className="font-medium m-2">Address:</span> No 17 Rockview Rd</p>
                                                        </div>

                                                        {/* Invoice Info <span className="font-medium m-2">Orser status:</span>*/}
                                                        <div>
                                                            <h3 className="text-lg p-[8px] bg-gray-100 font-semibold mb-2 text-gray-700">
                                                                <i className="fas fa-file-invoice mr-2 text-gray-600"></i>
                                                                Invoice Info
                                                            </h3>
                                                            <p className='flex items-center text-left'>
                                                                <span className="font-medium m-2 flex items-center"><i className="fas fa-money-bill-wave mr-1 text-gray-400"></i>Payment status:</span>
                                                                <span className={`w-20 flex items-center rounded-[5px] pl-[15px] text-center m-2 p-[2px] ${sale.paymentStatus === 'paid' ? 'bg-green-100 text-green-500' : 'bg-red-100 text-red-500'}`}>
                                                                    {sale.paymentStatus}
                                                                </span>
                                                            </p>
                                                            <p className='flex items-center text-left'>
                                                                <span className=" flex items-center font-medium m-2"><i className="fas fa-check-circle mr-1 text-gray-400"></i>Order status:</span>
                                                                <span className=' flex items-center w-20 rounded-[5px] pl-[5px] text-center m-2 p-[2px] bg-green-100 text-green-500'>
                                                                    {sale.orderStatus}
                                                                </span>
                                                            </p>
                                                            <p className='mt-2 text-left'>
                                                                <span className="font-medium m-2 mt-4"><i className="fas fa-warehouse mr-1 text-gray-400"></i>Warehouse:</span>
                                                                {sale.warehouse}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {/* Product data */}
                                                    <div className="mt-10">
                                                        <h3 className="text-lg font-semibold mb-2 mt-10 p-[8px] bg-gray-100 text-gray-700">Product Details</h3>
                                                        <table className=" mt-10 min-w-full bg-white border border-gray-300">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-gray-700 py-2 px-4 border-b text-left bg-gray-100 ">Product ID</th>
                                                                    <th className="text-gray-700 py-2 px-4 border-b text-left bg-gray-100 ">Product name</th>
                                                                    <th className="text-gray-700 py-2 px-4 border-b text-left bg-gray-100 ">Product price</th>
                                                                    <th className="text-gray-700 py-2 px-4 border-b text-left bg-gray-100 ">qty</th>
                                                                    <th className="text-gray-700 py-2 px-4 border-b text-left bg-gray-100 ">Sub total</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {sale.productsData.map((product) => (
                                                                    <tr key={product._id} className="text-gray-700">
                                                                        <td className="text-left py-2 px-4 border-b">{product.currentID}</td>
                                                                        <td className="text-left py-2 px-4 border-b">{product.name}</td>
                                                                        <td className="text-left py-2 px-4 border-b">Rs {product.price}</td>
                                                                        <td className="text-left py-2 px-4 border-b">{product.quantity}</td>
                                                                        <td className="text-left py-2 px-4 border-b">Rs {product.subtotal}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    {/* Additional data */}
                                                    <div className="mt-10">
                                                        <table className=" mt-10 min-w-[400px] bg-white border border-gray-300">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="text-left py-2 px-4 border-b">Tax</td>
                                                                    <td className="text-left py-2 px-4 border-b">{sale.tax} %</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left py-2 px-4 border-b">Shipping</td>
                                                                    <td className="text-left py-2 px-4 border-b">Rs {sale.shipping}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left py-2 px-4 border-b">Discount</td>
                                                                    <td className="text-left py-2 px-4 border-b">Rs {sale.discount}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left py-2 px-4 border-b">Total</td>
                                                                    <td className="text-left py-2 px-4 border-b">Rs {sale.grandTotal}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {/* Footer */}
                                                <div className="mt-8 flex justify-end">
                                                    {openViewQuatation === sale._id && (
                                                        <button onClick={() => toPDF()} className="submit px-6 py-3 mr-2 text-white  rounded-md shadow-md -600 transition">
                                                            <i className="fas fa-file-pdf mr-2 text-white"></i>
                                                            Download PDF
                                                        </button>
                                                    )}
                                                    <button
                                                        onClick={() => setOpenViewQuatation(null)}
                                                        className="px-6 py-3 bg-gray-500 text-white text-left rounded-md shadow-md hover:bg-gray-600 transition">
                                                        Close
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                    <LinearProgress />
                </Box>
            )
            }
        </div >
    );
}
export default ViewQuatationBody;
