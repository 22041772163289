import axios from "axios";
import { getTax, getPriceRange } from '../../component/sales/SaleController';

//HANDLE SAVE ADJUSTMENT
export const handleCreateTransfer = async (grandTotal, warehouseFrom, warehouseTo, selectedProduct, date, discountType, discount, shipping, tax, orderStatus, setResponseMessage) => {
    if (!warehouseFrom || !warehouseTo) {
        alert('Warehouse information is required');
        return;
    }
    if (!date) {
        alert('Date is required');
        return;
    }
    if (!selectedProduct || selectedProduct.length === 0) {
        setResponseMessage('At least one product is required');
        return;
    }
    const refferenceId = `TRN_${Math.floor(1000 + Math.random() * 9000)}`; // Generate ID in the format TRN_XXXX

    const commonAdjustmentData = {
        date,
        warehouseFrom,
        warehouseTo,
        grandTotal,
        discountType,
        discount,
        shipping,
        tax,
        orderStatus,
        refferenceId,
    };

    // Create products data array
    const productsData = selectedProduct.map(product => {
        const currentID = product._id;
        const ptype = product.ptype;
        const variationValue = product.selectedVariation || product.variationValues[0];
        const price = getPriceRange(product, product.selectedVariation);
        const quantity = product.barcodeQty || 1;
        const taxRate = product.orderTax ? product.orderTax / 100 : getTax(product, product.selectedVariation) / 100;
        const subtotal = (price * quantity) + (price * quantity * taxRate);

        return {
            currentID,
            ptype,
            variationValue,
            name: product.name,
            price,
            quantity,
            taxRate,
            subtotal,
        };
    }).filter(product => product); // Filter out any invalid products

    // Ensure there are valid products
    if (productsData.length === 0) {
        setResponseMessage('No valid products found');
        return;
    }
    // Combine common sale data with products data
    const finalTransferData = {
        ...commonAdjustmentData,
        productsData,
    };

    console.log('Final transfer Data:', finalTransferData);

    //Uncomment and adjust the URL as needed for actual API requests
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/createTransfer`, finalTransferData);
        console.log('Response:', response.data);
        setResponseMessage('Transfer created successfully!');
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'Failed to create Transfer';
        setResponseMessage(errorMessage);
        console.error('Error creating Transfer:', errorMessage);
    }
};

//HANDLE UPDATE THE TRANSFER
export const handleUpdateTransfer = async (
    id,grandTotal, orderStatus, discountType, discount, tax, shipping,warehouseFrom, warehouseTo, date,taxRate, productData, setResponseMessage) => {
    console.log('saleReturnData:', productData);
    // Validate required fields
    if (!orderStatus || !warehouseFrom || !warehouseTo || !date) {
        setResponseMessage('Please fill in all required fields.');
        return;
    }

    // Validate productData
    if (!productData || productData.length === 0) {
        setResponseMessage('At least one product is required.');
        return;
    }
    const commonTransferData = {
        date,
        warehouseFrom,
        warehouseTo,
        tax,
        discountType,
        discount,
        shipping,
        orderStatus,
        grandTotal

    };

  // Prepare products data array with validation
  const productsData = productData.map(product => {
    if (!product.currentID || !product.price || !product.quantity) {
        setResponseMessage('Product data is incomplete or invalid.');
        return null; // Skip invalid product
    }

    const currentID = product.currentID;
    const ptype = product.ptype;
    const variationValue = product.variationValue;
    const AdjustmentType = 'addition';
    const price = product.price;
    const quantity = product.quantity;
    const taxRate = product.taxRate;
    const subtotal = (price * quantity) + (price * quantity * taxRate);

    return {
        currentID,
        ptype,
        AdjustmentType,
        variationValue,
        name: product.name,
        price,
        quantity,
        taxRate,
        subtotal,
    };
    }).filter(Boolean); // Filter out invalid products

    // Ensure productsData is not empty
    if (productsData.length === 0) {
        setResponseMessage('No valid products found.');
        return;
    }

    // Prepare final data for update
    const updatedTransferData = {
        ...commonTransferData,
        productsData,
    };

    console.log('Updated transfer Data:', updatedTransferData);

    try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/updateTransfer/${id}`, updatedTransferData);
        console.log('Response:', response.data);

        // Handle successful response
        setResponseMessage(response.data.message || 'Transfer updated successfully!');
    } catch (error) {
        console.error('Error updating transfer:', error);

        // Handle error response
        const errorMessage = error.response?.data?.message || error.message || 'Failed to update transfer';
        setResponseMessage(errorMessage);
    }
};


