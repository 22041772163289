export const getPriceRange = (product) => {
    if (product.variationValues) {
        const prices = Object.values(product.variationValues)
            .map(variation => Number(variation.productPrice))
            .filter(price => !isNaN(price));

        if (prices.length > 0) {
            const minPrice = Math.min(...prices);
            return minPrice;
        }
    }
    const singlePrice = Number(product.productPrice);
    return !isNaN(singlePrice) && singlePrice > 0 ? `${singlePrice}` : 'Price not available';
};

// Calculate total quantity for products with variations
export const getQty = (product) => {
    if (product.variationValues) {
        const qty = Object.values(product.variationValues)
            .map(variation => Number(variation.productQty))
            .filter(qty => !isNaN(qty));
        return qty.length > 0 ? qty.reduce((total, current) => total + current, 0) : 0;
    }
    const singleProductQty = Number(product.productQty);
    return !isNaN(singleProductQty) && singleProductQty > 0 ? singleProductQty : 0;
};

// Calculate total order tax for products with variations
export const getTax = (product) => {
    if (product.variationValues) {
        const taxes = Object.values(product.variationValues)
            .map(variation => Number(variation.orderTax))
            .filter(tax => !isNaN(tax));

        return taxes.length > 0 ? taxes.reduce((total, current) => total + current, 0) : 0;
    }
    const singleProductTax = Number(product.orderTax);
    return !isNaN(singleProductTax) && singleProductTax > 0 ? singleProductTax : 0;
};
