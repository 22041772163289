import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Barcode from 'react-barcode';
import ReactToPrint from 'react-to-print';
import Decrease from '../../../../src/img/down-arrow (1).png'
import '../../../styles/printeTemplate.css'

function CreateBarcodeBody() {
    const [warehouse, setWarehouse] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [generatedBarcodeValue, setGeneratedBarcodeValue] = useState([]);
    const [warehouseData, setWarehouseData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [stickerWidth, setStickerWidth] = useState(50); // in mm
    const [stickerHeight, setStickerHeight] = useState(25); // in mm
    const [barcodesPerRow, setBarcodesPerRow] = useState(2);
    const [pageSize, setPageSize] = useState('');

    // New state variables for checkboxes
    const [showCompanyName, setShowCompanyName] = useState(false);
    const [showProductName, setShowProductName] = useState(false);
    const [showPrice, setShowPrice] = useState(false);
    const [error, setError] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const companyName = "IDEAZONE";

    // Fetch warehouse data
    const fetchData = async (url, setData, transformData) => {
        try {
            const response = await axios.get(url);
            const data = transformData ? transformData(response.data) : response.data;
            setData(data);
        } catch (error) {
            console.error(`Fetch data error from ${url}:`, error);
            setData([]);
            setError('Failed to fetch warehouse data. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchData(`${process.env.REACT_APP_BASE_URL}/api/fetchWarehouses`, setWarehouseData);
    }, []);

    // Calculate price range for products with variations
    const getPriceRange = (product, selectedVariation) => {
        if (selectedVariation && product.variationValues) {
            const variation = product.variationValues[selectedVariation];
            if (variation && !isNaN(Number(variation.productPrice))) {
                return `${variation.productPrice}`;
            }
        }

        const singlePrice = Number(product.productPrice);
        return singlePrice > 0 ? `${singlePrice}` : 'Price not available';
    };


    // Handle product search
    const handleProductSearch = async (e) => {
        const keyword = e.target.value;
        setSearchTerm(keyword);
        if (keyword.length > 0) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/searchProductByName`, {
                    params: {
                        name: keyword,
                        warehouse: warehouse,
                    },
                });
                console.log(response.data.products);
                setFilteredProducts(response.data.products);
            } catch (error) {
                console.error('Error fetching product:', error);
                setFilteredProducts([]);
                setError('Failed to fetch products. Please try again.');
            }
        } else {
            setFilteredProducts([]);
        }
    };

    //Handle Removeing product from table
    const handleDelete = (productId, selectedVariation) => {
        setSelectedProduct((prevProducts) =>
            prevProducts.filter((product) =>
                product._id !== productId || product.selectedVariation !== selectedVariation
            )
        );
        setResponseMessage('Product deleted successfully.');
    };

    // Handle product selection
    const handleProductSelect = (product) => {
        if (product.ptype === 'Variation' && product.variationValues) {
            // Add each variation as a separate row in the table
            const variations = Object.keys(product.variationValues).map((variationKey) => ({
                ...product,
                selectedVariation: variationKey, // Set the current variation
                barcodeFormat: product.barcode,
                barcodeQty: 0,  // Default barcode quantity for each variation
            }));

            setSelectedProduct((prevProducts) => [...prevProducts, ...variations]); // Add all variations as separate rows
        } else {
            // Handle normal product without variations
            setSelectedProduct((prevProducts) => [
                ...prevProducts,
                {
                    ...product,
                    barcodeFormat: product.barcode,
                    barcodeQty: 0, // Default barcode quantity for the product
                },
            ]);
        }

        setSearchTerm(''); // Clear search term
        setFilteredProducts([]); // Clear filtered products
    };

    // Generate barcodes based on the specified barcode quantity (barcodeQty)
    const generateBarcode = () => {
        try {
            const barcodes = selectedProduct.flatMap((product) => {
                const productCode = product.code || product._id;
                const productName = product.name;
                const qty = product.barcodeQty || 0;
                const barcodeValue = `${productCode}`;
                return Array.from({ length: qty }, () => ({
                    productId: product._id,
                    barcode: productCode,
                    barcodeFormat: product.barcodeFormat,
                    productName: showProductName ? productName : '',
                    price: getPriceRange(product, product.selectedVariation),
                    companyName: showCompanyName ? companyName : '',
                }));
            });
            setGeneratedBarcodeValue(barcodes);
            setResponseMessage('Barcodes generated successfully.');
        } catch (error) {
            console.error('Error generating barcodes:', error);
            setError('Failed to generate barcodes. Please try again.');
        }
    };

    // Handle barcode quantity changes (Increment/Decrement)
    const handleBarcodeQtyChange = (productId, selectedVariation, change) => {
        setSelectedProduct((prevProducts) =>
            prevProducts.map((product) =>
                product._id === productId && product.selectedVariation === selectedVariation
                    ? { ...product, barcodeQty: Math.max(0, (product.barcodeQty || 0) + change) }
                    : product
            )
        );
    };


    const componentRef = useRef();

    const handleSubmit = (event) => {
        event.preventDefault();
        generateBarcode();
    };

    //Handle clear 
    const HandleClear = () => {
        setWarehouse('');
        setSearchTerm('');
        setSelectedProduct([]);
        setGeneratedBarcodeValue([])
        setShowCompanyName(false);
        setShowProductName(false);
        setShowPrice(false)
        setPageSize('')
    }

    return (
        <div className="bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] h-[100vh] p-5">
            <div className="flex justify-between items-center">
                <h2 className="text-lightgray-300 text-2xl">Create Barcode</h2>
                <Link
                    className="px-4 py-1.5 border border-[#37b34a] text-[#37b34a] rounded-md transition-colors duration-300 hover:bg-[#37b34a] hover:text-white"
                    to={'/dashboard'}
                >
                    Back
                </Link>
            </div>
            <div className="bg-white mt-[20px] w-full overflow-x-auto rounded-2xl pb-20 px-8 shadow-md">
                <form onSubmit={handleSubmit}>
                    <div className="flex space-x-16">
                        <div className="flex-1">
                            {/* Warehouse */}
                            <div className="mt-5">
                                <label className="block text-sm text-left  font-medium leading-6 text-gray-900">Warehouse</label>
                                <div className="mt-2">
                                    <select
                                        required
                                        name="warehouse"
                                        value={warehouse}
                                        onChange={(e) => setWarehouse(e.target.value)}
                                        className="block w-[350px] rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                                    >
                                        <option value="">Select a warehouse</option>
                                        {warehouseData.map((wh) => (
                                            <option key={wh.name} value={wh.name}>
                                                {wh.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            {/* Product search */}
                            <div className="mt-5">
                                <label className="block text-left  text-sm text-left  font-medium leading-6 text-gray-900">Product name</label>
                                <div className="relative flex items-center w-[350px]">
                                    <input
                                        name="keyword"
                                        type="text"
                                        value={searchTerm}
                                        onChange={handleProductSearch}
                                        placeholder={searchTerm ? "" : "        Search..."}
                                        className="block w-[350px] rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                                    />
                                    {!searchTerm && (
                                        < button type="submit" className="absolute left-3 inset-y-0 flex items-center text-gray-400">
                                            <svg
                                                className="h-5 w-5"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M9 3a6 6 0 100 12A6 6 0 009 3zm0-1a7 7 0 110 14A7 7 0 019 2z"
                                                    clipRule="evenodd"
                                                />
                                                <path
                                                    fillRule="evenodd"
                                                    d="M12.9 12.9a1 1 0 011.41 0l3 3a1 1 0 01-1.41 1.41l-3-3a1 1 0 010-1.41z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </button>
                                    )}
                                    {filteredProducts.length > 0 && (
                                        <ul className="absolute top-12 left-0 z-10 w-[350px] bg-white border border-gray-300 rounded-md shadow-lg">
                                            {filteredProducts.map((product) => (
                                                <li
                                                    key={product._id}
                                                    onClick={() => handleProductSelect(product)}
                                                    className="cursor-pointer hover:bg-gray-100 px-4 py-2"
                                                >
                                                    {product.name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>

                            {/* Display product details */}
                            {loading ? (
                                <Box sx={{ width: '100%', position: 'absolute', top: '0', left: '0' }}>
                                    <LinearProgress />
                                </Box>
                            ) : (
                                <div className="overflow-x-auto">
                                    {selectedProduct.length > 0 && (
                                        <table className="mt-10 min-w-full bg-white border border-gray-200">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Product Name</th>
                                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Barcode Type</th>
                                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Code</th>
                                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Barcode amount</th>
                                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Product Price</th>
                                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedProduct.map((product, index) => (
                                                    <tr key={`${product._id}-${product.selectedVariation || ''}`}>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                            {product.name} {product.selectedVariation && `(${product.selectedVariation})`}
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                            <select
                                                                value={product.barcodeFormat || ''}
                                                                onChange={(e) => {
                                                                    const newBarcodeFormat = e.target.value;
                                                                    setSelectedProduct((prevProducts) =>
                                                                        prevProducts.map((p, i) =>
                                                                            i === index ? { ...p, barcodeFormat: newBarcodeFormat } : p
                                                                        )
                                                                    );
                                                                }}
                                                                className="block w-[150px] rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                                                            >
                                                                <option value="">Select a barcode symbol</option>
                                                                <option value="code128">Code 128</option>
                                                                <option value="code39">Code 39</option>
                                                            </select>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{product.code}</td>

                                                        {/* Barcode quantity with increase/decrease buttons */}
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                            <div className="flex items-center">
                                                                <button
                                                                    onClick={() => handleBarcodeQtyChange(product._id, product.selectedVariation, 1)}
                                                                    className="px-2 py-2 bg-gray-100 rounded hover:bg-gray-200"
                                                                >
                                                                    <img className='w-[18px] h-[18px] transform rotate-180' src={Decrease} alt='increase' />
                                                                </button>
                                                                <span className="mx-2">{product.barcodeQty || 0}</span>
                                                                <button
                                                                    onClick={() => handleBarcodeQtyChange(product._id, product.selectedVariation, -1)}
                                                                    className="px-2 py-2 bg-gray-100 rounded hover:bg-gray-200"
                                                                >
                                                                    <img className='w-[18px] h-[18px]' src={Decrease} alt='decrease' />
                                                                </button>
                                                            </div>
                                                        </td>

                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                            {product.ptype === 'Variation' && product.selectedVariation
                                                                ? getPriceRange(product.variationValues[product.selectedVariation])
                                                                : getPriceRange(product)}
                                                        </td>

                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                            <button
                                                                onClick={() => handleDelete(product._id, product.selectedVariation)}
                                                                className="text-red-500 hover:text-red-700 mt-[20px] font-bold py-1 px-2 text-lg ml-5"
                                                                style={{ background: 'transparent' }}
                                                            >
                                                                <i className="fas fa-trash"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            )}
                            <hr className='mt-10' />
                            {/* Page size */}
                            <div className="flex flex-wrap gap-x-6">
                                {/* <div className="mt-10 flex-1 min-w-[300px]">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">Barcode page size</label>
                                    <div className="">
                                        <select
                                            onChange={(e) => setPageSize(e.target.value)}
                                            className="block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                                        >
                                            <option value="">Select page size</option>
                                            <option value="40">40 per sheet (110 x 451 mm)</option>
                                            <option value="30">30 per sheet (666 x 254 mm)</option>
                                            <option value="24">24 per sheet (2.48 x 1.334)</option>
                                            <option value="20">20 per sheet (4 x 1)</option>
                                            <option value="18">18 per sheet (2.5 x 1.835)</option>
                                            <option value="14">14 per sheet (4 x 1.33)</option>
                                            <option value="12">12 per sheet (2.5 x 2.834)</option>
                                            <option value="10">10 per sheet (4 x 2)</option>
                                        </select>
                                    </div>
                                </div> */}

                                {/* Sticker width input */}
                                <div className="mt-10 flex-1 min-w-[300px]">
                                    <label className="block text text-left -sm font-medium leading-6 text-gray-900">Sticker Width</label>
                                    <input
                                        type="number"
                                        value={stickerWidth}
                                        onChange={(e) => setStickerWidth(parseInt(e.target.value))}
                                        placeholder="Sticker Width (mm)"
                                        className="block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                                    />
                                </div>

                                {/* Sticker height input */}
                                <div className="mt-10 flex-1 min-w-[300px]">
                                    <label className="block text-sm text-left  font-medium leading-6 text-gray-900">Sticker Height</label>
                                    <input
                                        type="number"
                                        value={stickerHeight}
                                        onChange={(e) => setStickerHeight(parseInt(e.target.value))}
                                        placeholder="Sticker Height (mm)"
                                        className="block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                                    />
                                </div>

                                {/* Barcodes per row input */}
                                <div className="mt-10 flex-1 min-w-[300px]">
                                    <label className="block text-sm text-left  font-medium leading-6 text-gray-900">Barcodes Per Row</label>
                                    <input
                                        type="number"
                                        value={barcodesPerRow}
                                        onChange={(e) => {
                                            const value = parseInt(e.target.value);
                                            if (value <= 4 && value > 0) {
                                                setBarcodesPerRow(value);
                                            }
                                        }}
                                        className="block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                                    />
                                </div>
                            </div>

                            {/* Checkbox options */}
                            <div className="mt-10 
                            flex space-x-10">
                                <div>
                                    <label className="block text-sm text-left  font-medium leading-6 text-gray-900">Show Company Name</label>
                                    <input
                                        type="checkbox"
                                        className="h-6 w-6 mt-2 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                        onChange={(e) => setShowCompanyName(e.target.checked)}
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm text-left  font-medium leading-6 text-gray-900">Show Product Name</label>
                                    <input
                                        type="checkbox"
                                        className="h-6 w-6  mt-2 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                        onChange={(e) => setShowProductName(e.target.checked)}
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm text-left  font-medium leading-6 text-gray-900">Show Price</label>
                                    <input
                                        type="checkbox"
                                        className="h-6 w-6  mt-2 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                        onChange={(e) => setShowPrice(e.target.checked)}
                                    />
                                </div>
                            </div>


                            {/* BUTTON SECTION*/}
                            <div className="container mx-auto text-left">
                                <div className='mt-10 flex justify-start'>
                                    {/* Generating button*/}
                                    <button
                                        type="button"
                                        onClick={generateBarcode}
                                        className="bg-green-500 text-white  py-2 px-4 rounded hover:bg-green-700 transition duration-300"
                                    >
                                        Preview
                                    </button>

                                    {/* Printing button*/}
                                    <ReactToPrint
                                        trigger={() => (
                                            <button className="bg-blue-500  ml-2 text-white  py-2 px-6 rounded hover:bg-blue-700 transition duration-300">
                                                Print
                                            </button>
                                        )}
                                        content={() => componentRef.current}
                                    />

                                    {/*Clear button*/}
                                    <button
                                        type="button"
                                        onClick={HandleClear}
                                        className="inline-flex ml-2 justify-center rounded-md bg-gray-600 py-2.5 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 w-[100px]  focus:ring-gray-500 focus:ring-offset-2"
                                    >
                                        Clear
                                    </button>
                                </div>
                            </div>

                            {/* Display generated barcode */}
                            {generatedBarcodeValue.length > 0 && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div
                                        style={{
                                            display: 'grid',
                                            gridTemplateColumns: `repeat(${barcodesPerRow}, ${stickerWidth}mm)`,
                                            gap: '4mm', // Changed 11px to 3mm for consistency
                                            width: `${(stickerWidth * barcodesPerRow) + 8}mm`,
                                            paddingLeft: '2mm', // Changed 6px to 2mm
                                            borderRadius: '2mm', // Changed 2px to 0.5mm
                                            height: 'auto',
                                            maxHeight: 'auto',
                                            overflow: 'hidden',
                                        }}
                                        ref={componentRef}
                                        id="printableArea"
                                    >
                                        {generatedBarcodeValue.map((item, index) => (
                                            <div
                                                key={`${item.productId}-${index}`}
                                                style={{
                                                    width: `${stickerWidth}mm`,
                                                    height: `${stickerHeight}mm`,
                                                    borderRadius: '1mm', // Changed 4px to 1mm
                                                }}
                                                className="border p-1 flex flex-col items-center justify-center"
                                            >
                                                {item.companyName && (
                                                    <p style={{ fontSize: '3mm', textAlign: 'center' }}>{item.companyName}</p> // Changed font size to 3mm
                                                )}
                                                <Barcode value={item.barcode} format={item.barcodeFormat} width={2} // Adjust the width of each barcode bar
                                                    height={70} // Adjust the height of the barcode
                                                    displayValue={true} // Show the code value below the barcode
                                                    fontSize={12} />

                                                {item.price && (
                                                    <p style={{ fontSize: '3.5mm', textAlign: 'center' }}>{`Rs : ${item.price}`}</p> // Changed font size to 3.5mm
                                                )}
                                                {item.productName && (
                                                    <p style={{ fontSize: '3mm', textAlign: 'center' }}>{`${item.productName}`}</p> // Changed font size to 3mm
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </form>
            </div >
            <div className='mt-5'>
                {/* Error and Response Messages */}
                {error && <p className="text-red-500 text-center">{error}</p>}
                {responseMessage && <p className="text-green-500 text-center">{responseMessage}</p>}
            </div>
        </div >
    );
}

export default CreateBarcodeBody;
