import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import '../../styles/login.css';

function ViewWhereHouseBody() {
    const [warehouseData, setWherehouseData] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [searchedWarehouse, setSearchedWarehouse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    // Fetch all warehouse data
    useEffect(() => {
        const fetchWarehouseData = async () => {
            setLoading(true);
            setError('');
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/fetchWarehouses`);
                setWherehouseData(response.data);
            } catch (err) {
                console.error('Fetch warehouse data error:', err);
                setError('Failed to fetch warehouse data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchWarehouseData();
    }, []);

    // Handle delete warehouse from full list
    const handleDelete = async (_id) => {
        setError('');
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/DeleteWarehouse/${_id}`);
            setWherehouseData(warehouseData.filter(wherehouse => wherehouse._id !== _id));
        } catch (err) {
            console.error('Delete warehouse error:', err);
            setError('Failed to delete the warehouse. Please try again later.');
        }
    };

    // Handle delete warehouse from search results
    const handleDeleteByFind = async (_id) => {
        setError('');
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/DeleteWarehouse/${_id}`);
            setSearchedWarehouse(null);
            setWherehouseData(warehouseData.filter(wherehouse => wherehouse._id !== _id));
        } catch (err) {
            console.error('Delete warehouse error:', err);
            setError('Failed to delete the warehouse. Please try again later.');
        }
    };

    // Handle search input change
    const handleFindUser = async (e) => {
        const value = e.target.value;
        setKeyword(value);
        setError('');

        if (value) {
            await performSearch(value);
        }
    };

    // Determine search type based on the keyword
    const determineSearchType = (keyword) => {
        if (/^\d+$/.test(keyword)) {
            return 'mobile';
        } else if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(keyword)) {
            return 'username';
        } else {
            return 'name';
        }
    };

    // Perform the search
    const performSearch = async (searchValue) => {
        setLoading(true);
        setError('');
        try {
            const searchType = determineSearchType(searchValue);
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/fetchWarehouses`, {
                params: { keyword: searchValue, searchType }
            });
            setSearchedWarehouse(response.data.length > 0 ? response.data[0] : null);
            if (response.data.length === 0) {
                setError('No warehouse found with the provided details.');
            }
        } catch (err) {
            console.error('Find warehouse error:', err);
            setError('Failed to search for the warehouse. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    // Handle search form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (keyword) {
            await performSearch(keyword);
        } else {
            setError('Please enter a keyword to search.');
        }
    };


    return (
        <div className='relative bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] h-[100vh] p-5'>
            <div className='flex justify-between mb-4'>
                <div className="relative w-full max-w-md">
                    <form onSubmit={handleSubmit} className="flex items-center">
                        <input
                            onChange={handleFindUser}
                            name='keyword'
                            type="text"
                            placeholder="Search..."
                            className="searchBox w-80 pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                            value={keyword}
                        />
                        <button type="submit" className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-400">
                            <svg
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M9 3a6 6 0 100 12A6 6 0 009 3zm0-1a7 7 0 110 14A7 7 0 019 2z"
                                    clipRule="evenodd"
                                />
                                <path
                                    fillRule="evenodd"
                                    d="M12.9 12.9a1 1 0 011.41 0l3 3a1 1 0 01-1.41 1.41l-3-3a1 1 0 010-1.41z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </form>
                </div>
                <div className="flex items-center">
                    <div>
                        <Link
                            to={'/createWherehouse'}
                            className="submit flex-none rounded-md px-4 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-60 text-center"
                        >
                            Create Warehouse
                        </Link>
                    </div>
                </div>
            </div>

            {loading ? (
                <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                    <LinearProgress />
                </Box>
            ) : searchedWarehouse ? (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Username</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Warehouse</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mobile</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Country</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">City</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ZIP code</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created on</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            <tr key={searchedWarehouse._id}>
                                <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{searchedWarehouse.username}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{searchedWarehouse.name}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{searchedWarehouse.mobile}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{searchedWarehouse.country}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{searchedWarehouse.city}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{searchedWarehouse.zip}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{new Date(searchedWarehouse.createdAt).toLocaleDateString()}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">
                                    <div className='flex items-center'>
                                        <Link to={`/editWarehouse/${searchedWarehouse._id}`}
                                            className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2 flex items-center"
                                            style={{ background: 'transparent' }}
                                        >
                                            <i className="fas fa-edit mr-1"></i>
                                        </Link>
                                        <button
                                            onClick={() => handleDeleteByFind(searchedWarehouse._id)}
                                            className="text-red-500 hover:text-red-700 font-bold py-1 px-2"
                                            style={{ background: 'transparent' }}
                                        >
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Username</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Warehouse</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mobile</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Country</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">City</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ZIP code</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created on</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {warehouseData.map((warehouse) => (
                                <tr key={warehouse._id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{warehouse.username}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{warehouse.name}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{warehouse.mobile}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{warehouse.country}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{warehouse.city}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{warehouse.zip}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{new Date(warehouse.createdAt).toLocaleDateString()}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">
                                        <div className='flex items-center'>
                                            <Link to={`/editWarehouse/${warehouse._id}`}
                                                className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2 flex items-center"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-edit mr-1"></i>
                                            </Link>
                                            <button
                                                onClick={() => handleDelete(warehouse._id)}
                                                className="text-red-500 hover:text-red-700 font-bold py-1 px-2"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-trash"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default ViewWhereHouseBody;
