import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { handleProductSelect, handleProductSearch, handleWarehouseChange, handleVariationChange, getQty, getPriceRange, handleDelete, handleQtyChange, getTax } from '../sales/SaleController'
import { handleCreateTransfer } from './TransferController';
import '../../styles/role.css';
import { Link } from 'react-router-dom';
import { fetchProductDataByWarehouse } from '../pos/utils/fetchByWarehose';
import Decrease from '../../img/down-arrow (1).png'

function CreateTransferBody() {
    // State management
    const [warehouseData, setWarehouseData] = useState([]);
    const [warehouseFrom, setWarehouseFrom] = useState('');
    const [warehouseTo, setWarehouseTo] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [date, setDate] = useState('')
    const [selectedCategoryProducts, setSelectedCategoryProducts] = useState([]);
    const [selectedBrandProducts, setSelectedBrandProducts] = useState([]);
    const [productBillingHandling, setSearchedProductData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [discountType, setDiscountType] = useState('');
    const [discountSymbole, setDiscountSymbole] = useState('Rs');
    const [discount, setDiscount] = useState('')
    const [shipping, setShipping] = useState('')
    const [tax, setTax] = useState('')
    const [orderStatus, setOrderStatus] = useState('');
    const [error, setError] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    const fetchData = async (url, setter) => {
        setLoading(true);
        try {
            const { data } = await axios.get(url);
            setter(data);
        } catch (error) {
            console.error(`${url} fetch error:`, error);
            setter([]);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData(`${process.env.REACT_APP_BASE_URL}/api/fetchWarehouses`, setWarehouseData);
        return () => { };
    }, []);

    const calculateTotal = () => {
        // Calculate the total with tax and discounts
        const productTotal = selectedProduct.reduce((total, product) => {
            const productPrice = Number(getPriceRange(product, product.selectedVariation));
            const productQty = product.barcodeQty || 1;
            const taxRate = product.oderTax
                ? product.oderTax / 100
                : getTax(product, product.selectedVariation) / 100;

            const subTotal = (productPrice * productQty) * (1 + taxRate);
            return total + subTotal;
        }, 0);

        let discountValue = 0;
        if (discountType === 'fixed') {
            discountValue = Number(discount);
        } else if (discountType === 'percentage') {
            discountValue = (productTotal * Number(discount)) / 100;
        }
        const shippingValue = Number(shipping);
        const globalTaxRate = Number(tax) / 100;
        const globalTax = productTotal * globalTaxRate;
        const grandTotal = productTotal - discountValue + shippingValue + globalTax;
        return grandTotal;
    };

    const handleDiscountType = (e) => {
        setDiscountType(e.target.value)
    }
    const handleDiscount = (e) => {
        if (!discountType) {
            alert('Please select a discount type first.');
            return;
        }
        const value = e.target.value;
        if (discountType === 'percentage') {
            const numericValue = parseFloat(value);
            if (numericValue < 1 || numericValue > 100) {
                alert('Please enter a percentage value between 1 and 100.');
                return;
            }
        }
        setDiscount(value);
    };
    useEffect(() => {
        if (discountType === 'fixed') {
            return setDiscountSymbole('Rs');
        }
        if (discountType === 'percentage') {
            return setDiscountSymbole('%');
        }
    }, [discountType]);

    const handleTax = (e) => {
        setTax(e.target.value)
    }
    const handleShippng = (e) => {
        setShipping(e.target.value)
    }
    return (
        <div className='bg-[#eff3f7] relative left-[18%] w-[82%] min-h-[100vh] p-5'>
            <div className='flex justify-between'>
                <div>
                    <h2 className="text-lightgray-300 ml-4 mt-[80px] p-0 text-2xl">Create Transfer</h2>
                </div>
                <div>
                    <Link className='px-4 py-1.5 border border-[#37b34a] text-[#37b34a] rounded-md transition-colors duration-300 hover:bg-[#37b34a] hover:text-white' to={'/viewCustomers'}>Back</Link>
                </div>
            </div>
            <div className="bg-white mt-[20px] pb-2 w-full rounded-2xl px-8 shadow-md">
                <div className="flex  flex-1 flex-col px-2 py-12 lg:px-8">
                    <form >
                        <div className="flex w-full space-x-5">
                            {/*from warehouse*/}
                            <div className="flex-1">
                                <label className="block text-sm font-medium leading-6 text-gray-900 text-left">From</label>
                                <select
                                    id="warehouse"
                                    name="warehouse"
                                    value={warehouseFrom}
                                    onChange={(e) => handleWarehouseChange(e, setWarehouseFrom, fetchProductDataByWarehouse, setProductData, setSelectedCategoryProducts, setSelectedBrandProducts, setSearchedProductData, setLoading)}
                                    className="searchBox w-full pl-10 pr-2 py-2.5 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                                >
                                    <option value="">Select a warehouse</option>
                                    {warehouseData.map((wh) => (
                                        <option key={wh.name} value={wh.name}>
                                            {wh.name}
                                        </option>
                                    ))}
                                </select>
                                {error.username && <p className="text-red-500">{error.username}</p>}
                            </div>

                            {/*to warehouse*/}
                            <div className="flex-1"> {/* Use flex-1 to allow the field to take full width */}
                                <label className="block text-sm font-medium leading-6 text-gray-900 text-left">To </label>
                                <select
                                    id="warehouse"
                                    name="warehouse"
                                    value={warehouseTo}
                                    onChange={(e) => handleWarehouseChange(e, setWarehouseTo, fetchProductDataByWarehouse, setProductData, setSelectedCategoryProducts, setSelectedBrandProducts, setSearchedProductData, setLoading)}
                                    className="searchBox w-full pl-10 pr-2 py-2.5 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                                >
                                    <option value="">Select a warehouse</option>
                                    {warehouseData.map((wh) => (
                                        <option key={wh.name} value={wh.name}>
                                            {wh.name}
                                        </option>
                                    ))}
                                </select>
                                {error.username && <p className="text-red-500">{error.username}</p>}
                            </div>

                            {/*Date*/}
                            <div className="flex-1 "> {/* Use flex-1 here as well */}
                                <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Date</label>
                                <input
                                    id="date"
                                    name="date"
                                    type="date"
                                    required
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border- pl-5 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                    </form>

                    <div className="flex-1 mt-5">
                        {/* Input Field */}
                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Product : </label>
                        <input
                            id="text"
                            name="text"
                            type="text"
                            required
                            value={searchTerm}
                            onChange={(e) => handleProductSearch(e, setSearchTerm, setFilteredProducts, warehouseFrom)}
                            placeholder={searchTerm ? "" : "        Search..."}
                            className="block w-full rounded-md border-0 py-2.5 pl-10 pr-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                        />
                    </div>
                    <div>
                        {filteredProducts.length > 0 && (
                            <ul className="absolute left-0 z-10 ml-[82px] w-[1213px] bg-white border border-gray-300 rounded-md shadow-lg">
                                {filteredProducts.map((product) => (
                                    <li
                                        key={product._id}
                                        onClick={() => handleProductSelect(product, setSelectedProduct, setSearchTerm, setFilteredProducts)}
                                        className="cursor-pointer hover:bg-gray-100 px-4 py-4"
                                    >
                                        {product.name}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <div className="overflow-x-auto">
                        <table className="mt-10 min-w-full bg-white border border-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Name</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Stock Qty</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Purchase Qty</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Price</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Variation</th>
                                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedProduct.map((product, index) => (
                                    <tr key={index}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {product.name}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm "><p className='rounded-[5px] text-center p-[6px] bg-green-100 text-green-500'>{product.productQty || getQty(product, product.selectedVariation)}</p></td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            <div className="flex items-center">
                                                <button
                                                    onClick={() => handleQtyChange(index, product.selectedVariation, setSelectedProduct, -1)} // Decrement
                                                    className="px-2 py-2 bg-gray-100 rounded hover:bg-gray-200"
                                                >
                                                    <img className='w-[16px] h-[16px]' src={Decrease} alt='increase' />
                                                </button>
                                                <span className="mx-2">
                                                    {product.ptype === "Variation"
                                                        ? product.variationValues[product.selectedVariation]?.barcodeQty || 1
                                                        : product.barcodeQty || 1
                                                    }
                                                </span>
                                                <button
                                                    onClick={() => handleQtyChange(index, product.selectedVariation, setSelectedProduct, 1)} // Increment            
                                                    className="px-2 py-2 bg-gray-100 rounded hover:bg-gray-200"
                                                >
                                                    <img className='w-[16px] h-[16px] transform rotate-180' src={Decrease} alt='decrease' />
                                                </button>
                                            </div>
                                        </td>

                                        {/* Product Price */}
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            Rs {getPriceRange(product, product.selectedVariation)}
                                        </td>

                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {product.ptype === 'Variation' && product.variationValues ? (
                                                <select
                                                    value={product.selectedVariation}
                                                    onChange={(e) => handleVariationChange(index, e.target.value, setSelectedProduct)}
                                                    className="block w-full border py-2 border-gray-300 rounded-md shadow-sm focus:border-transparent"
                                                >
                                                    {Object.keys(product.variationValues).map((variationKey) => (
                                                        <option key={variationKey} value={variationKey}>
                                                            {variationKey}
                                                        </option>
                                                    ))}
                                                </select>
                                            ) : (
                                                <span>No Variations</span>
                                            )}
                                        </td>

                                        {/* Delete Action */}
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-500">
                                            <button
                                                onClick={() => handleDelete(index, selectedProduct, setSelectedProduct)}
                                                className="text-red-500 hover:text-red-700 font-bold py-1 px-2"
                                            >
                                                <i className="fas fa-trash mr-1"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-4 text-right text-lg font-semibold">
                        Total: Rs {calculateTotal().toFixed(2)}
                    </div>

                    {/* DISCOUNT, SHIPPING AND TAX INPUT */}
                    <div className="grid grid-cols-4 gap-4 mb-4 mt-60">
                        <div className="relative">
                            <select
                                onChange={handleDiscountType}
                                value={discountType}
                                className="block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                            >
                                <option value=''>Discount type</option>
                                <option value='fixed'>Fixed</option>
                                <option value='percentage'>Percentage</option>
                            </select>
                        </div>
                        <div className='relative'>
                            <input
                                onChange={handleDiscount}
                                value={discount}
                                type="text"
                                placeholder="Discount"
                                className='block w-full rounded-md border-0 py-2.5 px-2 pr-10 text-gray-900 shadow-sm ring-1 ring-gray-400 placeholder:text-gray-400 focus:ring-gray-400 focus:outline-none sm:text-sm' />
                            <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">
                                {discountSymbole}
                            </span>
                        </div>
                        <div className="relative">
                            <input
                                onChange={handleTax}
                                value={tax}
                                type="text"
                                placeholder="Tax"
                                className="block w-full rounded-md border-0 py-2.5 px-2 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                            />
                            <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">
                                %
                            </span>
                        </div>
                        <div className='relative'>
                            <input
                                onChange={handleShippng}
                                value={shipping}
                                type="text"
                                placeholder="Shipping"
                                className='block w-full rounded-md border-0 py-2.5 px-2 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm' />
                            <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">
                                Rs
                            </span>
                        </div>
                    </div>

                    {/* Order, Payment Status, and Payment Type Selects */}
                    <div className="grid grid-cols-3 gap-4 mt-2">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Status:</label>
                            <select
                                value={orderStatus}
                                onChange={(e) => setOrderStatus(e.target.value)}
                                className="searchBox w-full pl-10 pr-2 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                            >
                                <option value="">Select Order Status</option>
                                <option value="ordered">Ordered</option>
                                <option value="pending">Pending</option>
                            </select>
                        </div>
                    </div>

                    <div>
                        <table className="mt-10 min-w-full bg-white border border-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <td className=" px-6 py-4 whitespace-nowrap text-sm text-gray-500">Date : {date}</td>
                                    <td colSpan={1} className=" px-6 py-4 whitespace-nowrap text-sm text-gray-500">From : {warehouseFrom ? warehouseFrom : 'No warehouse'} </td>
                                    <td colSpan={3} className=" px-6 py-4 whitespace-nowrap text-sm text-gray-500">To : {warehouseTo ? warehouseTo : 'No warehouse '}</td>
                                </tr>
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Discount</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Shipping</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Tax (%)</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Product Status</th>
                                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">Grand Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">Rs {discount}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">Rs {shipping}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{tax}%</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{orderStatus}</td>
                                    <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">Rs {calculateTotal().toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <button onClick={() => handleCreateTransfer(
                        calculateTotal().toFixed(2), warehouseFrom, warehouseTo, selectedProduct, date, discountType, discount, shipping, tax, orderStatus,setResponseMessage)} className="mt-10 submit  w-[200px] text-white rounded py-2 px-4">
                        Save Transfer
                    </button>
                </div>
                {/* Error and Response Messages */}
                {error && <p className="text-green-500 mb-20 text-center">{error}</p>}
                {responseMessage && <p className="text-green-500 mb-20 text-center">{responseMessage}</p>}
            </div>
        </div>
    );
}
export default CreateTransferBody;
