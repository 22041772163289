import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/role.css';
import { Link, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

function EditProductBody() {
    const { id } = useParams();
    // Add all your missing state definitions here:
    const [name, setProductName] = useState('');
    const [code, setCode] = useState('');
    const [category, setCategory] = useState('');
    const [brand, setBrands] = useState('');
    const [warehouse, setWarehouse] = useState('');
    const [barcode, setBarcode] = useState('');
    const [unit, setBaseUnit] = useState('');
    const [suplier, setSuplier] = useState('');
    const [saleUnit, setSaleUnit] = useState('');
    const [purchase, setPurchaseUnit] = useState('');
    const [status, setStatus] = useState('');
    const [quantityLimit, setQL] = useState('');

    const [variation, setVariation] = useState('');
    const [variationType, setVariationTypes] = useState([]);
    const [selectedVariationTypes, setSelectedVariationTypes] = useState([]);
    const [variationValues, setVariationValues] = useState({});
    const [showSections, setShowSections] = useState({});
    const [ptype, setType] = useState('');
    const [note, setNote] = useState('');
    const [image, setImage] = useState([]);
    const [stockAlert, setStockAlert] = useState('');
    const [oderTax, setOderTax] = useState('');
    const [productCost, setProductCost] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [taxType, setTaxType] = useState('');
    const [productQty, setProductQty] = useState('');
    const [error, setError] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    const [productData, setProductData] = useState([]);
    const [suplierData, setSuplierData] = useState([]);
    const [warehouseData, setWherehouseData] = useState([]);
    const [brandData, setBrandData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [unitData, setUnitData] = useState([]);
    const [variationData, setVariationData] = useState([]);
    const [loading, setLoading] = useState(true);

    //Fetching properties for create products ==========================================================================================================
    const fetchData = async (url, setData, transformData) => {
        try {
            const response = await axios.get(url);
            const data = transformData ? transformData(response.data) : response.data;
            setData(data);
        } catch (error) {
            console.error(`Fetch data error from ${url}:`, error);
            setData([]);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        setLoading(true);
        fetchData(`${process.env.REACT_APP_BASE_URL}/api/fetchSupplier`, setSuplierData);
        fetchData(`${process.env.REACT_APP_BASE_URL}/api/fetchWarehouses`, setWherehouseData);
        fetchData(`${process.env.REACT_APP_BASE_URL}/api/findCategory`, setCategoryData);
        fetchData(`${process.env.REACT_APP_BASE_URL}/api/findBrand`, setBrandData);
        fetchData(`${process.env.REACT_APP_BASE_URL}/api/findAllUnit`, setUnitData, (data) => data.units || []);
        fetchData(`${process.env.REACT_APP_BASE_URL}/api/findAllVariations`, setVariationData, (data) => data.variations || []);
        fetchData(`${process.env.REACT_APP_BASE_URL}/api/findProductForUpdate/${id}`, setProductData, (data) => data.product || []);
    }, []);

    useEffect(() => {
        if (productData) {
            setVariation(productData.variation);
            setVariationValues(productData.variationValues || {});
            setSelectedVariationTypes(Object.keys(productData.variationValues || {}));
            setShowSections(
                Object.keys(productData.variationValues || {}).reduce((acc, key) => ({
                    ...acc,
                    [key]: true
                }), {})
            );
            setType(productData.ptype)
        }
    }, [productData]);
    useEffect(() => {
        if (ptype === 'Single') {
            // Example data fetching logic, replace with your actual data source
            const fetchProductData = async () => {
                // Simulating data fetch
                const data = {
                    stockAlert: '10',
                    orderTax: '5',
                    productCost: '250',
                    taxType: 'Exclusive',
                    productPrice: '600',
                    productQty: '100',
                };
                setStockAlert(data.stockAlert);
                setOderTax(data.orderTax);
                setProductCost(data.productCost);
                setTaxType(data.taxType);
                setProductPrice(data.productPrice);
                setProductQty(data.productQty);
            };
            fetchProductData();
        }
    }, [ptype]);


    //================================================================================================================================================
    //Handle image files input
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file); // Ensure this is the correct file object
    };

    // Handle sale unit from base units
    const handleBaseUnitChange = (e) => {
        const selectedBaseUnit = e.target.value;
        setBaseUnit(selectedBaseUnit);
        const correspondingUnit = unitData.find((unit) => unit.baseUnit === selectedBaseUnit);
        if (correspondingUnit) {
            setSaleUnit(correspondingUnit.unitName);
        } else {
            setSaleUnit('');
        }
    };

    // Handle variation change
    useEffect(() => {
        // Set the initial variation to the default when the component loads
        const defaultVariation = productData.variation;
        setVariation(defaultVariation);

        const selectedData = variationData.find(varn => varn.variationName === defaultVariation);
        if (selectedData && Array.isArray(selectedData.variationType)) {
            setVariationTypes(selectedData.variationType);
        } else {
            setVariationTypes([]);
        }
        console.log("This is the default variation: ", defaultVariation);
    }, [productData.variation, variationData]);

    function handleVariationChange(e) {
        const selectedVariation = e.target.value;
        setVariation(selectedVariation);
        setProductData(prevData => ({ ...prevData, variation: selectedVariation }));

        setVariationValues({});

        const selectedData = variationData.find(varn => varn.variationName === selectedVariation);
        if (selectedData && Array.isArray(selectedData.variationType)) {
            setVariationTypes(selectedData.variationType); // Set the available variation types
        } else {
            setVariationTypes([]); // Clear if no types are available
        }
    }


    // Handle input change for each variation type
    const handleInputChange = (type, value) => {
        setVariationValues(prev => ({ ...prev, [type]: value }));
    };

    const handleAddVariationType = (e) => {
        const selectedOption = e.target.value;
        if (selectedOption && !selectedVariationTypes.includes(selectedOption)) {
            setSelectedVariationTypes([...selectedVariationTypes, selectedOption]);
            setShowSections({
                ...showSections,
                [selectedOption]: true,
            });
        }
        e.target.value = '';
    };

    // Handle removing a selected variation type
    const handleRemoveVariationType = (typeToRemove) => {
        setSelectedVariationTypes(selectedVariationTypes.filter(type => type !== typeToRemove));
        setShowSections({
            ...showSections,
            [typeToRemove]: false,
        });
    };

    //Handle variaion input changing 
    const handleVariationValueChange = (type, field, value) => {
        setProductData(prevData => ({
            ...prevData,
            variationValues: {
                ...prevData.variationValues,
                [type]: {
                    ...prevData.variationValues[type],
                    [field]: value,
                },
            },
        }));
    };

    //Save button enabile and disable checking 
    const isFormValid = productData.name && productData.code && productData.warehouse && productData.brand && productData.category && productData.suplier && 
    productData.ptype && productData.unit && productData.saleUnit && productData.purchase && productData.status && productData.barcode && productData.quantityLimit;

    //Handle submit for save product
    const handleSubmit = async (event) => {
        event.preventDefault();
    
        // Create a FormData object
        const formData = new FormData();
        formData.append('image', image);  // Ensure 'image' is a File object
        formData.append('name', productData.name);
        formData.append('code', productData.code);
        formData.append('brand', productData.brand);
        formData.append('unit', productData.unit);
        formData.append('ptype', productData.ptype);
        formData.append('category', productData.category);
        formData.append('warehouse', productData.warehouse);
        formData.append('saleUnit', productData.saleUnit);
        formData.append('suplier', productData.suplier);
        formData.append('status', productData.status);
        formData.append('note', productData.note);
        formData.append('purchase', productData.purchase);
        formData.append('quantityLimit', productData.quantityLimit);
        formData.append('barcode', productData.barcode);
        formData.append('variation', productData.variation);
        formData.append('stockAlert', productData.stockAlert);
        formData.append('oderTax', productData.oderTax);
        formData.append('taxType', productData.taxType);
        formData.append('productCost', productData.productCost);
        formData.append('productPrice', productData.productPrice);
        formData.append('productQty', productData.productQty);
    
        // Add variation values to formData
        selectedVariationTypes.forEach((type) => {
            formData.append(`variationTypes[]`, type);
            const value = variationValues[type];
            if (value) {
                // Here we loop through each variation value and append it individually to formData
                Object.entries(value).forEach(([field, fieldValue]) => {
                    formData.append(`variationValues[${type}][${field}]`, fieldValue);
                });
            }
        });
    
        try {
            const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/updateProduct/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            // Handle response
            if (response.status === 200) {
                setResponseMessage(response.data.message);
                console.log('Response:', response.data);
            } else {
                // If there's an issue with the update (e.g., missing fields), show the error message
                setError(response.data.message || 'An error occurred while updating the product.');
                console.error('Product update failed', response.data.message);
            }
    
        } catch (error) {
            // Handle any other errors such as network issues or server errors
            if (error.response) {
                // Handle known server errors (e.g., 400, 404, etc.)
                setError(error.response.data.message || 'Server error occurred while updating the product.');
                console.error('Error response:', error.response);
            } else if (error.request) {
                // Handle network errors where no response was received
                setError('Network error: No response from the server.');
                console.error('Error request:', error.request);
            } else {
                // Handle other unexpected errors
                setError('An unexpected error occurred: ' + error.message);
                console.error('Unexpected error:', error.message);
            }
        }
    
        // Log data to be submitted
        console.log('Product Data to Submit:', productData);
    };
    


    // Handle clear operation
    const handleClear = () => {
        setProductName(''); setBrands(''); setCategory(''); setNote(''); setSaleUnit(''); setCode(''); setPurchaseUnit(''); setQL(''); setStatus('');
        setSuplier(''); setWarehouse(''); setVariation(''); setBarcode(''); setError(''); setResponseMessage('');
    };

    return (
        <div className='bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] min-h-full p-5'>
            <div className='flex justify-between items-center'>
                <div>
                    <h2 className="text-lightgray-300 ml-4 m-0 p-0 text-2xl">Edit Product</h2>
                </div>
                <div>
                    <Link className='px-4 py-1.5 border border-[#37b34a] text-[#37b34a] rounded-md transition-colors duration-300 hover:bg-[#37b34a] hover:text-white' to={'/viewProducts'}>Back</Link>
                </div>
            </div>
            <div className="bg-white mt-[20px] w-full min-h-full rounded-2xl px-8 shadow-md">
                {loading ? (
                    <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                        <LinearProgress />
                    </Box>
                ) : (
                    <form className='pb-10' onSubmit={handleSubmit} encType="multipart/form-data">
                        <div className="flex min-h-full flex-1 flex-col px-2 py-10 lg:px-8">
                            {/* Container for the three sub-divs */}
                            <div className="flex flex-1 flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 mt-2">

                                {/* First Sub-Div============================================================================================================================================= */}
                                <div className="flex-1">

                                    {/* name*/}
                                    <div className="mt-2">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                            Product name
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="name"
                                                name="name"
                                                value={productData.name || ''}
                                                type="text"
                                                required
                                                placeholder="Enter name "
                                                onChange={(e) => setProductData({ ...productData, name: e.target.value })}
                                                className="block w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    {/* Category */}
                                    <div className="mt-5">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Category</label>
                                        <div className="mt-2">
                                            <select
                                                id="category"
                                                name="category"
                                                required
                                                value={productData.category}
                                                onChange={(e) => setProductData({ ...productData, category: e.target.value })}
                                                className="block w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            >
                                                <option value="">Select a category</option>
                                                {Array.isArray(categoryData) && categoryData.map((cat) => (
                                                    <option key={cat.category} value={cat.category}>{cat.category}</option>
                                                ))}
                                            </select>

                                        </div>
                                    </div>

                                    {/* Barcode */}
                                    <div className="mt-5">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Barcode type</label>
                                        <div className="mt-2">
                                            <select
                                                id="barcode"
                                                name="barcode"
                                                required
                                                value={productData.barcode}
                                                onChange={(e) => setProductData({ ...productData, barcode: e.target.value })}
                                                className="block w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            >
                                                <option value="">Select a barcode</option>
                                                <option value="code128">Code 128</option>
                                                <option value="code39">Code 39</option>
                                            </select>
                                        </div>
                                    </div>

                                    {/* Sale Unit */}
                                    <div className="mt-5">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Sale Unit</label>
                                        <div className="mt-2">
                                            <select
                                                id="sale_unit"
                                                name="saleuUnit"
                                                value={productData.saleUnit}
                                                required
                                                onChange={(e) => setProductData({ ...productData, saleUnit: e.target.value })}
                                                className="block w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            >
                                                <option value="">Select a sale unit</option>
                                                {Array.isArray(unitData) && unitData.map((u) => (
                                                    <option key={u.unitName} value={u.unitName}>{u.unitName}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/*Quantity Limitation:*/}
                                    <div className="mt-5">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                            Quantity Limitation
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="QuantityLimitation:"
                                                name="quantityLimit"
                                                type="text"
                                                value={productData.quantityLimit}
                                                required
                                                onChange={(e) => setProductData({ ...productData, quantityLimit: e.target.value })}
                                                placeholder="Quantity Limitation:"
                                                className="block w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    {/* Product type */}
                                    <div className="mt-5">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Product type</label>
                                        <div className="mt-2">
                                            <select
                                                id="ptype"
                                                name="ptype"
                                                required
                                                value={productData.ptype}
                                                onChange={(e) => setProductData({ ...productData, ptype: e.target.value })}
                                                className="block w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            >
                                                <option value="">Select a type</option>
                                                <option>Single</option>
                                                <option>Variation</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {/* second Sub-Div=============================================================================================================================================*/}

                                <div className="flex-1">
                                    {/*code*/}
                                    <div className="mt-2">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                            Product code
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="code"
                                                name="code"
                                                type="text"
                                                value={productData.code}
                                                required
                                                onChange={(e) => setProductData({ ...productData, code: e.target.value })}
                                                placeholder="Enter code"
                                                className="block w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    {/* Brand */}
                                    <div className="mt-5">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Brand</label>
                                        <div className="mt-2">
                                            <select
                                                id="brand"
                                                name="brand"
                                                required
                                                value={productData.brand}
                                                onChange={(e) => setProductData({ ...productData, brand: e.target.value })}
                                                className="block w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            >
                                                <option value="">Select a brand</option>
                                                {Array.isArray(brandData) && brandData.map((br) => (
                                                    <option key={br.brandName} value={br.brandName}>{br.brandName}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/* Warehouse */}
                                    <div className="mt-5">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Warehouse</label>
                                        <div className="mt-2">
                                            <select
                                                id="warehouse"
                                                required
                                                name="warehouse"
                                                value={productData.warehouse}
                                                onChange={(e) => setProductData({ ...productData, warehouse: e.target.value })}
                                                className="block w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            >
                                                <option value="">Select a warehouse</option>
                                                {warehouseData.map((wh) => (
                                                    <option key={wh.name} value={wh.name}>{wh.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/* Base Unit */}
                                    <div className="mt-5">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Base unit</label>
                                        <div className="mt-2">
                                            <select
                                                id="unit"
                                                name="unit"
                                                required
                                                value={productData.unit}
                                                onChange={(e) => {
                                                    setProductData({ ...productData, unit: e.target.value });
                                                    handleBaseUnitChange(e);
                                                }}
                                                className="block w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            >
                                                <option value="">Select a base unit</option>
                                                {Array.isArray(unitData) && unitData.map((bu) => (
                                                    <option key={bu.baseUnit} value={bu.baseUnit}>{bu.baseUnit}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/*perchese unit*/}
                                    <div className="mt-5">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Purchase Unit</label>
                                        <div className="mt-2">
                                            <select
                                                id="perchese_unit"
                                                name="purchase"
                                                value={productData.purchase}
                                                required
                                                onChange={(e) => setProductData({ ...productData, purchase: e.target.value })}
                                                className="block w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            >
                                                <option value="">Select a purchase unit</option>
                                                {Array.isArray(unitData) && unitData.map((u) => (
                                                    <option key={u.unitName} value={u.unitName}>{u.unitName}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div>
                                        {/* Variation Dropdown */}
                                        <div className="mt-5">
                                            <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Variation</label>
                                            <div className="mt-2">
                                                <select
                                                    id="variation"
                                                    name="variation"
                                                    value={productData.variation}
                                                    onChange={handleVariationChange}
                                                    className={`block w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6 ${ptype !== "Variation" ? "opacity-50 pointer-events-none" : ""}`}
                                                    disabled={ptype !== "Variation"}
                                                >
                                                    <option value="">Select a variation</option>
                                                    {variationData.map((varn) => (
                                                        <option key={varn.variationName} value={varn.variationName}>
                                                            {varn.variationName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Third Sub-Div=============================================================================================================================================*/}

                                <div className="flex-1">
                                    {/**Images */}
                                    <div className="mt-[7px]">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                            Images
                                        </label>
                                        <div className="mt-[9px]">
                                            <input
                                                id="image"
                                                name="image"
                                                type="file"
                                                onChange={handleImageChange}
                                                placeholder="Enter name "
                                                className="block w-[90%]  rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    {/* Supplier */}
                                    <div className="mt-5">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Supplier</label>
                                        <div className="mt-2">
                                            <select
                                                id="suplier"
                                                name="suplier"
                                                required
                                                value={productData.suplier}
                                                onChange={(e) => setProductData({ ...productData, suplier: e.target.value })}
                                                className="block w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            >
                                                <option value="">Select a supplier</option>
                                                {suplierData.map((s) => (
                                                    <option key={s.name} value={s.name}>{s.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/*status*/}
                                    <div className="mt-5">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Status</label>
                                        <div className="mt-2">
                                            <select
                                                id="status"
                                                name="status"
                                                required
                                                value={productData.status}
                                                onChange={(e) => setProductData({ ...productData, status: e.target.value })}
                                                className="block w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            >
                                                <option value=''>Select a status</option>
                                                <option value='Received'>Received</option>
                                                <option value='Pending'>Pending</option>
                                                <option value='Ordered'>Ordered</option>
                                            </select>
                                        </div>
                                    </div>

                                    {/*Note*/}
                                    <div className="mt-5">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                            Note
                                        </label>
                                        <div className="mt-[6px]">
                                            <textarea
                                                id="note"
                                                name="note"
                                                type="text"
                                                value={productData.note}
                                                placeholder="note"
                                                onChange={(e) => setProductData({ ...productData, note: e.target.value })}
                                                className="block w-[90%]  h-[139px] rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/**VARIATION MANAGE SECTION========================================================================================================== */}

                            <hr className="mt-20" />
                            <h2 className="mt-10">Manage variations</h2>
                            {/* Multi-select variation types with chips */}
                            {variation &&  ptype !== 'Single' &&(
                                <div className="mt-5">
                                    <label className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                        Select Variation Types
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            onChange={handleAddVariationType}
                                            className="block w-[29%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                        >
                                            <option value="">Select Types</option>
                                            {variationType
                                                .filter((type) => !selectedVariationTypes.includes(type)) // Exclude already selected types
                                                .map((type, index) => (
                                                    <option key={index} value={type}>
                                                        {type}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                </div>
                            )}

                            {/* Display selected types as chips */}
                            <div className="flex flex-wrap mt-3 gap-2">
                                {ptype === 'Variation' && selectedVariationTypes.map((type, index) => (
                                    <div
                                        key={index}
                                        className="flex items-center px-3 py-1 bg-gray-200 rounded-full text-sm"
                                    >
                                        {type}
                                        <button
                                            className="ml-2 text-red-500"
                                            onClick={() => handleRemoveVariationType(type)}
                                        >
                                            &#x2715;
                                        </button>
                                    </div>
                                ))}
                            </div>

                            {/*CREATE VARIATION PROPERTIES============================================================================================================================*/}
                            
                            {/**This is the variation property manage section one by one */}
                            {ptype === 'Variation' && selectedVariationTypes.map((type) => (
                                showSections[type] && (
                                    <div className=" mt-10 variationPropertyManaging" key={type}>
                                        <h3 className="text-lg font-semibold mb-4">{type} Properties</h3>
                                        <div className='p-[15px] border border-gray-300 rounded-md'>
                                            <div className="flex space-x-4 mb-5">
                                                <div className="flex-1">
                                                    <div>
                                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                                            Variation Type
                                                        </label>
                                                        <input
                                                            value={type}
                                                            type="text"
                                                            placeholder={`${type} Type`}
                                                            onChange={(e) => handleInputChange(type, e.target.value)}
                                                            className="block w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                    <label className="block mt-5 text-sm font-medium leading-6 text-gray-900 text-left">
                                                        Stock Alert
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={variationValues[type]?.stockAlert || ''}
                                                        placeholder="Stock Alert"
                                                        onChange={(e) => handleVariationValueChange(type, 'stockAlert', e.target.value)}
                                                        className="block  w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                                    />
                                                </div>

                                                <div className="flex-1">
                                                    <label className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                                        Product Cost
                                                    </label>
                                                    <div className="relative">
                                                        <input
                                                            type="text"
                                                            placeholder="Product Cost"
                                                            value={variationValues[type]?.productCost || ''}
                                                            onChange={(e) => handleVariationValueChange(type, 'productCost', e.target.value)}
                                                            className="block w-[90%]  rounded-md border-0 py-2.5 px-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                                        />

                                                        <span className="absolute m-[1px] inset-y-0 right-0 pr-3 flex items-center bg-gray-200 px-2 mr-[10.5%] rounded-r-[5px] text-gray-500">
                                                            Rs
                                                        </span>
                                                    </div>
                                                    <label className="block mt-5 text-sm font-medium leading-6 text-gray-900 text-left">
                                                        Oder Tax
                                                    </label>
                                                    <div className="relative">
                                                        <input
                                                            type="text"
                                                            placeholder="Oder Tax"
                                                            value={variationValues[type]?.orderTax || ''}
                                                            onChange={(e) => handleVariationValueChange(type, 'orderTax', e.target.value)}
                                                            className="block w-[90%]  rounded-md border-0 py-2.5 px-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                                        />
                                                        <span className="absolute m-[1px] inset-y-0 right-0 pr-3 flex items-center bg-gray-200 px-2 mr-[10.5%] rounded-r-[5px] text-gray-500">
                                                            %
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="flex-1">
                                                    <label className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                                        Code
                                                    </label>
                                                    <input
                                                        type="text"
                                                        placeholder="Code"
                                                        value={variationValues[type]?.code || ''}
                                                        onChange={(e) => handleVariationValueChange(type, 'code', e.target.value)}
                                                        className="block w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                                    />
                                                    <label className="block  mt-5 text-sm font-medium leading-6 text-gray-900 text-left">
                                                        Tax Type
                                                    </label>
                                                    <select
                                                        type="text"
                                                        placeholder=" Tax Type"
                                                        value={variationValues[type]?.taxType || ''}
                                                        onChange={(e) => handleVariationValueChange(type, 'taxType', e.target.value)}
                                                        className="block w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                                    >
                                                        <option>Exclusive</option>
                                                        <option>Inclusive</option>
                                                    </select>
                                                </div>

                                                <div className="flex-1">
                                                    <label className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                                        Product Price
                                                    </label>
                                                    <div className="relative">
                                                        <input
                                                            type="text"
                                                            placeholder="Product Price"
                                                            value={variationValues[type]?.productPrice || ''}
                                                            onChange={(e) => handleVariationValueChange(type, 'productPrice', e.target.value)}
                                                            className="block w-[90%]  rounded-md border-0 py-2.5 px-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                                        />
                                                        <span className="absolute m-[1px] inset-y-0 right-0 pr-3 flex items-center bg-gray-200 px-2 mr-[10.5%] rounded-r-[5px] text-gray-500">
                                                            Rs
                                                        </span>
                                                    </div>
                                                    <label className="block mt-5 text-sm font-medium leading-6 text-gray-900 text-left">
                                                        Add Product Quantity
                                                    </label>
                                                    <input
                                                        type="text"
                                                        placeholder="Add Product Quantity"
                                                        value={variationValues[type]?.productQty || ''}
                                                        onChange={(e) => handleVariationValueChange(type, 'productQty', e.target.value)}
                                                        className="block w-[90%]  rounded-md border-0 py-2.5 px-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )
                            ))}

                            {/**SINGLE PRODUCT VARIATION PROPERTIES */}

                            {productData.ptype === 'Single' && (<div className="mt-10 variationPropertyManaging">
                                <h3 className="text-lg font-semibold mb-4">Single Product Properties</h3>
                                <div className='p-6 border border-gray-300 rounded-md'>
                                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                                        <div className="flex flex-col space-y-5">
                                            <div>
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    Stock Alert
                                                </label>
                                                <input
                                                    value={productData.stockAlert}
                                                    type="text"
                                                    name='stockAlert'
                                                    placeholder="Stock Alert"
                                                    onChange={(e) => setProductData({ ...productData, stockAlert: e.target.value })}
                                                    className="block w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    Order Tax
                                                </label>
                                                <div className="relative">
                                                    <input
                                                        value={productData.oderTax}
                                                        type="text"
                                                        name='oderTax'
                                                        placeholder="Order Tax"
                                                        onChange={(e) => setProductData({ ...productData, oderTax: e.target.value })}
                                                        className="block w-[90%]  rounded-md border-0 py-2.5 px-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                                                    />
                                                    <span className="absolute m-[1px] inset-y-0 right-0 pr-3 flex items-center bg-gray-200 px-2 rounded-r-md text-gray-500">
                                                        %
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex flex-col space-y-5">
                                            <div>
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    Product Cost
                                                </label>
                                                <div className="relative">
                                                    <input
                                                        value={productData.productCost}
                                                        type="text"
                                                        name='productCost'
                                                        placeholder="Product Cost"
                                                        onChange={(e) => setProductData({ ...productData, productCost: e.target.value })}
                                                        className="block w-[90%]  rounded-md border-0 py-2.5 px-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    Tax Type
                                                </label>
                                                <select
                                                    className="block w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                                                    name='taxType'
                                                    onChange={(e) => setProductData({ ...productData, taxType: e.target.value })}
                                                    value={productData.taxType}
                                                >
                                                    <option>Exclusive</option>
                                                    <option>Inclusive</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="flex flex-col space-y-5">
                                            <div>
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    Product Price
                                                </label>
                                                <div className="relative">
                                                    <input
                                                        value={productData.productPrice}
                                                        name='productPrice'
                                                        type="text"
                                                        placeholder="Product Price"
                                                        onChange={(e) => setProductData({ ...productData, productPrice: e.target.value })}
                                                        className="block w-[90%]  rounded-md border-0 py-2.5 px-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    Add Product Quantity
                                                </label>
                                                <input
                                                    value={productData.productQty}
                                                    name='productQty'
                                                    type="text"
                                                    placeholder="Add Product Quantity"
                                                    onChange={(e) => setProductData({ ...productData, productQty: e.target.value })}
                                                    className="block w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )}

                            {/*SAVE AND CLEAR BUTTONS==============================================================================================================================*/}
                            <div className="flex justify-start mt-20">
                                <div className="mt-20">
                                    <button
                                        type="submit"
                                        disabled={!isFormValid}
                                        className={`rounded-md px-4 py-3.5 h-[48px] text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-[171px] text-center ${isFormValid ? 'bg-[#37b34a] hover:bg-[#2a9d34]' : 'bg-[#a6d8a6] cursor-not-allowed'
                                            }`}
                                    >
                                        save product
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex ml-2 justify-center rounded-md bg-gray-600 py-3.5 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 w-[170px] focus:ring-gray-500 focus:ring-offset-2"
                                        onClick={handleClear}
                                    >
                                        Clear
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Error and Response Messages */}
                        {error && <p className="text-red-500 text-center">{error}</p>}
                        {responseMessage && <p className="text-green-500 text-center">{responseMessage}</p>}
                    </form>

                )}
            </div>
        </div>
    );
}

export default EditProductBody;
