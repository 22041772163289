import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { handleReturnSale } from '../SaleController'
import '../../../styles/role.css';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';

function CreateSaleReturnBody() {
    // State management
    const [warehouseData, setWarehouseData] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [loading, setLoading] = useState(false);
    const [discountType, setDiscountType] = useState('');
    const [discountSymbole, setDiscountSymbole] = useState('Rs');
    const [error, setError] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [saleProduct, setSaleProduct] = useState([])
    const [purchasedQty, setPurchasedQty] = useState([])
    const { id } = useParams();

    useEffect(() => {
        if (purchasedQty.length > 0) {
            // Update selectedProduct based on the tax information in purchasedQty
            setSelectedProduct(prevSelectedProduct =>
                prevSelectedProduct.map((product, index) => {
                    const purchasedProduct = purchasedQty[index]; // Matching purchased product
                    if (purchasedProduct) {
                        return {
                            ...product,
                            taxRate: purchasedProduct.taxRate || 0 // Set the taxRate from purchasedQty or default 0
                        };
                    }
                    return product; // Return the product as is if no match
                })
            );
        }
    }, [purchasedQty])

    useEffect(() => {
        const findSaleById = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/fetchSales`, { params: { id } });

                if (!response.data) {
                    console.warn('No data found for the given sale ID.');
                    return;
                }

                const sale = response.data; // Sale object with updated productsData
                const baseProductData = sale.productsData || []; // productsData with stockQty

                const initializedProducts = baseProductData.map(product => ({
                    ...product,
                    selectedVariation: product.selectedVariation || Object.keys(product.variationValues || {})[0],
                    stockQty: product.stockQty || "0", // Include stockQty from backend
                }));

                setSelectedProduct(initializedProducts); // Set updated product details
                setSaleProduct(sale); // Set the entire sale object
            } catch (error) {
                console.error('Error fetching sale by ID:', error.response ? error.response.data : error.message);
            }
        };

        if (id) {
            findSaleById();
        }
    }, [id]);

    console.log("Product of this sale:", saleProduct);


    const fetchData = async (url, setter) => {
        setLoading(true);
        try {
            const { data } = await axios.get(url);
            setter(data);
        } catch (error) {
            console.error(`${url} fetch error:`, error);
            setter([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(`${process.env.REACT_APP_BASE_URL}/api/fetchWarehouses`, setWarehouseData);
        return () => { };
    }, []);

    const handleDiscountType = (e) => {
        setDiscountType(e.target.value)
    }
    return (
        <div className='bg-[#eff3f7] relative left-[18%] w-[82%] min-h-[100vh] p-5'>
            <div className='flex mt-20 justify-between items-center'>
                <div>
                    <h2 className="text-lightgray-300  m-0 p-0 text-2xl">Create Sale Return</h2>
                </div>
                <div>
                    <Link className='px-4 py-1.5 border border-[#37b34a] text-[#37b34a] rounded-md transition-colors duration-300 hover:bg-[#37b34a] hover:text-white' to={'/ViewSale'}>Back</Link>
                </div>
            </div>
            <div className="bg-white mt-[20px] w-full rounded-2xl px-8 shadow-md">
                <div className="flex  flex-1 flex-col px-2 py-12 lg:px-8">
                    <form >
                        <div className="flex w-full space-x-5">
                            {/* warehouse*/}
                            <div className="flex-1">
                                <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Select warehouse</label>
                                <select
                                    id="warehouse"
                                    name="warehouse"
                                    value={saleProduct.warehouse}
                                    className="searchBox w-full pl-10 pr-2 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                                >
                                    <option value="">Select a warehouse</option>
                                    {warehouseData.map((wh) => (
                                        <option key={wh.name} value={wh.name}>
                                            {wh.name}
                                        </option>
                                    ))}
                                </select>
                                {error.username && <p className="text-red-500">{error.username}</p>}
                            </div>

                            {/* customer */}
                            <div className="flex-1 ">
                                <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Customer</label>
                                <input
                                    id="customer"
                                    name="customer"
                                    value={saleProduct.customer}
                                    required
                                    className="searchBox w-full pl-2 pr-2 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                                />
                            </div>

                            {/*Date*/}
                            <div className="flex-1 ">
                                <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Date</label>
                                <input
                                    id="date"
                                    name="date"
                                    type="text"
                                    required
                                    disabled
                                    value={saleProduct.date ? format(new Date(saleProduct.date), 'yyyy-MM-dd') : ''}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border- pl-5 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                    </form>

                    <div className="overflow-x-auto">
                        {purchasedQty.length > 0 && (
                            <table className="mt-10 min-w-full bg-white border border-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Name</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Purchase Qty</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Price</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">tax</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Sub Total</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Variation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {purchasedQty.map((product, index) => (
                                        <tr key={index}>
                                            {/* Product Name from selectedProduct */}
                                            <td className="px-6 py-4 text-left  whitespace-nowrap text-sm text-gray-500">
                                                {product.name || 'Unknown Product'}
                                            </td>

                                            {/* Product Quantity */}
                                            <td className="px-6 py-4 text-left  whitespace-nowrap text-sm text-gray-500">
                                                <div className="flex items-center">
                                                    <span className="mx-2">{product.quantity || 1}</span>
                                                </div>
                                            </td>

                                            {/* Product Price */}
                                            <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500">
                                                Rs {product.price}
                                            </td>

                                            {/* Display Product Tax */}
                                            <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500">
                                                {(product.taxRate) * 100} %
                                            </td>

                                            {/* Subtotal */}
                                            <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500">
                                                Rs {product.subtotal}
                                            </td>

                                            {/* Variation Dropdown */}
                                            <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500">
                                                <span className="block w-full py-2 border-gray-300 rounded-md shadow-sm">
                                                    {product.variationValue || "No Variations"}
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>

                    <div className="">
                        {/* DISCOUNT, SHIPPING AND TAX INPUT */}
                        <div className="grid grid-cols-4 gap-4 mb-4 mt-60">
                            <div className="relative">
                                <label className="block text-left text-sm font-medium text-gray-700">Discount Type:</label>
                                <select
                                    onChange={handleDiscountType}
                                    value={saleProduct.discountType}
                                    className="block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                >
                                    <option value='fixed'>Fixed</option>
                                    <option value='percentage'>Percentage</option>
                                </select>
                            </div>
                            <div className='relative'>
                                <label className="block text-left text-sm font-medium text-gray-700">Discount:</label>

                                <input
                                    value={saleProduct.discount}
                                    type="text"
                                    placeholder="Discount"
                                    className='block w-full rounded-md border-0 py-2.5 px-2 pr-10 text-gray-900 shadow-sm ring-1 ring-gray-400 placeholder:text-gray-400 focus:ring-gray-400 focus:outline-none sm:text-sm'
                                    readOnly // Make the input read-only
                                />
                                <span className="absolute inset-y-0 right-0 flex items-end mb-2 pr-3 text-gray-500">
                                    {discountSymbole}
                                </span>
                            </div>
                            <div className="relative">
                                <label className="block text-left text-sm font-medium text-gray-700">Tax:</label>
                                <input
                                    value={saleProduct.tax}
                                    type="text"
                                    placeholder="Tax"
                                    className="block w-full rounded-md border-0 py-2.5 px-2 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                                    readOnly // Make the input read-only
                                />
                                <span className="absolute inset-y-0 right-0 flex items-end mb-2 pr-3 text-gray-500">
                                    %
                                </span>
                            </div>
                            <div className='relative'>
                                <label className="block text-left text-sm font-medium text-gray-700">Shipping:</label>
                                <input
                                    value={saleProduct.shipping}
                                    type="text"
                                    placeholder="Shipping"
                                    className='block w-full rounded-md border-0 py-2.5 px-2 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm'
                                    readOnly // Make the input read-only
                                />
                                <span className="absolute inset-y-0 right-0 flex items-end mb-2 pr-3 text-gray-500">
                                    Rs
                                </span>
                            </div>
                        </div>

                        {/* Order, Payment Status, and Payment Type Selects */}
                        <div className="grid grid-cols-3 gap-4 mt-10">
                            <div>
                                <label className="block text-sm font-medium text-left text-gray-700">Status:</label>
                                <select
                                    value={saleProduct.orderStatus}
                                    className="searchBox w-full pl-10 pr-2 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                                >
                                    <option value="">Select Order Status</option>
                                    <option value="ordered">Ordered</option>
                                    <option value="pending">Pending</option>
                                </select>
                            </div>

                            {/* Payment Status Select */}
                            <div>
                                <label className="block text-sm font-medium  text-left text-gray-700">Payment Status:</label>
                                <select
                                    value={saleProduct.paymentStatus}
                                    className="searchBox w-full pl-10 pr-2 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                                >
                                    <option value="">Select Payment Status</option>
                                    <option value="paid">Paid</option>
                                    <option value="unpaid">Unpaid</option>
                                </select>
                            </div>

                            {/* Payment Type Select */}
                            <div>
                                <label className="block text-sm font-medium  text-left text-gray-700">Payment Type:</label>
                                <select
                                    value={saleProduct.paymentType}
                                    className="searchBox w-full pl-10 pr-2 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                                >
                                    <option value="">Select Payment Type</option>
                                    <option value="cash">Cash</option>
                                    <option value="card">Card</option>
                                    <option value="check">Check</option>
                                    <option value="bank_transfer">Bank Transfer</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="mt-4 text-right text-lg font-semibold">
                            Paid Amount  :  Rs {saleProduct.paidAmount}
                        </div>
                        <div className="mt-4 text-right text-lg font-semibold">
                            Total  :  Rs {saleProduct.grandTotal}
                        </div>
                    </div>

                    <button onClick={() => handleReturnSale(
                        saleProduct.grandTotal, saleProduct.orderStatus, saleProduct.paymentStatus, saleProduct.paymentType, saleProduct.paidAmount, saleProduct.shipping, saleProduct.discountType, saleProduct.discount, saleProduct.tax, saleProduct.warehouse, saleProduct.customer, purchasedQty, saleProduct.date, setError, setResponseMessage)} className="mt-5 submit  w-[200px] text-white rounded py-2 px-4">
                        Return The Sale
                    </button>

                    {/* Error and Response Messages */}
                    <div className='mt-10'>
                        {error && (
                            <p className="text-red-600 px-5 py-2 rounded-md bg-red-100 mt-5 text-center mx-auto max-w-sm">
                                {error}
                            </p>
                        )}
                        {responseMessage && (
                            <p className="text-green-600 px-5 py-2 rounded-md bg-green-100 mt-5 text-center  mx-auto max-w-sminline-block">
                                {responseMessage}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CreateSaleReturnBody;
