import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    handleProductSelect,
    handleProductSearch,
    handleWarehouseChange,
    handleVariationChange,
    getQty,
    getPriceRange,
    handleDelete,
    handleQtyChange,
    getTax,
} from "../sales/SaleController";
import { handleCreateAdjustmenet } from "./AdjustmentController";
import "../../styles/role.css";
import { Link } from "react-router-dom";
import { fetchProductDataByWarehouse } from "../pos/utils/fetchByWarehose";
import Decrease from "../../img/down-arrow (1).png";

function CreateAdjustmentBody() {
    // State management
    const [warehouseData, setWarehouseData] = useState([]);
    const [warehouse, setWarehouse] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [date, setDate] = useState("");
    const [selectedCategoryProducts, setSelectedCategoryProducts] = useState(
        []
    );
    const [selectedBrandProducts, setSelectedBrandProducts] = useState([]);
    const [productBillingHandling, setSearchedProductData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [responseMessage, setResponseMessage] = useState("");

    const fetchData = async (url, setter) => {
        setLoading(true);
        try {
            const { data } = await axios.get(url);
            setter(data);
        } catch (error) {
            console.error(`${url} fetch error:`, error);
            setter([]);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData(`${process.env.REACT_APP_BASE_URL}/api/fetchWarehouses`, setWarehouseData);
        return () => { };
    }, []);

    const calculateTotal = () => {
        // Calculate the product total with each product's tax included
        const productTotal = selectedProduct.reduce((total, product) => {
            const productPrice = Number(
                getPriceRange(product, product.selectedVariation)
            );
            const productQty = product.barcodeQty || 1; // Default to 1 if barcodeQty is not set
            const taxRate = product.oderTax
                ? product.oderTax / 100
                : getTax(product, product.selectedVariation) / 100;

            const subTotal =
                productPrice * productQty + productPrice * productQty * taxRate;
            return total + subTotal;
        }, 0);
        const grandTotal = productTotal;
        return grandTotal;
    };

    const handleTypeChange = (index, AdjustmentType) => {
        setSelectedProduct((prevProducts) =>
            prevProducts.map((product, i) => {
                if (i === index) {
                    return {
                        ...product,
                        AdjustmentType: AdjustmentType,
                    };
                }
                return product;
            })
        );
    };

    return (
        <div className="bg-[#eff3f7] relative left-[18%] w-[82%] min-h-[100vh] p-5">
            <div className="flex mt-20 justify-between items-center">
                <div>
                    <h2 className="text-lightgray-300  m-0 p-0 text-2xl">
                        Create Adjustment
                    </h2>
                </div>
                <div>
                    <Link
                        className="px-4 py-1.5 border border-[#37b34a] text-[#37b34a] rounded-md transition-colors duration-300 hover:bg-[#37b34a] hover:text-white"
                        to={"/ViewAdjustment"}
                    >
                        Back
                    </Link>
                </div>
            </div>
            <div className="bg-white mt-[20px] w-full rounded-2xl px-8 shadow-md pb-28 ">
                <div className="flex  flex-1 flex-col px-2 py-12 lg:px-8">
                    <form>
                        <div className="flex w-full space-x-5">
                            {" "}
                            {/* warehouse*/}
                            <div className="flex-1">
                                {" "}
                                {/* Use flex-1 to allow the field to take full width */}
                                <label className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                    Select warehouse
                                </label>
                                <select
                                    id="warehouse"
                                    name="warehouse"
                                    value={warehouse}
                                    required
                                    onChange={(e) =>
                                        handleWarehouseChange(
                                            e,
                                            setWarehouse,
                                            fetchProductDataByWarehouse,
                                            setProductData,
                                            setSelectedCategoryProducts,
                                            setSelectedBrandProducts,
                                            setSearchedProductData,
                                            setLoading
                                        )
                                    }
                                    className="searchBox w-full pl-10 pr-2 py-2.5 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                                >
                                    <option value="">Select a warehouse</option>
                                    {warehouseData.map((wh) => (
                                        <option key={wh.name} value={wh.name}>
                                            {wh.name}
                                        </option>
                                    ))}
                                </select>
                                {error.username && (
                                    <p className="text-red-500">
                                        {error.username}
                                    </p>
                                )}
                            </div>
                            {/*Date*/}
                            <div className="flex-1 ">
                                {" "}
                                {/* Use flex-1 here as well */}
                                <label className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                    Date
                                </label>
                                <input
                                    id="date"
                                    name="date"
                                    type="date"
                                    required
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border- pl-5 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                    </form>

                    <div className="flex-1 mt-5 relative">
                        {/* Input Field */}
                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">
                            Product :{" "}
                        </label>
                        <input
                            id="text"
                            name="text"
                            type="text"
                            required
                            value={searchTerm}
                            onChange={(e) =>
                                handleProductSearch(
                                    e,
                                    setSearchTerm,
                                    setFilteredProducts,
                                    warehouse
                                )
                            }
                            placeholder={searchTerm ? "" : "        Search..."}
                            className="block w-full rounded-md border-0 py-2.5 pl-10 pr-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                        />
                        {filteredProducts.length > 0 && (
                            <ul className="absolute left-0 z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg mt-1">
                                {filteredProducts.map((product) => (
                                    <li
                                        key={product._id}
                                        onClick={() =>
                                            handleProductSelect(
                                                product,
                                                setSelectedProduct,
                                                setSearchTerm,
                                                setFilteredProducts
                                            )
                                        }
                                        className="cursor-pointer hover:bg-gray-100 text-left  px-4 py-2"
                                    >
                                        {product.name}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <div className="overflow-x-auto">
                        <table className="mt-10 min-w-full bg-white border border-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                                        Name
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                                        Stock Qty
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                                        Purchase Qty
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                                        Price
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                                        Type
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                                        Variation
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedProduct.map((product, index) => (
                                    <tr key={index}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {product.name}
                                        </td>

                                        <td className="px-6 py-4 whitespace-nowrap text-sm ">
                                            <p className="rounded-[5px] text-center p-[6px] bg-green-100 text-green-500">
                                                {product.productQty ||
                                                    getQty(
                                                        product,
                                                        product.selectedVariation
                                                    )}
                                            </p>
                                        </td>

                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            <div className="flex items-center">
                                                <button
                                                    onClick={() =>
                                                        handleQtyChange(
                                                            index,
                                                            product.selectedVariation,
                                                            setSelectedProduct,
                                                            -1
                                                        )
                                                    } // Decrement
                                                    className="px-2 py-2 bg-gray-100 rounded hover:bg-gray-200"
                                                >
                                                    <img
                                                        className="w-[16px] h-[16px]"
                                                        src={Decrease}
                                                        alt="increase"
                                                    />
                                                </button>
                                                <span className="mx-2">
                                                    {product.ptype ===
                                                    "Variation"
                                                        ? product
                                                              .variationValues[
                                                              product
                                                                  .selectedVariation
                                                          ]?.barcodeQty || 1
                                                        : product.barcodeQty ||
                                                          1}
                                                </span>
                                                <button
                                                    onClick={() =>
                                                        handleQtyChange(
                                                            index,
                                                            product.selectedVariation,
                                                            setSelectedProduct,
                                                            1
                                                        )
                                                    } // Increment
                                                    className="px-2 py-2 bg-gray-100 rounded hover:bg-gray-200"
                                                >
                                                    <img
                                                        className="w-[16px] h-[16px] transform rotate-180"
                                                        src={Decrease}
                                                        alt="decrease"
                                                    />
                                                </button>
                                            </div>
                                        </td>

                                        {/* Product Price */}
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            Rs{" "}
                                            {getPriceRange(
                                                product,
                                                product.selectedVariation
                                            )}
                                        </td>

                                        {/* Product adajustment type */}
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            <select
                                                required
                                                value={product.selectedType}
                                                onChange={(e) =>
                                                    handleTypeChange(
                                                        index,
                                                        e.target.value
                                                    )
                                                }
                                                className="block w-full border py-2 border-gray-300 rounded-md shadow-sm focus:border-transparent"
                                            >
                                                <option value="addition">
                                                    Addition
                                                </option>
                                                <option value="subtraction">
                                                    Subtraction
                                                </option>
                                            </select>
                                        </td>

                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {product.ptype === "Variation" &&
                                            product.variationValues ? (
                                                <select
                                                    value={
                                                        product.selectedVariation
                                                    }
                                                    onChange={(e) =>
                                                        handleVariationChange(
                                                            index,
                                                            e.target.value,
                                                            setSelectedProduct
                                                        )
                                                    }
                                                    className="block w-full border py-2 border-gray-300 rounded-md shadow-sm focus:border-transparent"
                                                >
                                                    {Object.keys(
                                                        product.variationValues
                                                    ).map((variationKey) => (
                                                        <option
                                                            key={variationKey}
                                                            value={variationKey}
                                                        >
                                                            {variationKey}
                                                        </option>
                                                    ))}
                                                </select>
                                            ) : (
                                                <span>No Variations</span>
                                            )}
                                        </td>

                                        {/* Delete Action */}
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            <button
                                                onClick={() =>
                                                    handleDelete(
                                                        index,
                                                        selectedProduct,
                                                        setSelectedProduct
                                                    )
                                                }
                                                className="text-red-500 hover:text-red-700 font-bold py-1 px-2"
                                            >
                                                <i className="fas fa-trash mr-1"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-4 text-right text-lg font-semibold">
                        Total: Rs {calculateTotal().toFixed(2)}
                    </div>
                    <button
                        onClick={() =>
                            handleCreateAdjustmenet(
                                calculateTotal().toFixed(2),
                                warehouse,
                                selectedProduct,
                                date,
                                setError,
                                setResponseMessage
                            )
                        }
                        className="mt-5 submit  w-[200px] text-white rounded py-2 px-4"
                    >
                        Save Adjustment
                    </button>
                </div>

                {/* Error and Response Messages */}
                <div className="absolute bottom-28 right-0 left-0">
                    {error && (
                        <p className="text-red-600 px-5 py-2 rounded-md bg-red-100 mt-5 text-center inline-block">
                            {error}
                        </p>
                    )}
                    {responseMessage && (
                        <p className="text-green-600 px-5 py-2 rounded-md bg-green-100 mt-5 text-center inline-block">
                            {responseMessage}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}
export default CreateAdjustmentBody;
