import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/role.css';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { FaShoppingCart, FaCartArrowDown, FaArrowRight, FaArrowLeft } from 'react-icons/fa'

function ViewReportBody() {
    // State management
    const [saleData, setSaleData] = useState({});
    const [saleReturnData, setSaleReturnData] = useState({});
    const [purchaseData, setPurchaseData] = useState({});
    const [purchaseReturnData, setPurchaseReturnData] = useState({});
    const [totalSaleAmount, setTotalSale] = useState(0);
    const [totalSaleReturnAmount, setTotalSaleReturn] = useState(0);
    const [totalPurchaseAmount, setTotalPurchase] = useState(0);
    const [totalPurchaseReturnAmount, setTotalPurchaseReturn] = useState(0);
    const [searchedCustomerSale, setSearchedCustomerSale] = useState(null);
    const [warehouseData, setWarehouseData] = useState([]);
    const [warehouse, setWarehouse] = useState(['all'])
    const [activeTable, setActiveTable] = useState('sales'); // 'sales' or 'salesReturn'
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [error, setError] = useState('')

    //COMBINE ALL DATA FETCHING TYPE INTO ONE STATE
    const combinedProductData = Array.isArray(searchedCustomerSale) && searchedCustomerSale.length > 0
        ? searchedCustomerSale
        : Array.isArray(saleReturnData) && saleReturnData.length > 0
            ? saleReturnData
            : [];

    const fetchData = async (url, setter) => {
        setLoading(true);
        try {
            const { data } = await axios.get(url);
            setter(data);
        } catch (error) {
            console.error(`${url} fetch error:`, error);
            setter([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(`${process.env.REACT_APP_BASE_URL}/api/fetchWarehouses`, setWarehouseData);
    }, []);

    useEffect(() => {
        const fetchReportData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/getReportData/${warehouse}`);
                setSaleData(response.data.data.sales);
                setSaleReturnData(response.data.data.saleReturns);
                setPurchaseData(response.data.data.purchases);
                setPurchaseReturnData(response.data.data.purchaseReturns);

                const sales = response.data.data.sales;
                const saleReturns = response.data.data.saleReturns;
                const purchases = response.data.data.purchases;
                const purchaseReturns = response.data.data.purchaseReturns;

                const formatAmount = (amount) => {
                    const value = amount / 1000;
                    if (value >= 1000) {
                        return `${(value / 1000).toFixed(2)}M`; // For values above 10 lakh
                    }
                    return `${value.toFixed(2)}K`; // For values below 10 lakh
                };

                const totalSaleAmount = sales.reduce((total, sale) => total + parseFloat(sale.paidAmount || 0), 0);
                const totalSaleReturnAmount = saleReturns.reduce((total, sale) => total + parseFloat(sale.paidAmount || 0), 0);
                const totalPurchaseAmount = purchases.reduce((total, sale) => total + parseFloat(sale.paidAmount || 0), 0);
                const totalPurchaseReturnAmount = purchaseReturns.reduce((total, sale) => total + parseFloat(sale.paidAmount || 0), 0);

                setTotalSale(formatAmount(totalSaleAmount));
                setTotalSaleReturn(formatAmount(totalSaleReturnAmount));
                setTotalPurchase(formatAmount(totalPurchaseAmount));
                setTotalPurchaseReturn(formatAmount(totalPurchaseReturnAmount));

                console.log(response.data.data);
            } catch (err) {
                console.error('Error fetching report data:', err);
                setError('Failed to fetch report data');
            }
        };

        fetchReportData();
    }, [warehouse]);

    const handleTableChange = (table) => {
        setActiveTable(table);
    };

    // Handle search input change
    const handleFindUser = async (e) => {
        const { value } = e.target;
        setKeyword(value); // Update the keyword state
        if (value.length > 0) {
            await handleSubmit(); // Call your search function
        } else {
            setSearchedCustomerSale([]); // Clear results if input is empty
        }
    };

    // Handle search form submission
    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/fetchSaleReturns`, {
                params: { customerName: keyword } // Send the keyword
            });

            console.log(response.data); // Log the response data to check its structure

            // Check if the response.data is an array or has a property that holds the array
            if (Array.isArray(response.data)) {
                setSearchedCustomerSale(response.data);
            } else if (response.data && response.data.sales) {
                setSearchedCustomerSale(response.data.sales); // Adjust based on your actual response structure
            } else {
                setSearchedCustomerSale([]); // Fallback to an empty array if data is unexpected
            }
        } catch (error) {
            console.error('Find customer error:', error);
            setSearchedCustomerSale([]); // Clear the search results on error
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='relative bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] min-h-screen p-5'>
            <div>
                <div className="m-0 flex justify-center">
                    {/* Warehouse field */}
                    <div className="mt-5 text-center">
                        <label className="block text-sm font-medium leading-6 text-gray-900 mb-2 text-left">Warehouse</label>
                        <select
                            value={warehouse}
                            onChange={(e) => setWarehouse(e.target.value)}
                            className="block w-[400px] mx-auto rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-200 focus:outline-none sm:text-sm sm:leading-6"
                        >
                            <option value="all">All warehouse</option>
                            {warehouseData.map((wh) => (
                                <option key={wh.name} value={wh.name}>
                                    {wh.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="flex justify-between p-4 bg-gray-100 mt-5">
                    <div className="flex-1 mx-2 h-40 flex flex-col items-center justify-center rounded-lg shadow-md" style={{ backgroundColor: '#6571ff' }}>
                        <FaShoppingCart className="text-white" style={{ fontSize: '2vw' }} />
                        <h1 className="text-white font-bold text-center" style={{ fontSize: '1vw' }}>Sales</h1>
                        <p className="text-white font-bold text-center" style={{ fontSize: '2vw' }}>{totalSaleAmount}</p>
                    </div>
                    <div className="flex-1 mx-2 h-40 flex flex-col items-center justify-center rounded-lg shadow-md" style={{ backgroundColor: '#0ac074' }}>
                        <FaCartArrowDown className="text-white" style={{ fontSize: '2vw' }} />
                        <p className="text-white font-bold text-center" style={{ fontSize: '1vw' }}>Purchases</p>
                        <p className="text-white font-bold text-center" style={{ fontSize: '2vw' }}>{totalPurchaseAmount}</p>
                    </div>
                    <div className="flex-1 mx-2 h-40 flex flex-col items-center justify-center rounded-lg shadow-md" style={{ backgroundColor: '#0099fb' }}>
                        <FaArrowRight className="text-white" style={{ fontSize: '2vw' }} />
                        <h1 className="text-white font-bold text-center" style={{ fontSize: '1vw' }}>Sales Return</h1>
                        <p className="text-white font-bold text-center" style={{ fontSize: '2vw' }}>{totalSaleReturnAmount}</p>
                    </div>
                    <div className="flex-1 mx-2 h-40 flex flex-col items-center justify-center rounded-lg shadow-md" style={{ backgroundColor: '#ffb821' }}>
                        <FaArrowLeft className="text-white" style={{ fontSize: '2vw' }} />
                        <h1 className="text-white font-bold text-center" style={{ fontSize: '1vw' }}>Purchases Return</h1>
                        <p className="text-white font-bold text-center" style={{ fontSize: '2vw' }}>{totalPurchaseReturnAmount}</p>
                    </div>
                </div>

                <div className='mt-5 mb-2 ml-[4px]'>
                    <button
                        className={`px-5 ${activeTable === 'sales' ? 'text-gray-800' : 'text-gray-500'} hover:text-gray-700`}
                        onClick={() => handleTableChange('sales')}
                    >
                        Sale
                    </button>
                    <button
                        className={`px-5 ${activeTable === 'purchase' ? 'text-gray-800' : 'text-gray-500'} hover:text-gray-700`}
                        onClick={() => handleTableChange('purchase')}
                    >
                        Purchase
                    </button>
                    <button
                        className={`px-5 ${activeTable === 'salesReturn' ? 'text-gray-800' : 'text-gray-500'} hover:text-gray-700`}
                        onClick={() => handleTableChange('salesReturn')}
                    >
                        Sale Return
                    </button>
                    <button
                        className={`px-5 ${activeTable === 'purchaseReturn' ? 'text-gray-800' : 'text-gray-500'} hover:text-gray-700`}
                        onClick={() => handleTableChange('purchaseReturn')}
                    >
                        Purchase Return
                    </button>
                </div>

                <div className='mt-5 mb-2 ml-[24px]'>
                    <form onChange={handleSubmit} className="flex items-center">
                        <input
                            onChange={handleFindUser}
                            name='keyword'
                            type="text"
                            placeholder="Search..."
                            className="searchBox w-80 pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                            value={keyword}
                        />
                    </form>
                </div>

                {loading ? (
                    <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                        <LinearProgress />
                    </Box>
                ) : activeTable === 'sales' && saleData.length > 0 ? (
                    <div className="overflow-x-auto p-6">
                        <table className="min-w-full bg-white border border-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Warehouse</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Status</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Type</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Grand Total</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Paid</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {saleData.map((sale) => (
                                    <tr key={sale._id}>
                                        <td className="px-6 text-left py-4 whitespace-nowrap text-m text-gray-900">
                                            <p className='rounded-[5px] text-center p-[6px] bg-red-100 text-red-500'>{sale.customer}</p>
                                        </td>
                                        <td className="px-6 text-left py-4 whitespace-nowrap text-m text-gray-900">{sale.warehouse}</td>
                                        <td className="px-6 text-left py-4 whitespace-nowrap text-m text-gray-900">{new Date(sale.date).toLocaleDateString()}</td>
                                        <td className="px-6 text-left py-4 whitespace-nowrap text-m text-gray-900">
                                            <p className='rounded-[5px] text-center p-[6px] bg-green-100 text-green-500'>{sale.orderStatus}</p>
                                        </td>
                                        <td className="px-6 text-left py-4 whitespace-nowrap text-m text-gray-900">
                                            <p className={`rounded-[5px] text-center p-[6px] ${sale.paymentStatus === 'paid' ? 'bg-green-100 text-green-500' : sale.paymentStatus === 'Partial' ? 'bg-yellow-100 text-yellow-500' : 'bg-red-100 text-red-500'}`}>
                                                {sale.paymentStatus}
                                            </p>
                                        </td>
                                        <td className="px-6 text-left py-4 whitespace-nowrap text-m text-gray-900">
                                            <p className='rounded-[5px] text-center p-[6px] bg-blue-100 text-blue-500'>{sale.paymentType}</p>
                                        </td>
                                        <td className="px-6 text-left py-4 whitespace-nowrap text-m text-gray-900">Rs {sale.grandTotal}</td>
                                        <td className="px-6 text-left py-4 whitespace-nowrap text-m text-gray-900">Rs {sale.paidAmount}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : activeTable === 'salesReturn' && combinedProductData.length > 0 ? (
                    <div className="overflow-x-auto p-6">
                        <table className="min-w-full bg-white border border-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Warehouse</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Status</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Type</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Grand Total</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Paid</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {combinedProductData.map((sale) => (
                                    <tr key={sale._id}>
                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900"><p className='rounded-[5px] text-center p-[6px] bg-red-100 text-red-500'>{sale.customer}</p></td>
                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{sale.warehouse}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{new Date(sale.date).toLocaleDateString()}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900"><p className='rounded-[5px] text-center p-[6px] bg-green-100 text-green-500'>{sale.orderStatus}</p></td>
                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">
                                            <p className={`rounded-[5px] text-center p-[6px] ${sale.paymentStatus === 'paid' ? 'bg-green-100 text-green-500' : sale.paymentStatus === 'partial' ? 'bg-yellow-100 text-yellow-500' : 'bg-red-100 text-red-500'}`}>
                                                {sale.paymentStatus}
                                            </p>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900"><p className='rounded-[5px] text-center p-[6px] bg-blue-100 text-blue-500'>{sale.paymentType}</p></td>
                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">Rs {sale.grandTotal}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">Rs {sale.paidAmount}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : activeTable === 'purchase' && purchaseData.length > 0 ? (
                    <div className="overflow-x-auto p-6">
                        <table className="min-w-full bg-white border border-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Suplier</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Warehouse</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Status</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Type</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Grand Total</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Paid</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {purchaseData.map((purchased) => (
                                    <tr key={purchased._id}>
                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900"><p className='rounded-[5px] text-center p-[6px] bg-red-100 text-red-500'>{purchased.customer}</p></td>
                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{purchased.warehouse}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{new Date(purchased.date).toLocaleDateString()}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900"><p className='rounded-[5px] text-center p-[6px] bg-green-100 text-green-500'>{purchased.orderStatus}</p></td>
                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">
                                            <p className={`rounded-[5px] text-center p-[6px] ${purchased.paymentStatus === 'paid' ? 'bg-green-100 text-green-500' : purchased.paymentStatus === 'Partial' ? 'bg-yellow-100 text-yellow-500' :
                                                'bg-red-100 text-red-500'}`}>
                                                {purchased.paymentStatus}
                                            </p>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900"><p className='rounded-[5px] text-center p-[6px] bg-blue-100 text-blue-500'>{purchased.paymentType}</p></td>
                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">Rs {purchased.grandTotal}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">Rs {purchased.paidAmount}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>) :
                    activeTable === 'purchaseReturn' && purchaseReturnData.length > 0 ? (
                        <div className="overflow-x-auto p-6">
                            <table className="min-w-full bg-white border border-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Suplier</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Warehouse</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Status</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Type</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Grand Total</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Paid</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {purchaseReturnData.map((purchased) => (
                                        <tr key={purchased._id}>
                                            <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900"><p className='rounded-[5px] text-center p-[6px] bg-red-100 text-red-500'>{purchased.customer}</p></td>
                                            <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{purchased.warehouse}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{new Date(purchased.date).toLocaleDateString()}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900"><p className='rounded-[5px] text-center p-[6px] bg-green-100 text-green-500'>{purchased.orderStatus}</p></td>
                                            <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">
                                                <p className={`rounded-[5px] text-center p-[6px] ${purchased.paymentStatus === 'paid' ? 'bg-green-100 text-green-500' : purchased.paymentStatus === 'Partial' ? 'bg-yellow-100 text-yellow-500' :
                                                    'bg-red-100 text-red-500'}`}>
                                                    {purchased.paymentStatus}
                                                </p>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900"><p className='rounded-[5px] text-center p-[6px] bg-blue-100 text-blue-500'>{purchased.paymentType}</p></td>
                                            <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">Rs {purchased.grandTotal}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">Rs {purchased.paidAmount}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>) :
                        <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                            <LinearProgress />
                        </Box>}
                <div>
                    {error && <p className="text-green-500 mt-5 text-center">{error}</p>}
                </div>
            </div>
        </div>
    );
}

export default ViewReportBody;
