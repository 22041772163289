import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import '../../styles/login.css';
import { read, utils } from 'xlsx';

function ViewSuplierBody() {
    // State variables
    const [suplierData, setSuplierData] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [searchedSuplier, setSearchedSuplier] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [excelData, setExcelData] = useState([]);
    const [error, setError] = useState('');
    const [successStatus, setSuccessStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    // Fetch all suppliers
    useEffect(() => {
        const fetchSuplierData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/fetchSupplier`);
                console.log('Fetched supplier data:', response.data);
                setSuplierData(response.data);
            } catch (error) {
                console.error('Fetch supplier data error:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSuplierData();
    }, []);

    // Handle delete supplier
    const handleDelete = async (_id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/DeleteSuplier/${_id}`);
            setSuplierData(suplierData.filter(supplier => supplier._id !== _id));
        } catch (error) {
            console.error('Delete supplier error:', error);
        }
    };

    // Handle delete searched supplier
    const handleDeleteByFind = async (_id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/DeleteSuplier/${_id}`);
            setSearchedSuplier(null);
        } catch (error) {
            console.error('Delete supplier error:', error);
        }
    };

    // Handle search input change
    const handleFindUser = (e) => {
        setKeyword(e.target.value);
    };

    // Determine search type based on the keyword
    const determineSearchType = (keyword) => {
        if (/^\d+$/.test(keyword)) { // If the keyword is numeric
            return 'mobile';
        } else if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(keyword)) { // If the keyword looks like an email
            return 'username';
        } else {
            return 'name'; // Default to name if nothing else fits
        }
    };

    // Handle search form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        // Validate keyword
        if (!keyword || keyword.trim().length === 0) {
            setSearchedSuplier(null);
            setResponseMessage('Please enter a valid keyword to search.');
            setLoading(false);
            return;
        }
    
        try {
            // Determine search type based on the keyword
            const searchType = determineSearchType(keyword);
            
            // Make the request to fetch the supplier
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/fetchSupplier`, {
                params: { keyword, searchType }
            });
    
            // Handle response data
            if (response.data.length > 0) {
                setSearchedSuplier(response.data[0]);
                setResponseMessage('Supplier found!');
            } else {
                setSearchedSuplier(null);
                setResponseMessage('No supplier found.');
            }
    
        } catch (error) {
            // Handle any errors
            console.error('Find supplier error:', error);
            setResponseMessage('Error fetching supplier. Please try again later.');
        } finally {
            setLoading(false); // Reset loading state
        }
    };
    

    // Handle file input change
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = utils.sheet_to_json(worksheet);
                setExcelData(json);
            };
            reader.readAsArrayBuffer(file);
        }
    };

    // Handle save button click in import customer
    const handleSave = async () => {
        try {
            if (excelData.length > 0) {
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/importSuplier`, { suplier: excelData });
                if (response.status === 201) {
                    console.log('Suplier saved successfully');

                    //Reloding new imported customer
                    const fetchSuplierData = async () => {
                        setLoading(true);
                        try {
                            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/fetchSupplier`);
                            console.log('Fetched suplier data:', response.data);
                            setSuplierData(response.data);
                        } catch (error) {
                            console.error('Fetch suplier data error:', error);
                        } finally {
                            setLoading(false);
                        }
                    };
                    fetchSuplierData();
                    setOpenPopup(false);
                    setExcelData([]);
                    setSuccessStatus('Suplier saved successfully')
                } else {
                    console.error('Failed to save Suplier:', response.data.message);
                    setError('Failed to save Suplier:', response.data.message)
                }
            } else {
                console.error('No data to save');
                setError('No data to save')
            }
        } catch (error) {
            setError('Save Suplier error:', error)
            console.error('Save Suplier error:', error);
        }
    };

    // Close popup and refresh data
    const handleClosePopup = () => {
        setOpenPopup(false);   
    }

    return (
        <div className='relative bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] min-h-[100vh] p-5'>
            <div className='flex justify-between mb-4'>
                <div className="relative w-full max-w-md">
                    <form onChange={handleSubmit} className="flex items-center">
                        <input
                            onChange={handleFindUser}
                            name='keyword'
                            type="text"
                            placeholder="Search..."
                            className="searchBox w-80 pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                            value={keyword}
                        />
                        <button type="submit" className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-400">
                            <svg
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M9 3a6 6 0 100 12A6 6 0 009 3zm0-1a7 7 0 110 14A7 7 0 019 2z"
                                    clipRule="evenodd"
                                />
                                <path
                                    fillRule="evenodd"
                                    d="M12.9 12.9a1 1 0 011.41 0l3 3a1 1 0 01-1.41 1.41l-3-3a1 1 0 010-1.41z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </form>
                </div>
                <div className="flex items-center">
                    <div>
                        <button onClick={() => setOpenPopup(true)} className="submit mr-2 flex-none rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-40 text-center">
                            Import Supplier
                        </button>
                    </div>
                    <div>
                        <Link
                            to={'/createSuplier'}
                            className="submit flex-none rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-40 text-center"
                        >
                            Create Supplier
                        </Link>
                    </div>
                </div>
            </div>

            {loading ? (
                <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                    <LinearProgress />
                </Box>
            ) : searchedSuplier ? (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Username</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Supplier name</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mobile</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created on</th>
                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            <tr key={searchedSuplier._id}>
                                <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-left">{searchedSuplier.username}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-left">{searchedSuplier.name}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-left">{searchedSuplier.mobile}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-left">{new Date(searchedSuplier.createdAt).toLocaleDateString()}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 ">
                                    <div className='flex items-center'>
                                        <Link to={`/editSuplier/${searchedSuplier._id}`}
                                            className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2 flex items-center"
                                            style={{ background: 'transparent' }}
                                        >
                                            <i className="fas fa-edit mr-1"></i>
                                        </Link>
                                        <button
                                            onClick={() => handleDeleteByFind(searchedSuplier._id)}
                                            className="text-red-500 hover:text-red-700 font-bold py-1 px-2 flex items-center"
                                            style={{ background: 'transparent' }}
                                        >
                                            <i className="fas fa-trash mr-1"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : suplierData.length > 0 ? (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Username</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Supplier name</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mobile</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created on</th>
                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {suplierData.map((suplier) => (
                                <tr key={suplier._id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-left">{suplier.username}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-left">{suplier.name}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-left">{suplier.mobile}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-left">{new Date(suplier.createdAt).toLocaleDateString()}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-right">
                                        <div className='flex items-center justify-end'>
                                            <Link to={`/editSuplier/${suplier._id}`}
                                                className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2 flex items-center"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-edit mr-1"></i>
                                            </Link>
                                            <button
                                                onClick={() => handleDelete(suplier._id)}
                                                className="text-red-500 hover:text-red-700 font-bold py-1 px-2 flex items-center"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-trash mr-1"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                    <LinearProgress />
                </Box>
            )}

            {/* Importing suplier section */}
            {openPopup && (
                <>
                    <div className="fixed inset-0 bg-gray-900  bg-opacity-50 z-40" onClick={() => setOpenPopup(false)}></div>
                    {/* Popup Container */}
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div className="bg-white p-6 rounded-lg shadow-lg w-1/2 h-[450px] overflow-y-auto">
                            <h2 className="text-lg font-semibold mb-4">Import Suplier</h2>
                            <div>
                                <input
                                    type="file"
                                    accept=".xlsx, .xls"
                                    onChange={handleFileChange}
                                    className=""
                                />
                            </div>

                            <div className='mt-10'>
                                <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Username : Required</label>
                                <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Name : Required</label>
                                <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Date of birth : Required</label>
                                <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Mobile : Required</label>
                                <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Country : Required</label>
                                <label className="block text-sm font-medium leading-6 text-gray-900 text-left">City : Required</label>
                                <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Address : Required</label>
                            </div>

                            <div>
                                <button onClick={handleSave} className="submit flex-none rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-40 text-center">
                                    Save
                                </button>
                                <button onClick={handleClosePopup} className="mt-20 inline-flex ml-2 justify-center rounded-md bg-gray-600 py-2.5 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 w-[100px]  focus:ring-gray-500 focus:ring-offset-2">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div className='mt-5'>
                {/* Error and Response Messages */}
                {error && <p className="text-red-500 text-center">{error}</p>}
                {successStatus && <p className="text-green-500 text-center">{successStatus}</p>}
            </div>
        </div>
    );
}

export default ViewSuplierBody;
