import { useState, useEffect, useRef, } from "react";
import ReactToPrint from "react-to-print";
import PrintInvoice from "./printInvoice";
import axios from "axios";
import { handleSave } from '../../sales/SaleController'

const PayingSection = ({ handlePopupClose, calculateTotalPrice, calculateTotalQty, tax, shipping, discount, productDetails, handleBillReset, setSelectedCategoryProducts, setSelectedBrandProducts, setSearchedProductData, setProductData, selectedCustomer, discountType, warehouse,responseMessage, setResponseMessage }) => {
    const [receivedAmount, setReceivedAmount] = useState('');
    const [returnAmount, setReturnAmount] = useState('');
    const [paymentType, setPaymentType] = useState('cash');
    const [note, setNote] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('paid');
    const printRef = useRef();
    const [registerData, setRegisterData] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('')
    const date = new Date().toISOString().split('T')[0];

    const handleReceivedAmount = (e) => {
        setReceivedAmount(e.target.value);
    };
    useEffect(() => {
        const totalAmount = calculateTotalPrice();
        const received = parseFloat(receivedAmount) || 0;
        const calculatedReturnAmount = received - totalAmount;
        setReturnAmount(calculatedReturnAmount.toFixed(2));
    }, [receivedAmount, calculateTotalPrice]);

    const handlePrintAndClose = () => {
        window.location.reload();
        setReceivedAmount('');
        setReturnAmount('');
        setPaymentType('cash');
        setNote('');
        setPaymentStatus('paid');
        handleBillReset();
        handlePopupClose();
    };

    const updateProductQuantities = async (productDetails) => {
        try {
            // Create an array of restructured product details
            const reStructuredProductDetails = productDetails.map(product => {
                const name = product.name;
                const _id = product.currentID;
                const ptype = product.ptype;
                const selectedVariation = product.variation;
                const price = product.price;
                const barcodeQty = product.qty || 1;
                const oderTax = 0;

                return {
                    name,
                    _id,
                    ptype,
                    selectedVariation,
                    price,
                    barcodeQty,
                    oderTax
                };
            });

            // Set selectedProduct to reStructuredProductDetails
            const selectedProduct = reStructuredProductDetails;

            console.log("Restructured Product Details:", reStructuredProductDetails);

            // Now call handleSave with selectedProduct
            handleSave(
                calculateTotalPrice(),
                "ordered",
                'paid',
                paymentType,
                shipping,
                discountType,
                discount,
                tax,
                warehouse ? warehouse : 'Unknown',
                selectedCustomer ? selectedCustomer : 'Unknown',
                selectedProduct,
                date,
                setError,
                setResponseMessage
            );
            return;
        } catch (error) {
            console.error('Error updating product quantities:', error);
        }
    };

    useEffect(() => {
        const fetchReportData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/findRegisterData`);
                console.log('Full response:', response.data);

                // Check if response data contains an array and access the first item
                if (Array.isArray(response.data.data) && response.data.data.length > 0) {
                    const name = response.data.data[0].name;
                    setRegisterData(name);
                    console.log('Name:', name);
                } else {
                    console.error('Data array is empty or not found.');
                    setRegisterData('');
                }
            } catch (err) {
                console.error('Error fetching report data:', err);
                setError('Failed to fetch report data');
            } finally {
                setLoading(false);
            }
        };
        fetchReportData();
    }, []);

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white w-[1200px] h-[700px] p-6 rounded-md shadow-lg">
                <h2 className="text-xl font-semibold">Make Payment</h2>
                <div className="flex mt-4">
                    <div>
                        <div className='flex'>
                            <div className='mr-[40px]'>
                                <label className="block text-sm font-medium leading-6 text-gray-900">Received Amount:</label>
                                <input
                                    onChange={handleReceivedAmount}
                                    type="text"
                                    placeholder="Received Amount"
                                    className="block w-[300px] rounded-md border-0 py-2.5 px-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium leading-6 text-gray-900">Paying Amount:</label>
                                <input
                                    type="text"
                                    placeholder="Paying Amount"
                                    value={calculateTotalPrice()}
                                    className="block w-[300px] rounded-md border-0 py-2.5 px-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className='flex mt-10'>
                            <div className='mr-[40px]'>
                                <label className="block text-sm font-medium leading-6 text-gray-900">Return Amount:</label>
                                <input
                                    type="text"
                                    placeholder="Return Amount"
                                    value={returnAmount}
                                    className="block w-[300px] rounded-md border-0 py-2.5 px-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                                    readOnly
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium leading-6 text-gray-900">Payment Type:</label>
                                <select
                                    value={paymentType}
                                    onChange={(e) => setPaymentType(e.target.value)}
                                    className="block w-[300px] rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                >
                                    <option>Cash</option>
                                    <option>Card</option>
                                    <option>Bank Transfer</option>
                                    <option>Cheque</option>
                                </select>
                            </div>
                        </div>

                        <div className='flex mt-10'>
                            <div className='w-full'>
                                <label className="block text-sm font-medium leading-6 text-gray-900">Note:</label>
                                <textarea
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                    placeholder="note"
                                    className="block w-full h-[80px] rounded-md border-0 py-2.5 px-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                                />
                            </div>
                        </div>

                        <div className='flex mt-10'>
                            <div className='w-full'>
                                <label className="block text-sm font-medium leading-6 text-gray-900">Payment Status:</label>
                                <select
                                    value={paymentStatus}
                                    onChange={(e) => setPaymentStatus(e.target.value)}
                                    className="block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                >
                                    <option>Paid</option>
                                    <option>Unpaid</option>
                                </select>
                            </div>
                        </div>

                        <div className='mt-10'>
                            <button
                                className="px-4 py-2 mr-2 bg-gray-500 text-white rounded-md"
                                onClick={handlePopupClose}
                                type="button"
                            >
                                Cancel
                            </button>
                            <button
                                className="px-4 py-2 bg-green-500 text-white rounded-md"
                                onClick={() => {
                                    console.log('POS closed', { receivedAmount, returnAmount, paymentType, note, paymentStatus });
                                    updateProductQuantities(productDetails);
                                }}
                                type="button"
                            >
                                Submit Payment
                            </button>
                            
                            <ReactToPrint
                                trigger={() => (
                                    <button
                                        className="px-4 py-2 ml-2 bg-green-500 text-white rounded-md"
                                        type="button"
                                    >
                                        Print Bill & Close
                                    </button>
                                )}
                                
                                content={() => printRef.current}
                                onBeforeGetContent={async () => {
                                    // Check if payment is valid before printing
                                    const totalAmount = calculateTotalPrice();
                                    const received = parseFloat(receivedAmount) || 0;

                                    if (received < totalAmount) {
                                        alert('Not enough money! Please provide sufficient funds.');
                                        return Promise.reject(); // Prevent printing if funds are insufficient
                                    }

                                    // Save the sale in the database before printing
                                    try {
                                        await updateProductQuantities(productDetails); // Ensure sale is saved
                                        console.log('Sale saved successfully before printing');
                                        return Promise.resolve();
                                    } catch (error) {
                                        console.error('Error saving sale before printing:', error);
                                        alert('Failed to save the sale. Please try again.');
                                        return Promise.reject(); // Prevent printing if saving fails
                                    }
                                }}
                                onAfterPrint={() => {
                                    handlePrintAndClose(); // Handle additional logic after printing
                                }}
                            />
                        </div>
                    </div>

                    <div className='mt-6 ml-10 w-[500px]'>
                        <table className="min-w-[450px] border border-gray-300 rounded-lg">
                            <tbody>
                                <tr className="border-t border-gray-300">
                                    <td className="px-2 py-2 text-m text-gray-600 border border-gray-300">Total Products</td>
                                    <td className="px-2 py-2 text-sm text-gray-600 border border-gray-300">{calculateTotalQty()}</td>
                                </tr>
                                <tr className="border-t border-gray-300">
                                    <td className="px-2 py-2 text-m text-gray-600 border border-gray-300">Total Amount</td>
                                    <td className="px-2 py-2 text-m text-gray-600 border border-gray-300">{calculateTotalPrice()}</td>
                                </tr>
                                <tr className="border-t border-gray-300">
                                    <td className="px-2 py-2 text-m text-gray-600 border border-gray-300">Order Tax</td>
                                    <td className="px-2 py-2 text-m text-gray-600 border border-gray-300">{tax}</td>
                                </tr>
                                <tr className="border-t border-gray-300">
                                    <td className="px-2 py-2 text-m text-gray-600 border border-gray-300">Discount</td>
                                    <td className="px-2 py-2 text-m text-gray-600 border border-gray-300">{discount}</td>
                                </tr>
                                <tr className="border-t border-gray-300">
                                    <td className="px-2 py-2 text-m text-gray-600 border border-gray-300">Shipping</td>
                                    <td className="px-2 py-2 text-m text-gray-600 border border-gray-300">{shipping}</td>
                                </tr>
                                <tr className="border-t border-gray-300">
                                    <td className="px-2 py-2 text-m text-gray-600 border border-gray-300">Grand Total</td>
                                    <td className="px-2 py-2 text-m text-gray-600 border border-gray-300">{calculateTotalPrice()}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Render PrintInvoice here but hide it */}
                <div style={{ display: 'none' }}>
                    <PrintInvoice
                        ref={printRef}
                        productDetails={productDetails}
                        total={calculateTotalPrice()}
                        qty={calculateTotalQty()}
                        receivedAmount={receivedAmount}
                        returnAmount={returnAmount}
                        tax={tax}
                        shipping={shipping}
                        discount={discount}
                        paymentType={paymentType}
                        paymentStatus={paymentStatus}
                        selectedCustomer={selectedCustomer}
                        registerData={registerData}
                    />
                </div>
                <div>
                    {error && <p className="text-green-500 mt-5 text-center">{error}</p>}
                </div>
            </div>
        </div>
    );
};

export default PayingSection;
