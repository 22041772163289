// fetchHeldProducts.js
import axios from 'axios';

export const getHeldProducts = async (setHeldProducts) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/viewAllHeldProducts`);
        setHeldProducts(response.data.data);
        console.log(response.data.data);
    } catch (error) {
        console.error('Error fetching held products:', error);
    }
};

export const handleDeleteHoldProduct = async (id, heldProducts, setHeldProducts) => {
    try {
        await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/deleteHeldProduct/${id}`);

        const updatedHeldProducts = heldProducts.filter(product => product._id !== id);
        setHeldProducts(updatedHeldProducts); // Update the state immediately

        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/viewAllHeldProducts`);
        setHeldProducts(response.data.data);
    } catch (error) {
        console.error('Error deleting held product:', error);
    }
};
