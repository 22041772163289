import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

function ViewBrandsBody() {
    const [brandData, setBrandData] = useState([]);
    const [brandName, setBrandName] = useState('');
    const [searchedBrand, setSearchedBrand] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    useEffect(() => {
        const fetchBrandData = async () => {
            setLoading(true);
            setError('');
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/findBrand`);
                console.log('Fetched brands data:', response.data);
                setBrandData(response.data);
            } catch (error) {
                console.error('Fetch brand data error:', error);
                setBrandData([]);
                setError('Cannot load brands. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchBrandData();
    }, []);

    const handleDelete = async (_id) => {
        setError('');
        setResponseMessage('');
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/deleteBrand/${_id}`);
            setBrandData(brandData.filter((brand) => brand._id !== _id));
            setResponseMessage('Brand deleted successfully.');
        } catch (error) {
            console.error('Delete brand error:', error);
            setError('Failed to delete the brand. Please try again later.');
        }
    };

    const handleDeleteByFind = async (_id) => {
        setError('');
        setResponseMessage('');
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/deleteBrand/${_id}`);
            setSearchedBrand(null);
            setBrandData(brandData.filter((brand) => brand._id !== _id));
            setResponseMessage('Brand deleted successfully.');
        } catch (error) {
            console.error('Delete brand by find error:', error);
            setError('Failed to delete the brand. Please try again later.');
        }
    };

    const handleFindBrand = async (e) => {
        const value = e.target.value;
        setBrandName(value);
        setError('');
        setResponseMessage('');

        if (value) {
            await performSearch(value);
        } else {
            setSearchedBrand(null);
        }
    };

    const performSearch = async (brandName) => {
        setLoading(true);
        setError('');
        setResponseMessage('');
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/findOneBrand`, { brandName });
            setSearchedBrand(response.data);
            setResponseMessage('Brand found successfully.');
        } catch (error) {
            console.error('Find brand error:', error);
            setSearchedBrand(null);
            setError('No brand found with the given name.');
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setResponseMessage('');
        if (brandName) {
            await performSearch(brandName);
        } else {
            setError('Please enter a brand name to search.');
        }
    };

    return (
        <div className='relative bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] h-[100vh] p-5'>
            <div className='flex justify-between mb-4'>
                <div className="relative w-full max-w-md">
                    <form onSubmit={handleSubmit} className="flex items-center">
                        <input
                            onChange={handleFindBrand}
                            name='keyword'
                            type="text"
                            placeholder="Search..."
                            className="searchBox w-80 pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                            value={brandName}
                        />
                        <button type="submit" className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-400">
                            <svg
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M9 3a6 6 0 100 12A6 6 0 009 3zm0-1a7 7 0 110 14A7 7 0 019 2z"
                                    clipRule="evenodd"
                                />
                                <path
                                    fillRule="evenodd"
                                    d="M12.9 12.9a1 1 0 011.41 0l3 3a1 1 0 01-1.41 1.41l-3-3a1 1 0 010-1.41z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </form>
                </div>
                <div className="flex items-center">
                    <div>
                        <Link
                            to={'/createBrands'}
                            className="submit flex-none rounded-md px-4 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-60 text-center"
                        >
                            Create Brands
                        </Link>
                    </div>
                </div>
            </div>

            {loading ? (
                <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                    <LinearProgress />
                </Box>
            ) : searchedBrand ? (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Logo</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Brand Name</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            <tr key={searchedBrand._id}>
                                <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">
                                    <img className="rounded-full" style={{ width: "40px", height: "40px" }} src={searchedBrand.logo} alt='logo' />
                                </td>
                                <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">{searchedBrand.brandName}</td>
                                <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900 text-right">
                                    <div className='flex justify-end items-center'>
                                        <Link to={`/editBrands/${searchedBrand._id}`}
                                            className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2 flex items-center"
                                            style={{ background: 'transparent' }}
                                        >
                                            <i className="fas fa-edit mr-1"></i>
                                        </Link>
                                        <button
                                            onClick={() => handleDeleteByFind(searchedBrand._id)}
                                            className="text-red-500 hover:text-red-700 font-bold py-1 px-2"
                                            style={{ background: 'transparent' }}
                                        >
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : brandData.length > 0 ? (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Logo</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Brand Name</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {brandData.map((brand) => (
                                <tr key={brand._id}>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">
                                        <img className="rounded-full" style={{ width: "40px", height: "40px" }} src={brand.logo} alt='logo' />
                                    </td>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">{brand.brandName}</td>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900 text-right">
                                        <div className='flex justify-end items-center'>
                                            <Link to={`/editBrands/${brand._id}`}
                                                className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2 flex items-center"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-edit mr-1"></i>
                                            </Link>
                                            <button
                                                onClick={() => handleDelete(brand._id)}
                                                className="text-red-500 hover:text-red-700 font-bold py-1 px-2"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-trash"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <p></p>
            )}
             {/* Error and Response Messages */}
             <div className="absolute bottom-28 right-0 left-0">
                    {error && (
                        <p className="text-red-600 px-5 py-2 rounded-md bg-red-100 mt-5 text-center inline-block">
                            {error}
                        </p>
                    )}
                    {responseMessage && (
                        <p className="text-green-600 px-5 py-2 rounded-md bg-green-100 mt-5 text-center inline-block">
                            {responseMessage}
                        </p>
                    )}
                </div>
        </div>
    );
}

export default ViewBrandsBody;
