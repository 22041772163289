import React, { useState, useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import '../../styles/tailwind.css';
import '../../styles/login.css';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [progress, setProgress] = useState(false);
    const { setUserData, clearUserData } = useContext(UserContext);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setProgress(true);
    
        const userData = { username, password };
    
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/login`, userData);
            if (response.data.token) {
                const { token } = response.data;
    
                // Clear any existing user data before setting new data
                sessionStorage.clear();
                clearUserData();
                sessionStorage.setItem('token', token);
    
                // Fetch and set user data inside the UserContext
                await setUserData(token);
    
                // Navigate to the dashboard
                navigate('/dashboard');
            } else {
                setError('Login failed. Please try again.');
            }
        } catch (error) {
            if (error.response) {
                // Server responded with a status code outside the 2xx range
                console.error('Error response:', error.response);
    
                switch (error.response.status) {
                    case 400:
                        setError('Invalid username or password.');
                        break;
                    case 401:
                        setError('Invalid username or password.');
                        break;
                    case 404:
                        setError('User not found.');
                        break;
                    case 500:
                        setError('Server error. Please try again later.');
                        break;
                    default:
                        setError('An unexpected error occurred. Please try again.');
                }
            } else if (error.request) {
                // No response received from the server
                console.error('No response received:', error.request);
                setError('No response received from server. Please check your network connection.');
            } else {
                // Something else caused the error
                console.error('Request setup error:', error.message);
                setError('An unexpected error occurred. Please try again.');
            }
        } finally {
            setProgress(false);
        }
    };

    return (
        <div className="w-full h-full min-h-screen flex items-center justify-center bg-[#eff3f7] px-6 py-12 lg:px-8">
            <div className="absolute top-0 left-0 w-full h-full bg-[#eff3f7] pb-20 flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="absolute w-full sm:w-[90%] md:w-[70%] lg:w-[30%] left-1/2 transform -translate-x-1/2 h-[72%] sm:h-[72%] top-[15%] bg-white p-[50px_30px] rounded-[15px] shadow-md">
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <img
                            alt="Your Company"
                            src="https://infypos-demo.nyc3.digitaloceanspaces.com/settings/337/logo-80.png"
                            className="mx-auto h-10 w-auto"
                        />
                        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                            Sign in to your account
                        </h2>
                    </div>

                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                    Email address
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        required
                                        autoComplete="email"
                                        placeholder='admin@infy-pos.com'
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        className="pass block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="flex items-center justify-between">
                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                        Password
                                    </label>
                                    <div className="text-sm">
                                    <Link to={`/forgetpassword`} className="forgotPass font-semibold text-indigo-600 hover:text-indigo-500">
                                            Forgot password?
                                        </Link>

                                    </div>
                                </div>
                                <div className="mt-2">
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        required
                                        placeholder='x  x  x  x  x  x'
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        autoComplete="current-password"
                                        className="pass block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="flex w-full justify-center">
                                <button
                                    type="submit"
                                    className="submit flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Login
                                </button>
                            </div>
                            {error && (
                                <h2 className="text-red-700 text">
                                    {error}
                                </h2>
                            )}
                        </form>
                        {progress && (
                            <div className="flex w-full justify-center mt-4">
                                <Box sx={{ display: 'flex' }}>
                                    <CircularProgress />
                                </Box>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;