import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import '../../styles/role.css';
import { usePDF } from 'react-to-pdf';

function ViewPurchaseBody() {
    // State variables
    //const [suplierData, setSuplierData] = useState([]);
    const [purchasedData, setPuchasedData] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [searchedSuplierPurchased, setSearchedSuplierPurchased] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openPopupId, setOpenPopupId] = useState(null);
    const popupRef = useRef(null);
    const [error, setError] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [openViewSale, setOpenViewSale] = useState(null);
    //const [filteredSaleData, setFilteredSaleData] = useState(purchasedData);
    const { toPDF, targetRef } = usePDF({ filename: `${purchasedData.customer || 'invoice'}.pdf` });
    //COMBINE ALL DATA FETCHING TYPE INTO ONE STATE
    const combinedProductData = Array.isArray(searchedSuplierPurchased) && searchedSuplierPurchased.length > 0
        ? searchedSuplierPurchased
        : Array.isArray(purchasedData) && purchasedData.length > 0
            ? purchasedData
            : [];

    // Fetch all supliers
    useEffect(() => {
        const fetchSaleData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/fetchPurchase`);
                console.log('Fetched purchased data:', response.data);
                setPuchasedData(response.data);
            } catch (error) {
                console.error('Fetch purchased data error:', error);
                setError('Cannot load sale data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchSaleData();
    }, []);

    // Handle delete purchase
    const handleDelete = async (_id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/deletePurchase/${_id}`);
            setPuchasedData(purchasedData.filter(sale => sale._id !== _id));
        } catch (error) {
            console.error('Delete sale error:', error);
        }
    };

    // Handle search input change
    const handleFindUser = async (e) => {
        const { value } = e.target;
        setKeyword(value); // Update the keyword state
        if (value.length > 0) {
            await handleSubmit(value); // Pass the input value to handleSubmit
        } else {
            setSearchedSuplierPurchased([]); // Clear results if input is empty
        }
    };

    // Determine search type based on the keyword
    const determineSearchType = (keyword) => {
        if (/^\d+$/.test(keyword)) { // If the keyword is numeric
            return 'mobile';
        } else if (/^PUR_\d{4}$/.test(keyword)) { // If the keyword matches 'PUR_' followed by 4 digits
            return 'purchaseId';
        } else if (/^[a-zA-Z0-9]{24}$/.test(keyword)) { // If the keyword looks like a MongoDB ID
            return 'id';
        } else if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(keyword)) { // If the keyword looks like an email
            return 'customer';
        } else {
            return 'customer'; // Default to name if nothing else fits
        }
    };
    

// Handle search form submission
const handleSubmit = async (inputValue) => {
    setLoading(true);
    let params = {};
    try {
        const searchType = determineSearchType(inputValue);

        console.log('Search Input:', inputValue);
console.log('Search Type:', searchType);
console.log('Request Params:', params);

        if (searchType === 'purchaseId') {
            params = { purchaseId: inputValue };
        } else if (searchType === 'id') {
            params = { id: inputValue };
        } else {
            params = { customerName: inputValue };
        }

        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/fetchPurchase`, { params });

        console.log('Response Data:', response.data);

        if (response.data && !Array.isArray(response.data)) {
            setSearchedSuplierPurchased([response.data]);
        } else if (Array.isArray(response.data)) {
            setSearchedSuplierPurchased(response.data);
        } else {
            setSearchedSuplierPurchased([]);
        }
    } catch (error) {
        console.error('Search error:', error);
        setSearchedSuplierPurchased([]);
    } finally {
        setLoading(false);
    }
};



    const handleTogglePopup = (saleId) => {
        setOpenPopupId(openPopupId === saleId ? null : saleId);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setOpenPopupId(null);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [popupRef]);

    const handleSaleViewPopUp = async (saleId) => {
        setOpenPopupId(null);
        setOpenViewSale(openViewSale === saleId ? null : saleId);
        if (openViewSale !== saleId) {
            // const sale = suplierData.find((sale) => sale._id === saleId);
            // const customerName = sale.customer;

            // try {
            //     if (customerName !== "") {
            //         const response = await axios.get(`http://localhost:5000/api/searchSuplierByName?name=${customerName}`);
            //         //setFilteredSaleData(response.data.customer);
            //         console.log(response.data)
            //     } else {
            //         setFilteredSaleData(suplierData);
            //     }
            // } catch (error) {
            //     console.log(error);
            // }
        }
    };

    return (
        <div className='relative bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%]  min-h-[100vh] p-5'>
            <div className='flex justify-between mb-4'>
                <div className="relative w-full max-w-md">
                    <form onChange={handleSubmit} className="flex items-center">
                        <input
                            onChange={handleFindUser}
                            name='keyword'
                            type="text"
                            placeholder="Search..."
                            className="searchBox w-80 pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                            value={keyword}
                        />
                        <button type="submit" className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-400">
                            <svg
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M9 3a6 6 0 100 12A6 6 0 009 3zm0-1a7 7 0 110 14A7 7 0 019 2z"
                                    clipRule="evenodd"
                                />
                                <path
                                    fillRule="evenodd"
                                    d="M12.9 12.9a1 1 0 011.41 0l3 3a1 1 0 01-1.41 1.41l-3-3a1 1 0 010-1.41z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </form>
                </div>
                <div className="flex items-center">
                    <div>
                        <Link
                            to={'/CreatePurchase'}
                            className="submit rounded-md px-5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-[200px] text-center"
                        >
                            Create Purchase
                        </Link>
                    </div>
                </div>
            </div>

            {loading ? (
                <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                    <LinearProgress />
                </Box>
            ) : combinedProductData.length > 0 ? (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead className="bg-gray-50">
                            <tr> 
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Refference</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Suplier</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Warehouse</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Status</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Type</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Grand Total</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Paid</th>
                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {combinedProductData.map((purchased) => (
                                <tr key={purchased._id}>
                                    <td className="px-6 py-5 whitespace-nowrap text-m text-left text-gray-900"><p className='rounded-[5px] text-center p-[6px] bg-red-100 text-red-500'>{purchased.refferenceId}</p></td>
                                    <td className="px-6 py-5 whitespace-nowrap text-m text-left text-gray-900"><p className='rounded-[5px] text-center p-[6px] bg-red-100 text-red-500'>{purchased.customer}</p></td>
                                    <td className="px-6 py-5 whitespace-nowrap text-m text-left text-gray-900">{purchased.warehouse}</td>
                                    <td className="px-6 py-5 whitespace-nowrap text-left text-m text-gray-900">{new Date(purchased.date).toLocaleDateString()}</td>
                                    <td className="px-6 py-5 whitespace-nowrap text-left text-m text-gray-900"><p className='rounded-[5px] text-center p-[6px] bg-green-100 text-green-500'>{purchased.orderStatus}</p></td>
                                    <td className="px-6 py-5 whitespace-nowrap text-left text-m text-gray-900">
                                        <p className={`rounded-[5px] text-center p-[6px] ${purchased.paymentStatus === 'paid' ? 'bg-green-100 text-green-500' : purchased.paymentStatus === 'Partial' ? 'bg-yellow-100 text-yellow-500' :
                                            'bg-red-100 text-red-500'}`}>
                                            {purchased.paymentStatus}
                                        </p>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-left text-m text-gray-900"><p className='rounded-[5px] text-center p-[6px] bg-blue-100 text-blue-500'>{purchased.paymentType}</p></td>
                                    <td className="px-6 py-4 whitespace-nowrap text-left text-m text-gray-900">Rs {purchased.grandTotal}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-left text-m text-gray-900">Rs {purchased.paidAmount}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">
                                        <div className='flex items-center justify-end'>
                                            <Link to={`/EditPurchase/${purchased._id}`}
                                                className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2 flex items-center"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-edit mr-1"></i>
                                            </Link>
                                            <button
                                                onClick={() => handleDelete(purchased._id)}
                                                className="text-red-500 hover:text-red-700 font-bold py-1 px-2 flex items-center"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-trash mr-1"></i>
                                            </button>
                                            <button
                                                onClick={() => handleTogglePopup(purchased._id)}
                                                className="text-gray-500 hover:text-gray-700 font-bold py-1 px-2 flex items-center rotate-90"
                                            >
                                                <i className="fa fa-ellipsis-h"></i>
                                            </button>

                                            {/* Conditional rendering of the popup for the specific sale._id */}
                                            {openPopupId === purchased._id && (
                                                <div ref={popupRef} className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-10">
                                                    <ul className="text-sm text-gray-700">
                                                        <li onClick={() => handleSaleViewPopUp(purchased._id)} className="px-4 py-4 hover:bg-gray-100 cursor-pointer flex items-center">
                                                            <i className="fas fa-eye mr-2 text-gray-600"></i> {/* Icon for "View Sale" */}
                                                            View Purchase
                                                        </li>
                                                        <Link to={`/CreatePurchaseReturn/${purchased._id}`} className="px-4 py-4 hover:bg-gray-100 cursor-pointer flex items-center">
                                                            <i className="fas fa-undo-alt mr-2 text-gray-600"></i> {/* Icon for "Create Return" */}
                                                            Create Return
                                                        </Link>
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    {/* View Sale popup */}
                                    {openViewSale === purchased._id && (
                                        <div ref={popupRef} className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                                            <div className="bg-white w-[1300px] max-h-[90vh] overflow-auto p-8 pt-8 rounded-md shadow-lg mt-40 mb-10">
                                                <div ref={targetRef} className="w-[1250px] p-10 bg-white" style={{ margin: '0 auto', padding: '15px', boxSizing: 'border-box' }}>
                                                    {/* Header */}
                                                    <div className="mb-6 flex justify-between items-center border-b pb-4">
                                                        <h2 className="text-2xl font-bold text-gray-700">Purchased Details from {purchased.customer}</h2>
                                                    </div>

                                                    {/* Sale Info Section */}
                                                    <div className="grid grid-cols-3 gap-8 text-gray-700">
                                                        {/* Customer Info */}
                                                        <div className="border-r pr-8">
                                                            <h3 className="text-lg text-left font-semibold mb-2 p-[8px] bg-gray-100 text-gray-700">
                                                                <i className="fas fa-user mr-2 text-gray-600"></i>
                                                                Suplier Info
                                                            </h3>
                                                            <p className="mb-1 text-left"><i className="fas fa-user ml-2 mr-2 text-gray-400"></i><span className="font-medium">Suplier:</span> {purchased.customer}</p>

                                                            {/* {filteredSaleData.map((customer) => (
                                                                <div>
                                                                    <p className='m-2'><i className="fas fa-envelope mr-2 text-gray-400"></i><span className="font-medium">Email:</span> {customer.username}</p>
                                                                    <p className='m-2'><i className="fas fa-city mr-2 text-gray-400"></i><span className="font-medium">City:</span> {customer.city}</p>
                                                                    <p className='m-2'><i className="fas fa-phone mr-2 text-gray-400"></i><span className="font-medium">Mobile:</span> {customer.mobile}</p>
                                                                </div>
                                                            ))} */}
                                                        </div>

                                                        {/* Company Info */}
                                                        <div className="border-r pr-8">
                                                            <h3 className="text-lg text-left p-[8px] bg-gray-100 font-semibold mb-2 text-gray-700">
                                                                <i className="fas fa-building mr-2  text-gray-600"></i>
                                                                Company Info
                                                            </h3>
                                                            <p className="m-2 text-left"><i className="fas fa-building mr-2 text-gray-400"></i><span className="font-medium">Company:</span> Ideazone</p>
                                                            <p className="m-2 text-left"><i className="fas fa-envelope mr-2 text-gray-400"></i><span className="font-medium">Email:</span> ideazone@info.gmail.com</p>
                                                            <p className="m-2 text-left"><i className="fas fa-phone mr-2 text-gray-400"></i><span className="font-medium">Phone:</span> +94 2828 395</p>
                                                            <p className="m-2 text-left"><i className="fas fa-map-marker-alt mr-2 text-gray-400"></i><span className="font-medium m-2">Address:</span> No 17 Rockview Rd</p>
                                                        </div>

                                                        {/* Invoice Info <span className="font-medium m-2">Orser status:</span>*/}
                                                        <div>
                                                            <h3 className="text-lg p-[8px] text-left bg-gray-100 font-semibold mb-2 text-gray-700">
                                                                <i className="fas fa-file-invoice mr-2 text-gray-600"></i>
                                                                Invoice Info
                                                            </h3>
                                                            <p className='flex items-center text-left'>
                                                                <span className="font-medium m-2 flex items-center"><i className="fas fa-money-bill-wave mr-1 text-gray-400"></i>Payment status:</span>
                                                                <span className={`w-20 flex items-center rounded-[5px] text-center pl-2 m-2 p-[2px] ${purchased.paymentStatus === 'paid' ? 'bg-green-100 text-green-500' : 'bg-red-100 text-red-500'}`}>
                                                                    {purchased.paymentStatus}
                                                                </span>
                                                            </p>
                                                            <p className='flex items-center text-left'>
                                                                <span className=" flex items-center font-medium m-2"><i className="fas fa-check-circle mr-1 text-gray-400"></i>Order status:</span>
                                                                <span className=' flex items-center w-20 rounded-[5px] text-center pl-2 m-2 p-[2px] bg-green-100 text-green-500'>
                                                                    {purchased.orderStatus}
                                                                </span>
                                                            </p>
                                                            <p className='mt-2 text-left'>
                                                                <span className="font-medium m-2 mt-4"><i className="fas fa-warehouse mr-1 text-gray-400"></i>Warehouse:</span>
                                                                {purchased.warehouse}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {/* Product data */}
                                                    <div className="mt-10">
                                                        <h3 className="text-lg font-semibold mb-2 mt-10 p-[8px] text-left bg-gray-100 text-gray-700">Product Details</h3>
                                                        <table className=" mt-10 min-w-full bg-white border border-gray-300">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-gray-700 py-2 px-4 border-b text-left bg-gray-100 ">Product ID</th>
                                                                    <th className="text-gray-700 py-2 px-4 border-b text-left bg-gray-100 ">Product name</th>
                                                                    <th className="text-gray-700 py-2 px-4 border-b text-left bg-gray-100 ">Product price</th>
                                                                    <th className="text-gray-700 py-2 px-4 border-b text-left bg-gray-100 ">qty</th>
                                                                    <th className="text-gray-700 py-2 px-4 border-b text-left bg-gray-100 ">Sub total</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {purchased.productsData.map((product) => (
                                                                    <tr key={product._id} className="text-gray-700">
                                                                        <td className="py-2 text-left px-4 border-b">{product.currentID}</td>
                                                                        <td className="py-2 text-left px-4 border-b">{product.name}</td>
                                                                        <td className="py-2 text-left px-4 border-b">Rs {product.price}</td>
                                                                        <td className="py-2 text-left px-4 border-b">{product.quantity}</td>
                                                                        <td className="py-2 text-left px-4 border-b">Rs {product.subtotal}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    {/* Additional data */}
                                                    <div className="mt-10">
                                                        <table className=" mt-10 min-w-[400px] bg-white border border-gray-300">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="py-2 px-4 text-left border-b">Tax</td>
                                                                    <td className="py-2 px-4 text-left border-b">{purchased.tax} %</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="py-2  text-left px-4 border-b">Shipping</td>
                                                                    <td className="py-2 text-left px-4 border-b">Rs {purchased.shipping}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="py-2  text-left px-4 border-b">Discount</td>
                                                                    <td className="py-2 text-left px-4 border-b">Rs {purchased.discount}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="py-2 text-left px-4 border-b">Total</td>
                                                                    <td className="py-2  text-left px-4 border-b">Rs {purchased.grandTotal}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {/* Footer */}
                                                <div className="mt-8 flex justify-end">
                                                    {openViewSale === purchased._id && (
                                                        <button onClick={() => toPDF()} className="submit px-6 py-3 mr-2 text-white  rounded-md shadow-md -600 transition">
                                                            <i className="fas fa-file-pdf mr-2 text-white"></i>
                                                            Download PDF
                                                        </button>
                                                    )}
                                                    <button
                                                        onClick={() => setOpenViewSale(null)}
                                                        className="px-6 py-3 bg-gray-500 text-white  rounded-md shadow-md hover:bg-gray-600 transition">
                                                        Close
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                    <LinearProgress />
                </Box>
            )
            }
        </div >
    );
}
export default ViewPurchaseBody;
