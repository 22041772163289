import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { Fragment, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import avatar from '../../img/avatar.png';
import exit from '../../img/exit.png';
import padlock from '../../img/padlock.png';
import { GlobeAltIcon } from '@heroicons/react/24/outline';
import PosIcon from '../../img/payment-terminal.png';
import axios from 'axios';
import Full from '../../img/full-screen.png';
import { handleFullScreen } from '../pos/utils/fullScreenView';
import { encryptData, decryptData } from '../utill/encryptionUtils';


const navigation = [
  { name: 'Dashboard', href: '#', current: true },
  { name: 'Team', href: '#', current: false },
  { name: 'Projects', href: '#', current: false },
  { name: 'Calendar', href: '#', current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Header({ userData }) {
  const [formData, setFormData] = useState({ username: '', firstName: '', lastName: '', profileImage: '', mobile: '' });
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [cashInHand, setCashInHand] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const savedUser = sessionStorage.getItem('user');
    if (savedUser) {
      try {
        // Decrypt the saved user data
        const decryptedUser = decryptData(savedUser);
        console.log('Decrypted user data:', decryptedUser); // Debugging line
        console.log('Profile Image Base64:', formData.profileImage);


        // Parse the decrypted data and update the form
        const parsedUser = JSON.parse(decryptedUser);
        console.log('Parsed user data:', parsedUser); // Check the data structure
        setFormData(parsedUser);
      } catch (error) {
        console.error('Error decrypting or parsing saved user data:', error);
      }
    }
  }, []);

  const handleLogout = () => {
    sessionStorage.clear();
    navigate('/');
  };

  const handleCashInHandChange = (e) => {
    setCashInHand(e.target.value);
    setError('');
  };

  const handlePOSClick = () => {
    const cashRegisterID = sessionStorage.getItem('cashRegisterID');
    console.log('POS button clicked', cashRegisterID);
    if (!cashRegisterID) {
      setShowModal(true);
    } else {
      navigate('/posSystem');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Retrieve and decrypt user data
    const encryptedUser = sessionStorage.getItem('user');
    let decryptedUser = null;

    if (encryptedUser) {
      try {
        console.log('Encrypted data from sessionStorage:', encryptedUser);
        decryptedUser = decryptData(encryptedUser);
        console.log('Decrypted user data:', decryptedUser);
      } catch (error) {
        console.error('Failed to decrypt user data:', error);
        sessionStorage.removeItem('user'); // Clear invalid data
        alert('Session data corrupted. Please log in again.');
        return; // Stop further execution
      }
    }

    if (!decryptedUser) {
      console.error('User data could not be retrieved or decrypted');
      alert('Could not retrieve user data. Please log in again.');
      return;
    }

    // Validate input fields
    const cashAmount = parseFloat(cashInHand);
    if (isNaN(cashAmount) || cashAmount < 500) {
      setError('Cash in hand must be a valid number and greater than 500 rupees.');
      return;
    }

    const username = decryptedUser.username;
    const name = decryptedUser.firstName;
    const openTime = new Date().toLocaleString();

    // Prepare data for API request
    const newCash = {
      username,
      name,
      cashAmount,
      openTime,
    };

    try {
      console.log('Submitting cash data:', newCash);

      // API call
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/cashHandIn`,
        newCash
      );

      if (response.data && response.data.cash) {
        const cashID = response.data.cash._id;
        const cashierUsername = response.data.cash.username;

        // Save response data in sessionStorage
        sessionStorage.setItem('cashRegisterID', cashID);
        sessionStorage.setItem('cashierUsername', cashierUsername);

        setShowModal(false);
        navigate('/posSystem'); // Redirect to POS system
      } else {
        console.error('Unexpected response format:', response);
        alert('An unexpected error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Error during cash submission:', error);

      // Handle specific error scenarios
      if (error.response) {
        // Server responded with a status other than 2xx
        const { status, data } = error.response;
        if (status === 400) {
          setError(data.message || 'Invalid request. Please check your input.');
        } else if (status === 401) {
          alert('Session expired. Please log in again.');
          navigate('/login');
        } else {
          alert(`Server error: ${data.message || 'Please try again later.'}`);
        }
      } else if (error.request) {
        // No response received from server
        alert('Network error. Please check your connection and try again.');
      } else {
        // Other unexpected errors
        alert(`An error occurred: ${error.message}`);
      }
    }
  };


  const handleModalClose = () => {
    setShowModal(false);
    setCashInHand('');
  };

  const defaultAvatar = 'https://jingslearning.com/media/images/login-user-head.png';

  return (
    <div className='navSec overflow-y-scroll pr-scroll-compensate'>
      <Disclosure as="nav" className="bg-white h-20 fixed top-0 left-0 right-0 z-50">
        {({ open }) => (
          <>
            <div className="flex h-full items-center justify-between px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-full items-center justify-between w-[100vw]">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-black hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center">
                    <img
                      className="h-10 w-10"
                      src="https://infypos-demo.nyc3.digitaloceanspaces.com/settings/337/logo-80.png"
                      alt="Your Company"
                    />
                  </div>
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={'/dashboard'}
                          className={classNames(
                            item.current ? 'bg-gray-900 text-white' : 'text-black hover:bg-gray-200 hover:text-black',
                            'rounded-md px-3 py-2 text-sm font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

                  <div className="p-2 m-2 w-[40px] h-[40px] mr-5  border bg-[#37b34a] hover:bg-[#2a9d34] rounded-[14px] flex items-center justify-center">
                    <button className='' onClick={handleFullScreen}>
                      <img className="w-[25px] h-[23px]" src={Full} alt="" />
                    </button>
                  </div>

                  <button
                    className="submit flex items-center text-center rounded-full px-5 py-3 pt-[7px] h-10 mr-5 text-sm font-semibold text-white shadow-lg hover:shadow-xl focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-[96px] text-center"
                    onClick={handlePOSClick}
                  >
                    <img className="w-[26px] h-[26px] ml-[6px] mr-[2px]" src={PosIcon} alt="pos" />
                    POS
                  </button>

                  <button
                    type="button"
                    className="relative rounded-full bg-white p-1 text-black hover:text-gray-800 mr-2 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 focus:ring-offset-white"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="flex relative rounded-full mr-2 bg-white text-sm focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 focus:ring-offset-gray-100">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-11 w-11 rounded-full"
                          alt="Profile"
                          src={formData.profileImage || defaultAvatar}
                          onError={(e) => { e.target.src = defaultAvatar; }}
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-72 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="flex items-center justify-center h-20">
                          <img
                            className="h-11 w-11 rounded-full"
                            alt="Profile"
                            src={formData.profileImage || defaultAvatar}
                            onError={(e) => { e.target.src = defaultAvatar; }}
                          />
                        </div>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={`/profile`}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block w-full px-6 py-4 text-sm text-black flex items-center'
                              )}
                            >
                              <img src={avatar} className="h-5 w-5" alt="Profile Icon" aria-hidden="true" />
                              <span className="ml-3">Your Profile</span>
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={'/forgetpassword'}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block w-full px-6 py-4 text-sm text-black flex items-center'
                              )}
                            >
                              <img src={padlock} className="h-5 w-5" alt="Padlock Icon" aria-hidden="true" />
                              <span className="ml-3">Change Password</span>
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={'/'}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block w-full px-6 py-4 text-sm text-black flex items-center'
                              )}
                            >
                              <GlobeAltIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                              <span className="ml-3">Change Language</span>
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={handleLogout}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block w-full px-6 py-4 text-sm text-black flex items-center'
                              )}
                            >
                              <img src={exit} className="h-5 w-5" alt="Exit Icon" aria-hidden="true" />
                              <span className="ml-3">Sign Out</span>
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current ? 'bg-gray-900 text-white' : 'text-black hover:bg-gray-200 hover:text-black',
                      'block rounded-md px-3 py-2 text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      {/* Modal for entering Cash In Hand */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white w-[400px] h-[240px] p-6 rounded-md shadow-lg">
            <h2 className="text-lg font-semibold mb-4">Enter Cash In Hand</h2>
            <form onSubmit={handleSubmit}>
              <input
                type="number"
                className="border border-gray-300 p-2 rounded w-full mb-4"
                placeholder="Cash In Hand"
                value={cashInHand}
                onChange={handleCashInHandChange}
              />
              {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
              <div className="flex justify-end mt-10">
                <button
                  type='submit'
                  className="submit w-[100px] text-white px-4 py-2 rounded mr-2"
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="bg-gray-300 px-4 py-2 rounded"
                  onClick={handleModalClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
