import React,{useState,useEffect, useContext} from 'react';
import { FaMoneyBill, FaCartPlus, FaExchangeAlt, FaUsers, FaChartLine, FaWarehouse, FaDollarSign, FaFileInvoiceDollar } from 'react-icons/fa';
import '../../styles/dashboardBody.css';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { UserContext } from '../../context/UserContext';

const DashboardBody = ({}) => {
    const { userData } = useContext(UserContext);
    const [formData, setFormData] = useState({ username: '', firstName: '', lastName: '', profileImage: '', mobile: '' });
    useEffect(() => {
        if (userData) {
            console.log("user data: ", userData);
          try {
            // Directly set formData from userData without parsing
            setFormData({
              username: userData.username || '',
              firstName: userData.firstName || '',
              lastName: userData.lastName || '',
              profileImage: userData.profileImage || '',
              mobile: userData.mobile || ''
            });
          } catch (error) {
            console.error('Error setting form data:', error);
          }
        }
      }, [userData]);
    return (
        <div className='dashBody'>
            {formData ? (
                <div>
                    <div>
                        <div className="flex justify-between p-4 bg-gray-100">
                            <div className="flex-1 mx-2 h-40 flex flex-col items-center justify-center rounded-lg shadow-md" style={{ backgroundColor: '#6571ff' }}>
                                <FaUsers className="text-white" style={{ fontSize: '3vw' }} />
                                <h1 className="text-white font-bold text-center" style={{ fontSize: '1vw' }}>Welcome, {formData.username}</h1>
                            </div>
                            <div className="flex-1 mx-2 h-40 flex flex-col items-center justify-center rounded-lg shadow-md" style={{ backgroundColor: '#0ac074' }}>
                                <FaChartLine className="text-white" style={{ fontSize: '1vw' }} />
                                <p className="text-white font-bold text-center" style={{ fontSize: '2vw' }}>Job role: {formData.firstName}</p>
                            </div>
                            <div className="flex-1 mx-2 h-40 flex flex-col items-center justify-center rounded-lg shadow-md" style={{ backgroundColor: '#0099fb' }}>
                                <FaDollarSign className="text-white" style={{ fontSize: '2vw' }} />
                                <h1 className="text-white font-bold text-center" style={{ fontSize: '2vw' }}>3K $</h1>
                            </div>
                            <div className="flex-1 mx-2 h-40 flex flex-col items-center justify-center rounded-lg shadow-md" style={{ backgroundColor: '#ffb821' }}>
                                <FaFileInvoiceDollar className="text-white" style={{ fontSize: '2vw' }} />
                                <h1 className="text-white font-bold text-center" style={{ fontSize: '2vw' }}>100 .K $</h1>
                            </div>
                        </div>
                        <div className="flex justify-between p-4 bg-gray-100">
                            <div className="flex-1 mx-2 h-40 flex flex-col items-center justify-center rounded-lg shadow-md" style={{ backgroundColor: '#6f42c1' }}>
                                <FaWarehouse className="text-white" style={{ fontSize: '2vw' }} />
                                <h1 className="text-white font-bold text-center" style={{ fontSize: '2vw' }}>4M</h1>
                            </div>
                            <div className="flex-1 mx-2 h-40 flex flex-col items-center justify-center rounded-lg shadow-md" style={{ backgroundColor: '#e83e8c' }}>
                                <FaCartPlus className="text-white" style={{ fontSize: '2vw' }} />
                                <h1 className="text-white font-bold text-center" style={{ fontSize: '2vw' }}>0.00</h1>
                            </div>
                            <div className="flex-1 mx-2 h-40 flex flex-col items-center justify-center rounded-lg shadow-md" style={{ backgroundColor: '#00c6ff' }}>
                                <FaExchangeAlt className="text-white" style={{ fontSize: '2vw' }} />
                                <h1 className="text-white font-bold text-center" style={{ fontSize: '2vw' }}>1.6M $</h1>
                            </div>
                            <div className="flex-1 mx-2 h-40 flex flex-col items-center justify-center rounded-lg shadow-md" style={{ backgroundColor: '#ff0000' }}>
                                <FaMoneyBill className="text-white" style={{ fontSize: '2vw' }} />
                                <h1 className="text-white font-bold text-center" style={{ fontSize: '2vw' }}>323.00K $</h1>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (

                <Box sx={{ width: '100%' ,position:"absolute",top:"0",left:"0",margin:"0", padding:"0"}}>
                    <LinearProgress />
                </Box>
            )}
        </div>
    );
};

export default DashboardBody;
