// productFunctions.js
import axios from 'axios';
import { debounce } from 'lodash';

export const handleFindProductBySearch = (e, setProductKeyword, handleProductSubmit) => {
    const keyword = e.target.value;
    setProductKeyword(keyword);
    debouncedProductSubmit(keyword, handleProductSubmit);
};

// Debounce the product search submit function
const debouncedProductSubmit = debounce((keyword, handleProductSubmit) => {
    handleProductSubmit(keyword);
}, 300); // Adjust the delay as needed (300ms works well for debouncing)

export const determineSearchTypeOfProduct = (keyword) => {
    if (/^\d+$/.test(keyword)) {
        return 'code';
    } else if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(keyword)) {
        return 'name';
    }
    return 'name';
};

export const handleProductSubmit = async (Productkeyword, setLoading, setSearchedProductData) => {
    setLoading(true);
    try {
        const searchType = determineSearchTypeOfProduct(Productkeyword);
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/findOneProduct`, {
            params: { keyword: Productkeyword, searchType }
        });
        if (response.data && response.data.product) {
            setSearchedProductData([response.data.product]);
        } else {
            setSearchedProductData([]);
        }
    } catch (error) {
        console.error('Find product error:', error);
        setSearchedProductData([]);
    } finally {
        setLoading(false);
    }
};
