import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import '../../styles/role.css';
import { usePDF } from 'react-to-pdf';

function ViewTransferBody() {
    // State variables
    const [transferData, setTransferData] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [searchedTransferData, setSearchTransferData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openPopupId, setOpenPopupId] = useState(null);
    const popupRef = useRef(null);
    const [openViewSale, setOpenViewSale] = useState(null);
    const [filteredSaleData, setFilteredSaleData] = useState(transferData);
    const { toPDF, targetRef } = usePDF({ filename: `${transferData.customer || 'invoice'}.pdf` });
    //COMBINE ALL DATA FETCHING TYPE INTO ONE STATE
    const combinedProductData = Array.isArray(searchedTransferData) && searchedTransferData.length > 0
        ? searchedTransferData
        : Array.isArray(transferData) && transferData.length > 0
            ? transferData
            : [];

    // Fetch all customers
    useEffect(() => {
        const fetchSaleData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/fetchTransferDetails`);
                console.log('Fetched sale data:', response.data);
                setTransferData(response.data);
            } catch (error) {
                console.error('Fetch sale data error:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSaleData();
    }, []);

    // Handle delete customer
    const handleDelete = async (_id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/deleteTransfer/${_id}`);
            setTransferData(transferData.filter(sale => sale._id !== _id));
        } catch (error) {
            console.error('Delete sale error:', error);
        }
    };

    // Handle search input change
    const handleFindUser = async (e) => {
        const { value } = e.target;
        setKeyword(value); // Update the keyword state
        if (value.length > 0) {
            await handleSubmit(); // Call your search function
        } else {
            setSearchTransferData([]); // Clear results if input is empty
        }
    };

    // Handle search form submission
    const handleSubmit = async () => {
        if (!keyword) {
            console.error('Keyword is required to fetch transfer details.');
            return;
        }
    
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/fetchTransferDetails`, {
                params: { refferenceId: keyword }, // Pass `keyword` as `refferenceId`
            });
    
            console.log('API Response:', response.data); // Log the entire response for debugging
    
            // Check the structure of `response.data` and update state accordingly
            if (Array.isArray(response.data)) {
                setSearchTransferData(response.data);
            } else if (response.data?.sales) {
                setSearchTransferData(response.data.sales); // Update state based on response
            } else {
                console.warn('Unexpected response structure:', response.data);
                setSearchTransferData([]); // Fallback to an empty array
            }
        } catch (error) {
            console.error('Error fetching transfer details:', error.response ? error.response.data : error.message);
            setSearchTransferData([]); // Clear data on error
        } finally {
            setLoading(false);
        }
    };
    

    // Close popup when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setOpenPopupId(null);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [popupRef]);

    const handleSaleViewPopUp = async (saleId) => {
        setOpenPopupId(null);
        setOpenViewSale(openViewSale === saleId ? null : saleId);
        if (openViewSale !== saleId) {
            const sale = transferData.find((sale) => sale._id === saleId);
            const customerName = sale.customer;

            try {
                if (customerName !== "") {
                    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/searchCustomerByName?name=${customerName}`);
                    setFilteredSaleData(response.data.customer);
                    console.log(response.data)
                } else {
                    setFilteredSaleData(transferData);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <div className='relative bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] h-[100vh] p-5'>
            <div className='flex justify-between mb-4'>
                <div className="relative w-full max-w-md">
                    <form onChange={handleSubmit} className="flex items-center">
                        <input
                            onChange={handleFindUser}
                            name='keyword'
                            type="text"
                            placeholder="Search..."
                            className="searchBox w-80 pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                            value={keyword}
                        />
                        <button type="submit" className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-400">
                            <svg
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M9 3a6 6 0 100 12A6 6 0 009 3zm0-1a7 7 0 110 14A7 7 0 019 2z"
                                    clipRule="evenodd"
                                />
                                <path
                                    fillRule="evenodd"
                                    d="M12.9 12.9a1 1 0 011.41 0l3 3a1 1 0 01-1.41 1.41l-3-3a1 1 0 010-1.41z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </form>
                </div>
                <div className="flex items-center">
                    <div>
                        <Link
                            to={'/createTransfer'}
                            className="submit rounded-md px-5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-[200px] text-center"
                        >
                            Edit Transfer
                        </Link>
                    </div>
                </div>
            </div>

            {loading ? (
                <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                    <LinearProgress />
                </Box>
            ) : combinedProductData.length > 0 ? (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Refference Id</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Warehouse From</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Warehouse To</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Grand total</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order status</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {combinedProductData.map((transfer) => (
                                <tr key={transfer._id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900"><p className='rounded-[5px] text-center p-[6px] bg-red-100 text-red-500'>{transfer.refferenceId}</p></td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{transfer.warehouseFrom}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{transfer.warehouseTo}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">Rs {transfer.grandTotal}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900"><p className='rounded-[5px] text-center p-[6px] bg-green-100 text-green-500'>{transfer.orderStatus}</p></td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900">{new Date(transfer.date).toLocaleDateString()}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-right">
                                        <div className="text-right flex justify-end ">
                                            <button
                                                onClick={() => handleSaleViewPopUp(transfer._id)}
                                                className="text-green-500 hover:text-green-700 font-bold py-1 px-2 flex items-center "
                                            >
                                                <i className="fas fa-eye mr-1"></i>
                                            </button>

                                            <Link to={`/EditTransfer/${transfer._id}`}
                                                className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2 flex items-center"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-edit mr-1"></i>
                                            </Link>
                                            <button
                                                onClick={() => handleDelete(transfer._id)}
                                                className="text-red-500 hover:text-red-700 font-bold py-1 px-2 flex items-center"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-trash mr-1"></i>
                                            </button>
                                        </div>
                                    </td>

                                    {/* View Sale popup */}
                                    {openViewSale === transfer._id && (
                                        <div ref={popupRef} className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                                            <div className="bg-white w-[1300px] max-h-[90vh] overflow-auto p-8 pt-16 rounded-md shadow-lg mt-40 mb-10">
                                                <div ref={targetRef} className="w-[1250px] p-10 bg-white" style={{ margin: '0 auto', padding: '15px', boxSizing: 'border-box' }}>
                                                    {/* Header */}
                                                    <div className="mb-6 flex justify-between items-center border-b pb-4">
                                                        <h2 className="text-2xl font-bold text-gray-800">Sale Details for {transfer.customer}</h2>
                                                    </div>

                                                    {/* Sale Info Section */}
                                                    <div className="grid grid-cols-3 gap-8 text-gray-700">
                                                        {/* Company Info */}
                                                        <div className="border-r pr-8">
                                                            <h3 className="text-lg p-[8px] bg-gray-100 font-semibold mb-2 text-gray-900">
                                                                <i className="fas fa-building mr-2 text-gray-600"></i>
                                                                Company Info
                                                            </h3>
                                                            <p className="m-2"><i className="fas fa-building mr-2 text-gray-400"></i><span className="font-medium">Company:</span> Ideazone</p>
                                                            <p className="m-2"><i className="fas fa-envelope mr-2 text-gray-400"></i><span className="font-medium">Email:</span> ideazone@info.gmail.com</p>
                                                            <p className="m-2"><i className="fas fa-phone mr-2 text-gray-400"></i><span className="font-medium">Phone:</span> +94 2828 395</p>
                                                            <p className="m-2"><i className="fas fa-map-marker-alt mr-2 text-gray-400"></i><span className="font-medium m-2">Address:</span> No 17 Rockview Rd</p>
                                                        </div>

                                                        {/* Invoice Info <span className="font-medium m-2">Orser status:</span>*/}
                                                        <div>
                                                            <h3 className="text-lg p-[8px] bg-gray-100 font-semibold mb-2 text-gray-900">
                                                                <i className="fas fa-file-invoice mr-2 text-gray-600"></i>
                                                                Invoice Info
                                                            </h3>
                                                            <p className='mt-2'>
                                                                <span className="font-medium m-2 mt-4"><i className="fas fa-warehouse mr-1 text-gray-400"></i>Warehouse From:</span>
                                                                {transfer.warehouseFrom}
                                                            </p>
                                                            <p className='mt-2'>
                                                                <span className="font-medium m-2 mt-4"><i className="fas fa-warehouse mr-1 text-gray-400"></i>Warehouse To:</span>
                                                                {transfer.warehouseTo}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {/* Product data */}
                                                    <div className="mt-10">
                                                        <h3 className="text-lg font-semibold mb-2 mt-10 p-[8px] bg-gray-100 text-gray-900">Product Details</h3>
                                                        <table className=" mt-10 min-w-full bg-white border border-gray-300">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-gray-900 py-2 px-4 border-b text-left bg-gray-100 ">Product ID</th>
                                                                    <th className="text-gray-900 py-2 px-4 border-b text-left bg-gray-100 ">Product name</th>
                                                                    <th className="text-gray-900 py-2 px-4 border-b text-left bg-gray-100 ">Product price</th>
                                                                    <th className="text-gray-900 py-2 px-4 border-b text-left bg-gray-100 ">qty</th>
                                                                    <th className="text-gray-900 py-2 px-4 border-b text-left bg-gray-100 ">Sub total</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {transfer.productsData.map((product) => (
                                                                    <tr key={product._id} className="text-gray-700">
                                                                        <td className="py-2 px-4 border-b">{product.currentID}</td>
                                                                        <td className="py-2 px-4 border-b">{product.name}</td>
                                                                        <td className="py-2 px-4 border-b">Rs {product.price}</td>
                                                                        <td className="py-2 px-4 border-b">{product.quantity}</td>
                                                                        <td className="py-2 px-4 border-b">Rs {product.subtotal}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    {/* Additional data */}
                                                    <div className="mt-10">
                                                        <table className=" mt-10 min-w-[400px] bg-white border border-gray-300">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="py-2 px-4 border-b">Total</td>
                                                                    <td className="py-2 px-4 border-b">Rs {transfer.grandTotal}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {/* Footer */}
                                                <div className="mt-8 flex justify-end">
                                                    {openViewSale === transfer._id && (
                                                        <button onClick={() => toPDF()} className="submit px-6 py-3 mr-2 text-white font-semibold rounded-md shadow-md -600 transition">
                                                            <i className="fas fa-file-pdf mr-2 text-white"></i>
                                                            Download PDF
                                                        </button>
                                                    )}
                                                    <button
                                                        onClick={() => setOpenViewSale(null)}
                                                        className="px-6 py-3 bg-gray-500 text-white font-semibold rounded-md shadow-md hover:bg-gray-600 transition">
                                                        Close
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                    <LinearProgress />
                </Box>
            )
            }
        </div >
    );
}

export default ViewTransferBody;
