import React, { useState } from 'react';
import axios from 'axios';
import '../../../styles/role.css';
import { Link } from 'react-router-dom';
import CamIcon from '../../../img/icons8-camera-100.png';

function CreateProductCategoryBody() {
    // State management
    const [category, setCatergory] = useState('');
    const [logo, setLogo] = useState(null);
    const [logoPreview, setLogoPreview] = useState(null);
    const [error, setError] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    const handleLogoChange = (event) => {
        const file = event.target.files[0]; // Store the file in state
        setLogo(file);
        setError(''); // Clear any previous error

        // Generate a preview URL
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogoPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        setError('');
        setResponseMessage('');

        const formData = new FormData();
        formData.append('category', category);
        formData.append('logo', logo);

        // Axios request to add category
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/createCategory`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(result => {
                setResponseMessage("Category added successfully!");
            })
            .catch(error => {
                setResponseMessage("Category not added: " + error.message);
            });
    };


    // Handle clear operation
    const handleClear = () => {
        setCatergory('');
        setLogo(null);
        setLogoPreview(null);
        setError('');
        setResponseMessage('');
    };

    return (
        <div className='bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] h-[800px] p-5'>
            <div className='flex justify-between items-center'>
                <div>
                    <h2 className="text-lightgray-300 m-0 p-0 text-2xl">Create Category</h2>
                </div>
                <div>
                    <Link className='px-4 py-1.5 border border-[#37b34a] text-[#37b34a] rounded-md transition-colors duration-300 hover:bg-[#37b34a] hover:text-white' to={'/dashboard'}>Back</Link>
                </div>
            </div>
            <div className="bg-white mt-[20px] w-[630px] h-[600px] rounded-2xl px-8 shadow-md">
                <div className="flex min-h-full flex-1 flex-col px-2 py-12 lg:px-8">
                    <form onSubmit={handleSubmit}>
                        <div className="flex space-x-16">
                            <div className="flex-1">
                                {/* Baseunit name field */}
                                <div className="mt-5">
                                    <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Category</label>
                                    <div className="mt-2">
                                        <input
                                            id="name"
                                            name="name"
                                            type="text"
                                            required
                                            placeholder='Enter the name'
                                            value={category}
                                            onChange={(e) => setCatergory(e.target.value)}
                                            autoComplete="given-name"
                                            className="block w-[500px] rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                {/* Logo upload field with pencil icon */}
                                <div className="mt-5 relative">
                                    <label className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                        Add logo
                                    </label>
                                    <div className="mt-2 relative">
                                        <input
                                            id="file"
                                            name="file"
                                            type="file"
                                            accept="image/*"
                                            onChange={handleLogoChange}
                                            className="hidden"
                                        />
                                        <button
                                            type="button"
                                            onClick={() => document.getElementById('file').click()}
                                            className={`block w-[100px] h-[100px] rounded-md border-0 py-2.5 px-2.5 shadow-sm ring-1 ring-inset ring-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6 ${logoPreview ? 'bg-cover bg-center' : 'bg-gray-200 opacity-70 hover:bg-gray-300'}`}
                                            style={{ backgroundImage: logoPreview ? `url(${logoPreview})` : 'none' }}
                                        >
                                            {!logoPreview && <img src={CamIcon} alt='cam' className="ml-5 w-10 h-10" />}
                                        </button>

                                        {error && <p className="text-red-500 mt-2">{error}</p>}
                                    </div>
                                </div>

                                <div className="container mx-auto text-left">
                                    <div className='mt-10 flex justify-start'>
                                        <button type='submit' className="saveBtn flex-none rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 w-[100px] text-center focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                                            Save
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-flex ml-2 justify-center rounded-md bg-gray-600 py-2.5 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 w-[100px]  focus:ring-gray-500 focus:ring-offset-2"
                                            onClick={handleClear}
                                        >
                                            Clear
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    {/* Error and Response Messages */}
                    <div className='mt-5'>
                        {error && (
                            <p className="text-red-600 px-5 py-2 rounded-md bg-red-100 mt-5 text-center mx-auto max-w-sm">
                                {error}
                            </p>
                        )}
                        {responseMessage && (
                            <p className="text-green-600 px-5 py-2 rounded-md bg-green-100 mt-5 text-center  mx-auto max-w-sminline-block">
                                {responseMessage}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateProductCategoryBody;
