import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/role.css';
import { Link } from 'react-router-dom';

function ReceiptSettingsBody() {
    // State management
    const [isOn, setIsOn] = useState({
        note: false,
        phone: false,
        customer: false,
        address: false,
        email: false,
        taxDiscountShipping: false,
        barcode: false,
        productCode: false,
        logo: false,
    });
    const [response, setResponse] = useState('');
    const [error, setError] = useState('');

    const handleToggle = (setting) => {
        setIsOn((prev) => ({ ...prev, [setting]: !prev[setting] }));
    };


    // Load currencies when the component mounts
    useEffect(() => {
        fetchSettings();
    }, []);

    const fetchSettings = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/getReceiptSettings`);
            console.log(data);

            // Assuming `data` contains the settings directly in the format matching `isOn` state
            setIsOn({
                note: data.note || false,
                phone: data.phone || false,
                customer: data.customer || false,
                address: data.address || false,
                email: data.email || false,
                taxDiscountShipping: data.taxDiscountShipping || false,
                barcode: data.barcode || false,
                productCode: data.productCode || false,
                logo: data.logo || false,
            });

        } catch (error) {
            console.error('Error fetching settings:', error);
            setError('Error fetching settings');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            note: isOn.note,
            phone: isOn.phone,
            customer: isOn.customer,
            address: isOn.address,
            email: isOn.email,
            taxDiscountShipping: isOn.taxDiscountShipping,
            barcode: isOn.barcode,
            productCode: isOn.productCode,
            logo: isOn.logo,
        };
        console.log(formData)
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/createOrUpdateReceiptSettings`, formData);
            setResponse(response.data.message);
        } catch (error) {
            console.error('Error saving data:', error);
            setError('There was an error saving the data.');
        }
    };

    return (
        <div className='bg-[#eff3f7] relative left-[18%] w-[82%] min-h-[100vh] p-5'>
            <div className='flex justify-between'>
                <div>
                    <h2 className="text-lightgray-300 mt-[80px] p-0 text-2xl">Receipt Settings</h2>
                </div>
                <div>
                    <Link className='px-4 py-1.5 border border-[#37b34a] text-[#37b34a] rounded-md transition-colors duration-300 hover:bg-[#37b34a] hover:text-white' to={'/viewCustomers'}>Back</Link>
                </div>
            </div>
            <div className="bg-white mt-[20px] pb-2 w-full rounded-2xl px-8 shadow-md">
                <div className="flex flex-1 flex-col px-2 py-12 lg:px-8">
                    <form onSubmit={handleSubmit}>
                        <div>
                            <div className="flex w-full space-x-5">
                                <div className="flex-1 flex items-center space-x-2">
                                    <button
                                        onClick={() => handleToggle('note')}
                                        className={`w-12 h-6 flex items-center rounded-full p-1 transition duration-300 ${isOn.note ? 'bg-green-500' : 'bg-gray-400'
                                            }`}
                                    >
                                        <div
                                            className={`bg-white w-4 h-4 rounded-full shadow-md transform transition duration-300 ${isOn.note ? 'translate-x-6' : 'translate-x-0'
                                                }`}
                                        ></div>
                                    </button>
                                    <label className="text-sm font-medium leading-6 text-gray-900">Show note</label>
                                </div>

                                <div className="flex-1 flex items-center space-x-2">
                                    <button
                                        onClick={() => handleToggle('phone')}
                                        className={`w-12 h-6 flex items-center rounded-full p-1 transition duration-300 ${isOn.phone ? 'bg-green-500' : 'bg-gray-400'
                                            }`}
                                    >
                                        <div
                                            className={`bg-white w-4 h-4 rounded-full shadow-md transform transition duration-300 ${isOn.phone ? 'translate-x-6' : 'translate-x-0'
                                                }`}
                                        ></div>
                                    </button>
                                    <label className="text-sm font-medium leading-6 text-gray-900">Show Phone</label>
                                </div>

                                <div className="flex-1 flex items-center space-x-2">
                                    <button
                                        onClick={() => handleToggle('customer')}
                                        className={`w-12 h-6 flex items-center rounded-full p-1 transition duration-300 ${isOn.customer ? 'bg-green-500' : 'bg-gray-400'
                                            }`}
                                    >
                                        <div
                                            className={`bg-white w-4 h-4 rounded-full shadow-md transform transition duration-300 ${isOn.customer ? 'translate-x-6' : 'translate-x-0'
                                                }`}
                                        ></div>
                                    </button>
                                    <label className="text-sm font-medium leading-6 text-gray-900">Show Customer</label>
                                </div>
                            </div>

                            <div className="flex w-full space-x-5 mt-10">
                                <div className="flex-1 flex items-center space-x-2">
                                    <button
                                        onClick={() => handleToggle('address')}
                                        className={`w-12 h-6 flex items-center rounded-full p-1 transition duration-300 ${isOn.address ? 'bg-green-500' : 'bg-gray-400'
                                            }`}
                                    >
                                        <div
                                            className={`bg-white w-4 h-4 rounded-full shadow-md transform transition duration-300 ${isOn.address ? 'translate-x-6' : 'translate-x-0'
                                                }`}
                                        ></div>
                                    </button>
                                    <label className="text-sm font-medium leading-6 text-gray-900">Show Address</label>
                                </div>
                                <div className="flex-1 flex items-center space-x-2">
                                    <button
                                        onClick={() => handleToggle('email')}
                                        className={`w-12 h-6 flex items-center rounded-full p-1 transition duration-300 ${isOn.email ? 'bg-green-500' : 'bg-gray-400'
                                            }`}
                                    >
                                        <div
                                            className={`bg-white w-4 h-4 rounded-full shadow-md transform transition duration-300 ${isOn.email ? 'translate-x-6' : 'translate-x-0'
                                                }`}
                                        ></div>
                                    </button>
                                    <label className="text-sm font-medium leading-6 text-gray-900">Show Email</label>
                                </div>
                                <div className="flex-1 flex items-center space-x-2">
                                    <button
                                        onClick={() => handleToggle('taxDiscountShipping')}
                                        className={`w-12 h-6 flex items-center rounded-full p-1 transition duration-300 ${isOn.taxDiscountShipping ? 'bg-green-500' : 'bg-gray-400'
                                            }`}
                                    >
                                        <div
                                            className={`bg-white w-4 h-4 rounded-full shadow-md transform transition duration-300 ${isOn.taxDiscountShipping ? 'translate-x-6' : 'translate-x-0'
                                                }`}
                                        ></div>
                                    </button>
                                    <label className="text-sm font-medium leading-6 text-gray-900">Show tax Discount & Shipping</label>
                                </div>
                            </div>

                            <div className="flex w-full space-x-5 mt-10">
                                <div className="flex-1 flex items-center space-x-2">
                                    <button
                                        onClick={() => handleToggle('barcode')}
                                        className={`w-12 h-6 flex items-center rounded-full p-1 transition duration-300 ${isOn.barcode ? 'bg-green-500' : 'bg-gray-400'
                                            }`}
                                    >
                                        <div
                                            className={`bg-white w-4 h-4 rounded-full shadow-md transform transition duration-300 ${isOn.barcode ? 'translate-x-6' : 'translate-x-0'
                                                }`}
                                        ></div>
                                    </button>
                                    <label className="text-sm font-medium leading-6 text-gray-900">Show barcode in receipt</label>
                                </div>
                                <div className="flex-1 flex items-center space-x-2">
                                    <button
                                        onClick={() => handleToggle('logo')}
                                        className={`w-12 h-6 flex items-center rounded-full p-1 transition duration-300 ${isOn.logo ? 'bg-green-500' : 'bg-gray-400'
                                            }`}
                                    >
                                        <div
                                            className={`bg-white w-4 h-4 rounded-full shadow-md transform transition duration-300 ${isOn.logo ? 'translate-x-6' : 'translate-x-0'
                                                }`}
                                        ></div>
                                    </button>
                                    <label className="text-sm font-medium leading-6 text-gray-900">Show Logo</label>
                                </div>
                                <div className="flex-1 flex items-center space-x-2">
                                    <button
                                        onClick={() => handleToggle('productCode')}
                                        className={`w-12 h-6 flex items-center rounded-full p-1 transition duration-300 ${isOn.productCode ? 'bg-green-500' : 'bg-gray-400'
                                            }`}
                                    >
                                        <div
                                            className={`bg-white w-4 h-4 rounded-full shadow-md transform transition duration-300 ${isOn.productCode ? 'translate-x-6' : 'translate-x-0'
                                                }`}
                                        ></div>
                                    </button>
                                    <label className="text-sm font-medium leading-6 text-gray-900">Show Product Code</label>
                                </div>
                            </div>

                        </div>
                        <div className="container mx-auto text-left">
                            <div className='mt-5 flex justify-start'>
                                <button className='submit text-white rounded-md mt-10' type='submit'>
                                    Save Settings
                                </button>
                            </div>
                        </div>
                    </form>

                    {/* Error and Response Messages */}
                    <div className='mt-5'>
                        {error && (
                            <p className="text-red-600 px-5 py-2 rounded-md bg-red-100 mt-5 text-center mx-auto max-w-sm">
                                {error}
                            </p>
                        )}
                        {response && (
                            <p className="text-green-600 px-5 py-2 rounded-md bg-green-100 mt-5 text-center  mx-auto max-w-sminline-block">
                                {response}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReceiptSettingsBody;
