import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import '../../styles/login.css';;

function ViewExpensesBody() {
    // State variables
    const [expensesData, setExpensesData] = useState([]);
    const [searchedExpensesData, setSearchedExpensesData] = useState(null);
    const [keyword, setKeyword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');



    // Combine all data fetching types into one state
    const combinedProductData = Array.isArray(searchedExpensesData) && searchedExpensesData.length > 0
        ? searchedExpensesData
        : Array.isArray(expensesData) && expensesData.length > 0
            ? expensesData
            : [];

    // Fetch all expenses
    useEffect(() => {
        const fetchExpensesData = async () => {
            setLoading(true);
            setError('');
            setSuccessMessage('');

            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/getExpenses`);
                console.log('Fetched expenses data:', response.data);
                setExpensesData(response.data.data);
            } catch (error) {
                console.error('Fetch expenses data error:', error);
                setError(error)
            } finally {
                setLoading(false);
            }
        };

        fetchExpensesData();
    }, []);

    // Handle delete expense
    const handleDelete = async (_id) => {
        setLoading(true);
        setError('');
        setSuccessMessage('');
    
        try {
           const response =  await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/deleteExpenses/${_id}`);
            if (response.data && response.data.success) {
                setExpensesData(expensesData.filter(exp => exp._id !== _id));
                setSuccessMessage('Expense deleted successfully.');
                console.log('Expense deleted:', response.data);
            } else {
                setError('Failed to delete expense. Please try again.');
            }
        } catch (err) {
            console.error('Delete expense error:', err);
            setError(err.response?.data?.message || 'Failed to delete expense. Please try again.');
        } finally {
            setLoading(false);
        }
    };
   
    // Handle keyword input change
    const handleFindUser = (e) => {
        setKeyword(e.target.value);
    };

    // Handle search for expenses by keyword
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError('');
        setSuccessMessage('');

        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/getExpenses`, {
                params: { category: keyword }
            });

            if (response.data && Array.isArray(response.data)) {
                setSearchedExpensesData(response.data);
                setSuccessMessage('Expenses search completed successfully.');
                console.log('Searched expenses data:', response.data);
            } else if (response.data && response.data.data) {
                setSearchedExpensesData(response.data.data);
                setSuccessMessage('Expenses search completed successfully.');
                console.log('Searched expenses data:', response.data.data);
            } else {
                setSearchedExpensesData([]);
                setError('No expenses found for the given category.');
            }
        } catch (err) {
            console.error('Find expenses error:', err);
            setError(err.response?.data?.message || 'Failed to search expenses. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='relative bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] h-[100vh] p-5'>
            <div className='flex justify-between mb-4'>
                <div className="relative w-full max-w-md">
                    <form onChange={handleSubmit} className="flex items-center">
                        <input
                            onChange={handleFindUser}
                            name='keyword'
                            type="text"
                            placeholder="Search..."
                            className="searchBox w-80 pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                            value={keyword}
                        />
                        <button type="submit" className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-400">
                            <svg
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M9 3a6 6 0 100 12A6 6 0 009 3zm0-1a7 7 0 110 14A7 7 0 019 2z"
                                    clipRule="evenodd"
                                />
                                <path
                                    fillRule="evenodd"
                                    d="M12.9 12.9a1 1 0 011.41 0l3 3a1 1 0 01-1.41 1.41l-3-3a1 1 0 010-1.41z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </form>
                </div>
                <div className="flex items-center">
                    <Link
                        to={'/createExpenses'}
                        className="submit flex-none rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-40 text-center"
                    >
                        Create Expenses
                    </Link>
                </div>
            </div>

            {loading ? (
                <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                    <LinearProgress />
                </Box>
            ) : combinedProductData.length > 0 ? (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead className="bg-gray-50">
                            <tr> 
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Refference</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Warehouse</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Catergory</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created on</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Details</th>
                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {combinedProductData.map((exp) => (
                                <tr key={exp._id}>
                                    <td className="px-6 py-4 text-left  whitespace-nowrap text-m text-gray-900"><p className='rounded-[5px] text-center p-[6px] bg-red-100 text-red-500'>{exp.refferenceId}</p></td>
                                    <td className="px-6 py-4 text-left  whitespace-nowrap text-m text-gray-900">{exp.title}</td>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">{exp.warehouse}</td>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">{exp.category}</td>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">Rs {exp.amount}</td>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">{new Date(exp.date).toLocaleDateString()}</td>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">{exp.details}</td>
                                    <td className="px-6 py-4 text-right whitespace-nowrap text-m text-gray-900">
                                        <div className='flex items-center justify-end'>
                                            <Link to={`/EditExpenses/${exp._id}`}
                                                className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2 flex items-center"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-edit mr-1"></i>
                                            </Link>
                                            <button
                                                onClick={(e) => handleDelete(exp._id)}
                                                className="text-red-500 hover:text-red-700 font-bold py-1 px-2 flex items-center"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-trash mr-1"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                    <LinearProgress />
                </Box>
            )}
            <div className='mt-5'>
                {/* Error and Response Messages */}
                {error && <p className="text-red-500 text-center">{error}</p>}
                {successMessage && <p className="text-green-500 text-center">{successMessage}</p>}
            </div>
        </div>
    );
}

export default ViewExpensesBody;
