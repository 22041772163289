import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

function ViewVariationBody() {
    const [variationData, setVariationData] = useState([]);
    const [variationName, setVariationName] = useState('');
    const [searchedVariation, setSearchedVariation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    useEffect(() => {
        const fetchVariationData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/findAllVariations`);
                if (response.data && response.data.variations && Array.isArray(response.data.variations)) {
                    setVariationData(response.data.variations);
                } else {
                    setVariationData([]);
                }
            } catch (error) {
                console.error('Error fetching variations:', error);
                setVariationData([]);
                setError('Cannot load variations. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchVariationData();
    }, []);

    const handleDelete = async (_id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/deleteVariation/${_id}`);
            setVariationData(variationData.filter(variation => variation._id !== _id));
            setResponseMessage('Variation deleted successfully.');
        } catch (error) {
            console.error('Delete variation error:', error);
            setError('Cannot delete variation.');
        }
    };

    const handleDeleteByFind = async (_id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/deleteVariation/${_id}`);
            setSearchedVariation(null);
            setVariationData(variationData.filter(variation => variation._id !== _id));
            setResponseMessage('Variation deleted successfully.');
        } catch (error) {
            console.error('Delete variation error:', error);
            setError('Cannot delete variation.');
        }
    };

    const handleFindVariation = (event) => {
        setVariationName(event.target.value);
    };

    const performSearch = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (variationName) {
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/findVariation`, { variationName });
                setSearchedVariation(response.data.variation || null);
            }
        } catch (error) {
            console.error('Find variation error:', error);
            setError('Cannot find the variation.');
            setSearchedVariation(null);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='relative bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] h-[100vh] p-5'>
            <div className='flex justify-between mb-4'>
                <div className="relative w-full max-w-md">
                    <form onSubmit={performSearch} className="flex items-center">
                        <input
                            onChange={handleFindVariation}
                            name='variationName'
                            type="text"
                            placeholder="Search Variation"
                            className="searchBox w-80 pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                            value={variationName}
                        />
                        <button type="submit" className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-400">
                            <svg
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M9 3a6 6 0 100 12A6 6 0 009 3zm0-1a7 7 0 110 14A7 7 0 019 2z"
                                    clipRule="evenodd"
                                />
                                <path
                                    fillRule="evenodd"
                                    d="M12.9 12.9a1 1 0 011.41 0l3 3a1 1 0 01-1.41 1.41l-3-3a1 1 0 010-1.41z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </form>
                </div>
                <div className="flex items-center">
                    <Link
                        to={'/createVariation'}
                        className="submit flex-none rounded-md px-4 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-60 text-center"
                    >
                        Create Variation
                    </Link>
                </div>
            </div>

            {loading ? (
                <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                    <LinearProgress />
                </Box>
            ) : searchedVariation ? (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Variation name</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Variation type</th>
                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            <tr key={searchedVariation._id}>
                                <td className="px-6 text-left py-4 whitespace-nowrap text-m text-gray-900">{searchedVariation.variationName}</td>
                                <td className="px-6 text-left py-4 whitespace-nowrap text-m text-gray-900">{searchedVariation.variationType} </td>
                                <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-right">
                                    <div className='flex items-center justify-end'>
                                        <Link to={`/editVariation/${searchedVariation._id}`}
                                            className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2 flex items-center"
                                            style={{ background: 'transparent' }}
                                        >
                                            <i className="fas fa-edit mr-1"></i>
                                        </Link>
                                        <button
                                            onClick={() => handleDeleteByFind(searchedVariation._id)}
                                            className="text-red-500 hover:text-red-700 font-bold py-1 px-2"
                                            style={{ background: 'transparent' }}
                                        >
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Variation name</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Variation type</th>
                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {variationData.map((variation) => (
                                <tr key={variation._id}>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">{variation.variationName}</td>
                                    <td className="px-6 py-4 text-left whitespace-nowrap text-m text-gray-900">{variation.variationType.join(' '+' , '+'')}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-right">
                                        <div className='flex items-center justify-end'>
                                            <Link to={`/editVariation/${variation._id}`}
                                                className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2 flex items-center"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-edit mr-1"></i>
                                            </Link>
                                            <button
                                                onClick={() => handleDelete(variation._id)}
                                                className="text-red-500 hover:text-red-700 font-bold py-1 px-2"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-trash"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
             {/* Error and Response Messages */}
             <div className="absolute bottom-28 right-0 left-0">
                    {error && (
                        <p className="text-red-600 px-5 py-2 rounded-md bg-red-100 mt-5 text-center inline-block">
                            {error}
                        </p>
                    )}
                    {responseMessage && (
                        <p className="text-green-600 px-5 py-2 rounded-md bg-green-100 mt-5 text-center inline-block">
                            {responseMessage}
                        </p>
                    )}
                </div>
        </div>
    );
}

export default ViewVariationBody;
