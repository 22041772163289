import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/role.css';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

function CreateProductBody() {
    // State management for submit
    const [name, setProductName] = useState('');
    const [code, setCode] = useState('');
    const [category, setCategory] = useState('');
    const [brand, setBrands] = useState('');
    const [warehouse, setWarehouse] = useState('');
    const [barcode, setBarcode] = useState('');
    const [unit, setBaseUnit] = useState('');
    const [suplier, setSuplier] = useState('');
    const [saleUnit, setSaleUnit] = useState('');
    const [purchase, setPurchaseUnit] = useState('');
    const [status, setStatus] = useState('');
    const [quantityLimit, setQL] = useState('');
    const [variation, setVariation] = useState('');
    const [variationType, setVariationTypes] = useState([]);
    const [selectedVariationTypes, setSelectedVariationTypes] = useState([]);
    const [variationValues, setVariationValues] = useState({});
    const [showSections, setShowSections] = useState({});
    const [ptype, setType] = useState('');
    const [note, setNote] = useState('');
    const [image, setImage] = useState([])
    const [stockAlert, setStockAlert] = useState('');
    const [oderTax, setOderTax] = useState('');
    const [productCost, setProductCost] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [taxType, setTaxType] = useState('');
    const [productQty, setProductQty] = useState('');
    const [error, setError] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    //satae managment for fetching
    const [suplierData, setSuplierData] = useState([]);
    const [warehouseData, setWherehouseData] = useState([]);
    const [brandData, setBrandData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [unitData, setUnitData] = useState([]);
    const [variationData, setVariationData] = useState([]);
    const [loading, setLoading] = useState(true);

    //Fetching properties for create products ==========================================================================================================
    const fetchData = async (url, setData, transformData) => {
        try {
            const response = await axios.get(url);
            const data = transformData ? transformData(response.data) : response.data;
            setData(data);
        } catch (error) {
            console.error(`Fetch data error from ${url}:`, error);
            setData([]);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        setLoading(true);
        fetchData(`${process.env.REACT_APP_BASE_URL}/api/fetchSupplier`, setSuplierData);
        fetchData(`${process.env.REACT_APP_BASE_URL}/api/fetchWarehouses`, setWherehouseData);
        fetchData(`${process.env.REACT_APP_BASE_URL}/api/findCategory`, setCategoryData);
        fetchData(`${process.env.REACT_APP_BASE_URL}/api/findBrand`, setBrandData);
        fetchData(`${process.env.REACT_APP_BASE_URL}/api/findAllUnit`, setUnitData, (data) => data.units || []);
        fetchData(`${process.env.REACT_APP_BASE_URL}/api/findAllVariations`, setVariationData, (data) => data.variations || []);
    }, []);
    //================================================================================================================================================

    //Handle image files input
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
        setError('');

    };

    // Handle sale unit from base units
    const handleBaseUnitChange = (e) => {
        const selectedBaseUnit = e.target.value;
        setBaseUnit(selectedBaseUnit);
        const correspondingUnit = unitData.find((unit) => unit.baseUnit === selectedBaseUnit);
        if (correspondingUnit) {
            setSaleUnit(correspondingUnit.unitName);
        } else {
            setSaleUnit('');
        }
    };

    // Handle variation change
    const handleVariationChange = (e) => {
        const selectedVariation = e.target.value;
        setVariation(selectedVariation);
        setVariationValues({});

        const selectedData = variationData.find(varn => varn.variationName === selectedVariation);

        if (selectedData && Array.isArray(selectedData.variationType)) {
            setVariationTypes(selectedData.variationType); // Update available types
        } else {
            setVariationTypes([]);
        }
    };

    // Handle input change for each variation type
    const handleInputChange = (type, value) => {
        setVariationValues(prev => ({ ...prev, [type]: value }));
    };

    // Handle adding a selected variation type
    const handleAddVariationType = (e) => {
        const selectedOption = e.target.value;

        // Only add if it's not already in the list
        if (selectedOption && !selectedVariationTypes.includes(selectedOption)) {
            setSelectedVariationTypes([...selectedVariationTypes, selectedOption]);
            setShowSections({
                ...showSections,
                [selectedOption]: true,
            });
        }

        // Clear select value after selection
        e.target.value = '';
    };

    // Handle removing a selected variation type
    const handleRemoveVariationType = (typeToRemove) => {
        setSelectedVariationTypes(selectedVariationTypes.filter(type => type !== typeToRemove));
        setShowSections({
            ...showSections,
            [typeToRemove]: false,
        });
    };

    //Handle variaion input changing 
    const handleVariationValueChange = (type, field, value) => {
        setVariationValues(prevValues => ({
            ...prevValues,
            [type]: {
                ...prevValues[type],
                [field]: value
            }
        }));
    };

    //Save button enabile and disable checking 
    const isFormValid = name && code && warehouse && brand && category && suplier && ptype && unit && saleUnit && purchase && status && barcode && quantityLimit;

    //Handle submit for save product
    const handleSubmit = async (event) => {
        event.preventDefault();

        // Create FormData object
        const formData = new FormData();
        formData.append('name', name);
        formData.append('image', image);
        formData.append('code', code);
        formData.append('brand', brand);
        formData.append('unit', unit);
        formData.append('ptype', ptype);
        formData.append('category', category);
        formData.append('warehouse', warehouse);
        formData.append('saleUnit', saleUnit);
        formData.append('suplier', suplier);
        formData.append('status', status);
        formData.append('note', note);
        formData.append('purchase', purchase);
        formData.append('quantityLimit', quantityLimit);
        formData.append('barcode', barcode);
        formData.append('variation', variation);
        formData.append('stockAlert', stockAlert);
        formData.append('oderTax', oderTax);
        formData.append('taxType', taxType);
        formData.append('productCost', productCost);
        formData.append('productPrice', productPrice);
        formData.append('productQty', productQty);

        // Convert variationType and variationValues to JSON strings
        formData.append('variationType', JSON.stringify(variationType));
        formData.append('variationValues', JSON.stringify(variationValues));


        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/createProduct`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setResponseMessage(response.data.status);
        } catch (error) {
            if (error.response) {
                const { message } = error.response.data;
                if (message) {
                    setError(message); // Display specific backend error message
                } else {
                    setError("An error occurred while creating the product.");
                }
            } else {
                setError("An error occurred while creating the product. Please try again.");
            }
            console.error("Product creation failed:", error);
        }
        console.log("Product Data to Submit:");

        console.log({
            name,
            image,
            code,
            brand,
            unit,
            ptype,
            category,
            warehouse,
            saleUnit,
            suplier,
            status,
            note,
            purchase,
            quantityLimit,
            barcode,
            variation,
            variationType,
            variationValues,
            productCost,
            productPrice,
            productQty,
            oderTax,
            taxType,
            stockAlert
        });

    };


    // Handle clear operation
    const handleClear = () => {
        setProductName(''); setBrands(''); setCategory(''); setNote(''); setSaleUnit(''); setCode(''); setPurchaseUnit(''); setQL(''); setStatus('');
        setSuplier(''); setWarehouse(''); setVariation(''); setBarcode(''); setError(''); setResponseMessage('');
    };


    return (
        <div className='bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] min-h-full p-5'>
            <div className='flex justify-between items-center'>
                <div>
                    <h2 className="text-lightgray-300 ml-4 m-0 p-0 text-2xl">Create Product</h2>
                </div>
                <div>
                    <Link className='px-4 py-1.5 border border-[#37b34a] text-[#37b34a] rounded-md transition-colors duration-300 hover:bg-[#37b34a] hover:text-white' to={'/viewProducts'}>Back</Link>
                </div>
            </div>
            <div className="bg-white mt-[20px] w-full min-h-full rounded-2xl px-8 shadow-md">
                {loading ? (
                    <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                        <LinearProgress />
                    </Box>
                ) : (
                    <form className='pb-10' onSubmit={handleSubmit}>
                        <div className="flex min-h-full flex-1 flex-col px-2 py-10 lg:px-8">
                            {/* Container for the three sub-divs */}
                            <div className="flex flex-1 flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 mt-2">

                                {/* First Sub-Div============================================================================================================================================= */}
                                <div className="flex-1">

                                    {/* name*/}
                                    <div className="mt-2">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                            Product name
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="name"
                                                name="name"
                                                type="text"
                                                required
                                                placeholder="Enter name "
                                                onChange={(e) => setProductName(e.target.value)}
                                                className="block w-[90%] rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    {/* Category */}
                                    <div className="mt-5">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Category</label>
                                        <div className="mt-2">
                                            <select
                                                id="category"
                                                name="category"
                                                required
                                                value={category}
                                                onChange={(e) => setCategory(e.target.value)}
                                                className="block  w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            >
                                                <option value="">Select a category</option>
                                                {Array.isArray(categoryData) && categoryData.map((cat) => (
                                                    <option key={cat.category} value={cat.category}>{cat.category}</option>
                                                ))}
                                            </select>

                                        </div>
                                    </div>

                                    {/* Barcode */}
                                    <div className="mt-5">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Barcode type</label>
                                        <div className="mt-2">
                                            <select
                                                id="barcode"
                                                name="barcode"
                                                required
                                                value={barcode}
                                                onChange={(e) => setBarcode(e.target.value)}
                                                className="block  w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            >
                                                <option value="">Select a barcode</option>
                                                <option value="code128">Code 128</option>
                                                <option value="code39">Code 39</option>
                                            </select>
                                        </div>
                                    </div>

                                    {/* Base Unit */}
                                    <div className="mt-5">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Base unit</label>
                                        <div className="mt-2">
                                            <select
                                                id="unit"
                                                name="unit"
                                                required
                                                value={unit}
                                                onChange={handleBaseUnitChange}
                                                className="block w-[90%] rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            >
                                                <option value="">Select a base unit</option>
                                                {Array.isArray(unitData) && unitData.map((bu) => (
                                                    <option key={bu.baseUnit} value={bu.baseUnit}>{bu.baseUnit}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/*Quantity Limitation:*/}
                                    <div className="mt-5">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                            Quantity Limitation
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="QuantityLimitation:"
                                                name="QuantityLimitation:"
                                                type="text"
                                                required
                                                onChange={(e) => setQL(e.target.value)}
                                                placeholder="Quantity Limitation:"
                                                className="block  w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    {/* Product type */}
                                    <div className="mt-5">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Product type</label>
                                        <div className="mt-2">
                                            <select
                                                id="ptype"
                                                name="ptype"
                                                required
                                                value={ptype}
                                                onChange={(e) => setType(e.target.value)}
                                                className="block  w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            >
                                                <option value="">Select a type</option>
                                                <option>Single</option>
                                                <option>Variation</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {/* second Sub-Div=============================================================================================================================================*/}

                                <div className="flex-1">
                                    {/*code*/}
                                    <div className="mt-2">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                            Product code
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="code"
                                                name="code"
                                                type="text"
                                                required
                                                onChange={(e) => setCode(e.target.value)}
                                                placeholder="Enter code"
                                                className="block  w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    {/* Brand */}
                                    <div className="mt-5">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Brand</label>
                                        <div className="mt-2">
                                            <select
                                                id="brand"
                                                name="brand"
                                                required
                                                value={brand}
                                                onChange={(e) => setBrands(e.target.value)}
                                                className="block  w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            >
                                                <option value="">Select a brand</option>
                                                {Array.isArray(brandData) && brandData.map((br) => (
                                                    <option key={br.brandName} value={br.brandName}>{br.brandName}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/* Warehouse */}
                                    <div className="mt-5">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Warehouse</label>
                                        <div className="mt-2">
                                            <select
                                                id="warehouse"
                                                required
                                                name="warehouse"
                                                value={warehouse}
                                                onChange={(e) => setWarehouse(e.target.value)}
                                                className="block  w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            >
                                                <option value="">Select a warehouse</option>
                                                {warehouseData.map((wh) => (
                                                    <option key={wh.name} value={wh.name}>{wh.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/* Sale Unit */}
                                    <div className="mt-5">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Sale Unit</label>
                                        <div className="mt-2">
                                            <select
                                                id="sale_unit"
                                                name="sale_unit"
                                                value={saleUnit}
                                                required
                                                onChange={(e) => setSaleUnit(e.target.value)}
                                                className="block  w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            >
                                                <option value="">Select a sale unit</option>
                                                {Array.isArray(unitData) && unitData.map((u) => (
                                                    <option key={u.unitName} value={u.unitName}>{u.unitName}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/*perchese unit*/}
                                    <div className="mt-5">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Purchase Unit</label>
                                        <div className="mt-2">
                                            <select
                                                id="perchese_unit"
                                                name="perchese_unit"
                                                value={purchase}
                                                required
                                                onChange={(e) => setPurchaseUnit(e.target.value)}
                                                className="block  w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            >
                                                <option value="">Select a purchase unit</option>
                                                {Array.isArray(unitData) && unitData.map((u) => (
                                                    <option key={u.unitName} value={u.unitName}>{u.unitName}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div>
                                        {/* Variation Dropdown */}
                                        <div className="mt-5">
                                            <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Variation</label>
                                            <div className="mt-2">
                                                <select
                                                    id="variation"
                                                    name="variation"
                                                    value={variation}
                                                    onChange={handleVariationChange}
                                                    className={`block  w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6 ${ptype !== "Variation" ? "opacity-50 pointer-events-none" : ""}`}
                                                    disabled={ptype !== "Variation"}
                                                >
                                                    <option value="">Select a variation</option>
                                                    {variationData.map((varn) => (
                                                        <option key={varn.variationName} value={varn.variationName}>
                                                            {varn.variationName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Third Sub-Div=============================================================================================================================================*/}

                                <div className="flex-1">
                                    {/**Images */}
                                    <div className="mt-[7px]">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                            Images
                                        </label>
                                        <div className="mt-[9px]">
                                            <input
                                                id="image"
                                                name="image"
                                                type="file"
                                                onChange={handleImageChange}
                                                placeholder="Enter name "
                                                className="block  w-[90%] rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    {/* Supplier */}
                                    <div className="mt-5">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Supplier</label>
                                        <div className="mt-2">
                                            <select
                                                id="suplier"
                                                name="suplier"
                                                required
                                                value={suplier}
                                                onChange={(e) => setSuplier(e.target.value)}
                                                className="block  w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            >
                                                <option value="">Select a supplier</option>
                                                {suplierData.map((s) => (
                                                    <option key={s.name} value={s.name}>{s.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/*status*/}
                                    <div className="mt-5">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Status</label>
                                        <div className="mt-2">
                                            <select
                                                id="status"
                                                name="status"
                                                required
                                                value={status}
                                                onChange={(e) => setStatus(e.target.value)}
                                                className="block  w-[90%] rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            >
                                                <option value=''>Select a status</option>
                                                <option value='Received'>Received</option>
                                                <option value='Pending'>Pending</option>
                                                <option value='Ordered'>Ordered</option>
                                            </select>
                                        </div>
                                    </div>

                                    {/*Note*/}
                                    <div className="mt-5">
                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                            Note
                                        </label>
                                        <div className="mt-[6px]">
                                            <textarea
                                                id="note"
                                                name="note"
                                                type="text"
                                                placeholder="note"
                                                onChange={(e) => setNote(e.target.value)}
                                                className="block w-[90%] h-[139px] rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/**VARIATION MANAGE SECTION========================================================================================================== */}

                            <hr className="mt-20" />
                            <h2 className="mt-10">Manage variations</h2>
                            {/* Multi-select variation types with chips */}
                            {variation && ptype !== 'Single' && (
                                <div className="mt-5">
                                    <label className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                        Select Variation Types
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            onChange={handleAddVariationType}
                                            className="block w-[29%] rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                        >
                                            <option value="">Select Types</option>
                                            {variationType.map((type, index) => (
                                                <option key={index} value={type}>{type}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            )}

                            {/* Display selected types as chips */}
                            <div className="flex flex-wrap mt-3 gap-2">
                                {ptype === 'Variation' && selectedVariationTypes.map((type, index) => (
                                    <div
                                        key={index}
                                        className="flex items-center px-3 py-1 bg-gray-200 rounded-full text-sm"
                                    >
                                        {type}
                                        <button
                                            className="ml-2 text-red-500"
                                            onClick={() => handleRemoveVariationType(type)}
                                        >
                                            &#x2715;
                                        </button>
                                    </div>
                                ))}
                            </div>

                            {/*CREATE VARIATION PROPERTIES============================================================================================================================*/}
                            {/**This is the variation property manage section one by one */}
                            {ptype === 'Variation' && selectedVariationTypes.map((type) => (
                                showSections[type] && (
                                    <div className=" mt-10 variationPropertyManaging" key={type}>
                                        <h3 className="text-lg font-semibold mb-4">{type} Properties</h3>
                                        <div className='p-[15px] border border-gray-300 rounded-md'>
                                            <div className="flex space-x-4 mb-5">
                                                <div className="flex-1">
                                                    <div>
                                                        <label className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                                            Variation Type
                                                        </label>
                                                        <input
                                                            value={type}
                                                            type="text"
                                                            placeholder={`${type} Type`}
                                                            onChange={(e) => handleInputChange(type, e.target.value)}
                                                            className="block w-[90%] rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                    <label className="block mt-5 text-sm font-medium leading-6 text-gray-900 text-left">
                                                        Stock Alert
                                                    </label>
                                                    <input
                                                        type="text"
                                                        placeholder="Stock Alert"
                                                        onChange={(e) => handleVariationValueChange(type, 'stockAlert', e.target.value)}
                                                        className="block  w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                                    />
                                                </div>

                                                <div className="flex-1">
                                                    <label className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                                        Product Cost
                                                    </label>
                                                    <div className="relative">
                                                        <input
                                                            type="text"
                                                            placeholder="Product Cost"
                                                            onChange={(e) => handleVariationValueChange(type, 'productCost', e.target.value)}
                                                            className="block w-[90%]  rounded-md border-0 py-2.5 px-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                                        />
                                                        <span className="absolute mr-[10.2%] rounded-r-[4px] bg-gray-400 text-gray-100 inset-y-0 right-0 pr-3 flex items-center px-2 ">
                                                            Rs
                                                        </span>
                                                    </div>
                                                    <label className="block mt-5 text-sm font-medium leading-6 text-gray-900 text-left">
                                                        Oder Tax
                                                    </label>
                                                    <div className="relative">
                                                        <input
                                                            type="text"
                                                            placeholder="Oder Tax"
                                                            onChange={(e) => handleVariationValueChange(type, 'orderTax', e.target.value)}
                                                            className="block w-[90%]  rounded-md border-0 py-2.5 px-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                                        />
                                                        <span className="absolute mr-[10.2%] rounded-r-[4px] bg-gray-400 text-gray-100 inset-y-0 right-0 pr-3 flex items-center px-2 ">
                                                            %
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="flex-1">
                                                    <label className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                                        Code
                                                    </label>
                                                    <input
                                                        type="text"
                                                        placeholder="Code"
                                                        onChange={(e) => handleVariationValueChange(type, 'code', e.target.value)}
                                                        className="block w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                                    />
                                                    <label className="block  mt-5 text-sm font-medium leading-6 text-gray-900 text-left">
                                                        Tax Type
                                                    </label>
                                                    <select
                                                        type="text"
                                                        placeholder=" Tax Type"
                                                        onChange={(e) => handleVariationValueChange(type, 'taxType', e.target.value)}
                                                        className="block  w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                                    >
                                                        <option>Exclusive</option>
                                                        <option>Inclusive</option>
                                                    </select>
                                                </div>

                                                <div className="flex-1">
                                                    <label className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                                        Product Price
                                                    </label>
                                                    <div className="relative">
                                                        <input
                                                            type="text"
                                                            placeholder="Product Price"
                                                            onChange={(e) => handleVariationValueChange(type, 'productPrice', e.target.value)}
                                                            className="block w-[90%]  rounded-md border-0 py-2.5 px-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                                        />
                                                        <span className="absolute mr-[10.2%] rounded-r-[4px] bg-gray-400 text-gray-100 inset-y-0 right-0 pr-3 flex items-center px-2 ">
                                                            Rs
                                                        </span>
                                                    </div>
                                                    <label className="block mt-5 text-sm font-medium leading-6 text-gray-900 text-left">
                                                        Add Product Quantity
                                                    </label>
                                                    <input
                                                        type="text"
                                                        placeholder="Add Product Quantity"
                                                        onChange={(e) => handleVariationValueChange(type, 'productQty', e.target.value)}
                                                        className="block w-[90%]  rounded-md border-0 py-2.5 px-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )
                            ))}

                            {/**SINGLE PRODUCT VARIATION PROPERTIES */}
                            {ptype === 'Single' && (
                                <div className="mt-10 variationPropertyManaging">
                                    <h3 className="text-lg font-semibold mb-4">Single Product Properties</h3>
                                    <div className='p-6 border border-gray-300 rounded-md'>
                                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                                            <div className="flex flex-col space-y-5">
                                                <div>
                                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                                        Stock Alert
                                                    </label>
                                                    <input
                                                        type="text"
                                                        placeholder="Stock Alert"
                                                        onChange={(e) => setStockAlert(e.target.value)}
                                                        className="block w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                                                    />
                                                </div>
                                                <div>
                                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                                        Order Tax
                                                    </label>
                                                    <div className="relative">
                                                        <input
                                                            type="text"
                                                            placeholder="Order Tax"
                                                            onChange={(e) => setOderTax(e.target.value)}
                                                            className="block w-[90%]  rounded-md border-0 py-2.5 px-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                                                        />
                                                        <span className="absolute mr-[10.2%] rounded-r-[4px] bg-gray-400 text-gray-100 inset-y-0 right-0 pr-3 flex items-center px-2">
                                                            %
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex flex-col space-y-5">
                                                <div>
                                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                                        Product Cost
                                                    </label>
                                                    <div className="relative">
                                                        <input
                                                            type="text"
                                                            placeholder="Product Cost"
                                                            onChange={(e) => setProductCost(e.target.value)}
                                                            className="block w-[90%]  rounded-md border-0 py-2.5 px-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                                                        />
                                                        <span className="absolute mr-[10.2%] rounded-r-[4px] bg-gray-400 text-gray-100 inset-y-0 right-0 pr-3 flex items-center px-2 ">
                                                            Rs
                                                        </span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                                        Tax Type
                                                    </label>
                                                    <select
                                                        className="block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                                                        onChange={(e) => setTaxType(e.target.value)}
                                                    >
                                                        <option>Exclusive</option>
                                                        <option>Inclusive</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="flex flex-col space-y-5">
                                                <div>
                                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                                        Product Price
                                                    </label>
                                                    <div className="relative">
                                                        <input
                                                            type="text"
                                                            placeholder="Product Price"
                                                            onChange={(e) => setProductPrice(e.target.value)}
                                                            className="block w-[90%]  rounded-md border-0 py-2.5 px-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                                                        />
                                                        <span className="absolute mr-[10.2%] rounded-r-[4px] bg-gray-400 text-gray-100 inset-y-0 right-0 pr-3 flex items-center px-2 ">
                                                            Rs
                                                        </span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                                        Add Product Quantity
                                                    </label>
                                                    <input
                                                        type="text"
                                                        placeholder="Add Product Quantity"
                                                        onChange={(e) => setProductQty(e.target.value)}
                                                        className="block w-[90%]  rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/*SAVE AND CLEAR BUTTONS==============================================================================================================================*/}
                            <div className="flex justify-start mt-20">
                                <div className="mt-20">
                                    <button
                                        type="submit"
                                        disabled={!isFormValid}
                                        className={`rounded-md px-4 py-3.5 h-[48px] text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-[171px] text-center ${isFormValid ? 'bg-[#37b34a] hover:bg-[#2a9d34]' : 'bg-[#a6d8a6] cursor-not-allowed'
                                            }`}
                                    >
                                        Save Product
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex ml-2 justify-center rounded-md bg-gray-600 py-3.5 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 w-[170px] focus:ring-gray-500 focus:ring-offset-2"
                                        onClick={handleClear}
                                    >
                                        Clear
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Error and Response Messages */}
                        {error && (
                            <p className="text-red-600 px-5 py-2 rounded-md bg-red-100 mt-5 text-center inline-block">
                                {error}
                            </p>
                        )}
                        {responseMessage && (
                            <p className="text-green-600 px-5 py-2 rounded-md bg-green-100 mt-5 text-center inline-block">
                                {responseMessage}
                            </p>
                        )}
                    </form>

                )}
            </div>
        </div>
    );
}

export default CreateProductBody;
