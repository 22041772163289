import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./component/auth/Login";
import "./App.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

// Importing components
import "@fortawesome/fontawesome-free/css/all.min.css";
import ForgetPassword from "./component/auth/ForgetPassword";
import SendOTP from "./component/auth/SendOTP";
import NewPassword from "./component/auth/NewPassword";
import PrivateRoute from "./component/routing/privateRoute";

import CreateProductBody from "./component/product/createProduct";
import EditProductBody from "./component/product/editProduct";
import MainLayout from "./component/layout/MainLayout";
import ViewProductsBody from "./component/product/viewProducts";
import DashboardBody from "./component/layout/DashboardCards";
import CreateUserBody from "./component/user/CreateUser";
import CreateAdminBody from "./component/admin/CreateAdmin";
import ProfileBody from "./component/user/ProfileBody";
import EditProfileByAdmin from "./component/admin/EditProfileByAdmin";
import CreateRoleBody from "./component/admin/CreateRole";
import ViewRoleAndPermissionBody from "./component/admin/ViewRoleAndPermission";
import EditPermissionsBody from "./component/admin/EditPermissions";
import CreateCustomerBody from "./component/customer/CreateCustomer";
import ViewCustomersBody from "./component/customer/ViewCustomers";
import CreateBarcodeBody from "./component/product/barcode/createBarcode";
import EditCustomerBody from "./component/customer/EditCustomer";
import CreateSuplierBody from "./component/supplier/CreateSupplier";
import ViewSuplierBody from "./component/supplier/ViewSuplier";
import EditSuplierBody from "./component/supplier/EditSuplier";
import CreateWhereHouseBody from "./component/warehouse/CreateWhereHouse";
import ViewWhereHouseBody from "./component/warehouse/ViewWhereHouse";
import EditWarehouseBody from "./component/warehouse/EditWarehouse";
import CreateBaseUnitBody from "./component/product/baseUnit/createBaseUnit";
import ViewBaseUnitBody from "./component/product/baseUnit/viewBaseUnit";
import EditBaseUnitBody from "./component/product/baseUnit/editBaseUnit";
import CreateUnitBody from "./component/product/units/createUnit";
import ViewUnitBody from "./component/product/units/viewUnit";
import EditUnitBody from "./component/product/units/editUnit";
import CreateVariationBody from "./component/product/variations/createVariation";
import ViewVariationBody from "./component/product/variations/viewVariation";
import EditVariationBody from "./component/product/variations/editVariation";
import CreateBrandsBody from "./component/product/brands/createBrands";
import ViewBrandsBody from "./component/product/brands/viewBrands";
import EditBrandBody from "./component/product/brands/editBrands";
import CreateProductCategoryBody from "./component/product/categories/createProductCategory";
import ViewCategoryBody from "./component/product/categories/viewCatergory";
import EditCatergoryBody from "./component/product/categories/editCategory";
import PosSystemBody from "./component/pos/components/PosSystemBody";
import CreateSaleBody from "./component/sales/CreateSale";
import ViewSaleBody from "./component/sales/ViewSale";
import EditSaleBody from "./component/sales/EditSale";
import CreateSaleReturnBody from "./component/sales/saleReturns/CreateSaleReturn";
import ViewSaleReturnBody from "./component/sales/saleReturns/ViewSaleReturn";
import EditSaleReturnBody from "./component/sales/saleReturns/EditSaleReturn";
import CreatePurchaseBody from "./component/purchase/CreatePurchase";
import ViewPurchaseBody from "./component/purchase/ViewPurchase";
import EditPurchaseBody from "./component/purchase/EditPurchase";
import CreatePurchaseReturnBody from "./component/purchase/purchaseReturns/CreatePurchaseReturn";
import ViewPurchaseReturnBody from "./component/purchase/purchaseReturns/ViewPurchaseReturn";
import EditPurchaseReturnBody from "./component/purchase/purchaseReturns/EditPurchaseReturn";
import CreateQuatationBody from "./component/quotation/CreateQuatation";
import ViewQuatationBody from "./component/quotation/ViewQuatation";
import EditQuatationBody from "./component/quotation/EditQuatation";
import CreateSaleFromQuatationBody from "./component/quotation/CreateSaleFromQuatation";
import CreateAdjustmentBody from "./component/adjustment/CreateAdjustment";
import ViewAdjustmentBody from "./component/adjustment/ViewAdjustment";
import EditAdjustmentBody from "./component/adjustment/EditAdjustment";
import ViewCurrencyBody from "./component/currency/ViewCurrency";
import ViewExpensesCategoryBody from "./component/expenses/CreateExpensesCategory";
import CreateExpensesBody from "./component/expenses/CreateExpenses";
import ViewExpensesBody from "./component/expenses/ViewExpenses";
import EditExpensesBody from "./component/expenses/EditExpenses";
import ViewReportBody from "./component/reports/ViewReports";
import CustomerReportBody from "./component/reports/CustomerReportBody";
import ClickedCustomerReport from "./component/reports/ClickedCustomerReport";
import SuplierReportBody from "./component/reports/SuplierReportBody";
import ClickedSuplierReport from "./component/reports/ClickedSuplierRep";
import QuantityAlertReportBody from "./component/reports/QuantityAlertReportBody";
import ClickedStokeReport from "./component/reports/ClickedStokeReport";
import CreateTransferBody from "./component/transfers/CreateTransfer";
import ViewTransferBody from "./component/transfers/ViewTransfer";
import EditTransferBody from "./component/transfers/EditTransfer";
import SystemSettingsBody from "./component/settings/SystemSettings";
import MailSettingsBody from "./component/settings/MailSettings";
import ReceiptSettingsBody from "./component/settings/ReceiptSettings";
import PrefixSettingsBody from "./component/settings/prefixSettings";
import ViewUserBody from "./component/user/ViewUser";
import PermissionGuard from "./component/guard/PermissionGuard";
import PermissionController from "./component/utill/permissionController";
import StokeReportBody from "./component/reports/ViewStokeReport";
import ViedRegisterReportBody from "./component/reports/ViewRegisterReport";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* public routes */}
          <Route path="/" element={<Login />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="/newpassword" element={<NewPassword />} />
          <Route path="/sendOTP" element={<SendOTP />} />



          {/* private routes */}
          <Route
            element={
              <PrivateRoute>
                <MainLayout /> {/* Header and Sidebar always present */}
              </PrivateRoute>
            }
          >
            <Route path="/dashboard" element={<DashboardBody />} />
            {/**crearte, read and edit USER BY ADMIN*/}
            <Route path="/createUser" element={
                            <PermissionGuard requiredPermissions={['manageUser']}>
                                <CreateUserBody />
                            </PermissionGuard>
                        } />
            <Route path="/createAdmin" element={<CreateAdminBody />} />
            <Route path="/users" element={<ViewUserBody />} />
            <Route path="/profile" element={<ProfileBody />} />
            <Route path="/editprofilebyadmin/:id" element={<EditProfileByAdmin />} />

            {/**creat read and update PERMISSIONS*/}
            <Route path="/CreateroleAndPermissions" element={
              <PermissionGuard requiredPermissions={['manageRoles']}>
              <CreateRoleBody />
              </PermissionGuard>} />
            <Route path="/ViewroleAndPermissions" element={
              <PermissionGuard requiredPermissions={['manageRoles']}>
              <ViewRoleAndPermissionBody />
              </PermissionGuard>} />
            <Route path="/editPermissions/:id" element={
              <PermissionGuard requiredPermissions={['manageRoles']}>
              <EditPermissionsBody />
              </PermissionGuard>} />

            {/**creat read and update CUSTOMER*/}
            <Route path="/createCustomer" element={
              <PermissionGuard requiredPermissions={['manageCustomers']}>
              <CreateCustomerBody />
              </PermissionGuard>} />
            <Route path="/viewCustomers" element={
              <PermissionGuard requiredPermissions={['manageCustomers']}>
              <ViewCustomersBody />
              </PermissionGuard>} />
            <Route path="/editCustomerDetails/:id" element={
              <PermissionGuard requiredPermissions={['manageCustomers']}>
              <EditCustomerBody />
              </PermissionGuard>} />

            {/**creat read and update SUPLIER*/}
            <Route path="/createSuplier" element={
               <PermissionGuard requiredPermissions={['manageSuppliers']}>
              <CreateSuplierBody />
              </PermissionGuard>} />
            <Route path="/viewSuplier" element={
               <PermissionGuard requiredPermissions={['manageSuppliers']}>
              <ViewSuplierBody />
              </PermissionGuard>} />
            <Route path="/editSuplier/:id" element={
               <PermissionGuard requiredPermissions={['manageSuppliers']}>
              <EditSuplierBody />
              </PermissionGuard>} />

            {/**creat read and update WAREHOUSE*/}
            <Route path="/createWherehouse" element={<CreateWhereHouseBody />} />
            <Route path="/viewWarehouse" element={<ViewWhereHouseBody />} />
            <Route path="/editWarehouse/:id" element={<EditWarehouseBody />} />

            {/**creat read and update BASE UNIT*/}
            <Route path="/createBaseUnit" element={
              <PermissionGuard requiredPermissions={['manageUnits']}>
              <CreateBaseUnitBody />
              </PermissionGuard>} />
            <Route path="/viewBaseUnit" element={
              <PermissionGuard requiredPermissions={['manageUnits']}>
              <ViewBaseUnitBody />
              </PermissionGuard>} />
            <Route path="/editBaseUnit/:id" element={
              <PermissionGuard requiredPermissions={['manageUnits']}>
              <EditBaseUnitBody />
              </PermissionGuard>} />

            {/**creat read and update UNIT*/}
            <Route path="/createUnit" element={
              <PermissionGuard requiredPermissions={['manageUnits']}>
              <CreateUnitBody />
              </PermissionGuard>} />
            <Route path="/viewUnit" element={
              <PermissionGuard requiredPermissions={['manageUnits']}>
              <ViewUnitBody />
              </PermissionGuard>} />
            <Route path="/editUnit/:id" element={
              <PermissionGuard requiredPermissions={['manageUnits']}>
              <EditUnitBody />
              </PermissionGuard>} />

            {/**creat read and update VARIATION*/}
            <Route path="/createVariation" element={
              <PermissionGuard requiredPermissions={['manageProducts']}>
              <CreateVariationBody />
              </PermissionGuard>} />
            <Route path="/viewVariation" element={
              <PermissionGuard requiredPermissions={['manageProducts']}>
              <ViewVariationBody />
              </PermissionGuard>} />
            <Route path="/editVariation/:id" element={
              <PermissionGuard requiredPermissions={['manageProducts']}>
              <EditVariationBody />
              </PermissionGuard>} />

            {/**creat read and update BRANDS*/}
            <Route path="/createBrands" element={
              <PermissionGuard requiredPermissions={['manageBrands']}>
              <CreateBrandsBody />
              </PermissionGuard>} />
            <Route path="/viewBrands" element={
              <PermissionGuard requiredPermissions={['manageBrands']}>
              <ViewBrandsBody />
              </PermissionGuard>} />
            <Route path="/editBrands/:id" element={
               <PermissionGuard requiredPermissions={['manageBrands']}>
              <EditBrandBody />
              </PermissionGuard>} />

            {/**creat read and update CATEGORY*/}
            <Route path="/createCategory" element={
              <PermissionGuard requiredPermissions={['manageProductCategories']}>
              <CreateProductCategoryBody />
              </PermissionGuard>} />
            <Route path="/viewCategory" element={
              <PermissionGuard requiredPermissions={['manageProductCategories']}>
              <ViewCategoryBody />
              </PermissionGuard>} />
            <Route path="/editCategory/:id" element={
              <PermissionGuard requiredPermissions={['manageProductCategories']}>
              <EditCatergoryBody />
              </PermissionGuard>} />

            {/**create , read ,and update product */}
            <Route path="/createProduct" element={
              <PermissionGuard requiredPermissions={['manageProducts']}>
              <CreateProductBody />
              </PermissionGuard>} />
            <Route path="/viewProducts" element={
               <PermissionGuard requiredPermissions={['manageProducts']}>
              <ViewProductsBody />
              </PermissionGuard>} />
            <Route path="/editProduct/:id" element={
               <PermissionGuard requiredPermissions={['manageProducts']}>
              <EditProductBody />
              </PermissionGuard> } />

            {/**Barcode creating and printing */}
            <Route path="/barcodePrint" element={
              <PermissionGuard requiredPermissions={['manageProducts']}>
              <CreateBarcodeBody />
              </PermissionGuard>} />


            {/*Create sale*/}
            <Route path="/CreateSale" element={<CreateSaleBody />} />
            <Route path="/ViewSale" element={<ViewSaleBody />} />
            <Route path="/EditSale/:id" element={<EditSaleBody />} />

            {/*Create sale return*/}
            <Route path="/CreateSaleReturn/:id" element={<CreateSaleReturnBody />} />
            <Route path="/ViewSaleReturns" element={<ViewSaleReturnBody />} />
            <Route path="/EditSaleReturn/:id" element={<EditSaleReturnBody />} />

            {/* Create purchase CreatePurchase */}
            <Route path="/CreatePurchase" element={<CreatePurchaseBody />} />
            <Route path="/ViewPurchase" element={<ViewPurchaseBody />} />
            <Route path="/EditPurchase/:id" element={<EditPurchaseBody />} />

            {/*Create purchase return*/}
            <Route path="/CreatePurchaseReturn/:id" element={<CreatePurchaseReturnBody />} />
            <Route path="/ViewPurchaseReturns" element={<ViewPurchaseReturnBody />} />
            <Route path="/EditPurchaseReturn/:id" element={<EditPurchaseReturnBody />} />

            {/*Create quatation*/}
            <Route path="/CreateQuatation" element={<CreateQuatationBody />} />
            <Route path="/ViewQuatation" element={<ViewQuatationBody />} />
            <Route path="/EditQutation/:id" element={<EditQuatationBody />} />
            <Route path="/CreateSaleFromQuatation/:id" element={<CreateSaleFromQuatationBody />} />

            {/*Create adjustment*/}
            <Route path="/CreateAdjustment" element={<CreateAdjustmentBody />} />
            <Route path="/ViewAdjustment" element={<ViewAdjustmentBody />} />
            <Route path="/EditAdjustment/:id" element={<EditAdjustmentBody />} />

            {/*Create currency*/}
            <Route path="/ViewCurrency" element={<ViewCurrencyBody />} />

            {/*Create expenses category*/}
            <Route path="/ViewExpensesCategory" element={
              <PermissionGuard requiredPermissions={['manageExpenses']}>
              <ViewExpensesCategoryBody />
              </PermissionGuard>} />

            {/*Create expenses*/}
            <Route path="/createExpenses" element={
              <PermissionGuard requiredPermissions={['manageExpenses']}>
              <CreateExpensesBody />
              </PermissionGuard>} />
            <Route path="/ViewExpenses" element={
              <PermissionGuard requiredPermissions={['manageExpenses']}>
              <ViewExpensesBody />
              </PermissionGuard>} />
            <Route path="/EditExpenses/:id" element={
              <PermissionGuard requiredPermissions={['manageExpenses']}>
              <EditExpensesBody />
              </PermissionGuard>} />
{/* 
            View Reports */}
            <Route path="/ViewReport" element={<ViewReportBody />} />
            <Route path="/customerReport" element={<CustomerReportBody />} />
            <Route path="/viewCustomerRep/:sale" element={<ClickedCustomerReport />} />
            <Route path="/suplierReport" element={<SuplierReportBody />} />
            <Route path="/viewSuplierRep/:sale" element={<ClickedSuplierReport />} />
            <Route path="/ViewStokeRep" element={<StokeReportBody/>} />
            <Route path="/quantityAlertRep" element={<QuantityAlertReportBody />} />
            <Route path="/viewRegisterRep" element={<ViedRegisterReportBody/>} />
            <Route path="/clickedStokeRep/:id" element={<ClickedStokeReport />} />

            {/* Transfer routes */}
            <Route path="/createTransfer" element={<CreateTransferBody />} />
            <Route path="/viewTransfer" element={<ViewTransferBody />} />
            <Route path="/EditTransfer/:id" element={<EditTransferBody />} />

            {/* Setting routes */}
            <Route path="/settings" element={<SystemSettingsBody />} />
            <Route path="/mailSettings" element={<MailSettingsBody />} />
            <Route path="/receiptSettings" element={<ReceiptSettingsBody />} />
            <Route path="/prefixSettings" element={<PrefixSettingsBody />} />
          </Route>

          <Route path="/posSystem" element={<PrivateRoute><PosSystemBody /></PrivateRoute>} />

        </Routes>
        
      </div>
    </Router>
  );
}

export default App;
