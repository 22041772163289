import { useState } from 'react';
import '../../styles/role.css';
import { Link } from 'react-router-dom';
import axios from 'axios';

const CreateRoleBody = () => {
    // Initialize state variables for input values
    const [roleName, setRoleName] = useState('');
    const [permissions, setPermissions] = useState({
        manageRoles: false,
        manageProducts: false,
        manageUser: false,
        manageWarehouse: false,
        manageBrands: false,
        manageUnits: false,
        manageSuppliers: false,
        manageExpenseCategories: false,
        manageCurrency: false,
        manageProductCategories: false,
        manageCustomers: false,
        manageExpenses: false,
    });
    const [error, setError] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    // Handle role name
    const handleRoleNameChange = (event) => {
        setRoleName(event.target.value);
    };

    // Handle role permissions
    const handlePermissionChange = (event) => {
        const { name, checked } = event.target;
        setPermissions((prevPermissions) => ({
            ...prevPermissions,
            [name]: checked,
        }));
    };

    // Handle submit
    const handleSubmit = (event) => {
        event.preventDefault();

        // Reset any previous error or success messages
        setError('');
        setResponseMessage('');

        // Check if role name is entered
        if (roleName.trim() === '') {
            setError('Please enter a role name.');
            console.error('Role name is empty');
            return;
        }

        // Check if at least one permission is selected
        const isPermissionSelected = Object.values(permissions).includes(true);
        if (!isPermissionSelected) {
            setError('Please select at least one permission for the role.');
            console.error('No permissions selected');
            return;
        }

        const Permission = {
            roleName: roleName.trim(),
            manageRoles: permissions.manageRoles,
            manageProducts: permissions.manageProducts,
            manageUser: permissions.manageUser,
            manageWarehouse: permissions.manageWarehouse,
            manageBrands: permissions.manageBrands,
            manageUnits: permissions.manageUnits,
            manageSuppliers: permissions.manageSuppliers,
            manageExpenseCategories: permissions.manageExpenseCategories,
            manageCurrency: permissions.manageCurrency,
            manageProductCategories: permissions.manageProductCategories,
            manageCustomers: permissions.manageCustomers,
            manageExpenses: permissions.manageExpenses,
        };

        // Log permission object before sending
        console.log('Permission object being sent:', Permission);

        // API request to create the role
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/createPermissions`, Permission)
            .then((response) => {
                setResponseMessage('Successfully added the permissions and created the role.');
                console.log('Response from API:', response);
                setRoleName('');
                setPermissions({
                    manageRoles: false,
                    manageProducts: false,
                    manageUser: false,
                    manageWarehouse: false,
                    manageBrands: false,
                    manageUnits: false,
                    manageSuppliers: false,
                    manageExpenseCategories: false,
                    manageCurrency: false,
                    manageProductCategories: false,
                    manageCustomers: false,
                    manageExpenses: false,
                });
            })
            .catch((err) => {
                // Log the error for debugging purposes
                console.error('Error occurred during API request:', err);

                if (err.response && err.response.data && err.response.data.message === 'Role already exists') {
                    setError('Role already exists.');
                } else {
                    setError('An error occurred while adding the permissions. Please try again later.');
                }
            });
    };

    // Handle clear
    const handleClear = () => {
        setRoleName('');
        setPermissions({
            manageRoles: false,
            manageProducts: false,
            manageUser: false,
            manageWarehouse: false,
            manageBrands: false,
            manageUnits: false,
            manageSuppliers: false,
            manageExpenseCategories: false,
            manageCurrency: false,
            manageProductCategories: false,
            manageCustomers: false,
            manageExpenses: false,
        });
        setError('');
        setResponseMessage('');
    };

    return (
        <div className="bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] h-[800px] p-5">
            <div className='flex justify-between items-center'>
                <div>
                    <h2 className="text-lightgray-300 m-0 p-0 text-2xl">Create User Role</h2>
                </div>
                <div>
                    <Link className='px-4 py-1.5 border border-[#37b34a] text-[#37b34a] rounded-md transition-colors duration-300 hover:bg-[#37b34a] hover:text-white' to={'/ViewroleAndPermissions'}>Back</Link>
                </div>
            </div>
            <div className="bg-white mt-5 w-full h-[640px] rounded-2xl p-8 shadow-md">
                <form onSubmit={handleSubmit}>
                    <div>
                        <input
                            className="roleName w-50 pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                            type="text"
                            name="role"
                            placeholder="Enter the role name"
                            value={roleName}
                            onChange={handleRoleNameChange}
                        />
                    </div>
                    <div className="mt-10 flex items-center">
                        <label className="text-lightgray-300 font-semibold text-gray-700">Permissions:</label>
                        <input
                            type="checkbox"
                            id="checkbox-all"
                            name='allPermissions'
                            className="checkbox-custom ml-4"
                            onChange={(event) => {
                                const { checked } = event.target;
                                setPermissions((prevPermissions) => {
                                    const newPermissions = {};
                                    for (const key in prevPermissions) {
                                        newPermissions[key] = checked;
                                    }
                                    return newPermissions;
                                });
                            }}
                        />
                        <label className="text-lightgray-300 ml-4">All Permissions</label>
                    </div>
                    <div className="mt-10 flex justify-between items-center w-full">
                        <div className="flex-1 text-left m-0 p-0">
                            <div className="flex items-center mt-2">
                                <input
                                    type="checkbox"
                                    id="checkbox-topLevel"
                                    name="manageRoles"
                                    className="checkbox-custom"
                                    checked={permissions.manageRoles}
                                    onChange={handlePermissionChange}
                                />
                                <label className="text-lightgray-300 ml-4">Manage Roles</label>
                            </div>
                            <div className="flex items-center mt-6">
                                <input
                                    type="checkbox"
                                    id="checkbox-manageProducts"
                                    name="manageProducts"
                                    className="checkbox-custom"
                                    checked={permissions.manageProducts}
                                    onChange={handlePermissionChange}
                                />
                                <label className="text-lightgray-300 ml-4">Manage Products</label>
                            </div>
                            <div className="flex items-center mt-6">
                                <input
                                    type="checkbox"
                                    id="checkbox-manageUser"
                                    name="manageUser"
                                    className="checkbox-custom"
                                    checked={permissions.manageUser}
                                    onChange={handlePermissionChange}
                                />
                                <label className="text-lightgray-300 ml-4">Manage Users</label>
                            </div>
                            <div className="flex items-center mt-6">
                                <input
                                    type="checkbox"
                                    id="checkbox-manageWarehouse"
                                    name="manageWarehouse"
                                    className="checkbox-custom"
                                    checked={permissions.manageWarehouse}
                                    onChange={handlePermissionChange}
                                />
                                <label className="text-lightgray-300 ml-4">Manage Warehouse</label>
                            </div>
                        </div>

                        <div className="flex-1 text-left m-0 p-0">
                            <div className="flex items-center mt-2">
                                <input
                                    type="checkbox"
                                    id="checkbox-manageBrands"
                                    name="manageBrands"
                                    className="checkbox-custom"
                                    checked={permissions.manageBrands}
                                    onChange={handlePermissionChange}
                                />
                                <label className="text-lightgray-300 ml-4">Manage Brands</label>
                            </div>
                            <div className="flex items-center mt-6">
                                <input
                                    type="checkbox"
                                    id="checkbox-manageUnits"
                                    name="manageUnits"
                                    className="checkbox-custom"
                                    checked={permissions.manageUnits}
                                    onChange={handlePermissionChange}
                                />
                                <label className="text-lightgray-300 ml-4">Manage Units</label>
                            </div>
                            <div className="flex items-center mt-6">
                                <input
                                    type="checkbox"
                                    id="checkbox-manageSuppliers"
                                    name="manageSuppliers"
                                    className="checkbox-custom"
                                    checked={permissions.manageSuppliers}
                                    onChange={handlePermissionChange}
                                />
                                <label className="text-lightgray-300 ml-4">Manage Suppliers</label>
                            </div>
                            <div className="flex items-center mt-6">
                                <input
                                    type="checkbox"
                                    id="checkbox-manageExpenseCategories"
                                    name="manageExpenseCategories"
                                    className="checkbox-custom"
                                    checked={permissions.manageExpenseCategories}
                                    onChange={handlePermissionChange}
                                />
                                <label className="text-lightgray-300 ml-4">Manage Expense Categories</label>
                            </div>
                        </div>

                        <div className="flex-1 text-left m-0 p-0">
                            <div className="flex items-center mt-2">
                                <input
                                    type="checkbox"
                                    id="checkbox-manageCurrency"
                                    name="manageCurrency"
                                    className="checkbox-custom"
                                    checked={permissions.manageCurrency}
                                    onChange={handlePermissionChange}
                                />
                                <label className="text-lightgray-300 ml-4">Manage Currency</label>
                            </div>
                            <div className="flex items-center mt-6">
                                <input
                                    type="checkbox"
                                    id="checkbox-manageProductCategories"
                                    name="manageProductCategories"
                                    className="checkbox-custom"
                                    checked={permissions.manageProductCategories}
                                    onChange={handlePermissionChange}
                                />
                                <label className="text-lightgray-300 ml-4">Manage Product Categories</label>
                            </div>
                            <div className="flex items-center mt-6">
                                <input
                                    type="checkbox"
                                    id="checkbox-manageCustomers"
                                    name="manageCustomers"
                                    className="checkbox-custom"
                                    checked={permissions.manageCustomers}
                                    onChange={handlePermissionChange}
                                />
                                <label className="text-lightgray-300 ml-4">Manage Customers</label>
                            </div>
                            <div className="flex items-center mt-6">
                                <input
                                    type="checkbox"
                                    id="checkbox-manageExpenses"
                                    name="manageExpenses"
                                    className="checkbox-custom"
                                    checked={permissions.manageExpenses}
                                    onChange={handlePermissionChange}
                                />
                                <label className="text-lightgray-300 ml-4">Manage Expenses</label>
                            </div>
                        </div>
                    </div>
                    <div className="flex space-x-4 mt-40">
                        <button
                            type="submit"
                            className="bg-[#37b34a] text-white rounded-md px-4 py-2 transition-colors duration-300 hover:bg-[#2a9d34] text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-[#37b34a] focus:ring-offset-2"
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            onClick={handleClear}
                            className="bg-gray-600 text-white rounded-md px-4 py-2 text-sm font-medium shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                        >
                            Clear
                        </button>
                    </div>
                </form>
            </div>

            {/* Error and Response Messages */}
            <div className="absolute bottom-28 right-0 left-0">
                    {error && (
                        <p className="text-red-600 px-5 py-2 rounded-md bg-red-100 mt-5 mb-7 text-center inline-block">
                            {error}
                        </p>
                    )}
                    {responseMessage && (
                        <p className="text-green-600 px-5 py-2 rounded-md bg-green-100 mt-5 mb-7 text-center inline-block">
                            {responseMessage}
                        </p>
                    )}
            </div>
        </div>
    );
};

export default CreateRoleBody;