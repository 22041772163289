import axios from 'axios';
//HANDLE SEARCH PRODUCT

export const handleProductSearch = async (e, setSearchTerm, setFilteredProducts, warehouse) => {
    const keyword = e.target.value;
    setSearchTerm(keyword);
    if (keyword.length > 0) {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/searchProductByName`, {
                params: {
                    name: keyword,
                    warehouse: warehouse,
                },
            });
            console.log(response.data.products);
            setFilteredProducts(response.data.products);
        } catch (error) {
            console.error('Error fetching product:', error);
            setFilteredProducts([]);
        }
    } else {
        setFilteredProducts([]);
    }
};

//HANDLE SEARCH CUSTOMERS
export const handleCustomerSearch = async (e, setSearchCustomer, setFilteredCustomer) => {
    const keyword = e.target.value;
    setSearchCustomer(keyword);
    if (keyword.length > 0) {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/searchCustomerByName`, {
                params: { name: keyword },
            });
            setFilteredCustomer(response.data.customer); // assuming response data has a customer field
        } catch (error) {
            console.error('Error fetching customer:', error);
            setFilteredCustomer([]);
        }
    } else {
        setFilteredCustomer([]);
    }
};

//HANDLE WAREHOUSE CHANGE
export const handleWarehouseChange = (e, setWarehouse, fetchProductDataByWarehouse, setProductData, setSelectedCategoryProducts, setSelectedBrandProducts, setSearchedProductData, setLoading) => {
    const selectedWarehouse = e.target.value;
    setWarehouse(selectedWarehouse);
    if (selectedWarehouse) {
        fetchProductDataByWarehouse(
            selectedWarehouse,
            setProductData,
            setSelectedCategoryProducts,
            setSelectedBrandProducts,
            setSearchedProductData,
            setLoading
        );
    } else {
        setProductData([]);
    }
};

//HANDLE CUSTOMER SELECT
export const handleCustomerSelect = (customer, setSelectedCustomer, setSearchCustomer, setFilteredCustomer) => {
    setSelectedCustomer(customer);
    setSearchCustomer(customer.name);
    setFilteredCustomer([]);
};

// HANDLE PRODUCT SELECTION
export const handleProductSelect = (product, setSelectedProduct, setSearchTerm, setFilteredProducts) => {
    if (product.ptype === 'Variation' && product.variationValues) {
        // Set the product with a variation
        setSelectedProduct((prevProducts) => {
            const existingProductWithSameVariation = prevProducts.find(
                p => p._id === product._id && p.selectedVariation === Object.keys(product.variationValues)[0]
            );
            if (existingProductWithSameVariation) {
                // If product with the same variation already exists, return the previous state
                alert(`The variation "${Object.keys(product.variationValues)[0]}" is already added.`);
                return prevProducts;
            }
            return [
                ...prevProducts,
                {
                    ...product,
                    selectedVariation: Object.keys(product.variationValues)[0],
                    barcodeFormat: product.barcode,
                    barcodeQty: 0,
                },
            ];
        });
    } else {
        // Handle normal product without variations
        setSelectedProduct((prevProducts) => {
            const existingProduct = prevProducts.find(p => p._id === product._id);
            if (existingProduct) {
                alert("This product is already added.");
                return prevProducts;
            }
            return [
                ...prevProducts,
                {
                    ...product,
                    barcodeFormat: product.barcode,
                    barcodeQty: 0,
                },
            ];
        });
    }
    setSearchTerm('');
    setFilteredProducts([]);
};

//HANDLE VARIATION CHANGE
export const handleVariationChange = (index, variation, setSelectedProduct) => {
    setSelectedProduct((prevProducts) =>
        prevProducts.map((product, i) => {
            if (i === index) { // Use index to find the correct product instance
                const productWithSameVariation = prevProducts.find(
                    (p, j) => j !== index && p._id === product._id && p.selectedVariation === variation
                );

                if (productWithSameVariation) {
                    alert(`The variation "${variation}" is already added.`);
                    return product;
                }

                const stockQty = product.variationValues[variation]?.productQty || 0;
                let newPurchaseQty = product.barcodeQty || 1;

                if (newPurchaseQty > stockQty) {
                    alert(`Purchase quantity adjusted to the available stock (${stockQty}) for the "${variation}" variation.`);
                    newPurchaseQty = stockQty;
                }

                return {
                    ...product,
                    selectedVariation: variation,
                    barcodeQty: newPurchaseQty
                };
            }
            return product;
        })
    );
};


// CALCULATE SINGLE & VARIATION PRODUCT QTY
export const getQty = (product, selectedVariation) => {
    // If the product has variations
    if (product.variationValues && selectedVariation) {
        const variationQty = Number(product.variationValues[selectedVariation]?.productQty);
        return !isNaN(variationQty) && variationQty > 0 ? variationQty : 0;
    }
    const singleProductQty = Number(product.productQty);
    console.log("qty check ", singleProductQty)
    return !isNaN(singleProductQty) && singleProductQty > 0 ? singleProductQty : 0;
};


// CALCULATE SINGLE & VARIATION PRODUCT PRICE
export const getPriceRange = (product, selectedVariation) => {
    if (product.variationValues) {
        // If a variation is selected, return the price of that variation
        if (selectedVariation && product.variationValues[selectedVariation]) {
            const variationPrice = Number(product.variationValues[selectedVariation].productPrice);
            return !isNaN(variationPrice) ? `${variationPrice}` : 'Price not available';
        }
        // Otherwise, return the minimum price of all variations
        const prices = Object.values(product.variationValues)
            .map(variation => Number(variation.productPrice))
            .filter(price => !isNaN(price));

        if (prices.length > 0) {
            const minPrice = Math.min(...prices);
            return minPrice;
        }
    }
    const singlePrice = Number(product.productPrice);
    return !isNaN(singlePrice) && singlePrice > 0 ? `${singlePrice}` : 'Price not available';
};

//REMOVE PRODUCT FROM LIST
export const handleDelete = (index, selectedProduct, setSelectedProduct) => {
    const updatedProducts = selectedProduct.filter((_, i) => i !== index); // Exclude the product at the given index
    setSelectedProduct(updatedProducts); // Update the state with the new product list
};

// HANDLE QUANTITY CHANGING
export const handleQtyChange = (index, selectedVariation, setSelectedProduct, change) => {
    setSelectedProduct((prevProducts) => {
        return prevProducts.map((product, i) => {
            if (i === index) { // Use the row index to find the correct product
                // For variation products
                if (product.variationValues && selectedVariation) {
                    const variation = product.variationValues[selectedVariation];
                    const currentQty = variation?.barcodeQty || 1; // Default value should be 1
                    const stockQty = variation ? variation.productQty : 1; // Stock quantity for the variation

                    // Validate stock quantity
                    if (change > 0 && currentQty + change > stockQty) {
                        alert(`Cannot exceed stock quantity of ${stockQty} for this variation.`);
                        return product; // Prevent changing if stock limit is exceeded
                    }

                    // Update both the product's barcodeQty and the specific variation's barcodeQty
                    const updatedQty = Math.max(1, currentQty + change); // Ensure the quantity doesn't go below 1

                    return {
                        ...product,
                        barcodeQty: updatedQty, // Update at the product level
                        variationValues: {
                            ...product.variationValues,
                            [selectedVariation]: {
                                ...variation,
                                barcodeQty: updatedQty // Update at the variation level
                            }
                        }
                    };
                }
                // For single products
                else {
                    const currentQty = product.barcodeQty || 1; // Default value should be 1
                    const stockQty = product.productQty || 1; // Stock quantity for the product

                    // Validate stock quantity
                    if (change > 0 && currentQty + change > stockQty) {
                        alert(`Cannot exceed stock quantity of ${stockQty} for this product.`);
                        return product; // Prevent changing if stock limit is exceeded
                    }

                    // Update the single product's quantity
                    return { ...product, barcodeQty: Math.max(1, currentQty + change) }; // Ensure quantity doesn't go below 1
                }
            }
            return product; // Return unchanged product
        });
    });
};


//GET TAX
export const getTax = (product, selectedVariation) => {
    if (product.variationValues && selectedVariation && product.variationValues[selectedVariation]) {
        const variationTax = Number(product.variationValues[selectedVariation].orderTax);
        return !isNaN(variationTax) ? variationTax : 0;
    }
    return 0;
};

//HANDLE DISCOUNT TYPE
export const handleDiscount = (e, discountType, setDiscount) => {
    if (!discountType) {
        alert('Please select a discount type first.');
        return;
    }
    const value = e.target.value;
    if (discountType === 'percentage') {
        const numericValue = parseFloat(value);
        if (numericValue < 1 || numericValue > 100) {
            alert('Please enter a percentage value between 1 and 100.');
            return;
        }
    }
    setDiscount(value);
};


//HANDLE PREVIEW
export const handlePreview = (selectedProduct, grandTotal, paymentStatus, paymentType, orderStatus, discountType, discount, shipping, setSavedProducts) => {
    if (!selectedProduct || selectedProduct.length === 0) {
        console.error('No selected products to preview.');
        return;
    }
    const productsToSave = selectedProduct.map(product => {
        const price = getPriceRange(product, product.selectedVariation);
        const quantity = product.barcodeQty || 1;
        const taxRate = product.oderTax ? product.oderTax / 100 : getTax(product, product.selectedVariation) / 100;
        const subtotal = (price * quantity) + (price * quantity * taxRate);

        return {
            name: product.name,
            quantity,
            price,
            subtotal,
            selectedVariation: product.selectedVariation,
            tax: taxRate * 100,
            discount,
            shipping,
            grandTotal: Number(grandTotal) || 0,
            pStatus: paymentStatus,
            pType: paymentType,
            productStatus: orderStatus
        };
    });
    setSavedProducts(productsToSave);
};


//HANDLE SAVE SALE
export const handleSave = async (grandTotal, orderStatus, paymentStatus, paymentType, shipping, discountType, discount, tax, warehouse, selectedCustomer, selectedProduct, date, setResponseMessage, setError) => {
    setError('')
    setResponseMessage('');
    const totalAmount = Number(grandTotal) || 0;
    const paidAmount = paymentStatus.toLowerCase() === 'paid' ? grandTotal : 0;

    let referenceId = '';
    let prefixKey;

    try {
        const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/getRefixSettings`);
        console.log(data.salePrefix);
        prefixKey = data.salePrefix;
    } catch (error) {
        console.error('Error fetching settings:', error);
    }

    if (prefixKey) {
        referenceId = `${prefixKey}_${Math.floor(1000 + Math.random() * 9000)}`;
        console.log(referenceId);
    } else {
        console.error('Prefix key is not available');
        referenceId = `SL_${Math.floor(1000 + Math.random() * 9000)}`;
    }
    if (!referenceId || typeof referenceId !== 'string' || referenceId.trim() === '') {
        throw new Error('Invalid reference ID. It must be a non-empty string.');
    }

    // Validate if `tax`, `discount`, and `shipping` contain only numbers
    const numberRegex = /^[0-9]*(\.[0-9]+)?$/; // Regex for numeric values (integer or float)
    if (!numberRegex.test(tax)) {
        setError('Tax must be a valid number');
        return;
    }
    if (!numberRegex.test(discount)) {
        setError('Discount must be a valid number');
        return;
    }
    if (!numberRegex.test(shipping)) {
        setError('Shipping must be a valid number');
        return;
    }
    if (!selectedCustomer) {
        setError('Customer information is required');
        return;
    }
    if (!date) {
        setError('Date is required');
        return;
    }
    if (!paymentStatus) {
        setError('Payment Status is required');
        return;
    }
    if (!warehouse) {
        setError('Warehouse is required');
        return;
    }

    const cashierUsername = sessionStorage.getItem('cashierUsername');

    const commonSaleData = {
        refferenceId: referenceId,
        date,
        customer: selectedCustomer ? selectedCustomer : 'Unknown',
        warehouse: warehouse ? warehouse : 'Unknown',
        tax,
        discountType: discountType ? discountType : 'fixed',
        discount,
        shipping,
        paymentStatus,
        paymentType: paymentType ? paymentType : 'cash',
        orderStatus: orderStatus ? orderStatus : 'ordered',
        paidAmount,
        grandTotal: totalAmount,
        cashierUsername: cashierUsername ? cashierUsername : 'Unknown'
    };

    // Create products data array
    const productsData = selectedProduct.map(product => {
        const currentID = product._id;
        const ptype = product.ptype;
        const variationValue = product.selectedVariation;
        const price = product.price ? product.price : getPriceRange(product, product.selectedVariation);
        const quantity = product.barcodeQty ? product.barcodeQty : 1;
        const taxRate = product.oderTax ? product.oderTax / 100 : getTax(product, product.selectedVariation) / 100;
        const subtotal = (price * quantity) + (price * quantity * taxRate);

        return {
            currentID,
            ptype,
            variationValue: variationValue ? variationValue : 'No variations',
            name: product.name,
            price,
            quantity,
            taxRate,
            subtotal,
        };
    });

    // Combine common sale data with products data
    const finalSaleData = {
        ...commonSaleData,
        productsData,
    };

    console.log('Final Sale Data:', finalSaleData);
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/createSale`, finalSaleData);
        console.log('Response:', response.data.message);
        setResponseMessage(response.data.message);
    } catch (error) {
        console.error('Error creating sale:', error);
        if (error.response) {
            console.error('Error details:', error.response.data);
            setError(error.response.data.message || 'An error occurred on the server');
        } else if (error.request) {
            console.error('No response received:', error.request);
            setError('No response received from server. Please try again later.');
        } else {
            console.error('Request setup error:', error.message);
            setError(error.message || 'An unexpected error occurred.');
        }
    }
};

//HANDLE UPDATE SALE
export const handleUpdateSale = async (
    id, grandTotal, orderStatus, paymentStatus, PaidAmount, paymentType, shipping,
    discountType, discount, tax, warehouse, selectedCustomer,
    productData, date, setError, setResponseMessage
) => {
    setError('')
    setResponseMessage('');
    console.log('saleReturnData:', productData);

    if (!Array.isArray(productData)) {
        setError('Invalid sale return data format. Expected an array.');
        return;
    }

    const totalAmount = Number(grandTotal) || 0;
    const paidAmount =
        paymentStatus.toLowerCase() === 'paid' ? grandTotal
            : paymentStatus.toLowerCase() === 'unpaid' ? 0
                : paymentStatus.toLowerCase() === 'partial' ? PaidAmount : 0;

    const cashierUsername = sessionStorage.getItem('cashierUsername');

    const numberRegex = /^[0-9]*(\.[0-9]+)?$/; // Regex for numeric values (integer or float)
    if (!numberRegex.test(tax)) {
        setError('Tax must be a valid number');
        return;
    }
    if (!numberRegex.test(discount)) {
        setError('Discount must be a valid number');
        return;
    }
    if (!numberRegex.test(shipping)) {
        setError('Shipping must be a valid number');
        return;
    }
    if (!date) {
        setError('Date is required');
        return;
    }
    if (!paymentStatus) {
        setError('Payment Status is required');
        return;
    }
    if (!warehouse) {
        setError('Warehouse is required');
        return;
    }

    const commonSaleData = {
        date,
        selectedCustomer,
        warehouse: warehouse || null,
        tax,
        discountType,
        discount,
        shipping,
        paymentStatus,
        paymentType,
        orderStatus,
        paidAmount,
        grandTotal: totalAmount,
        cashierUsername: cashierUsername ? cashierUsername : 'unknown'
    };

    // Create products data array
    const productsData = productData.map(product => {
        const currentID = product.currentID;
        const ptype = product.ptype;
        const variationValue = product.variationValue;
        const price = product.price;
        const quantity = product.quantity || 1;
        const taxRate = product.taxRate
        const subtotal = product.subtotal;

        return {
            currentID,
            ptype,
            variationValue : variationValue ? variationValue : 'No variations',
            name: product.name,
            price,
            quantity,
            taxRate,
            subtotal,
        };
    });

    const updatedSaleData = {
        ...commonSaleData,
        productsData,
    };

    console.log('Updated Sale Data:', updatedSaleData);
    try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/updateSale/${id}`, updatedSaleData);
        console.log('Response:', response.data.message);
        setResponseMessage(response.data.message);
    } catch (error) {
        console.error('Error updating sale:', error);
        if (error.response) {
            console.error('Error details:', error.response.data);
            setError(error.response.data.message || 'An error occurred on the server');
        } else if (error.request) {
            console.error('No response received:', error.request);
            setError('No response received from server. Please try again later.');
        } else {
            console.error('Request setup error:', error.message);
            setError(error.message || 'An unexpected error occurred.');
        }
    }
};

//HANDLE THE RETURN OF SALE
export const handleReturnSale = async (grandTotal, orderStatus, paymentStatus, paymentType, paidAmount, shipping, discountType, discount, tax, warehouse, customer, selectedProduct, date, setResponseMessage) => {
    let referenceId = '';
    let prefixKey;
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/getRefixSettings`);
        console.log(data.saleReturnPrefix);
        prefixKey = data.saleReturnPrefix;
    } catch (error) {
        console.error('Error fetching settings:', error);
    }

    if (prefixKey) {
        referenceId = `${prefixKey}_${Math.floor(1000 + Math.random() * 9000)}`;
        console.log(referenceId);
    } else {
        console.error('Prefix key is not available');
        referenceId = `SL_${Math.floor(1000 + Math.random() * 9000)}`;
    }

    const commonSaleData = {
        refferenceId: referenceId,
        date,
        customer,
        warehouse: warehouse || null,
        tax,
        discountType,
        discount,
        shipping,
        paymentStatus,
        paymentType,
        orderStatus,
        grandTotal,
        paidAmount,
    };
    // Create products data array
    const productsData = selectedProduct.map(product => {
        const currentID = product.currentID;
        const ptype = product.ptype;
        const variationValue = product.variationValue;
        const price = product.price;
        const quantity = product.quantity;
        const taxRate = product.taxRate * 100;
        const subtotal = product.subtotal;

        return {
            currentID,
            variationValue,
            ptype,
            name: product.name,
            price,
            quantity,
            taxRate,
            subtotal,
        };
    });

    // Combine common sale data with products data
    const finalSaleData = {
        ...commonSaleData,
        productsData,
    };

    console.log('Final Sale Data:', finalSaleData);
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/returnSale`, finalSaleData);
        console.log('Response:', response.data);
        //setError('');
        setResponseMessage(response.data.message || 'Sale returned successfully!');
    } catch (error) {
        console.error('Error creating sale:', error);
        //setError(error.response?.data?.message || 'Failed to return sale');
        setResponseMessage('');
    }

}

//HANDLE UPDATE SALE RETURN
export const handleUpdateSaleReturn = async (
    id, grandTotal, orderStatus, paymentStatus, PaidAmount, paymentType, shipping,
    discountType, discount, tax, warehouse, selectedCustomer,
    productData, date, setError, setResponseMessage
) => {
    setError('')
    setResponseMessage('');
    console.log('saleReturnData:', productData);

    if (!Array.isArray(productData)) {
        setError('Invalid sale return data format. Expected an array.');
        return;
    }

    const totalAmount = Number(grandTotal) || 0;
    const paidAmount =
        paymentStatus.toLowerCase() === 'paid' ? grandTotal
            : paymentStatus.toLowerCase() === 'unpaid' ? 0
                : paymentStatus.toLowerCase() === 'partial' ? PaidAmount : 0;

    const commonSaleData = {
        date,
        selectedCustomer,
        warehouse: warehouse || null,
        tax,
        discountType,
        discount,
        shipping,
        paymentStatus,
        paymentType,
        orderStatus,
        paidAmount,
        grandTotal: totalAmount,
    };

    // Create products data array
    const productsData = productData.map(product => {
        const currentID = product.currentID;
        const ptype = product.ptype;
        const variationValue = product.variationValue;
        const price = product.price;
        const quantity = product.quantity || 1;
        const taxRate = product.taxRate
        const subtotal = product.subtotal;

        return {
            currentID,
            ptype,
            variationValue,
            name: product.name,
            price,
            quantity,
            taxRate,
            subtotal,
        };
    });

    const updatedSaleReturnData = {
        ...commonSaleData,
        productsData,
    };

    console.log('Updated Return Data:', updatedSaleReturnData);
    try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/updateReturnSale/${id}`, updatedSaleReturnData);
        console.log('Response:', response.data);
        // setError('');
        setResponseMessage([response.data.message]);
    } catch (error) {
        console.error('Error creating sale:', error);

    }
};
