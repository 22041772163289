import { useState, useEffect } from 'react';
import PayingSection from "./payingSection";
import delSound from '../../../../src/audio/delet pop.mp3';
import axios from 'axios';

const BillingSection = ({ productBillingHandling, setProductBillingHandling, setProductData, selectedCustomer, setSelectedCustomer, warehouse }) => {
    const [productDetailsForPrinting, setProductDetailsForPrinting] = useState([]);
    const [productDetailsForHolding, setProductDetailsForHolding] = useState([]);
    const [refferenceNumber, setRefferenceNumber] = useState([])
    const [showPayingSec, setShowPayingSection] = useState(false)
    const [showProductHolding, setShowProductHolding] = useState(false)
    const [discountType, setDiscountType] = useState('');
    const [discountSymbole, setDiscountSymbole] = useState('Rs');
    const [discount, setDiscount] = useState('')
    const [shipping, setShipping] = useState('')
    const [tax, setTax] = useState('')
    const [responseMessage, setResponseMessage] = useState('')

    const handleIncrement = (index) => {
        setProductBillingHandling((prev) => {
            const product = prev[index];
            const variation = product.selectedVariation
                ? product.variationValues[product.selectedVariation]
                : null;
            const availableStock = variation ? variation.productQty : product.stokeQty;

            if (product.qty >= availableStock) {
                alert(`Cannot increase more, only ${availableStock} in stock.`);
                return prev;
            }

            return prev.map((p, i) => (i === index ? { ...p, qty: p.qty + 1 } : p));
        });
    };

    const handleQtyChange = (e, index) => {
        const inputValue = e.target.value;
        const newQty = Number(inputValue);
        const product = productBillingHandling[index];
        const variation = product.selectedVariation
            ? product.variationValues[product.selectedVariation]
            : null;
        const availableStock = variation ? variation.productQty : product.stokeQty;

        if (inputValue === "") {
            setProductBillingHandling((prev) =>
                prev.map((p, i) => (i === index ? { ...p, qty: "" } : p))
            );
            return;
        }
        if (isNaN(newQty) || newQty < 1) {
            alert("Quantity must be at least 1.");
            return;
        }
        if (newQty > availableStock) {
            alert(`Cannot enter more than ${availableStock} in stock.`);
            return;
        }

        setProductBillingHandling((prev) =>
            prev.map((p, i) => (i === index ? { ...p, qty: newQty } : p))
        );
    };

    // useEffect for validating quantities when loading held products for editing
    useEffect(() => {
        // Adjust quantities when loading the hold product for editing
        const adjustQuantitiesForStock = () => {
            setProductBillingHandling((prevProducts) =>
                prevProducts.map((product) => {
                    const variation = product.selectedVariation
                        ? product.variationValues[product.selectedVariation]
                        : null;
                    const availableStock = variation ? variation.productQty : product.stokeQty;

                    // Check if the saved quantity exceeds the available stock
                    if (product.qty > availableStock) {
                        alert(
                            `Quantity for "${product.name}" adjusted to available stock (${availableStock}).`
                        );
                        return { ...product, qty: availableStock };
                    }
                    return product;
                })
            );
        };

        adjustQuantitiesForStock();
    }, []); // Runs whenever heldProducts are loaded or changed productBillingHandling


    // Handle decrementing the quantity of a product or its variation
    const handleDecrement = (index) => {
        setProductBillingHandling((prev) =>
            prev.map((product, i) => {
                if (i === index && product.qty > 1) {
                    return { ...product, qty: product.qty - 1 };
                }
                return product;
            })
        );
    };

    // Handle deleting a product or its variation from the list
    const handleDelete = (index) => {
        setProductBillingHandling((prev) => prev.filter((_, i) => i !== index));
    };

    //calculating total price
    const calculateTotalPrice = () => {
        // Calculate total without discount, tax, and shipping
        let total = productBillingHandling
            .filter(product => product.ptype !== 'Base') // Exclude base products
            .reduce((acc, product) => acc + ((product.price * product.qty) + ((product.price * product.qty * product.tax)/2)), 0);

        // Apply discount if applicable
        let discountAmount = 0;
        if (discountType === 'fixed') {
            discountAmount = parseFloat(discount) || 0; // Fixed discount
        } else if (discountType === 'percentage') {
            discountAmount = (total * (parseFloat(discount) || 0) / 100); // Percentage discount
        }
        // Apply tax if applicable
        const taxAmount = (total * (parseFloat(tax) || 0) / 100); // Tax as percentage

        // Add shipping cost
        const shippingCost = parseFloat(shipping) || 0; // Fixed shipping cost

        // Calculate final total
        total = total - discountAmount + taxAmount + shippingCost;
        return total.toFixed(2); // Return total formatted to 2 decimal places
    };

    // Calculate the total quantity excluding base products, only considering variations
    const calculateTotalQty = () => {
        return productBillingHandling
            .filter(product => product.ptype !== 'Base') // Exclude base products
            .reduce((acc, product) => acc + product.qty, 0);
    };

    // Reset the billing section (clear the cart)
    const handleBillReset = () => {
        setProductBillingHandling([]);
        setDiscount('');
        setShipping('');
        setTax('');
        setSelectedCustomer('')
    };

    // Close the popup modal
    const handlePopupClose = () => {
        setShowPayingSection(false);
        setShowProductHolding(false)
    };

    // Play the delete sound effect
    const playSound = () => {
        const audio = new Audio(delSound);
        audio.play().catch((error) => console.error('Audio play failed:', error));
    };

    const handleDiscountType = (e) => {
        setDiscountType(e.target.value)
    }
    useEffect(() => {
        if (discountType === 'fixed') {
            return setDiscountSymbole('Rs');
        }
        if (discountType === 'percentage') {
            return setDiscountSymbole('%');
        }
    }, [discountType]);

    const handleDiscount = (e) => {
        if (!discountType) {
            alert('Please select a discount type first.');
            return;
        }
        const value = e.target.value;
        if (discountType === 'percentage') {
            const numericValue = parseFloat(value);
            if (numericValue < 1 || numericValue > 100) {
                alert('Please enter a percentage value between 1 and 100.');
                return;
            }
        }

        setDiscount(value);
    };

    const handleTax = (e) => {
        setTax(e.target.value)
    }
    const handleShippng = (e) => {
        setShipping(e.target.value)
    }
    const gatherProductDetails = () => {
        console.log(productBillingHandling)
        return productBillingHandling
            .filter(product => product.ptype !== 'Base') // Exclude base products
            .map(product => ({
                currentID: product.id,
                name: product.name,
                ptype: product.ptype,
                variation: product.selectedVariation ? product.selectedVariation : null,
                qty: product.qty,
                tax: product.tax,
                price: product.price,
                subTotal: (product.price * product.qty).toFixed(2)
            }));
    };

    const handleHoldingProduct = async () => {
        sessionStorage.setItem('heldProducts', JSON.stringify(productDetailsForHolding));
        console.log("For holding ", productDetailsForHolding)
        if (refferenceNumber) {
            const dataToSend = {
                referenceNo: refferenceNumber, // Reference number
                products: productDetailsForHolding // The array of held products
            };
            console.log('datasss',dataToSend)
            try {
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/holdProducts`, dataToSend);
                console.log(response)
                handleBillReset();
                window.location.reload();
                setShowProductHolding(false);
            } catch (error) {
                console.error('Error saving held products:', error);
            }
        } else {
            alert('Reference Number is Required');
        }
    };

    const handleDeleteHoldProduct = async (id) => {
        try {
            // Send DELETE request to delete the product
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/deleteHeldProduct/${id}`);
        } catch (error) {
            console.error('Error deleting held product:', error);
        }
    };

    return (
        <div>
            <div className='flex justify-between'>
                <h2 className="text-lg font-semibold mb-4 text-gray-500"> {new Date().toLocaleDateString('en-GB')} - {new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</h2>
                <h2 className="text-lg font-semibold mb-4 text-gray-500">{selectedCustomer}</h2>
            </div>

            <div style={{ minHeight: '260px' }}>
                <div className="overflow-auto" style={{ maxHeight: '260px' }}>
                    <table className="min-w-full table-auto">
                        <thead>
                            <tr>
                                <th className="px-4 py-2 text-left text-gray-500 text-base">Product Name</th>
                                <th className="px-4 py-2 text-left text-gray-500 text-base">Quantity</th>
                                <th className="px-4 py-2 text-left text-gray-500 text-base">Price</th>
                                <th className="px-4 py-2 text-left text-gray-500 text-base">Sub Total</th>
                                <th className="px-2 py-2 text-left text-gray-500 text-base text-right">#</th>
                            </tr>
                        </thead>
                        {productBillingHandling.length === 0 ? (
                            <div className="text-center">
                                <p className="text-left pl-4">No products</p>
                            </div>
                        ) : (
                            <tbody>
                                {productBillingHandling.length === 0 ? (
                                    <tr>
                                        <td className="text-center" colSpan="5">
                                            No products selected yet.
                                        </td>
                                    </tr>
                                ) : (
                                    productBillingHandling.map((product, index) => (
                                        <tr key={index} className="border-t">
                                            <td className="px-4 py-2 text-sm font-medium">
                                                {product.name}
                                                {/* Show variation info if the product is a Variation type */}
                                                {product.selectedVariation && (
                                                    <span className="text-gray-500 text-xs ml-1">
                                                        ({product.selectedVariation})
                                                    </span>

                                                )}
                                                {/* Edit Button */}
                                                <button>
                                                    <img
                                                        className="mt-[2px] ml-2 w-[15px] h-[15px]"
                                                        src="https://static-00.iconduck.com/assets.00/edit-icon-512x490-oaajgjo6.png"
                                                        alt="edit"
                                                    />
                                                </button>
                                            </td>

                                            {/* Quantity Control Section */}
                                            <td className="px-4 py-2 text-sm flex items-center">
                                                <button
                                                    onClick={() => handleDecrement(index)}
                                                    className={`px-2 py-1 rounded-md bg-gray-200 text-gray-600`}
                                                >
                                                    -
                                                </button>
                                                <input
                                                    className="w-[30px] text-center mx-2"
                                                    value={product.qty || 1}
                                                    onChange={(e) => handleQtyChange(e, index)}
                                                />
                                                <button
                                                    onClick={() => handleIncrement(index)}
                                                    className={`px-2 py-1 rounded-md bg-gray-200 text-gray-600`}
                                                >
                                                    +
                                                </button>
                                            </td>

                                            {/* Product Price */}
                                            <td className="px-4 py-2 text-sm text-gray-600">
                                                Rs {product.price}
                                            </td>

                                            {/* Total Price = price * qty */}
                                            <td className="px-4 py-2 text-sm text-gray-600">
                                                Rs {(
                                                    (product.price * product.qty) +
                                                    ((product.price * product.qty * (product.tax ? product.tax : 0))/2) // Convert percentage to fraction
                                                ).toFixed(2)}
                                            </td>

                                            {/* Delete Button */}
                                            <td className="px-2 py-2 text-sm text-gray-600">
                                                <button
                                                    onClick={() => {
                                                        playSound();
                                                        handleDelete(index);
                                                    }}
                                                    className="text-red-500 hover:text-red-700"
                                                >
                                                    <i className="fas fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        )}
                    </table>
                </div>
            </div>

            <div className="mt-10">
                <div className="px-4 py-2 text-left text-gray-500 text-base text-xl text-right">
                    <h1>Total Quantity: {calculateTotalQty()}</h1>
                </div>
                <div className="px-4 py-2 text-left text-gray-800 text-base text-right">
                    <h1 className="text-3xl">Total : Rs {calculateTotalPrice()}</h1>
                </div>
            </div>

            {/* Container for Discount, Shipping, and Tax Inputs */}
            <div className='fixed bottom-10 w-[32.5%]'>
                <div className="flex gap-4 px-4 py-1 mt-4">
                    <select
                        onChange={handleDiscountType}
                        className="w-[143px] bg-black bg-opacity-[1%] rounded-md border border-gray-300 py-2 px-3 text-gray-900 shadow-sm focus:ring-gray-400 focus:border-gray-400 sm:text-sm"
                    >
                        <option value=''>Discount type</option>
                        <option value='fixed'>Fixed</option>
                        <option value='percentage'>Percentage</option>
                    </select>
                </div>

                <div className='flex gap-4 px-4 py-1 mt-2'>
                    <div className="relative w-[160px]">
                        <input
                            onChange={handleDiscount}
                            value={discount}
                            type="text"
                            placeholder="Discount"
                            className="w-full  bg-black bg-opacity-[1%] rounded-md border border-gray-300 py-2 px-3 pr-10 text-gray-900 shadow-sm focus:ring-gray-400 focus:border-gray-400 sm:text-sm"
                        />
                        <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">
                            {discountSymbole}
                        </span>
                    </div>
                    <div className="relative w-[160px]">
                        <input
                            onChange={handleTax}
                            value={tax}
                            type="text"
                            placeholder="Tax"
                            className="w-full bg-black bg-opacity-[1%] rounded-md border border-gray-300 py-2 px-3 pr-10 text-gray-900 shadow-sm focus:ring-gray-400 focus:border-gray-400 sm:text-sm"
                        />
                        <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">
                            %
                        </span>
                    </div>

                    <div className="relative w-[160px]">
                        <input
                            onChange={handleShippng}
                            value={shipping}
                            type="text"
                            placeholder="Shipping"
                            className="w-full bg-black bg-opacity-[1%] rounded-md border border-gray-300 py-2 px-3 pr-10 text-gray-900 shadow-sm focus:ring-gray-400 focus:border-gray-400 sm:text-sm"
                        />
                        <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">
                            Rs
                        </span>
                    </div>
                </div>

                {/* Buttons Section */}
                <div className="flex gap-4 px-4 py-1 mt-2">
                    <button
                        onClick={handleBillReset}
                        className="bg-gray-800 w-[160px] rounded-md px-4 py-3 text-white font-semibold text-sm shadow-md focus:outline-none"
                    >
                        Reset
                    </button>
                    <button
                        onClick={() => {
                            setShowProductHolding(true);
                            const ProductHoldList = gatherProductDetails();
                            setProductDetailsForHolding(ProductHoldList);
                        }}
                        className="bg-blue-600 w-[160px] rounded-md px-4 py-3 text-white font-semibold text-sm shadow-md focus:outline-none"
                    >
                        Hold
                    </button>
                    <button
                        onClick={() => {
                            setShowPayingSection(true);
                            const productDetails = gatherProductDetails();
                            setProductDetailsForPrinting(productDetails);
                            handleDeleteHoldProduct();
                        }}
                        className="bg-green-600  w-[160px] rounded-md px-4 py-3 text-white font-semibold text-sm shadow-md focus:outline-none"
                    >
                        Pay Now
                    </button>
                </div>
            </div>

            {/* PAYING SECTION */}
            <div>
                {showPayingSec && (
                    <PayingSection
                        handlePopupClose={handlePopupClose}
                        calculateTotalPrice={calculateTotalPrice}
                        calculateTotalQty={calculateTotalQty}
                        tax={tax}
                        shipping={shipping}
                        discount={discount}
                        productDetails={productDetailsForPrinting}
                        handleBillReset={handleBillReset}
                        setProductData={setProductData}
                        selectedCustomer={selectedCustomer}
                        discountType={discountType}
                        warehouse={warehouse}
                        responseMessage={responseMessage}
                        setResponseMessage={setResponseMessage}
                    />
                )}
            </div>

            {/*PRODUCT HOLDING POP UP*/}
            <div>
                {showProductHolding && productDetailsForHolding && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                        <div className="bg-white w-[600px] h-[700px] p-6 rounded-md shadow-lg z-50">
                            <h1 className='className="text-lg font-semibold'>Hold this product in the list</h1>
                            <div className='mt-5'>
                                <label className="block text-sm font-medium leading-6 text-gray-900">Add a Refference number</label>
                                <input
                                    onChange={(e) => { setRefferenceNumber(e.target.value) }}
                                    type="text"
                                    required
                                    placeholder="Refference number"
                                    className="block w-full mb-10 mt-2 rounded-md border-0 py-2.5 px-2 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm"
                                />
                            </div>
                            <table className="w-full table-auto border-collapse border border-gray-300">
                                <thead>
                                    <tr className="bg-gray-100 border-b">
                                        <th className="px-4 py-2 border">Product Name</th>
                                        <th className="px-4 py-2 border">Quantity</th>
                                        <th className="px-4 py-2 border">Price</th>
                                        <th className="px-4 py-2 border">Sub Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productDetailsForHolding.map((product, index) => {
                                        const price = parseFloat(product.price) || 0;
                                        const qty = parseInt(product.qty, 10) || 0;
                                        return (
                                            <tr key={index} className="border-b">
                                                <td className="px-4 py-2 border">{product.name}</td>
                                                <td className="px-4 py-2 border">{qty}</td>
                                                <td className="px-4 py-2 border">Rs {price.toFixed(2)}</td>
                                                <td className="px-4 py-2 border">Rs {(price * qty).toFixed(2)}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>

                            <div className='flex  mt-5'>
                                <button
                                    className="px-4 py-2  bg-gray-500 text-white rounded-md"
                                    onClick={handlePopupClose}
                                    type="button"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleHoldingProduct}
                                    className="submit ml-2 rounded-md px-2 py-2 h-[41px] text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-[140px] text-center"
                                >
                                    Hold
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
export default BillingSection;
