import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, BellIcon } from '@heroicons/react/24/outline';
import React, { Fragment, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import avatar from '../../img/avatar.png';
import exit from '../../img/exit.png';
import padlock from '../../img/padlock.png';
import { GlobeAltIcon } from '@heroicons/react/24/outline';
import { handleFullScreen } from '../pos/utils/fullScreenView';
import Full from '../../img/full-screen.png';
import PosIcon from '../../img/payment-terminal.png';
import axios from 'axios';

const navigation = [
    { name: 'Warehouse Report', href: '/ViewReport' },
    { name: 'Stock Report', href: '/ViewStokeRep' },
    { name: 'Customer Report', href: '/customerReport' },
    { name: 'Supplier Report', href: '/suplierReport' },
    { name: 'Register Report', href: '/viewRegisterRep' },
    { name: 'Quantity Report', href: '/quantityAlertRep' },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function ReportHeader({ userData }) {
    const [formData, setFormData] = useState({
        username: '',
        firstName: '',
        lastName: '',
        profileImage: '',
        mobile: '',
    });
    const [showModal, setShowModal] = useState(false);
    const [cashInHand, setCashInHand] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const savedUser = sessionStorage.getItem('user');
        if (savedUser) {
            try {
                setFormData(JSON.parse(savedUser));
            } catch (error) {
                console.error('Error parsing saved user data:', error);
            }
        }
    }, []);

    const handleLogout = () => {
        sessionStorage.clear();
        navigate('/');
    };

    const handleCashInHandChange = (e) => {
        setCashInHand(e.target.value);
        setError('');
    };

    const handlePOSClick = () => {
        const cashRegisterID = sessionStorage.getItem('cashRegisterID');
        if (!cashRegisterID) {
            setShowModal(true);
        } else {
            navigate('/posSystem');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const cashAmount = parseFloat(cashInHand);
        if (cashAmount >= 500) {
            const newCash = { cashAmount };
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/api/cashHandIn`,
                    newCash
                );
                if (response.data && response.data.cash) {
                    const cashID = response.data.cash._id;
                    sessionStorage.setItem('cashRegisterID', cashID);
                    setShowModal(false);
                    navigate('/posSystem');
                }
            } catch (error) {
                alert('Error submitting cash in hand. Please try again.');
            }
        } else {
            setError('Cash in hand must be more than 500 rupees.');
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
        setCashInHand('');
    };

    const linkClasses = (path) =>
        `block text-gray-500 hover:text-gray-800 px-3 py-2 rounded-md ${location.pathname === path ? 'text-gray-800 ' : ''
        }`;

    const defaultAvatar = 'https://jingslearning.com/media/images/login-user-head.png';

    return (
        <div className="navSec">
            <Disclosure as="nav" className="bg-white shadow h-20 fixed top-0 left-0 right-0 z-50">
                {({ open }) => (
                    <>
                        <div className="flex h-full items-center justify-between px-4 md:px-8">
                            <div className="flex items-center">
                                <img
                                    className="h-10 w-auto"
                                    src="https://infypos-demo.nyc3.digitaloceanspaces.com/settings/337/logo-80.png"
                                    alt="Your Company Logo"
                                />
                            </div>
                            <div className="hidden md:flex space-x-4">
                                {navigation.map((item) => (
                                    <Link
                                        key={item.name}
                                        to={item.href}
                                        className={linkClasses(item.href)}
                                    >
                                        {item.name}
                                    </Link>
                                ))}
                            </div>
                            <div className="flex items-center space-x-4">
                                <div className="p-2 m-2 w-[40px] h-[40px] mr-5  border bg-[#37b34a] hover:bg-[#2a9d34] rounded-[14px] flex items-center justify-center">
                                    <button className='' onClick={handleFullScreen}>
                                        <img className="w-[25px] h-[23px]" src={Full} alt="" />
                                    </button>
                                </div>

                                <button
                                    className="submit flex items-center text-center rounded-full px-5 py-3 pt-[7px] h-10 mr-5 text-sm font-semibold text-white shadow-lg hover:shadow-xl focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-[96px] text-center"
                                    onClick={handlePOSClick}
                                >
                                    <img className="w-[26px] h-[26px] ml-[6px] mr-[2px]" src={PosIcon} alt="pos" />
                                    POS
                                </button>
                                <button className="text-gray-500 hover:text-gray-700">
                                    <BellIcon className="h-6 w-6" />
                                </button>
                                <Menu as="div" className=" ml-3">
                                    <Menu.Button>
                                        <img
                                            className="h-10 w-10 rounded-full"
                                            src={formData.profileImage || defaultAvatar}
                                            alt="User Avatar"
                                            style={{ width: '40px', height: '40px' }}
                                        />
                                    </Menu.Button>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-72 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="flex items-center justify-center h-20">
                                                <img
                                                    className="h-11 w-11 rounded-full"
                                                    alt="Profile"
                                                    src={formData.profileImage || defaultAvatar}
                                                    onError={(e) => { e.target.src = defaultAvatar; }}
                                                />
                                            </div>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link
                                                        to={`/profile`}
                                                        className={classNames(
                                                            active ? 'bg-gray-100' : '',
                                                            'block w-full px-6 py-4 text-sm text-black flex items-center'
                                                        )}
                                                    >
                                                        <img src={avatar} className="h-5 w-5" alt="Profile Icon" aria-hidden="true" />
                                                        <span className="ml-3">Your Profile</span>
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link
                                                        to={'/forgetpassword'}
                                                        className={classNames(
                                                            active ? 'bg-gray-100' : '',
                                                            'block w-full px-6 py-4 text-sm text-black flex items-center'
                                                        )}
                                                    >
                                                        <img src={padlock} className="h-5 w-5" alt="Padlock Icon" aria-hidden="true" />
                                                        <span className="ml-3">Change Password</span>
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link
                                                        to={'/'}
                                                        className={classNames(
                                                            active ? 'bg-gray-100' : '',
                                                            'block w-full px-6 py-4 text-sm text-black flex items-center'
                                                        )}
                                                    >
                                                        <GlobeAltIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                                        <span className="ml-3">Change Language</span>
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                        onClick={handleLogout}
                                                        className={classNames(
                                                            active ? 'bg-gray-100' : '',
                                                            'block w-full px-6 py-4 text-sm text-black flex items-center'
                                                        )}
                                                    >
                                                        <img src={exit} className="h-5 w-5" alt="Exit Icon" aria-hidden="true" />
                                                        <span className="ml-3">Sign Out</span>
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                        <Disclosure.Panel className="md:hidden">
                            <div className="space-y-1 px-2 pt-2 pb-3">
                                {navigation.map((item) => (
                                    <Disclosure.Button
                                        key={item.name}
                                        as={Link}
                                        to={item.href}
                                        className={linkClasses(item.href)}
                                    >
                                        {item.name}
                                    </Disclosure.Button>
                                ))}
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
            {/* Modal for entering Cash In Hand */}
            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white w-[400px] h-[240px] p-6 rounded-md shadow-lg">
                        <h2 className="text-lg font-semibold mb-4">Enter Cash In Hand</h2>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="number"
                                className="border border-gray-300 p-2 rounded w-full mb-4"
                                placeholder="Cash In Hand"
                                value={cashInHand}
                                onChange={handleCashInHandChange}
                            />
                            {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
                            <div className="flex justify-end mt-10">
                                <button
                                    type='submit'
                                    className="submit w-[100px] text-white px-4 py-2 rounded mr-2"
                                >
                                    Submit
                                </button>
                                <button
                                    type="button"
                                    className="bg-gray-300 px-4 py-2 rounded"
                                    onClick={handleModalClose}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}
