import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

function ViewBaseUnitBody() {
    const [baseunitData, setBaseUnitData] = useState([]);
    const [BaseUnitName, setKeyword] = useState('');
    const [searchedBaseUnits, setSearchedBaseUnits] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    // Fetch all base unit data
    useEffect(() => {
        const fetchBaseUnitData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/allBaseUnit`);
                if (response.data && response.data.baseUnits && Array.isArray(response.data.baseUnits)) {
                    setBaseUnitData(response.data.baseUnits);
                } else {
                    setBaseUnitData([]);
                }
            } catch (error) {
                console.error('Fetch base unit data error:', error);
                setBaseUnitData([]);
                setError('Failed to load baseunits. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchBaseUnitData();
    }, []);

    // Handle delete base unit
    const handleDelete = async (_id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/deleteBaseUnit/${_id}`);
            setBaseUnitData(baseunitData.filter(baseUnit => baseUnit._id !== _id));
            setSearchedBaseUnits(searchedBaseUnits.filter(baseUnit => baseUnit._id !== _id));
        } catch (error) {
            console.error('Delete base unit error:', error);
            setError('Error deleting baseunit.');
        }
    };

    // Handle search input change
    const handleFindBaseUnit = (event) => {
        setKeyword(event.target.value);
    };

    // Perform the search
    const performSearch = async (e) => {
        e.preventDefault();
        setLoading(true);
        // Validate BaseUnitName
    if (BaseUnitName && /^[a-zA-Z]+$/.test(BaseUnitName)) {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/fetchBaseUnits`, { BaseUnitName });
            setSearchedBaseUnits(response.data.baseUnits || []);
        } catch (error) {
            console.error('Find base unit error:', error);
            setError('Error finding baseunit');
        } finally {
            setLoading(false);
        }
    } else {
        console.log('Please enter a valid BaseUnitName containing only alphabetic characters.');
        setSearchedBaseUnits([]);
        setLoading(false);
    }
    };

    return (
        <div className='relative bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] h-[100vh] p-5'>
            <div className='flex justify-between mb-4'>
                <div className="relative w-full max-w-md">
                    <form className="flex items-center" onSubmit={performSearch}>
                        <input
                            onChange={handleFindBaseUnit}
                            name='keyword'
                            type="text"
                            placeholder="Search..."
                            className="searchBox w-80 pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                        />
                        <button type="submit" className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-400">
                            <svg
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M9 3a6 6 0 100 12A6 6 0 009 3zm0-1a7 7 0 110 14A7 7 0 019 2z"
                                    clipRule="evenodd"
                                />
                                <path
                                    fillRule="evenodd"
                                    d="M12.9 12.9a1 1 0 011.41 0l3 3a1 1 0 01-1.41 1.41l-3-3a1 1 0 010-1.41z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </form>
                </div>
                <div className="flex items-center">
                    <div>
                        <Link
                            to={'/createBaseUnit'}
                            className="submit flex-none rounded-md px-4 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-60 text-center"
                        >
                            Create Base Unit
                        </Link>
                    </div>
                </div>
            </div>

            {loading ? (
                <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                    <LinearProgress />
                </Box>
            ) : (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Base unit</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {(searchedBaseUnits.length > 0 ? searchedBaseUnits : baseunitData).map((baseUnit) => (
                                <tr key={baseUnit._id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-left">{baseUnit.BaseUnitName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-left">{baseUnit.description}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-m text-gray-900 text-right">
                                        <div className='flex items-center justify-end'>
                                            <Link to={`/editBaseUnit/${baseUnit._id}`}
                                                className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2 flex items-center"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-edit mr-1"></i>
                                            </Link>
                                            <button
                                                onClick={() => handleDelete(baseUnit._id)}
                                                className="text-red-500 hover:text-red-700 font-bold py-1 px-2"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-trash"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            {/* Error and Response Messages */}
            <div className="absolute bottom-28 right-0 left-0">
                    {error && (
                        <p className="text-red-600 px-5 py-2 rounded-md bg-red-100 mt-5 text-center inline-block">
                            {error}
                        </p>
                    )}
                    {responseMessage && (
                        <p className="text-green-600 px-5 py-2 rounded-md bg-green-100 mt-5 text-center inline-block">
                            {responseMessage}
                        </p>
                    )}
                </div>
        </div>
    );
}

export default ViewBaseUnitBody;
