import React, { useState } from 'react';
import popSound from '../../../../src/audio/b.mp3'; // Import the pop sound

const Calculator = () => {
    const [input, setInput] = useState('');
    const [result, setResult] = useState('');

    // Function to play the pop sound
    const playPopSound = () => {
        const sound = new Audio(popSound);
        sound.play();
    };

    const handleClick = (value) => {
        playPopSound(); // Play pop sound on button click
        setInput(input + value);
    };

    const handleClear = () => {
        playPopSound(); // Play pop sound on button click
        setInput('');
        setResult('');
    };

    const handleCalculate = () => {
        playPopSound(); // Play pop sound on button click
        try {
            setResult(eval(input)); // Be careful with eval, use it cautiously
        } catch (error) {
            setResult('Error');
        }
    };

    return (
        <div className="flex flex-col items-center justify-center w-[380px] h-[580px] mx-auto border border-gray-300 rounded-lg p-5 pb-20 shadow-[10px_10px_20px_rgba(0,0,0,0.2),-8px_0_15px_rgba(0,0,0,0.2)] bg-white">
            {/* Fixed height for display section */}
            <div className="mb-5 w-full mr-[31px] text-right text-2xl h-[70px] overflow-hidden">
                <div className="text-gray-800 break-words">{input}</div>
                <div className="text-[#37b34a]">{result}</div>
            </div>
            
            {/* Buttons section stays fixed */}
            <div className="grid grid-cols-4 gap-5">
                <button onClick={() => handleClick('1')} className="p-6 text-lg rounded-md bg-gray-100 hover:bg-gray-300">1</button>
                <button onClick={() => handleClick('2')} className="p-6 text-lg rounded-md bg-gray-100 hover:bg-gray-300">2</button>
                <button onClick={() => handleClick('3')} className="p-6 text-lg rounded-md bg-gray-100 hover:bg-gray-300">3</button>
                <button onClick={() => handleClick('+')} className="p-6 text-lg rounded-md bg-gray-100 hover:bg-gray-300">+</button>

                <button onClick={() => handleClick('4')} className="p-6 text-lg rounded-md bg-gray-100 hover:bg-gray-300">4</button>
                <button onClick={() => handleClick('5')} className="p-6 text-lg rounded-md bg-gray-100 hover:bg-gray-300">5</button>
                <button onClick={() => handleClick('6')} className="p-6 text-lg rounded-md bg-gray-100 hover:bg-gray-300">6</button>
                <button onClick={() => handleClick('-')} className="p-6 text-lg rounded-md bg-gray-100 hover:bg-gray-300">-</button>

                <button onClick={() => handleClick('7')} className="p-6 text-lg rounded-md bg-gray-100 hover:bg-gray-300">7</button>
                <button onClick={() => handleClick('8')} className="p-6 text-lg rounded-md bg-gray-100 hover:bg-gray-300">8</button>
                <button onClick={() => handleClick('9')} className="p-6 text-lg rounded-md bg-gray-100 hover:bg-gray-300">9</button>
                <button onClick={() => handleClick('*')} className="p-6 text-lg rounded-md bg-gray-100 hover:bg-gray-300">*</button>

                <button onClick={handleClear} className="p-6 text-lg text-white rounded-md submit">C</button>
                <button onClick={() => handleClick('0')} className="p-6 text-lg rounded-md bg-gray-100 hover:bg-gray-300">0</button>
                <button onClick={handleCalculate} className="p-6 text-lg rounded-md bg-gray-100 hover:bg-gray-200">=</button>
                <button onClick={() => handleClick('/')} className="p-6 text-lg rounded-md bg-gray-100 hover:bg-gray-300">/</button>
            </div>
        </div>
    );
};

export default Calculator;
