import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { handleFindProductBySearch, handleProductSubmit } from '../pos/utils/searchProduct';

function ViewProductsBody() {
    // State variables
    const [productData, setProductData] = useState([]);
    const [code, setKeyword] = useState('');
    const [searchedProduct, setSearchedProduct] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const ProductIcon = 'https://cdn0.iconfinder.com/data/icons/creative-concept-1/128/PACKAGING_DESIGN-512.png';

    // Fetch all product data
    useEffect(() => {
        const fetchUnitData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/findAllProduct`);
                console.log('Fetched product data:', response.data);

                // Handle the response format with 'products' key
                if (response.data && response.data.products && Array.isArray(response.data.products)) {
                    setProductData(response.data.products);
                    console.log(response.data.products)
                } else {
                    console.error('Unexpected response format:', response.data);
                    setProductData([]);
                    setError('Failed to load all products. Please try again later.');
                }
            } catch (error) {
                console.error('Fetch product data error:', error);
                setProductData([]);
            } finally {
                setLoading(false);
            }
        };

        fetchUnitData();
    }, []);

    // Handle delete unit from full list
    const handleDelete = async (_id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/deleteProduct/${_id}`);
            setProductData(productData.filter(p => p._id !== _id));
        } catch (error) {
            console.error('Delete product error:', error);
            setError('Error deleting the product.');
        }
    };

    // Search function
    const searchProducts = async (code) => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/routes/findOneProduct`);
            // Handle the response format with 'product' key
            if (response.data && response.data.product) {
                setSearchedProduct([response.data.product]);
            } else {
                console.error('Unexpected response format:', response.data);
                setSearchedProduct([]);
            }
        } catch (error) {
            console.error('Find product error:', error);
            setSearchedProduct([]);
            setError('Failed to find the product.');
        } finally {
            setLoading(false);
        }

    };

    // Perform the search
    const performSearch = (e) => {
        e.preventDefault();
        searchProducts(code);
    };

    // Calculate price range (min and max) for products with variations
    const getPriceRange = (product) => {
        if (product.variationValues) {
            // Get prices from variationValues
            const prices = Object.values(product.variationValues).map(variation => Number(variation.productPrice)).filter(price => !isNaN(price));

            if (prices.length > 0) {
                const minPrice = Math.min(...prices);
                const maxPrice = Math.max(...prices);

                // If all variation prices are the same, return the single price
                if (minPrice === maxPrice) {
                    return `${minPrice}`;
                } else {
                    return `${minPrice} - ${maxPrice}`;
                }
            }
        }
        // Fallback to single product price if no variations are available
        const singlePrice = Number(product.productPrice);
        if (!isNaN(singlePrice) && singlePrice > 0) {
            return `${singlePrice}`;
        }
        return 'Price not available'; // Default case when no price is found
    };
    // Calculate price range (min and max) for products with variations
    const getQty = (product) => {
        if (product.variationValues) {
            const qty = Object.values(product.variationValues).map(variation => Number(variation.productQty)).filter(qty => !isNaN(qty));
            return qty.length > 0 ? qty.reduce((total, current) => total + current, 0) : 0;
        }
        const singleProductQty = Number(product.productQty);
        return !isNaN(singleProductQty) && singleProductQty > 0 ? singleProductQty : 0;
    };

    // Show the popup with selected product
    const handleViewProduct = (product) => {
        setSelectedProduct(product);
        setShowPopup(true);
    };

    // Close the popup
    const closePopup = () => {
        setShowPopup(false);
        setSelectedProduct(null);
    };

    // Render variation details
    const renderVariationDetails = (variationValues) => {
        if (!variationValues || Object.keys(variationValues).length === 0) {
            return <p className="text-gray-700 ">No Variations</p>;
        }
        return Object.entries(variationValues).map(([key, value]) => (
            <div key={key} className="mt-5">
                <div className='flex ml-10 text-gray-700'>
                    <p className='text-left mr-10'><strong className='text-left mr-10'>Variation Type</strong><br /> {key}</p>
                    <p className='text-left mr-10'><strong className='text-left mr-10'>Price</strong><br /> {value.productPrice}{' '}Rs</p>
                    <p className='text-left mr-10'><strong className='text-left mr-10'>Quantity</strong><br /> {productData.productQty ? productData.productQty : value.productQty}</p>
                    <p className='text-left mr-10'><strong className='text-left mr-10'>Code</strong> <br />{value.code}</p>
                </div>
            </div>
        ));
    };

    return (
        <div className='relative bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] min-h-[100vh] p-5'>
            <div className='flex justify-between mb-4'>
                <div className="relative w-full max-w-md">
                    <form onSubmit={performSearch} className="flex items-center">
                        <input
                            name='keyword'
                            type="text"
                            placeholder="Search..."
                            className="searchBox w-80 pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-transparent"
                            value={code}
                            onChange={(e) => handleFindProductBySearch(e, setKeyword, (keyword) => handleProductSubmit(keyword, setLoading, setSearchedProduct))}
                        />
                        <button type="submit" className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-400">
                            <svg
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M9 3a6 6 0 100 12A6 6 0 009 3zm0-1a7 7 0 110 14A7 7 0 019 2z"
                                    clipRule="evenodd"
                                />
                                <path
                                    fillRule="evenodd"
                                    d="M12.9 12.9a1 1 0 011.41 0l3 3a1 1 0 01-1.41 1.41l-3-3a1 1 0 010-1.41z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </form>
                </div>
                <div className="flex items-center">
                    <div>
                        <Link
                            to={'/createProduct'}
                            className="submit flex-none rounded-md px-4 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-60 text-center"
                        >
                            Create Product
                        </Link>
                    </div>
                </div>
            </div>

            {loading ? (
                <Box sx={{ width: '100%', position: "absolute", top: "0", left: "0", margin: "0", padding: "0" }}>
                    <LinearProgress />
                </Box>
            ) : searchedProduct.length > 0 ? (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product</th>
                                <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Code</th>
                                <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Brand</th>
                                <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                                <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Unit</th>
                                <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">In Stock</th>
                                <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created On</th>
                                <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 text-left">
                            {searchedProduct.map((p) => (
                                <tr key={p._id}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <img
                                            src={p.image ? `${p.image}` : ProductIcon}
                                            alt={p.name}
                                            className="w-10 h-10 object-cover rounded-full"
                                        />
                                    </td>
                                    <td className="px-7 py-5 whitespace-nowrap text-m text-gray-900 text-left">{p.name}</td>
                                    <td className="px-7 py-5 whitespace-nowrap text-m text-gray-900 text-left">{p.code}</td>
                                    <td className="px-4 py-5 whitespace-nowrap text-m text-gray-900 text-left">{p.brand}</td>
                                    <td className="px-7 py-5 whitespace-nowrap text-m text-gray-900 text-left">{getPriceRange(p)}</td>
                                    <td className="px-7 py-5 whitespace-nowrap text-m text-gray-900 text-left">{p.saleUnit}</td>
                                    <td className="px-7 py-5 whitespace-nowrap text-m text-gray-900 text-left">{p.productQty ? p.productQty : getQty(p)}</td>
                                    <td className="px-7 py-5 whitespace-nowrap text-m text-gray-900 text-left">{p.createdAt}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                                        <div className='flex items-center justify-end'>
                                            <button
                                                className="text-green-500 hover:text-green-700 font-bold py-1 px-2 mr-2 text-lg"
                                                onClick={() => handleViewProduct(p)}
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-eye mr-1"></i>
                                            </button>
                                            <Link to={`/editProduct/${p._id}`}
                                                className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2 text-lg"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-edit mr-1"></i>
                                            </Link>
                                            <button
                                                onClick={() => handleDelete(p._id)}
                                                className="text-red-500 hover:text-red-700 font-bold py-1 px-2 text-lg"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-trash"></i>
                                            </button>
                                        </div>

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product</th>
                                <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Code</th>
                                <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Brand</th>
                                <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                                <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Unit</th>
                                <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">In Stock</th>
                                <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created On</th>
                                <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {productData.map((p) => (
                                <tr key={p._id}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <img
                                            src={p.image ? p.image : ProductIcon}
                                            alt={p.name}
                                            className="w-10 h-10 object-cover rounded-full"
                                        />
                                    </td>
                                    <td className="px-7 py-5 whitespace-nowrap text-m text-gray-900 text-left">{p.name}</td>
                                    <td className="px-7 py-5 whitespace-nowrap text-m text-gray-900 text-left">{p.code}</td>
                                    <td className="px-4 py-5 whitespace-nowrap text-m text-gray-900 text-left">{p.brand}</td>
                                    <td className="px-7 py-5 whitespace-nowrap text-m text-gray-900 text-left">{getPriceRange(p)}</td>
                                    <td className="px-7 py-5 whitespace-nowrap text-m text-gray-900 text-left">{p.saleUnit}</td>
                                    <td className="px-7 py-5 whitespace-nowrap text-m text-gray-900 text-left">{p.productQty ? p.productQty : getQty(p)}</td>
                                    <td className="px-7 py-5 whitespace-nowrap text-m text-gray-900 text-left">{p.createdAt}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                                        <div className='flex items-center justify-end'>
                                            <button
                                                className="text-green-500 hover:text-green-700 font-bold py-1 px-2 mr-2 text-lg"
                                                onClick={() => handleViewProduct(p)}
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-eye mr-1"></i>
                                            </button>
                                            <Link to={`/editProduct/${p._id}`}
                                                className="text-blue-500 hover:text-blue-700 font-bold py-1 px-2 mr-2 text-lg"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-edit mr-1"></i>
                                            </Link>
                                            <button
                                                onClick={() => handleDelete(p._id)}
                                                className="text-red-500 hover:text-red-700 font-bold py-1 px-2 text-lg"
                                                style={{ background: 'transparent' }}
                                            >
                                                <i className="fas fa-trash"></i>
                                            </button>
                                        </div>

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            {/* Popup for viewing product details */}
            {showPopup && selectedProduct && (
                <div className="fixed inset-0  flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-10 mt-20 rounded-lg max-h-[80vh]  overflow-y-auto shadow-lg w-1/2 text-gray-700">
                        <div className='flex justify-between items-center w-full'>
                            <h2 className="text-2xl font-semibold mb-2">Product Details</h2>
                            <button onClick={closePopup} className=" text-white px-2 py-2 rounded">
                                <img className='w-[25px] h-[24px]' src={'https://th.bing.com/th/id/OIP.Ej48Pm2kmEsDdVNyEWkW0AHaHa?rs=1&pid=ImgDetMain'} alt='close' />
                            </button>
                        </div>
                        <p className='mt-5 text-left'><strong className='mr-2'>Name:</strong> {selectedProduct.name}</p>
                        <p className='mt-5 text-left'><strong className='mr-2'>Code:</strong> {selectedProduct.code}</p>
                        <p className='mt-5 text-left'><strong className='mr-2'>Category:</strong> {selectedProduct.category}</p>
                        <p className='mt-5 text-left'><strong className='mr-2'>Brand:</strong> {selectedProduct.brand}</p>
                        <p className='mt-5 text-left'><strong className='mr-2'>Price:</strong> {getPriceRange(selectedProduct)}</p>
                        <p className='mt-5 text-left'><strong className='mr-2'>Purchase Unit:</strong> {selectedProduct.purchase}</p>
                        <p className='mt-5 text-left'><strong className='mr-2'>Sale Unit:</strong> {selectedProduct.saleUnit}</p>
                        <p className='mt-5 text-left'><strong className='mr-2'>In Stock:</strong> {selectedProduct.productQty ? selectedProduct.productQty : getQty(selectedProduct)}</p>
                        <p className='mt-5 text-left'><strong className='mr-2'>Created On:</strong> {new Date(selectedProduct.createdAt).toLocaleDateString()}</p>
                        <div className=" mt-5 mb-4">
                            <h3 className="forgotPass text-lg font-semibold mb-2">Variation Details</h3>
                            {renderVariationDetails(selectedProduct.variationValues)}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ViewProductsBody;
