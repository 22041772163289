import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CamIcon from '../../../img/icons8-camera-100.png';

function EditBrandBody() {
    const { id } = useParams();

    const [brandData, setBrandData] = useState({ brandName: '', logo: '' });
    const [logo, setLogo] = useState(null);
    const [logoPreview, setLogoPreview] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        const fetchBrandDetails = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/getBrandForUpdate/${id}`);
                setBrandData(response.data);

                if (response.data.logo) {
                    setLogoPreview(response.data.logo);
                }
            } catch (error) {
                console.error('Error fetching brand details:', error);
                setError('Failed to load brand details.');
            } finally {
                setLoading(false);
            }
        };

        fetchBrandDetails();
    }, [id]);

    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        setLogo(file);
        setError('');

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogoPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleBrandNameChange = (e) => {
        setBrandData((prevData) => ({
            ...prevData,
            brandName: e.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess('');

        const formDataToUpdate = new FormData();
        formDataToUpdate.append('brandName', brandData.brandName);
        if (logo) {
            formDataToUpdate.append('logo', logo);
        }

        for (let [key, value] of formDataToUpdate.entries()) {
            console.log("TO UPDATE ", key, value);
        }

        try {
            const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/updateBrand/${id}`, formDataToUpdate, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            if (response.data.status === 'success') {
                setSuccess('Brand updated successfully!');
            } else {
                setError('Failed to update base unit');
            }
            console.log('Update response:', response.data); // Check response
        } catch (error) {
            console.error('Error updating brand:', error);
            setError('Failed to update brand.');
        } finally {
            setLoading(false);
        }
    };

    // Handle clear operation
    const handleClear = () => {
        setBrandData({brandName:''});
        setLogoPreview(null);
        setError('');
        setSuccess('');
    };

    return (
        <div className="bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] h-[800px] p-5">
            <div className="flex justify-between items-center">
                <div>
                    <h2 className="text-lightgray-300 m-0 p-0 text-2xl">Edit Brands</h2>
                </div>
            </div>
            {loading && (
                <Box sx={{ width: '100%', position: 'absolute', top: '0', left: '0', margin: '0', padding: '0' }}>
                    <LinearProgress />
                </Box>
            )}
            <div className="bg-white mt-[20px] w-[630px] h-[600px] rounded-2xl px-8 shadow-md">
                <div className="flex min-h-full flex-1 flex-col px-2 py-12 lg:px-8">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                        <div className="mb-4">
                            <label htmlFor="brandName" className="mb-2 text-left block text-sm font-medium text-gray-700">Brand Name</label>
                            <input
                                type="text"
                                id="brandName"
                                value={brandData.brandName}
                                onChange={handleBrandNameChange}
                                className="block w-[500px] rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                            />
                        </div>
                        <div className="mb-4 relative">
                            <label htmlFor="logo" className="block text-sm text-left font-medium text-gray-700">Logo</label>
                            <div className="mt-2 relative">
                                <input
                                    id="logo"
                                    name="logo"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleLogoChange}
                                    className="hidden"
                                />
                                <button
                                    type="button"
                                    onClick={() => document.getElementById('logo').click()}
                                    className={`block w-[100px] h-[100px] rounded-md border-0 py-2.5 px-2.5 shadow-sm ring-1 ring-inset ring-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6 ${logoPreview ? 'bg-cover bg-center' : 'bg-gray-200 opacity-70 hover:bg-gray-300'}`}
                                    style={{ backgroundImage: logoPreview ? `url(${logoPreview})` : `url(${brandData.logo})` }}
                                >
                                    {!logoPreview && !brandData.logo && <img src={CamIcon} alt="cam" className="ml-5 w-10 h-10" />}
                                </button>
                            </div>
                        </div>

                        <div className="container mx-auto text-left">
                            <div className='mt-10 flex justify-start'>
                                <button type='submit' className="saveBtn flex-none rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 w-[100px] text-center focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                                    Save
                                </button>
                                <button
                                    type="button"
                                    className="inline-flex ml-2 justify-center rounded-md bg-gray-600 py-2.5 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 w-[100px]  focus:ring-gray-500 focus:ring-offset-2"
                                    onClick={handleClear}
                                >
                                    Clear
                                </button>
                            </div>
                        </div>

                        {/* Error and Response Messages */}
                        <div className='mt-10'>
                            {error && (
                                <p className="text-red-600 px-5 py-2 rounded-md bg-red-100 mt-5 text-center mx-auto max-w-sm">
                                    {error}
                                </p>
                            )}
                            {success && (
                                <p className="text-green-600 px-5 py-2 rounded-md bg-green-100 mt-5 text-center  mx-auto max-w-sminline-block">
                                    {success}
                                </p>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditBrandBody;
