import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import '../../styles/dashboardBody.css';

function EditProfileByAdmin() {
    const { id } = useParams();
    const [formData, setFormData] = useState({
        username: '',
        firstName: '',
        lastName: '',
        role: '',
        mobile: '',
        profileImage: ''
    });
    const [errors, setErrors] = useState('');
    const [jobRoles, setJobRoles] = useState([]);
    const [responseMessage, setResponseMessage] = useState('');

    // Fetch job roles on component mount
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/getJobRoles`)
            .then(response => {
                setJobRoles(response.data.jobRoles);
            })
            .catch(error => {
                console.error('Error fetching job roles:', error);
                setErrors(prevErrors => ({ ...prevErrors, general: 'Failed to fetch job roles.' }));
            });
    }, []);

    // Fetch user data when component mounts
    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/fetchUsers`, {
                    params: { id } // Pass id as a query parameter
                });

                const fetchedData = response.data;

                // If profileImage is missing or empty, set it to an empty string
                if (!fetchedData.profileImage) {
                    fetchedData.profileImage = '';
                }

                setFormData(fetchedData);
            } catch (error) {
                console.error('Error fetching user data:', error);
                setErrors(prevErrors => ({ ...prevErrors, general: 'Failed to fetch user data.' }));
            }
        };

        fetchProfile();
    }, [id]);

    // Handle input field values
    const handleChange = (e) => {
        setErrors('')
        setResponseMessage('')
        const { name, value } = e.target;
        let updatedFormData = { ...formData, [name]: value };

        // Mobile number validation
        if (name === 'mobile') {
            const mobileRegex = /^\+94\d{9}$/;  // This ensures the format +94xxxxxxxxx
            if (!mobileRegex.test(value)) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    mobile: 'Invalid mobile number. Format: +94xxxxxxxxx'
                }));
            } else {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    mobile: ''  // Clear error if valid
                }));
            }
        }

        // Email validation for username field
        if (name === 'username') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value) || !value.endsWith('@gmail.com')) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    username: 'Username must be a valid email address with @gmail.com'
                }));
            } else {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    username: ''  // Clear error if valid
                }));
            }
        }
        setFormData(updatedFormData);
    };


    // Handle submit
    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors('')
        setResponseMessage('')
        if (errors.mobile || errors.role || errors.username) {
            alert('Please fix the errors before submitting.');
            return;
        }

        const formDataToSubmit = {
            id,
            username: formData.username,
            firstName: formData.firstName,
            lastName: formData.lastName,
            role: formData.role,
            mobile: formData.mobile
        };

        axios.put(`${process.env.REACT_APP_BASE_URL}/api/updateUser`, formDataToSubmit)
            .then(response => {
                console.log('User updated successfully:', response.data);
                setResponseMessage("Successfully updated the user");
            })
            .catch(error => {
                console.error('Error updating user:', error);
                setResponseMessage("Unsuccessfully updated the user");
            });
    };

    // Clear all fields except the profileImage
    const handleCancel = () => {
        setFormData(prevData => ({
            ...prevData,
            username: '',
            firstName: '',
            lastName: '',
            role: '',
            mobile: ''
        }));
        setErrors('');
        setResponseMessage('')
    };
    const defaultAvatar = 'https://jingslearning.com/media/images/login-user-head.png';
    return (
        <div className='bg-[#eff3f7] absolute top-[80px] left-[18%] w-[82%] h-[900px] p-5'>
            <div className='flex justify-between items-center'>
                <div>
                    <h2 className="text-lightgray-300 ml-4 m-0 p-0 text-2xl">Edit user</h2>
                </div>
                <div>
                    <Link className='px-4 py-1.5 border border-[#37b34a] text-[#37b34a] rounded-md transition-colors duration-300 hover:bg-[#37b34a] hover:text-white' to={'/users'}>Back</Link>
                </div>
            </div>
            <div className="bg-white mt-[20px] w-full h-[800px] rounded-2xl px-8 shadow-md">
                <div className="flex min-h-full flex-1 flex-col px-2 py-12 lg:px-8">
                    <form onSubmit={handleSubmit}>
                        <div className="flex items-center justify-center h-20 mt-20 relative">
                            <div>
                                <div>
                                    <img
                                        style={{ width: "140px", height: "140px" }}
                                        className="rounded-full"
                                        alt="Profile"
                                        src={formData.profileImage || defaultAvatar}
                                        onError={(e) => { e.target.src = defaultAvatar; }}
                                    />

                                </div>
                            </div>
                        </div>
                        <div className="flex space-x-6 mt-10" style={{ padding: '40px' }}>
                            <div className="flex-1 space-y-6">
                                <div>
                                    <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                        First name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="firstName"
                                            name="firstName"
                                            type="text"
                                            required
                                            value={formData.firstName}
                                            onChange={handleChange}
                                            placeholder='Alex'
                                            className="pass block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                        Last Name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="lastName"
                                            name="lastName"
                                            type="text"
                                            required
                                            value={formData.lastName}
                                            onChange={handleChange}
                                            placeholder='Boult'
                                            className="pass block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                {/* Role field */}
                                <div className="mt-5">
                                    <label className="block text-sm font-medium leading-6 text-gray-900 text-left">Select user role</label>
                                    <select
                                        id="role"
                                        name="role"
                                        value={formData.role}
                                        onChange={handleChange}
                                        className="block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                    >
                                        <option value="#">#Select Role</option>
                                        {jobRoles.map((jobRole) => (
                                            <option key={jobRole._id} value={jobRole.roleName}>{jobRole.roleName}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="flex space-x-4 mt-5">
                                    <button
                                        type="submit"
                                        className="saveBtn inline-flex justify-center rounded-md bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        Save
                                    </button>
                                    <button
                                        onClick={handleCancel}
                                        type="button"
                                        className="inline-flex justify-center rounded-md bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                    >
                                        Clear
                                    </button>
                                </div>
                            </div>

                            <div className="flex-1 space-y-6">
                                <div>
                                    <label htmlFor="mobile" className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                        Mobile number
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="mobile"
                                            name="mobile"
                                            type="tel"
                                            required
                                            value={formData.mobile}
                                            onChange={handleChange}
                                            placeholder='+94xxxxxxxxx'
                                            className="pass block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                        />
                                        {errors.mobile && <p className="text-red-600 text-sm mt-1">{errors.mobile}</p>}
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                        Username
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="username"
                                            name="username"
                                            type="email"
                                            required
                                            value={formData.username}
                                            onChange={handleChange}
                                            placeholder='example@gmail.com'
                                            className="pass block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Display multiple error messages in one block */}
                        {(errors.username || errors.mobile) && (
                            <div className="text-red-600 px-5 py-2 rounded-md bg-red-100 mt-5 text-center mx-auto max-w-sm">
                                {errors.username && (
                                    <p>{errors.username}</p>
                                )}
                                {errors.mobile && (
                                    <p>{errors.mobile}</p>
                                )}
                            </div>
                        )}

                        {responseMessage && (
                            <p className="text-green-600 px-5 py-2 rounded-md bg-green-100 mt-5 text-center mx-auto max-w-sm">
                                {responseMessage}
                            </p>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditProfileByAdmin;
